import React from 'react';

import {
    VStack, Container, Heading, Image, Flex,
} from '@chakra-ui/react';
import House from '../assets/Horizontal LOGO.webp';
import { useNavigate } from 'react-router-dom';


function CheckEmail() {
    const navigate = useNavigate();
    const handleGoHome = () => {
        navigate('/');
    };

    return (
        <Container maxH={"100vh"} overflowY={"auto"} maxW="xl" mt={10}>
            <VStack w={"100%"} as="form" spacing={6} justify={"center"} align="stretch">
                <Flex w={"100%"} justify={"center"}>
                    <Image onClick={handleGoHome} src={House} maxH={"300px"} w={"fit-content"} />
                </Flex>
                <Heading as="h1" size="xl" color={"gray.700"} textAlign="center">
                    Success! Please Verify Email
                </Heading>
            </VStack>
        </Container>
    );
}

export default CheckEmail;