import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { Box, CircularProgress, Text, VStack, Heading, Icon, Button } from '@chakra-ui/react';
import { CheckCircleIcon, WarningIcon } from '@chakra-ui/icons';

function EmailVerification() {
    const location = useLocation();
    const navigate = useNavigate();
    const [verificationMessage, setVerificationMessage] = useState('Verifying your email...');
    const [isLoading, setIsLoading] = useState(true);
    const [isSuccess, setIsSuccess] = useState(false);
    const [countdown, setCountdown] = useState(3);

    useEffect(() => {
        const queryParams = new URLSearchParams(location.search);
        const token = queryParams.get('token');

        if (token) {
            axios.get(`${process.env.REACT_APP_API_URL}/api/verify-email?token=${token}`)
                .then(response => {
                    setIsLoading(false);
                    setVerificationMessage(response.data.message);
                    setIsSuccess(!response.data.error);

                    if (!response.data.error) {
                        const timer = setInterval(() => {
                            setCountdown((prevCountdown) => prevCountdown - 1);
                        }, 1000);

                        setTimeout(() => {
                            navigate('/login');
                        }, 3000);

                        return () => clearInterval(timer);
                    }
                })
                .catch(error => {
                    setIsLoading(false);
                    setVerificationMessage('Verification failed. Please try again.');
                });
        } else {
            setIsLoading(false);
            setVerificationMessage('Invalid request. Please ensure you have a valid verification link.');
        }
    }, [location.search, navigate]);

    return (
        <VStack spacing={8} justify="center" align="center" height="100vh" bg="gray.100">
            <Box textAlign="center">
                <Icon as={isSuccess ? CheckCircleIcon : WarningIcon} boxSize={16} color={isSuccess ? 'green.500' : 'red.500'} mb={4} />
                <Heading size="xl" mb={4}>
                    {isSuccess ? 'Email Verified!' : 'Verification Failed'}
                </Heading>
                {isLoading ? (
                    <CircularProgress isIndeterminate color="teal.300" />
                ) : (
                    <Text fontSize="xl">{verificationMessage}</Text>
                )}
                {isSuccess && (
                    <Text fontSize="lg" mt={4}>
                        Redirecting you in {countdown} seconds...
                    </Text>
                )}
            </Box>
            {!isLoading && !isSuccess && (
                <Button colorScheme="teal" size="lg" onClick={() => navigate('/login')}>
                    Go to Sign Up
                </Button>
            )}
        </VStack>
    );
}

export default EmailVerification;