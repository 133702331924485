import React from 'react';
import { Box, HStack, useRadio, useRadioGroup } from '@chakra-ui/react';
import { FaHome, FaHammer } from 'react-icons/fa';

function RadioCard(props) {
  const { getInputProps, getCheckboxProps } = useRadio(props);

  const input = getInputProps();
  const checkbox = getCheckboxProps();

  return (
    <Box as='label'>
      <input {...input} />
      <Box
        {...checkbox}
        display='flex'
        alignItems='center'
        justifyContent='center'
        borderWidth='2px'
        borderRadius='md'
        boxShadow='md'
        bg='white'
        _checked={{
          color: 'black',
          border: "2px",
        }}
        _focus={{
          border: "2px",
        }}
        cursor={"pointer"}
        px={5}
        py={3}
      >
        {props.children}
      </Box>
    </Box>
  );
}

function RoleSelector({ value, onChange }) {
  const options = [
    { value: 'Home Owner', label: 'Homeowner', icon: FaHome },
    { value: 'Contractor', label: 'Contractor', icon: FaHammer },
  ];

  const { getRootProps, getRadioProps } = useRadioGroup({
    name: 'role',
    defaultValue: value,
    onChange: onChange,
  });

  const group = getRootProps();

  return (
    <HStack w={"100%"} justify={"center"} {...group}>
      {options.map((option) => {
        const radio = getRadioProps({ value: option.value });
        return (
          <RadioCard key={option.value} {...radio}>
            <Box as={option.icon} mr={2} />
            {option.label}
          </RadioCard>
        );
      })}
    </HStack>
  );
}

export default RoleSelector;