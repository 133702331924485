import { Box, Flex, Text, keyframes } from '@chakra-ui/react';
import { useState, useEffect } from 'react';

const flashAnimation = keyframes`
  0% { opacity: 1; }
  50% { opacity: 0; }
  100% { opacity: 1; }
`;

const RapidResponseAlert = () => {
    const [isBlinking, setIsBlinking] = useState(true);

    useEffect(() => {
        const timer = setTimeout(() => {
            setIsBlinking(false);
        }, 10000); // Stop blinking after 15 seconds (15000 milliseconds)

        return () => {
            clearTimeout(timer);
        };
    }, []);

    return (
        <Flex
            justify={"flex-end"}
            position="sticky"
            mr={12}
            zIndex={4}
            mt={-5}
            h={"100%"}
            w={"100%"}
        >
            <Box
                as="a"
                h={"fit-content"}
                bg={"red.500"}
                p={2}
                href="/rapid-response"
                color="white"
                borderRadius="md"
                zIndex={4}
                fontWeight="bold"
                animation={isBlinking ? `${flashAnimation} 2s ease-in-out infinite` : 'none'}
                _hover={{ textDecoration: 'none' }}
            >
                <Text mt={2}>Rapid Response</Text>
            </Box>
        </Flex>
    );
};

export default RapidResponseAlert;