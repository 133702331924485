import React, { useEffect, useState, useRef, useMemo, useLayoutEffect } from 'react';
import { useChat } from '../contexts/ChatContext';
import moment from 'moment';
import {
    Box, Button, Input, VStack, HStack, Avatar, Text,
    InputGroup, InputRightElement, Flex, useToast, Heading, Link, IconButton, useColorModeValue, useBreakpointValue
} from '@chakra-ui/react';
import axios from 'axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowDownLong, faCalendarAlt, faCalendarXmark, faCircleCheck, faComments, faFileCircleCheck, faFileInvoice, faFileInvoiceDollar, faHouseCircleCheck, faMoneyBill1, faMoneyBillWave, faPaperPlane, faPaperclip, faThumbtack } from '@fortawesome/free-solid-svg-icons';

const MessageComponent = ({ projectId, conversationId, socket, isDisabled }) => {
    const { isChatOpen, toggleChat } = useChat();
    const [messages, setMessages] = useState([]);
    const [unread, setUnread] = useState(false);
    const menuType = useBreakpointValue({ base: 'drawer', lg: 'regular' });
    const [newMessage, setNewMessage] = useState('');
    const [selectedFile, setSelectedFile] = useState(null);
    const [sending, setSending] = useState(false);
    const [filename, setFilename] = useState('');
    const [displayName, setDisplayName] = useState('');
    const [typingUsers, setTypingUsers] = useState({});
    const [typing, setTyping] = useState(false); // Define typing state to track if the local user is typing
    const typingTimeout = useRef(null); // Use useRef to hold a reference to the timeout
    const userId = localStorage.getItem("id");
    const messageRefs = useRef({});
    const endOfMessagesRef = useRef(null);
    const toast = useToast();

    useEffect(() => {
        if (socket) {
            socket.emit('join-room', conversationId);

            socket.on('receive-message', (data) => {
                setMessages(prevMessages => [...prevMessages, data]);
                if (!isChatOpen) {
                    setUnread(true);
                }
            });
            socket.on('existing-messages', (existingMessages) => {
                setMessages(existingMessages);
            });
            socket.on('file-uploaded', (fileData) => {
                setMessages(prevMessages => prevMessages.map(msg =>
                    msg.conversationId === fileData.conversationId ? { ...msg, attachments: [...(msg.attachments || []), { url: fileData.url, filename: fileData.filename }] } : msg
                ));
            });
            socket.on('update-message', (updatedMessage) => {
                setMessages((currentMessages) => currentMessages.map(msg =>
                    msg._id === updatedMessage._id ? updatedMessage : msg
                ));
            });

            return () => {
                socket.off('receive-message');
                socket.off('existing-messages');
                socket.off('file-uploaded');
                socket.off('update-message');
            };
        }
    }, [conversationId, socket, isChatOpen]);

    useEffect(() => {
        if (isChatOpen) {
            setUnread(false);
        }
    }, [isChatOpen]);

    useEffect(() => {
        socket.on('message-read-by-update', (updatedMessage) => {
            setMessages(currentMessages => currentMessages.map(msg =>
                msg._id === updatedMessage._id ? updatedMessage : msg
            ));
        });

        return () => socket.off('message-read-by-update');
    }, [socket]);

    useEffect(() => {
        socket.on('update-message', (updatedMessage) => {
            setMessages(currentMessages => currentMessages.map(msg =>
                msg._id === updatedMessage._id ? { ...msg, ...updatedMessage } : msg
            ));
        });

        return () => socket.off('update-message');
    }, [socket]);

    useEffect(() => {
        const handleTyping = ({ userId, typing, firstName }) => {

            setTypingUsers(prev => ({
                ...prev,
                [userId]: { typing, firstName }
            }));
        };

        if (socket) {
            socket.on('user-typing', handleTyping);

            return () => socket.off('user-typing', handleTyping);
        }
    }, [socket]);

    useEffect(() => {
        const messagesContainer = document.querySelector('.messages-container');
        if (messagesContainer) {
            messagesContainer.scrollTop = messagesContainer.scrollHeight;
        }

        const userId = localStorage.getItem("id");
        const firstName = localStorage.getItem("firstName");

        const observer = new IntersectionObserver((entries) => {
            entries.forEach(entry => {
                if (entry.isIntersecting) {
                    const messageId = entry.target.getAttribute('data-message-id');
                    // Retrieve the message from state using messageId
                    const message = messages.find(m => m._id === messageId);

                    // Check if the message has been read by this user
                    const hasRead = message.readBy.some(entry => entry.userId === userId);
                    if (messageId && !hasRead) {
                        // Mark as read in the local state to prevent duplicate emits
                        const updatedMessages = messages.map(msg => {
                            if (msg._id === messageId) {
                                return {
                                    ...msg,
                                    readBy: [...msg.readBy, { userId, firstName, readAt: new Date() }]
                                };
                            }
                            return msg;
                        });
                        setMessages(updatedMessages);

                        // Emit the event to the server
                        socket.emit('mark-message-as-read', { messageId, userId, conversationId, firstName });
                    }
                }
            });
        }, { threshold: 0.1 });

        const elements = document.querySelectorAll('[data-message-id]');
        elements.forEach(el => observer.observe(el));

        return () => {
            observer.disconnect();
        };
    }, [messages, socket, userId, conversationId]);

    const handleInputChange = (e) => {
        setNewMessage(e.target.value);

        if (!typing) {
            setTyping(true);
            socket.emit('start-typing', { conversationId });
        }

        clearTimeout(typingTimeout.current);
        typingTimeout.current = setTimeout(() => {
            setTyping(false);
            socket.emit('stop-typing', { conversationId });
        }, 2000);
    };

    const typingIndicator = useMemo(() => {
        const activeTypers = Object.entries(typingUsers)
            .filter(([id, { typing }]) => typing && id !== userId)
            .map(([_, { firstName }]) => firstName);

        if (activeTypers.length > 0) {
            return `${activeTypers.join(", ")} is typing...`;
        }
        return "";
    }, [typingUsers, userId]);

    const bgColorUser = useColorModeValue('gray.700', 'gray.700');
    const bgColorOther = useColorModeValue('gray.600', 'gray.600');
    const textColor = useColorModeValue('whiteAlpha.800', 'whiteAlpha.800');

    const togglePin = (msg) => {
        const optimisticUpdatedMsg = { ...msg, pinned: !msg.pinned };
        setMessages(currentMessages => currentMessages.map(currentMsg =>
            currentMsg._id === msg._id ? optimisticUpdatedMsg : currentMsg
        ));
        socket.emit('toggle-pin', { messageId: msg._id, pinned: !msg.pinned, conversationId });
    };

    useEffect(() => {
        socket.on('update-message', (updatedMessage) => {
            setMessages(currentMessages => currentMessages.map(msg =>
                msg._id === updatedMessage._id ? { ...msg, ...updatedMessage } : msg
            ));
        });

        return () => socket.off('update-message');
    }, [socket]);

    const scrollToMessage = (id) => {
        messageRefs.current[id]?.scrollIntoView({ behavior: 'smooth' });
    };

    useLayoutEffect(() => {
        if (isChatOpen && endOfMessagesRef.current) {
            endOfMessagesRef.current.scrollIntoView({ behavior: 'smooth' });
        }
    }, [messages, isChatOpen]);

    function truncateText(text, limit = 45) {
        return text.length > limit ? text.substring(0, limit) + "..." : text;
    }

    const handleFileChange = async (event) => {
        const file = event.target.files[0];
        if (file) {
            setSelectedFile(file);
            setFilename(`${userId}-${projectId}-${Date.now()}-${file.name}`);
            setDisplayName(`${file.name}`);
        }
    };

    const uploadFile = async (file) => {
        const formData = new FormData();
        formData.append('file', file);
        formData.append('filename', filename);
        formData.append('conversationId', conversationId);

        try {
            const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/upload`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            });
            return response.data;  // The response should contain the URL of the uploaded file
        } catch (error) {
            console.error('Error uploading file:', error);
            toast({
                title: "Error uploading file.",
                description: error.message,
                status: "error",
                duration: 9000,
                isClosable: true,
            });
        }
    };

    const sendMessage = async () => {
        if (newMessage.trim() !== '' || selectedFile) { // Check if there is a message or a file
            const sender = userId;
            const avatar = "path/to/avatar.jpg"; // Replace with actual path or logic to fetch user's avatar
            let attachments = []; // Initialize attachments as an empty array

            if (selectedFile) {
                setSending(true);
                const uploadedFile = await uploadFile(selectedFile);
                if (uploadedFile && uploadedFile.url) {
                    attachments.push({
                        filename: selectedFile.name,
                        url: uploadedFile.url,
                        mimeType: selectedFile.type // Assuming mimeType is included
                    });
                } else {
                    toast({
                        title: "Upload failed",
                        description: "Could not upload file.",
                        status: "error",
                        duration: 9000,
                        isClosable: true,
                    });
                    return; // Stop sending message if critical to have the attachment
                }
            }

            const data = {
                conversationId,
                message: newMessage.trim() || ' ', // Ensure a space is sent if message is empty
                sender,
                projectId,
                avatar,
                attachments // Include attachments in the data being sent
            };

            socket.emit('send-message', data);
            setNewMessage('');
            setSelectedFile(null);
            setFilename('');
            setDisplayName('');
            setSending(false);
        }
    };

    return (
        <Flex align={"flex-start"} justify={"flex-start"} position={isChatOpen ? "fixed" : "relative"} bottom="1" right={{ base: "0", lg: "5" }} p={{ base: 0, lg: 4 }} w={isChatOpen ? { base: "100%", lg: "60%" } : "auto"} h={menuType === 'drawer' ? "100%" : isChatOpen ? "80vh" : "auto"} bg={isChatOpen ? "blackAlpha.900" : ""} zIndex="modal" shadow={isChatOpen ? "dark-lg" : ""} borderRadius={isChatOpen ? "lg" : "full"}>
            {isChatOpen ? (
                <>
                    {menuType === 'drawer' ? (
                        <>
                            <Flex h={"100%"} overflowY={"scroll"} zIndex={5} p={2} w={"100%"} direction={"column"}>
                                <Flex w={"100%"}>
                                    <Box mt={1} p={2} bg={"gray.700"} borderRadius={"md"}>
                                        <Heading color={"white"} size={"md"}>Messages</Heading>
                                        <Text color={"darkgray"}>
                                            Official correspondence. These messages constitute part of the agreed-upon document and may modify the contract.
                                        </Text>
                                    </Box>
                                    <Button m={1} colorScheme='green' onClick={toggleChat}>X</Button>
                                </Flex>
                                
                                <Flex direction={"column"} justify={"center"} p={2} borderRadius="lg" w="100%">
                                    <VStack border={"1px"} borderColor={"lightgrey"} shadow={"md"} borderRadius={"md"} align={"flex-start"} justify={"flex-start"} p={{ base: 1, lg: 5 }} maxH={"370px"} overflowY={"scroll"}>
                                        <Heading color={"whitesmoke"} size="md">Pinned Messages</Heading>
                                        {messages.filter(msg => msg.pinned).map((msg) => (
                                            <Button color={"whitesmoke"} cursor={"pointer"} size={"sm"} key={msg._id} onClick={() => scrollToMessage(msg._id)} variant="ghost">
                                                {truncateText(msg.message || 'Attachment')}
                                            </Button>
                                        ))}

                                        <Heading color={"whitesmoke"} mt={4} size={"md"}>Images</Heading>
                                        {messages
                                            .filter(msg => msg.attachments && msg.attachments.some(att => att.mimeType.startsWith('image')))
                                            .flatMap(msg => msg.attachments.filter(att => att.mimeType.startsWith('image')))
                                            .map((file, index) => (
                                                <Link cursor={"pointer"} key={index} href={file.url} isExternal color="teal.500">
                                                    {truncateText(file.filename)}
                                                </Link>
                                            ))
                                        }

                                        <Heading color={"whitesmoke"} mt={4} size={"md"}>Files</Heading>
                                        {messages
                                            .filter(msg => msg.attachments && msg.attachments.some(att => !att.mimeType.startsWith('image')))
                                            .flatMap(msg => msg.attachments.filter(att => !att.mimeType.startsWith('image')))
                                            .map((file, index) => (
                                                <Link cursor={"pointer"} key={index} href={file.url} isExternal color="steelblue">
                                                    {truncateText(file.filename)}
                                                </Link>
                                            ))
                                        }
                                    </VStack>
                                    <Flex direction={"column"} w={"100%"}>
                                        <Heading mb={2} mt={4} color={"whitesmoke"} size={"md"}>Timeline</Heading>
                                        <VStack borderRadius={"lg"} p={4} bg={"gray.700"} w={"100%"} maxH={"550px"} overflowY={"scroll"}>
                                            <VStack spacing={2}>
                                                <Flex mt={2} direction={"column"}>
                                                    <FontAwesomeIcon color='coral' icon={faFileInvoice} />
                                                    <Text color="coral">Project Selected</Text>
                                                </Flex>
                                            </VStack>
                                            {messages
                                                .filter(msg => msg.messageType === 'event' || msg.messageType === 'funding' || msg.messageType === 'deletedEvent' || msg.messageType === 'transfer' || msg.messageType === 'accepted' || msg.messageType === 'sent' || msg.messageType === 'settled' || msg.messageType === 'contractorApproved' || msg.messageType === 'homeownerApproved')
                                                .sort((a, b) => new Date(a.timestamp) - new Date(b.timestamp)) // Sort messages by timestamp
                                                .map((msg, index) => {
                                                    if (msg.messageType === 'event') {
                                                        return (
                                                            <VStack key={index} spacing={2}>
                                                                <FontAwesomeIcon color='whitesmoke' icon={faArrowDownLong} />
                                                                <Flex mt={2} direction={"column"}>
                                                                    <FontAwesomeIcon color='lightblue' icon={faCalendarAlt} />
                                                                    <Text color="blue.500">{msg.message}</Text>
                                                                </Flex>
                                                            </VStack>
                                                        );
                                                    } else if (msg.messageType === 'funding') {
                                                        return (
                                                            <VStack key={index} spacing={2}>
                                                                <FontAwesomeIcon color='whitesmoke' icon={faArrowDownLong} />
                                                                <Flex mt={2} direction={"column"}>
                                                                    <FontAwesomeIcon icon={faMoneyBillWave} color='steelblue' />
                                                                    <Text color="steelblue">{msg.message}</Text>
                                                                </Flex>
                                                            </VStack>
                                                        );
                                                    } else if (msg.messageType === 'deletedEvent') {
                                                        return (
                                                            <VStack key={index} spacing={2}>
                                                                <FontAwesomeIcon color='whitesmoke' icon={faArrowDownLong} />
                                                                <Flex mt={2} direction={"column"}>
                                                                    <FontAwesomeIcon icon={faCalendarXmark} color='pink' />
                                                                    <Text color="red.300">{msg.message}</Text>
                                                                </Flex>
                                                            </VStack>
                                                        );
                                                    } else if (msg.messageType === 'transfer') {
                                                        return (
                                                            <VStack key={index} spacing={2}>
                                                                <FontAwesomeIcon color='whitesmoke' icon={faArrowDownLong} />
                                                                <Flex mt={2} direction={"column"}>
                                                                    <FontAwesomeIcon icon={faFileInvoiceDollar} color='yellow' />
                                                                    <Text color="yellow">{msg.message}</Text>
                                                                </Flex>
                                                            </VStack>
                                                        );
                                                    } else if (msg.messageType === 'accepted') {
                                                        return (
                                                            <VStack key={index} spacing={2}>
                                                                <FontAwesomeIcon color='whitesmoke' icon={faArrowDownLong} />
                                                                <Flex mt={2} direction={"column"}>
                                                                    <FontAwesomeIcon icon={faFileCircleCheck} color='deepskyblue' />
                                                                    <Text color="deepskyblue">{msg.message}</Text>
                                                                </Flex>
                                                            </VStack>
                                                        );
                                                    } else if (msg.messageType === 'sent') {
                                                        return (
                                                            <VStack key={index} spacing={2}>
                                                                <FontAwesomeIcon color='whitesmoke' icon={faArrowDownLong} />
                                                                <Flex mt={2} direction={"column"}>
                                                                    <FontAwesomeIcon icon={faPaperPlane} color='cornsilk' />
                                                                    <Text color="cornsilk">{msg.message}</Text>
                                                                </Flex>
                                                            </VStack>
                                                        );
                                                    } else if (msg.messageType === 'settled') {
                                                        return (
                                                            <VStack key={index} spacing={2}>
                                                                <FontAwesomeIcon color='whitesmoke' icon={faArrowDownLong} />
                                                                <Flex mt={2} direction={"column"}>
                                                                    <FontAwesomeIcon icon={faMoneyBill1} color='limegreen' />
                                                                    <Text color="limegreen">{msg.message}</Text>
                                                                </Flex>
                                                            </VStack>
                                                        );
                                                    } else if (msg.messageType === 'homeownerApproved') {
                                                        return (
                                                            <VStack key={index} spacing={2}>
                                                                <FontAwesomeIcon color='whitesmoke' icon={faArrowDownLong} />
                                                                <Flex mt={2} direction={"column"}>
                                                                    <FontAwesomeIcon icon={faHouseCircleCheck} color='lime' />
                                                                    <Text color="lime">{msg.message}</Text>
                                                                </Flex>
                                                            </VStack>
                                                        );
                                                    } else if (msg.messageType === 'contractorApproved') {
                                                        return (
                                                            <VStack key={index} spacing={2}>
                                                                <FontAwesomeIcon color='whitesmoke' icon={faArrowDownLong} />
                                                                <Flex mt={2} direction={"column"}>
                                                                    <FontAwesomeIcon icon={faCircleCheck} color='lime' />
                                                                    <Text color="lime">{msg.message}</Text>
                                                                </Flex>
                                                            </VStack>
                                                        );
                                                    }
                                                })
                                            }
                                        </VStack>
                                    </Flex>
                                </Flex>
                                <Box borderRadius={"lg"} shadow={"md"} mt={2} p={{ base: 1, lg: 5 }}>
                                    <Flex border={"1px"} borderColor={"darkgray"} bg={"gray.800"} h={{ base: "650px", lg: "800px" }} direction="column" p={{ base: 1, lg: 5 }} borderRadius="lg" w="100%" overflowY="auto" className="messages-container">
                                        {messages?.map((msg, index) => (
                                            <Box mb={4} key={msg._id} ref={el => messageRefs.current[msg._id] = el} p={3} data-message-id={msg._id}>
                                                <HStack
                                                    align="flex-start"
                                                    justify={msg.sender === userId ? 'flex-end' : 'flex-start'}
                                                    {...(msg.messageType !== 'text' ? { justify: 'center' } : {})}
                                                >
                                                    {msg.messageType === 'text' && (
                                                        <>
                                                            {msg.sender !== userId && (
                                                                <Avatar name={msg.senderName} src={msg.avatar} size="sm" />
                                                            )
                                                            }
                                                        </>
                                                    )}
                                                    <VStack
                                                        bg={msg.messageType !== 'text' ? "" : msg.sender !== userId ? bgColorUser : bgColorOther}
                                                        borderRadius="lg"
                                                        p={2}
                                                        align="start"
                                                        w={msg.messageType !== 'text' ? "100%" : "fit-content"}
                                                        maxWidth={msg.messageType !== 'text' ? "100%" : "65%"}
                                                    >
                                                        {msg.messageType === 'text' && (
                                                            <>
                                                                {msg.sender !== userId && (
                                                                    <>
                                                                        <Text fontWeight="bold" color={textColor}>
                                                                            {msg.senderName}
                                                                        </Text>
                                                                    </>
                                                                )}
                                                            </>
                                                        )}
                                                        {/* Handle rendering based on message type */}
                                                        {msg.messageType === 'text' && <Text color={textColor}>{msg.message}</Text>}
                                                        {msg.messageType === 'event' && (
                                                            <Flex p={2} w={"100%"} justify={"center"} align={"center"} direction={"column"}>
                                                                <Flex p={1} bg={"gray.700"} borderRadius={"lg"} minW={"50%"} align={"center"} justify={"center"}>
                                                                    <FontAwesomeIcon size='lg' color='lightblue' icon={faCalendarAlt} />
                                                                    <Text ml={4} color="blue.500">{msg.message}</Text>
                                                                    <Text ml={4} color={textColor} fontSize="xs">
                                                                        {moment(msg.timestamp).fromNow()}
                                                                    </Text>
                                                                </Flex>
                                                                <Text color="gray.400" fontSize="xs">
                                                                    Seen by: {msg.readBy.map(user => user.firstName).join(', ')}
                                                                </Text>
                                                            </Flex>
                                                        )}
                                                        {msg.messageType === 'transfer' && (
                                                            <Flex p={2} w={"100%"} justify={"center"} align={"center"} direction={"column"}>
                                                                <Flex p={1} bg={"gray.700"} borderRadius={"lg"} minW={"50%"} align={"center"} justify={"center"}>
                                                                    <FontAwesomeIcon size='lg' icon={faFileInvoiceDollar} color='lightgreen' />
                                                                    <Text ml={4} color="green.500">{msg.message}</Text>
                                                                    <Text ml={4} color={textColor} fontSize="xs">
                                                                        {moment(msg.timestamp).fromNow()}
                                                                    </Text>
                                                                </Flex>
                                                                <Text color="gray.400" fontSize="xs">
                                                                    Seen by: {msg.readBy.map(user => user.firstName).join(', ')}
                                                                </Text>
                                                            </Flex>
                                                        )}
                                                        {msg.messageType === 'accepted' && (
                                                            <Flex p={2} w={"100%"} justify={"center"} align={"center"} direction={"column"}>
                                                                <Flex p={1} bg={"gray.700"} borderRadius={"lg"} minW={"50%"} align={"center"} justify={"center"}>
                                                                    <FontAwesomeIcon size='lg' icon={faFileCircleCheck} color='steelblue' />
                                                                    <Text ml={4} color="steelblue">{msg.message}</Text>
                                                                    <Text ml={4} color={textColor} fontSize="xs">
                                                                        {moment(msg.timestamp).fromNow()}
                                                                    </Text>
                                                                </Flex>
                                                                <Text color="gray.400" fontSize="xs">
                                                                    Seen by: {msg.readBy.map(user => user.firstName).join(', ')}
                                                                </Text>
                                                            </Flex>
                                                        )}
                                                        {msg.messageType === 'funding' && (
                                                            <Flex p={2} w={"100%"} justify={"center"} align={"center"} direction={"column"}>
                                                                <Flex p={1} bg={"gray.700"} borderRadius={"lg"} minW={"50%"} align={"center"} justify={"center"}>
                                                                    <FontAwesomeIcon size='lg' icon={faMoneyBillWave} color='lightgreen' />
                                                                    <Text ml={4} color="green.500">{msg.message}</Text>
                                                                    <Text ml={4} color={textColor} fontSize="xs">
                                                                        {moment(msg.timestamp).fromNow()}
                                                                    </Text>
                                                                </Flex>
                                                                <Text color="gray.400" fontSize="xs">
                                                                    Seen by: {msg.readBy.map(user => user.firstName).join(', ')}
                                                                </Text>
                                                            </Flex>
                                                        )}
                                                        {msg.messageType === 'deletedEvent' && (
                                                            <Flex p={2} w={"100%"} justify={"center"} align={"center"} direction={"column"}>
                                                                <Flex p={1} bg={"gray.700"} borderRadius={"lg"} minW={"50%"} align={"center"} justify={"center"}>
                                                                    <FontAwesomeIcon size='lg' icon={faCalendarXmark} color='pink' />
                                                                    <Text ml={4} color="red.300">{msg.message}</Text>
                                                                    <Text ml={4} color={textColor} fontSize="xs">
                                                                        {moment(msg.timestamp).fromNow()}
                                                                    </Text>
                                                                </Flex>
                                                                <Text color="gray.400" fontSize="xs">
                                                                    Seen by: {msg.readBy.map(user => user.firstName).join(', ')}
                                                                </Text>
                                                            </Flex>
                                                        )}
                                                        {msg.messageType === 'sent' && (
                                                            <Flex p={2} w={"100%"} justify={"center"} align={"center"} direction={"column"}>
                                                                <Flex p={1} bg={"gray.700"} borderRadius={"lg"} minW={"50%"} align={"center"} justify={"center"}>
                                                                    <FontAwesomeIcon size='lg' icon={faPaperPlane} color='cornsilk' />
                                                                    <Text ml={4} color="cornsilk">{msg.message}</Text>
                                                                    <Text ml={4} color={textColor} fontSize="xs">
                                                                        {moment(msg.timestamp).fromNow()}
                                                                    </Text>
                                                                </Flex>
                                                                <Text color="gray.400" fontSize="xs">
                                                                    Seen by: {msg.readBy.map(user => user.firstName).join(', ')}
                                                                </Text>
                                                            </Flex>
                                                        )}
                                                        {msg.messageType === 'settled' && (
                                                            <Flex p={2} w={"100%"} justify={"center"} align={"center"} direction={"column"}>
                                                                <Flex p={1} bg={"gray.700"} borderRadius={"lg"} minW={"50%"} align={"center"} justify={"center"}>
                                                                    <FontAwesomeIcon size='lg' icon={faMoneyBill1} color='limegreen' />
                                                                    <Text ml={4} color="limegreen">{msg.message}</Text>
                                                                    <Text ml={4} color={textColor} fontSize="xs">
                                                                        {moment(msg.timestamp).fromNow()}
                                                                    </Text>
                                                                </Flex>
                                                                <Text color="gray.400" fontSize="xs">
                                                                    Seen by: {msg.readBy.map(user => user.firstName).join(', ')}
                                                                </Text>
                                                            </Flex>
                                                        )}
                                                        {msg.messageType === 'contractorApproved' && (
                                                            <Flex p={2} w={"100%"} justify={"center"} align={"center"} direction={"column"}>
                                                                <Flex p={1} bg={"gray.700"} borderRadius={"lg"} minW={"50%"} align={"center"} justify={"center"}>
                                                                    <FontAwesomeIcon size='lg' icon={faCircleCheck} color='lime' />
                                                                    <Text ml={4} color="lime">{msg.message}</Text>
                                                                    <Text ml={4} color={textColor} fontSize="xs">
                                                                        {moment(msg.timestamp).fromNow()}
                                                                    </Text>
                                                                </Flex>
                                                                <Text color="gray.400" fontSize="xs">
                                                                    Seen by: {msg.readBy.map(user => user.firstName).join(', ')}
                                                                </Text>
                                                            </Flex>
                                                        )}
                                                        {msg.messageType === 'homeownerApproved' && (
                                                            <Flex p={2} w={"100%"} justify={"center"} align={"center"} direction={"column"}>
                                                                <Flex p={1} bg={"gray.700"} borderRadius={"lg"} minW={"50%"} align={"center"} justify={"center"}>
                                                                    <FontAwesomeIcon size='lg' icon={faHouseCircleCheck} color='lime' />
                                                                    <Text ml={4} color="lime">{msg.message}</Text>
                                                                    <Text ml={4} color={textColor} fontSize="xs">
                                                                        {moment(msg.timestamp).fromNow()}
                                                                    </Text>
                                                                </Flex>
                                                                <Text color="gray.400" fontSize="xs">
                                                                    Seen by: {msg.readBy.map(user => user.firstName).join(', ')}
                                                                </Text>
                                                            </Flex>
                                                        )}

                                                        {msg?.attachments?.map((att, idx) => (
                                                            <Link key={idx} href={att.url} isExternal color={textColor}>
                                                                {att.filename}
                                                            </Link>
                                                        ))}
                                                        {msg.messageType === 'text' && (
                                                            <>
                                                                <Flex w="100%" justify={msg.sender === userId ? 'flex-end' : 'flex-start'}>
                                                                    <Box>
                                                                        <Text color={textColor} fontSize="xs">
                                                                            {moment(msg.timestamp).fromNow()}
                                                                        </Text>
                                                                        <Text color="gray.400" fontSize="xs">
                                                                            Seen by: {msg.readBy.map(user => user.firstName).join(', ')}
                                                                        </Text>
                                                                    </Box>
                                                                </Flex>
                                                            </>
                                                        )}
                                                    </VStack>
                                                    {msg.messageType === 'text' && (
                                                        <IconButton
                                                            icon={<FontAwesomeIcon icon={faThumbtack} />}
                                                            onClick={() => togglePin(msg)}
                                                            colorScheme={msg.pinned ? "red" : "gray"}
                                                            aria-label="Pin Message"
                                                            size="xs"
                                                        />
                                                    )}
                                                </HStack>
                                            </Box>
                                        ))}
                                        <Text fontSize="sm" color="gray.500">{typingIndicator}</Text>
                                        <div ref={endOfMessagesRef}></div>
                                    </Flex>
                                    <InputGroup size="md" mt={2}>
                                        <Input
                                            pr="4.5rem"
                                            color={"white"}
                                            type="text"
                                            placeholder="Type a message..."
                                            value={newMessage}
                                            onChange={(e) => {
                                                setNewMessage(e.target.value);
                                                handleInputChange(e);
                                            }}
                                            onKeyPress={(e) => {
                                                if (e.key === 'Enter' && !e.shiftKey) {
                                                    e.preventDefault();
                                                    sendMessage();
                                                }
                                            }}
                                            borderRadius="full"
                                            wordBreak="break-word"
                                            whiteSpace="pre-wrap"
                                        />
                                        <InputRightElement width="6.5rem">
                                            <Button
                                                variant={"ghost"}
                                                color={"white"}
                                                size="sm"
                                                onClick={() => document.getElementById('file-upload').click()}
                                            >
                                                <FontAwesomeIcon icon={faPaperclip} />
                                            </Button>
                                            <input
                                                id="file-upload"
                                                type="file"
                                                onChange={handleFileChange}
                                                style={{ display: 'none' }}
                                            />
                                            <Button
                                                h="2rem"
                                                size="sm"
                                                isDisabled={isDisabled}
                                                isLoading={sending}
                                                colorScheme='green'
                                                borderRadius={"full"}
                                                onClick={sendMessage}
                                            >
                                                Send
                                            </Button>
                                        </InputRightElement>
                                    </InputGroup>
                                    <Text fontSize="sm">{displayName}</Text>
                                </Box>
                            </Flex>
                        </>
                    ) : (
                        <>
                            <Button m={2} colorScheme='green' onClick={toggleChat}>X</Button>
                            <Flex p={4} w={{ base: "100%", lg: "70%" }} direction={"column"}>
                                <Box p={2} bg={"gray.700"} borderRadius={"md"}>
                                    <Heading color={"white"} size={"md"}>Messages</Heading>
                                    <Text color={"darkgray"}>
                                        Official correspondence. These messages constitute part of the agreed-upon document and may modify the contract.
                                    </Text>
                                </Box>
                                <Box borderRadius={"lg"} shadow={"md"} mt={2} p={{ base: 1, lg: 5 }}>
                                    <Flex border={"1px"} borderColor={"darkgray"} bg={"gray.800"} h={{ base: "650px", lg: "800px" }} direction="column" p={{ base: 1, lg: 5 }} borderRadius="lg" w="100%" overflowY="auto" className="messages-container">
                                        {messages?.map((msg, index) => (
                                            <Box mb={4} key={msg._id} ref={el => messageRefs.current[msg._id] = el} p={3} data-message-id={msg._id}>
                                                <HStack
                                                    align="flex-start"
                                                    justify={msg.sender === userId ? 'flex-end' : 'flex-start'}
                                                    {...(msg.messageType !== 'text' ? { justify: 'center' } : {})}
                                                >
                                                    {msg.messageType === 'text' && (
                                                        <>
                                                            {msg.sender !== userId && (
                                                                <Avatar name={msg.senderName} src={msg.avatar} size="sm" />
                                                            )
                                                            }
                                                        </>
                                                    )}
                                                    <VStack
                                                        bg={msg.messageType !== 'text' ? "" : msg.sender !== userId ? bgColorUser : bgColorOther}
                                                        borderRadius="lg"
                                                        p={2}
                                                        align="start"
                                                        w={msg.messageType !== 'text' ? "100%" : "fit-content"}
                                                        maxWidth={msg.messageType !== 'text' ? "100%" : "65%"}
                                                    >
                                                        {msg.messageType === 'text' && (
                                                            <>
                                                                {msg.sender !== userId && (
                                                                    <>
                                                                        <Text fontWeight="bold" color={textColor}>
                                                                            {msg.senderName}
                                                                        </Text>
                                                                    </>
                                                                )}
                                                            </>
                                                        )}
                                                        {/* Handle rendering based on message type */}
                                                        {msg.messageType === 'text' && <Text color={textColor}>{msg.message}</Text>}
                                                        {msg.messageType === 'event' && (
                                                            <Flex p={2} w={"100%"} justify={"center"} align={"center"} direction={"column"}>
                                                                <Flex p={1} bg={"gray.700"} borderRadius={"lg"} minW={"50%"} align={"center"} justify={"center"}>
                                                                    <FontAwesomeIcon size='lg' color='lightblue' icon={faCalendarAlt} />
                                                                    <Text ml={4} color="blue.500">{msg.message}</Text>
                                                                    <Text ml={4} color={textColor} fontSize="xs">
                                                                        {moment(msg.timestamp).fromNow()}
                                                                    </Text>
                                                                </Flex>
                                                                <Text color="gray.400" fontSize="xs">
                                                                    Seen by: {msg.readBy.map(user => user.firstName).join(', ')}
                                                                </Text>
                                                            </Flex>
                                                        )}
                                                        {msg.messageType === 'transfer' && (
                                                            <Flex p={2} w={"100%"} justify={"center"} align={"center"} direction={"column"}>
                                                                <Flex p={1} bg={"gray.700"} borderRadius={"lg"} minW={"50%"} align={"center"} justify={"center"}>
                                                                    <FontAwesomeIcon size='lg' icon={faFileInvoiceDollar} color='lightgreen' />
                                                                    <Text ml={4} color="green.500">{msg.message}</Text>
                                                                    <Text ml={4} color={textColor} fontSize="xs">
                                                                        {moment(msg.timestamp).fromNow()}
                                                                    </Text>
                                                                </Flex>
                                                                <Text color="gray.400" fontSize="xs">
                                                                    Seen by: {msg.readBy.map(user => user.firstName).join(', ')}
                                                                </Text>
                                                            </Flex>
                                                        )}
                                                        {msg.messageType === 'accepted' && (
                                                            <Flex p={2} w={"100%"} justify={"center"} align={"center"} direction={"column"}>
                                                                <Flex p={1} bg={"gray.700"} borderRadius={"lg"} minW={"50%"} align={"center"} justify={"center"}>
                                                                    <FontAwesomeIcon size='lg' icon={faFileCircleCheck} color='steelblue' />
                                                                    <Text ml={4} color="steelblue">{msg.message}</Text>
                                                                    <Text ml={4} color={textColor} fontSize="xs">
                                                                        {moment(msg.timestamp).fromNow()}
                                                                    </Text>
                                                                </Flex>
                                                                <Text color="gray.400" fontSize="xs">
                                                                    Seen by: {msg.readBy.map(user => user.firstName).join(', ')}
                                                                </Text>
                                                            </Flex>
                                                        )}
                                                        {msg.messageType === 'funding' && (
                                                            <Flex p={2} w={"100%"} justify={"center"} align={"center"} direction={"column"}>
                                                                <Flex p={1} bg={"gray.700"} borderRadius={"lg"} minW={"50%"} align={"center"} justify={"center"}>
                                                                    <FontAwesomeIcon size='lg' icon={faMoneyBillWave} color='lightgreen' />
                                                                    <Text ml={4} color="green.500">{msg.message}</Text>
                                                                    <Text ml={4} color={textColor} fontSize="xs">
                                                                        {moment(msg.timestamp).fromNow()}
                                                                    </Text>
                                                                </Flex>
                                                                <Text color="gray.400" fontSize="xs">
                                                                    Seen by: {msg.readBy.map(user => user.firstName).join(', ')}
                                                                </Text>
                                                            </Flex>
                                                        )}
                                                        {msg.messageType === 'deletedEvent' && (
                                                            <Flex p={2} w={"100%"} justify={"center"} align={"center"} direction={"column"}>
                                                                <Flex p={1} bg={"gray.700"} borderRadius={"lg"} minW={"50%"} align={"center"} justify={"center"}>
                                                                    <FontAwesomeIcon size='lg' icon={faCalendarXmark} color='pink' />
                                                                    <Text ml={4} color="red.300">{msg.message}</Text>
                                                                    <Text ml={4} color={textColor} fontSize="xs">
                                                                        {moment(msg.timestamp).fromNow()}
                                                                    </Text>
                                                                </Flex>
                                                                <Text color="gray.400" fontSize="xs">
                                                                    Seen by: {msg.readBy.map(user => user.firstName).join(', ')}
                                                                </Text>
                                                            </Flex>
                                                        )}
                                                        {msg.messageType === 'sent' && (
                                                            <Flex p={2} w={"100%"} justify={"center"} align={"center"} direction={"column"}>
                                                                <Flex p={1} bg={"gray.700"} borderRadius={"lg"} minW={"50%"} align={"center"} justify={"center"}>
                                                                    <FontAwesomeIcon size='lg' icon={faPaperPlane} color='cornsilk' />
                                                                    <Text ml={4} color="cornsilk">{msg.message}</Text>
                                                                    <Text ml={4} color={textColor} fontSize="xs">
                                                                        {moment(msg.timestamp).fromNow()}
                                                                    </Text>
                                                                </Flex>
                                                                <Text color="gray.400" fontSize="xs">
                                                                    Seen by: {msg.readBy.map(user => user.firstName).join(', ')}
                                                                </Text>
                                                            </Flex>
                                                        )}
                                                        {msg.messageType === 'settled' && (
                                                            <Flex p={2} w={"100%"} justify={"center"} align={"center"} direction={"column"}>
                                                                <Flex p={1} bg={"gray.700"} borderRadius={"lg"} minW={"50%"} align={"center"} justify={"center"}>
                                                                    <FontAwesomeIcon size='lg' icon={faMoneyBill1} color='limegreen' />
                                                                    <Text ml={4} color="limegreen">{msg.message}</Text>
                                                                    <Text ml={4} color={textColor} fontSize="xs">
                                                                        {moment(msg.timestamp).fromNow()}
                                                                    </Text>
                                                                </Flex>
                                                                <Text color="gray.400" fontSize="xs">
                                                                    Seen by: {msg.readBy.map(user => user.firstName).join(', ')}
                                                                </Text>
                                                            </Flex>
                                                        )}
                                                        {msg.messageType === 'contractorApproved' && (
                                                            <Flex p={2} w={"100%"} justify={"center"} align={"center"} direction={"column"}>
                                                                <Flex p={1} bg={"gray.700"} borderRadius={"lg"} minW={"50%"} align={"center"} justify={"center"}>
                                                                    <FontAwesomeIcon size='lg' icon={faCircleCheck} color='lime' />
                                                                    <Text ml={4} color="lime">{msg.message}</Text>
                                                                    <Text ml={4} color={textColor} fontSize="xs">
                                                                        {moment(msg.timestamp).fromNow()}
                                                                    </Text>
                                                                </Flex>
                                                                <Text color="gray.400" fontSize="xs">
                                                                    Seen by: {msg.readBy.map(user => user.firstName).join(', ')}
                                                                </Text>
                                                            </Flex>
                                                        )}
                                                        {msg.messageType === 'homeownerApproved' && (
                                                            <Flex p={2} w={"100%"} justify={"center"} align={"center"} direction={"column"}>
                                                                <Flex p={1} bg={"gray.700"} borderRadius={"lg"} minW={"50%"} align={"center"} justify={"center"}>
                                                                    <FontAwesomeIcon size='lg' icon={faHouseCircleCheck} color='lime' />
                                                                    <Text ml={4} color="lime">{msg.message}</Text>
                                                                    <Text ml={4} color={textColor} fontSize="xs">
                                                                        {moment(msg.timestamp).fromNow()}
                                                                    </Text>
                                                                </Flex>
                                                                <Text color="gray.400" fontSize="xs">
                                                                    Seen by: {msg.readBy.map(user => user.firstName).join(', ')}
                                                                </Text>
                                                            </Flex>
                                                        )}

                                                        {msg?.attachments?.map((att, idx) => (
                                                            <Link key={idx} href={att.url} isExternal color={textColor}>
                                                                {att.filename}
                                                            </Link>
                                                        ))}
                                                        {msg.messageType === 'text' && (
                                                            <>
                                                                <Flex w="100%" justify={msg.sender === userId ? 'flex-end' : 'flex-start'}>
                                                                    <Box>
                                                                        <Text color={textColor} fontSize="xs">
                                                                            {moment(msg.timestamp).fromNow()}
                                                                        </Text>
                                                                        <Text color="gray.400" fontSize="xs">
                                                                            Seen by: {msg.readBy.map(user => user.firstName).join(', ')}
                                                                        </Text>
                                                                    </Box>
                                                                </Flex>
                                                            </>
                                                        )}
                                                    </VStack>
                                                    {msg.messageType === 'text' && (
                                                        <IconButton
                                                            icon={<FontAwesomeIcon icon={faThumbtack} />}
                                                            onClick={() => togglePin(msg)}
                                                            colorScheme={msg.pinned ? "red" : "gray"}
                                                            aria-label="Pin Message"
                                                            size="xs"
                                                        />
                                                    )}
                                                </HStack>
                                            </Box>
                                        ))}
                                        <Text fontSize="sm" color="gray.500">{typingIndicator}</Text>
                                        <div ref={endOfMessagesRef}></div>
                                    </Flex>
                                    <InputGroup size="md" mt={2}>
                                        <Input
                                            pr="4.5rem"
                                            color={"white"}
                                            type="text"
                                            placeholder="Type a message..."
                                            value={newMessage}
                                            onChange={(e) => {
                                                setNewMessage(e.target.value);
                                                handleInputChange(e);
                                            }}
                                            onKeyPress={(e) => {
                                                if (e.key === 'Enter' && !e.shiftKey) {
                                                    e.preventDefault();
                                                    sendMessage();
                                                }
                                            }}
                                            borderRadius="full"
                                            wordBreak="break-word"
                                            whiteSpace="pre-wrap"
                                        />
                                        <InputRightElement width="6.5rem">
                                            <Button
                                                variant={"ghost"}
                                                color={"white"}
                                                size="sm"
                                                onClick={() => document.getElementById('file-upload').click()}
                                            >
                                                <FontAwesomeIcon icon={faPaperclip} />
                                            </Button>
                                            <input
                                                id="file-upload"
                                                type="file"
                                                onChange={handleFileChange}
                                                style={{ display: 'none' }}
                                            />
                                            <Button
                                                h="2rem"
                                                size="sm"
                                                isDisabled={isDisabled}
                                                isLoading={sending}
                                                colorScheme='green'
                                                borderRadius={"full"}
                                                onClick={sendMessage}
                                            >
                                                Send
                                            </Button>
                                        </InputRightElement>
                                    </InputGroup>
                                    <Text fontSize="sm">{displayName}</Text>
                                </Box>
                            </Flex>
                            <Flex direction={"column"} justify={"center"} p={2} borderRadius="lg" w="30%">
                                <VStack border={"1px"} borderColor={"lightgrey"} shadow={"md"} borderRadius={"md"} align={"flex-start"} justify={"flex-start"} p={{ base: 1, lg: 5 }} maxH={"370px"} overflowY={"scroll"}>
                                    <Heading color={"whitesmoke"} size="md">Pinned Messages</Heading>
                                    {messages.filter(msg => msg.pinned).map((msg) => (
                                        <Button color={"whitesmoke"} cursor={"pointer"} size={"sm"} key={msg._id} onClick={() => scrollToMessage(msg._id)} variant="ghost">
                                            {truncateText(msg.message || 'Attachment')}
                                        </Button>
                                    ))}

                                    <Heading color={"whitesmoke"} mt={4} size={"md"}>Images</Heading>
                                    {messages
                                        .filter(msg => msg.attachments && msg.attachments.some(att => att.mimeType.startsWith('image')))
                                        .flatMap(msg => msg.attachments.filter(att => att.mimeType.startsWith('image')))
                                        .map((file, index) => (
                                            <Link cursor={"pointer"} key={index} href={file.url} isExternal color="teal.500">
                                                {truncateText(file.filename)}
                                            </Link>
                                        ))
                                    }

                                    <Heading color={"whitesmoke"} mt={4} size={"md"}>Files</Heading>
                                    {messages
                                        .filter(msg => msg.attachments && msg.attachments.some(att => !att.mimeType.startsWith('image')))
                                        .flatMap(msg => msg.attachments.filter(att => !att.mimeType.startsWith('image')))
                                        .map((file, index) => (
                                            <Link cursor={"pointer"} key={index} href={file.url} isExternal color="steelblue">
                                                {truncateText(file.filename)}
                                            </Link>
                                        ))
                                    }
                                </VStack>
                                <Flex direction={"column"} w={"100%"}>
                                    <Heading mb={2} mt={4} color={"whitesmoke"} size={"md"}>Timeline</Heading>
                                    <VStack borderRadius={"lg"} p={4} bg={"gray.700"} w={"100%"} maxH={"550px"} overflowY={"scroll"}>
                                        <VStack spacing={2}>
                                            <Flex mt={2} direction={"column"}>
                                                <FontAwesomeIcon color='coral' icon={faFileInvoice} />
                                                <Text color="coral">Project Selected</Text>
                                            </Flex>
                                        </VStack>
                                        {messages
                                            .filter(msg => msg.messageType === 'event' || msg.messageType === 'funding' || msg.messageType === 'deletedEvent' || msg.messageType === 'transfer' || msg.messageType === 'accepted' || msg.messageType === 'sent' || msg.messageType === 'settled' || msg.messageType === 'contractorApproved' || msg.messageType === 'homeownerApproved')
                                            .sort((a, b) => new Date(a.timestamp) - new Date(b.timestamp)) // Sort messages by timestamp
                                            .map((msg, index) => {
                                                if (msg.messageType === 'event') {
                                                    return (
                                                        <VStack key={index} spacing={2}>
                                                            <FontAwesomeIcon color='whitesmoke' icon={faArrowDownLong} />
                                                            <Flex mt={2} direction={"column"}>
                                                                <FontAwesomeIcon color='lightblue' icon={faCalendarAlt} />
                                                                <Text color="blue.500">{msg.message}</Text>
                                                            </Flex>
                                                        </VStack>
                                                    );
                                                } else if (msg.messageType === 'funding') {
                                                    return (
                                                        <VStack key={index} spacing={2}>
                                                            <FontAwesomeIcon color='whitesmoke' icon={faArrowDownLong} />
                                                            <Flex mt={2} direction={"column"}>
                                                                <FontAwesomeIcon icon={faMoneyBillWave} color='steelblue' />
                                                                <Text color="steelblue">{msg.message}</Text>
                                                            </Flex>
                                                        </VStack>
                                                    );
                                                } else if (msg.messageType === 'deletedEvent') {
                                                    return (
                                                        <VStack key={index} spacing={2}>
                                                            <FontAwesomeIcon color='whitesmoke' icon={faArrowDownLong} />
                                                            <Flex mt={2} direction={"column"}>
                                                                <FontAwesomeIcon icon={faCalendarXmark} color='pink' />
                                                                <Text color="red.300">{msg.message}</Text>
                                                            </Flex>
                                                        </VStack>
                                                    );
                                                } else if (msg.messageType === 'transfer') {
                                                    return (
                                                        <VStack key={index} spacing={2}>
                                                            <FontAwesomeIcon color='whitesmoke' icon={faArrowDownLong} />
                                                            <Flex mt={2} direction={"column"}>
                                                                <FontAwesomeIcon icon={faFileInvoiceDollar} color='yellow' />
                                                                <Text color="yellow">{msg.message}</Text>
                                                            </Flex>
                                                        </VStack>
                                                    );
                                                } else if (msg.messageType === 'accepted') {
                                                    return (
                                                        <VStack key={index} spacing={2}>
                                                            <FontAwesomeIcon color='whitesmoke' icon={faArrowDownLong} />
                                                            <Flex mt={2} direction={"column"}>
                                                                <FontAwesomeIcon icon={faFileCircleCheck} color='deepskyblue' />
                                                                <Text color="deepskyblue">{msg.message}</Text>
                                                            </Flex>
                                                        </VStack>
                                                    );
                                                } else if (msg.messageType === 'sent') {
                                                    return (
                                                        <VStack key={index} spacing={2}>
                                                            <FontAwesomeIcon color='whitesmoke' icon={faArrowDownLong} />
                                                            <Flex mt={2} direction={"column"}>
                                                                <FontAwesomeIcon icon={faPaperPlane} color='cornsilk' />
                                                                <Text color="cornsilk">{msg.message}</Text>
                                                            </Flex>
                                                        </VStack>
                                                    );
                                                } else if (msg.messageType === 'settled') {
                                                    return (
                                                        <VStack key={index} spacing={2}>
                                                            <FontAwesomeIcon color='whitesmoke' icon={faArrowDownLong} />
                                                            <Flex mt={2} direction={"column"}>
                                                                <FontAwesomeIcon icon={faMoneyBill1} color='limegreen' />
                                                                <Text color="limegreen">{msg.message}</Text>
                                                            </Flex>
                                                        </VStack>
                                                    );
                                                } else if (msg.messageType === 'homeownerApproved') {
                                                    return (
                                                        <VStack key={index} spacing={2}>
                                                            <FontAwesomeIcon color='whitesmoke' icon={faArrowDownLong} />
                                                            <Flex mt={2} direction={"column"}>
                                                                <FontAwesomeIcon icon={faHouseCircleCheck} color='lime' />
                                                                <Text color="lime">{msg.message}</Text>
                                                            </Flex>
                                                        </VStack>
                                                    );
                                                } else if (msg.messageType === 'contractorApproved') {
                                                    return (
                                                        <VStack key={index} spacing={2}>
                                                            <FontAwesomeIcon color='whitesmoke' icon={faArrowDownLong} />
                                                            <Flex mt={2} direction={"column"}>
                                                                <FontAwesomeIcon icon={faCircleCheck} color='lime' />
                                                                <Text color="lime">{msg.message}</Text>
                                                            </Flex>
                                                        </VStack>
                                                    );
                                                }
                                            })
                                        }
                                    </VStack>
                                </Flex>
                            </Flex>
                        </>
                    )}
                </>
            ) : (
                <Flex p={2} shadow={"dark-lg"} h={"110px"} borderRadius={"xl"} bg={"gray.700"} position="fixed" bottom="-10" right="20" minW="200px" justify="center">
                    <Button shadow={"dark-lg"} onClick={toggleChat} size="lg" bg="#1DADE3" _hover={"blue"} color={"white"} > <FontAwesomeIcon icon={faComments} /><Text ml={2}>Open Chat</Text> {unread && <Text color="lime" ml={2}>(New Messages)</Text>}</Button>
                </Flex>
            )}
        </Flex>
    );
};

export default MessageComponent;