import React, { useState, useEffect } from 'react';
import { Box, Flex, Text, VStack, Input, Button, Select, Spinner, Textarea, Spacer, Image, HStack, Heading, useBreakpointValue, Menu, MenuButton, MenuList, MenuItem, FormLabel, Icon, TagCloseButton, Tag } from '@chakra-ui/react';
import Header from '../elements/Header';
import ContractorSideMenu from '../contractor/SideMenu';
import VerifyPhone from '../elements/VerifyPhone';
import axios from 'axios';
import Logo from '../assets/Horizontal LOGO.webp';
import { io } from 'socket.io-client';
import { useAtom } from 'jotai';
import { socketAtom } from '../socketAtom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAnglesLeft, faAnglesRight, faChevronCircleDown, faMinus, faPlus } from '@fortawesome/free-solid-svg-icons';

function BusinessAccount() {
    const [currentStep, setCurrentStep] = useState(1);
    const menuType = useBreakpointValue({ base: 'drawer', lg: 'regular' });
    const [contractor, setContractor] = useState({
        licenses: [{ type: '', number: '', expiryDate: '', certifications: [] }],
        insurance: [{ provider: '', type: '', policyNumber: '', coverageAmount: '', expiryDate: '' }],
        bonds: [{ bondNumber: '', bondingCompany: '', amount: '', expiryDate: '' }],
        business: {}
    });
    const [loading, setLoading] = useState(false);
    const [logoFile, setLogoFile] = useState(null);
    const [logoUrl, setLogoUrl] = useState('');
    const [error, setError] = useState('');
    const [editing, setEditing] = useState(true);
    const token = localStorage.getItem('token');
    const [socket, setSocket] = useAtom(socketAtom);

    useEffect(() => {
        const newSocket = io(process.env.REACT_APP_API_URL, {
            autoConnect: true,
            auth: {
                token: token,
            },
        });

        newSocket.on('connect_error', (error) => {
            console.error('Socket connection error:', error);
        });

        setSocket(newSocket);

        return () => newSocket.close();
    }, [setSocket, token]);

    useEffect(() => {
        const getContractor = async () => {
            setLoading(true);
            try {
                const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/contractor`, {
                    headers: { Authorization: `Bearer ${token}` }
                });
                const fetchedData = response.data;
                fetchedData.specialties = fetchedData.specialties || [];
                fetchedData.professionalAssociations = fetchedData.professionalAssociations || [];
                fetchedData.servicesOffered = fetchedData.servicesOffered || [];
                fetchedData.warranties = fetchedData.warranties || [];
                setContractor(fetchedData);
                if (fetchedData.phoneVerified) {
                    setCurrentStep(2);
                }
                setLoading(false);
            } catch (error) {
                console.error('Error fetching contractor data:', error);
                setLoading(false);
            }
        };
        getContractor();
    }, [token]);

    const onPhoneVerified = () => {
        setCurrentStep(2);
    };

    const handleFileChange = async (event) => {
        const file = event.target.files[0];
        if (!file) {
            setError('No file selected');
            return;
        }
        if (!file.type.startsWith('image/')) {
            setError('File is not an image');
            return;
        }
        if (file.size > 5000000) {
            setError('File is too large');
            return;
        }
        setError('');
        setLogoUrl(URL.createObjectURL(file));
        await uploadLogo(file);
    };

    const uploadLogo = async (file) => {
        const formData = new FormData();
        formData.append('logo', file);
        setLoading(true);
        try {
            const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/upload-logo`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                    Authorization: `Bearer ${localStorage.getItem('token')}`,
                },
            });
            setLogoUrl(response.data.location);
            setError('');
        } catch (error) {
            setError('Error uploading logo: ' + error.message);
        } finally {
            setLoading(false);
        }
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setContractor(prev => {
            const levels = name.split('.');
            const last = levels.pop();
            const lastObj = levels.reduce((acc, val) => {
                return acc[val] = acc[val] || {};
            }, prev);
            lastObj[last] = value;
            return { ...prev };
        });
    };

    const handleLicenseChange = (index, field, value) => {
        let newLicenses = [...contractor.licenses];
        if (!newLicenses[index]) {
            newLicenses[index] = { type: '', number: '', expiryDate: '', certifications: [] };
        }
        if (field === 'certifications') {
            newLicenses[index][field] = value.map(cert => cert.trim()).filter(cert => cert !== '');
        } else {
            newLicenses[index][field] = value;
        }
        setContractor(prev => ({ ...prev, licenses: newLicenses }));
    };

    const addLicense = () => {
        setContractor(prev => ({
            ...prev,
            licenses: [...prev.licenses, { type: '', number: '', expiryDate: '', certifications: [] }]
        }));
    };

    const removeLicense = index => {
        setContractor(prev => ({
            ...prev,
            licenses: prev.licenses.filter((_, i) => i !== index)
        }));
    };

    const handleInsuranceChange = (index, field, value) => {
        let newInsurance = [...contractor.insurance];
        if (!newInsurance[index]) {
            newInsurance[index] = { provider: '', type: '', policyNumber: '', coverageAmount: '', expiryDate: '' };
        }
        newInsurance[index][field] = value;
        setContractor(prev => ({ ...prev, insurance: newInsurance }));
    };

    const addInsurance = () => {
        setContractor(prev => ({
            ...prev,
            insurance: [...prev.insurance, { provider: '', type: '', policyNumber: '', coverageAmount: '', expiryDate: '' }]
        }));
    };

    const removeInsurance = index => {
        setContractor(prev => ({
            ...prev,
            insurance: prev.insurance.filter((_, i) => i !== index)
        }));
    };

    const addBond = () => {
        setContractor(prev => ({
            ...prev,
            bonds: [
                ...prev.bonds || [], // Ensure that it's always an array
                { bondNumber: '', bondingCompany: '', bondAmount: '', expiryDate: '' }
            ]
        }));
    };

    const removeBond = index => {
        setContractor(prev => ({
            ...prev,
            bonds: (prev.bonds || []).filter((_, i) => i !== index) // Check and use filter safely
        }));
    };

    const handleBondChange = (index, field, value) => {
        setContractor(prev => {
            const newBonds = (prev.bonds || []).slice(); // Make a shallow copy safely
            if (!newBonds[index]) {
                newBonds[index] = { bondNumber: '', bondingCompany: '', bondAmount: '', expiryDate: '' };
            }
            newBonds[index][field] = value;
            return { ...prev, bonds: newBonds };
        });
    };

    const handleSaveAndCreateStripeAccount = async () => {
        setLoading(true);
        const headers = {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json'
        };

        if (!contractor.business.email || !contractor.business.phone || !contractor.business.address) {
            alert('Please make sure all business details are filled out correctly.');
            setLoading(false);
            return;
        }

        let stripeBusinessType;
        switch (contractor.business.type) {
            case 'Sole Proprietorship':
            case 'Partnership':
                stripeBusinessType = 'individual';
                break;
            case 'Corporation':
                stripeBusinessType = 'company';
                break;
            default:
                stripeBusinessType = 'individual';
        }

        const stripeData = {
            email: contractor.business.email,
            phone: contractor.business.phone,
            business_type: stripeBusinessType,
            company: {
                name: contractor.business.name,
                address: {
                    line1: contractor.business.address.street,
                    city: contractor.business.address.city,
                    state: contractor.business.address.state,
                    postal_code: contractor.business.address.zipCode,
                    country: 'US',
                },
            },
        };

        const { address } = stripeData.company;
        if (!address || !address.line1 || !address.city || !address.state || !address.postal_code) {
            alert('Please provide a complete business address.');
            setLoading(false);
            return;
        }

        const updatedContractor = {
            ...contractor,
            business: {
                ...contractor.business,
                logo: logoUrl,
            }
        };

        console.log('Sending the following data to the server for Stripe account creation:', stripeData);

        try {
            const contractorResponse = await axios.put(`${process.env.REACT_APP_API_URL}/api/contractor`, updatedContractor, { headers });
            const stripeResponse = await axios.post(`${process.env.REACT_APP_API_URL}/api/stripe/create-express-account`, stripeData, { headers });

            if (stripeResponse.data.url) {
                window.location.href = stripeResponse.data.url;
            } else {
                console.error('No redirect URL provided');
                alert('There was a problem with creating your Stripe account. Please try again.');
            }
        } catch (error) {
            console.error('Error saving contractor data or creating/updating Stripe account:', error);
            console.log(error.response.data); // Log the server's response to get more details
            alert(`Failed to process your request: ${error.response.data.error}`);
        } finally {
            setLoading(false);
        }
    };

    const handleStateChange = (selectedState) => {
        setContractor(prevContractor => ({
            ...prevContractor,
            business: {
                ...prevContractor.business,
                address: {
                    ...prevContractor.business.address,
                    state: selectedState
                }
            }
        }));
    };

    const states = ['Alabama', 'Alaska', 'Arizona', 'Arkansas', 'California', 'Colorado', 'Connecticut', 'Delaware', 'Florida', 'Georgia',
        'Hawaii', 'Idaho', 'Illinois', 'Indiana', 'Iowa', 'Kansas', 'Kentucky', 'Louisiana', 'Maine', 'Maryland', 'Massachusetts', 'Michigan',
        'Minnesota', 'Mississippi', 'Missouri', 'Montana', 'Nebraska', 'Nevada', 'New Hampshire', 'New Jersey', 'New Mexico', 'New York',
        'North Carolina', 'North Dakota', 'Ohio', 'Oklahoma', 'Oregon', 'Pennsylvania', 'Rhode Island', 'South Carolina', 'South Dakota',
        'Tennessee', 'Texas', 'Utah', 'Vermont', 'Virginia', 'Washington', 'West Virginia', 'Wisconsin', 'Wyoming'
    ];

    return (
        <Flex direction={"column"} w={"100%"} justify={"center"}>
            <Flex direction={{ base: "column", lg: "row" }} p={1} w="100%" align="flex-start" justify="flex-start">
                {menuType === 'drawer' ? (
                    <Flex m={{ base: 0, lg: 4 }} direction="column" w="100%" justify="center">
                        <Flex mt={2} w={"100%"} direction="column" align="center" justify="flex-start">
                            <Flex w={"100%"} justify={"center"}><Image mb={4} src={Logo} w={"80%"} h={"auto"} /></Flex>
                            {loading ? (
                                <Spinner />
                            ) : (
                                <>
                                    {currentStep === 1 && (
                                        <VStack w={"100%"} p={8} spacing={4} align={"center"}>
                                            <Box w={"100%"} maxW={"900px"} p={8} borderRadius={"lg"} border={"1px"} borderColor={"lightgray"} shadow={"lg"}>
                                                <VerifyPhone onPhoneVerified={onPhoneVerified} />
                                            </Box>
                                        </VStack>
                                    )}

                                    {currentStep >= 2 && (
                                        <VStack w={"100%"} spacing={4} align={"center"}>
                                            <Box w={"100%"} borderRadius={"lg"} border={"1px"} borderColor={"lightgray"} shadow={"lg"}>
                                                {currentStep === 2 && (
                                                    <VStack w={"100%"} spacing={4} justify={"center"}>
                                                        <Heading mt={2} size={"sm"}>Business Information</Heading>
                                                        <Flex direction="column" w={"100%"} justify={"space-between"} align="center">
                                                            <Text fontWeight={"semibold"}>Business Name:</Text>
                                                            {editing ? (
                                                                <Input w={"95%"} name="business.name" value={contractor?.business?.name || ''} onChange={handleInputChange} />
                                                            ) : (
                                                                <Text>{contractor?.business?.name}</Text>
                                                            )}
                                                        </Flex>
                                                        <Flex direction="column" w={"100%"} justify={"space-between"} align="center">
                                                            <Text fontWeight={"semibold"}>Business Type:</Text>
                                                            {editing ? (
                                                                <Select w={"95%"} name="business.type" value={contractor?.business?.type || ''} onChange={handleInputChange}>
                                                                    <option value="">Select Business Type</option>
                                                                    <option value="Sole Proprietorship">Sole Proprietorship</option>
                                                                    <option value="Partnership">Partnership</option>
                                                                    <option value="Corporation">Corporation</option>
                                                                </Select>
                                                            ) : (
                                                                <Text>{contractor?.business?.type}</Text>
                                                            )}
                                                        </Flex>
                                                        <Flex direction="column" w={"100%"} justify={"space-between"} align="center">
                                                            <Text fontWeight={"semibold"}>Business Description:</Text>
                                                            {editing ? (
                                                                <Textarea
                                                                    w={"95%"}
                                                                    name="business.description"
                                                                    value={contractor?.business?.description || ''}
                                                                    onChange={handleInputChange}
                                                                    placeholder="Enter a brief description of your business"
                                                                />
                                                            ) : (
                                                                <Text>{contractor?.business?.description}</Text>
                                                            )}
                                                        </Flex>
                                                        <Flex direction="column" w={"100%"} justify={"space-between"} align="center">
                                                            <Text fontWeight={"semibold"}>Add Logo:</Text>
                                                            <Text fontSize={"xs"}>.PNG, .JPEG, .WEBP</Text>
                                                            {!loading && (
                                                                <>
                                                                    <Input w={"95%"} type="file" onChange={handleFileChange} />
                                                                </>
                                                            )}
                                                            {loading && <Spinner />}
                                                        </Flex>
                                                        {logoUrl && (
                                                            <Flex w={"100%"} justify={"center"}>
                                                                <Image src={logoUrl} alt="Logo" w={'auto'} h={'100px'} />
                                                            </Flex>
                                                        )}
                                                        {error && <Text color="red.500">{error}</Text>}
                                                        {editing ? (
                                                            <VStack w={"100%"} p={2} spacing={2} align={"flex-start"}>
                                                                <Text fontWeight={"semibold"}>Address:</Text>
                                                                <Input w={"100%"}
                                                                    placeholder="Street"
                                                                    name="business.address.street"
                                                                    value={contractor?.business?.address?.street || ''}
                                                                    onChange={handleInputChange}
                                                                />
                                                                <Input w={"100%"}
                                                                    placeholder="City"
                                                                    name="business.address.city"
                                                                    value={contractor?.business?.address?.city || ''}
                                                                    onChange={handleInputChange}
                                                                />
                                                                <Box w={"100%"}>
                                                                    <FormLabel>State</FormLabel>
                                                                    <Menu>
                                                                        <MenuButton as={Button} rightIcon={<Icon as={FontAwesomeIcon} icon={faChevronCircleDown} />} w="100%">
                                                                            {contractor?.business?.address?.state || 'Select state'}
                                                                        </MenuButton>
                                                                        <MenuList maxHeight="200px" overflowY="auto">
                                                                            {states.map(state => (
                                                                                <MenuItem key={state} onClick={() => handleStateChange(state)}>
                                                                                    {state}
                                                                                </MenuItem>
                                                                            ))}
                                                                        </MenuList>
                                                                    </Menu>
                                                                </Box>
                                                                <HStack w={"100%"}>
                                                                    <Input w={"70%"}
                                                                        placeholder="Zip Code"
                                                                        name="business.address.zipCode"
                                                                        value={contractor?.business?.address?.zipCode || ''}
                                                                        onChange={handleInputChange}
                                                                    />
                                                                    <Input w={"30%"}
                                                                        placeholder="Country"
                                                                        name="business.address.country"
                                                                        value={'US'}
                                                                        onChange={handleInputChange}
                                                                    />
                                                                </HStack>
                                                            </VStack>
                                                        ) : (
                                                            <VStack w={"100%"} spacing={1} align={"flex-start"}>
                                                                <Text fontWeight={"semibold"}>Address:</Text>
                                                                <Text>{contractor?.business?.address?.street}</Text>
                                                                <Text>{contractor?.business?.address?.city}, {contractor?.business?.address?.state} {contractor?.business?.address?.zipCode}</Text>
                                                                <Input
                                                                    w={"30%"}
                                                                    placeholder="Country"
                                                                    name="business.address.country"
                                                                    value={'US'}
                                                                    onChange={handleInputChange}
                                                                />
                                                            </VStack>
                                                        )}
                                                        <Flex direction="column" w={"100%"} justify={"space-between"} align="center">
                                                            <Text fontWeight={"semibold"}>Business Phone:</Text>
                                                            {editing ? (
                                                                <Input w={"95%"}
                                                                    name="business.phone"
                                                                    value={contractor?.business?.phone || ''}
                                                                    onChange={handleInputChange}
                                                                />
                                                            ) : (
                                                                <Text>{contractor?.business?.phone}</Text>
                                                            )}
                                                        </Flex>
                                                        <Flex direction="column" w={"100%"} justify={"space-between"} align="center">
                                                            <Text fontWeight={"semibold"}>Business Email:</Text>
                                                            {editing ? (
                                                                <Input w={"95%"}
                                                                    name="business.email"
                                                                    value={contractor?.business?.email || ''}
                                                                    onChange={handleInputChange}
                                                                />
                                                            ) : (
                                                                <Text>{contractor?.business?.email}</Text>
                                                            )}
                                                        </Flex>
                                                        <Flex direction="column" w={"100%"} justify={"space-between"} align="center">
                                                            <Text fontWeight={"semibold"}>Business Website:</Text>
                                                            {editing ? (
                                                                <Input w={"95%"}
                                                                    name="business.website"
                                                                    value={contractor?.business?.website || ''}
                                                                    onChange={handleInputChange}
                                                                />
                                                            ) : (
                                                                <Text>{contractor?.business?.website}</Text>
                                                            )}
                                                        </Flex>
                                                        <Flex direction="column" w={"100%"} justify={"space-between"} align="center">
                                                            <Text fontWeight={"semibold"}>Years in Business:</Text>
                                                            {editing ? (
                                                                <Input w={"95%"}
                                                                    type="number"
                                                                    name="business.yearsInBusiness"
                                                                    value={contractor?.business?.yearsInBusiness || ''}
                                                                    onChange={handleInputChange}
                                                                />
                                                            ) : (
                                                                <Text>{contractor?.business?.yearsInBusiness}</Text>
                                                            )}
                                                        </Flex>
                                                        <Flex direction="column" w={"100%"} justify={"space-between"} align="center">
                                                            <Text fontWeight={"semibold"}>Years of Experience:</Text>
                                                            {editing ? (
                                                                <Input w={"95%"}
                                                                    type="number"
                                                                    name="yearsOfExperience"
                                                                    value={contractor?.yearsOfExperience || ''}
                                                                    onChange={handleInputChange}
                                                                    placeholder="Enter total years of experience"
                                                                />
                                                            ) : (
                                                                <Text>{contractor?.yearsOfExperience}</Text>
                                                            )}
                                                        </Flex>
                                                    </VStack>
                                                )}

                                                {currentStep === 3 && (
                                                    <VStack w={"100%"} p={2} spacing={4} align={"flex-start"}>
                                                        <Heading size={"md"}>Additional Information</Heading>
                                                        <Flex w={"100%"} justify={"space-between"} align="center">
                                                            <Text fontWeight={"semibold"}>Services Offered:</Text>
                                                            {editing ? (
                                                                <Flex wrap="wrap" w="70%">
                                                                    {contractor?.servicesOffered?.map((service, index) => (
                                                                        <Tag
                                                                            key={service}
                                                                            m={1}
                                                                            variant="solid"
                                                                            colorScheme="blue"
                                                                            borderRadius="full"
                                                                        >
                                                                            {service}
                                                                            <TagCloseButton onClick={() => {
                                                                                setContractor(prev => ({
                                                                                    ...prev,
                                                                                    servicesOffered: prev.servicesOffered.filter((_, i) => i !== index)
                                                                                }));
                                                                            }} />
                                                                        </Tag>
                                                                    ))}
                                                                    <Input
                                                                        placeholder="Enter a service and press enter"
                                                                        onKeyDown={(e) => {
                                                                            if (e.key === 'Enter' && e.target.value.trim() !== '') {
                                                                                const newService = e.target.value.trim();
                                                                                setContractor(prev => ({
                                                                                    ...prev,
                                                                                    servicesOffered: [...prev.servicesOffered, newService]
                                                                                }));
                                                                                e.target.value = '';
                                                                            }
                                                                        }}
                                                                    />
                                                                </Flex>
                                                            ) : (
                                                                <Flex wrap="wrap">
                                                                    {contractor?.servicesOffered?.map(service => (
                                                                        <Tag key={service} m={1}>{service}</Tag>
                                                                    ))}
                                                                </Flex>
                                                            )}
                                                        </Flex>
                                                        <Flex w={"100%"} justify={"space-between"} align="center">
                                                            <Text fontWeight={"semibold"}>Warranties:</Text>
                                                            {editing ? (
                                                                <Flex wrap="wrap" w="70%">
                                                                    {contractor?.warranties?.map((warranty, index) => (
                                                                        <Tag
                                                                            key={warranty}
                                                                            m={1}
                                                                            variant="solid"
                                                                            colorScheme="green"
                                                                            borderRadius="full"
                                                                        >
                                                                            {warranty}
                                                                            <TagCloseButton onClick={() => {
                                                                                setContractor(prev => ({
                                                                                    ...prev,
                                                                                    warranties: prev.warranties.filter((_, i) => i !== index)
                                                                                }));
                                                                            }} />
                                                                        </Tag>
                                                                    ))}
                                                                    <Input
                                                                        placeholder="Enter a warranty and press enter"
                                                                        onKeyDown={(e) => {
                                                                            if (e.key === 'Enter' && e.target.value.trim() !== '') {
                                                                                const newWarranty = e.target.value.trim();
                                                                                setContractor(prev => ({
                                                                                    ...prev,
                                                                                    warranties: [...prev.warranties, newWarranty]
                                                                                }));
                                                                                e.target.value = '';
                                                                            }
                                                                        }}
                                                                    />
                                                                </Flex>
                                                            ) : (
                                                                <Flex wrap="wrap">
                                                                    {contractor?.warranties?.map(warranty => (
                                                                        <Tag key={warranty} m={1}>{warranty}</Tag>
                                                                    ))}
                                                                </Flex>
                                                            )}
                                                        </Flex>
                                                    </VStack>
                                                )}

                                                {currentStep === 4 && (
                                                    <VStack w={"100%"} spacing={4} align={"flex-start"}>
                                                        <Heading size={"md"}>Licensing Information</Heading>
                                                        {contractor.licenses.map((license, index) => (
                                                            <Box key={index} w={"100%"}>
                                                                <Input mb={2} placeholder="License Type" value={license.type} onChange={e => handleLicenseChange(index, 'type', e.target.value)} />
                                                                <Input mb={2} placeholder="License Number" value={license.number} onChange={e => handleLicenseChange(index, 'number', e.target.value)} />
                                                                <Input mb={2} type="date" placeholder="Expiry Date" value={license.expiryDate} onChange={e => handleLicenseChange(index, 'expiryDate', e.target.value)} />
                                                                <Textarea
                                                                    mb={2}
                                                                    placeholder="Certifications"
                                                                    value={license.certifications?.join(', ') || ''}
                                                                    onChange={e => handleLicenseChange(index, 'certifications', e.target.value.split(', '))}
                                                                />
                                                                <Button onClick={() => removeLicense(index)} colorScheme="red" size="sm" leftIcon={<FontAwesomeIcon icon={faMinus} />}>
                                                                    Remove
                                                                </Button>
                                                            </Box>
                                                        ))}
                                                        <Button onClick={addLicense} bg="#1DADE3" _hover={"blue"} color={"white"}
                                                            size="sm" leftIcon={<FontAwesomeIcon icon={faPlus} />}>
                                                            Add License
                                                        </Button>
                                                    </VStack>
                                                )}

                                                {currentStep === 5 && (
                                                    <VStack w={"100%"} spacing={4} align={"flex-start"}>
                                                        <Heading size={"md"}>Insurance Information</Heading>
                                                        {contractor.insurance.map((ins, index) => (
                                                            <Box key={index} w={"100%"}>
                                                                <Input mb={2} placeholder="Insurance Provider" value={ins.provider} onChange={(e) => handleInsuranceChange(index, 'provider', e.target.value)} />
                                                                <Input mb={2} placeholder="Insurance Type" value={ins.type} onChange={(e) => handleInsuranceChange(index, 'type', e.target.value)} />
                                                                <Input mb={2} placeholder="Policy Number" value={ins.policyNumber} onChange={(e) => handleInsuranceChange(index, 'policyNumber', e.target.value)} />
                                                                <Input mb={2} placeholder="Coverage Amount" value={ins.coverageAmount} onChange={(e) => handleInsuranceChange(index, 'coverageAmount', e.target.value)} />
                                                                <Input mb={2} type="date" placeholder="Expiry Date" value={ins.expiryDate} onChange={(e) => handleInsuranceChange(index, 'expiryDate', e.target.value)} />
                                                                <Button onClick={() => removeInsurance(index)} colorScheme="red" size="sm" leftIcon={<FontAwesomeIcon icon={faMinus} />}>
                                                                    Remove
                                                                </Button>
                                                            </Box>
                                                        ))}
                                                        <Button onClick={addInsurance} bg="#1DADE3" _hover={"blue"} color={"white"} size="sm" leftIcon={<FontAwesomeIcon icon={faPlus} />}>
                                                            Add Insurance
                                                        </Button>
                                                    </VStack>
                                                )}

                                                {currentStep === 6 && (
                                                    <VStack w={"100%"} spacing={4} align={"flex-start"}>
                                                        <Heading size={"md"}>Bond Information</Heading>
                                                        {contractor?.bonds?.map((bond, index) => (
                                                            <Box key={index} w={"100%"}>
                                                                <Input mb={2} placeholder="Bond Number" value={bond.bondNumber} onChange={e => handleBondChange(index, 'bondNumber', e.target.value)} />
                                                                <Input mb={2} placeholder="Bonding Company" value={bond.bondingCompany} onChange={e => handleBondChange(index, 'bondingCompany', e.target.value)} />
                                                                <Input mb={2} type="number" placeholder="Bond Amount" value={bond.amount} onChange={e => handleBondChange(index, 'bondAmount', e.target.value)} />
                                                                <Input mb={2} type="date" placeholder="Expiry Date" value={bond.expiryDate} onChange={e => handleBondChange(index, 'expiryDate', e.target.value)} />
                                                                <Button onClick={() => removeBond(index)} colorScheme="red" size="sm" leftIcon={<FontAwesomeIcon icon={faMinus} />}>
                                                                    Remove
                                                                </Button>
                                                            </Box>
                                                        ))}
                                                        <Button onClick={addBond} bg="#1DADE3" _hover={"blue"} color={"white"} size="sm" leftIcon={<FontAwesomeIcon icon={faPlus} />}>
                                                            Add Bond
                                                        </Button>
                                                    </VStack>
                                                )}

                                                <HStack w={"100%"} justify={"space-between"} mt={4}>
                                                    <Button onClick={() => setCurrentStep(currentStep - 1)} colorScheme="blue" leftIcon={<FontAwesomeIcon icon={faAnglesLeft} />}>
                                                        Back
                                                    </Button>
                                                    {currentStep === 6 ? (
                                                        <Button onClick={handleSaveAndCreateStripeAccount} bg="#1DADE3" _hover={"blue"} color={"white"} rightIcon={<FontAwesomeIcon icon={faAnglesRight} />}>
                                                            Submit
                                                        </Button>
                                                    ) : (
                                                        <Button onClick={() => setCurrentStep(currentStep + 1)} colorScheme="blue" rightIcon={<FontAwesomeIcon icon={faAnglesRight} />}>
                                                            Next
                                                        </Button>
                                                    )}
                                                </HStack>
                                            </Box>
                                        </VStack>
                                    )}
                                </>
                            )}
                        </Flex>
                    </Flex>
                ) : (
                    <Flex m={{ base: 0, lg: 4 }} direction="column" w="100%" justify="center">
                        <Flex w={"100%"} direction="column" align="center" justify="flex-start">
                            <Flex w={"100%"} justify={"center"}><Image mb={4} src={Logo} w={"400px"} h={"auto"} /></Flex>
                            {loading ? (
                                <Spinner />
                            ) : (
                                <>
                                    {currentStep === 1 && (
                                        <VStack w={"100%"} p={8} spacing={4} align={"center"}>
                                            <Box w={"100%"} maxW={"900px"} p={8} borderRadius={"lg"} border={"1px"} borderColor={"lightgray"} shadow={"lg"}>
                                                <VerifyPhone onPhoneVerified={onPhoneVerified} />
                                            </Box>
                                        </VStack>
                                    )}

                                    {currentStep >= 2 && (
                                        <VStack w={"100%"} p={8} spacing={4} align={"center"}>
                                            <Box w={"100%"} maxW={"900px"} p={8} borderRadius={"lg"} border={"1px"} borderColor={"lightgray"} shadow={"lg"}>
                                                {currentStep === 2 && (
                                                    <VStack w={"100%"} spacing={4} align={"flex-start"}>
                                                        <Heading size={"md"}>Business Information</Heading>
                                                        <Flex w={"100%"} justify={"space-between"} align="center">
                                                            <Text fontWeight={"semibold"}>Business Name:</Text>
                                                            {editing ? (
                                                                <Input w={"70%"} name="business.name" value={contractor?.business?.name || ''} onChange={handleInputChange} />
                                                            ) : (
                                                                <Text>{contractor?.business?.name}</Text>
                                                            )}
                                                        </Flex>
                                                        <Flex w={"100%"} justify={"space-between"} align="center">
                                                            <Text fontWeight={"semibold"}>Business Type:</Text>
                                                            {editing ? (
                                                                <Select w={"70%"} name="business.type" value={contractor?.business?.type || ''} onChange={handleInputChange}>
                                                                    <option value="">Select Business Type</option>
                                                                    <option value="Sole Proprietorship">Sole Proprietorship</option>
                                                                    <option value="Partnership">Partnership</option>
                                                                    <option value="Corporation">Corporation</option>
                                                                </Select>
                                                            ) : (
                                                                <Text>{contractor?.business?.type}</Text>
                                                            )}
                                                        </Flex>
                                                        <Flex w={"100%"} justify={"space-between"} align="center">
                                                            <Text fontWeight={"semibold"}>Business Description:</Text>
                                                            {editing ? (
                                                                <Textarea
                                                                    w={"70%"}
                                                                    name="business.description"
                                                                    value={contractor?.business?.description || ''}
                                                                    onChange={handleInputChange}
                                                                    placeholder="Enter a brief description of your business"
                                                                />
                                                            ) : (
                                                                <Text>{contractor?.business?.description}</Text>
                                                            )}
                                                        </Flex>
                                                        <Flex w={"100%"} justify={"space-between"} align="center">
                                                            <Text fontWeight={"semibold"}>Add Logo:</Text>
                                                            <Text fontSize={"xs"}>.PNG, .JPEG, .WEBP</Text>
                                                            {!loading && (
                                                                <>
                                                                    <Input w={"70%"} type="file" onChange={handleFileChange} />
                                                                </>
                                                            )}
                                                            {loading && <Spinner />}
                                                        </Flex>
                                                        {logoUrl && (
                                                            <Flex w={"100%"} justify={"center"}>
                                                                <Image src={logoUrl} alt="Logo" w={'auto'} h={'100px'} />
                                                            </Flex>
                                                        )}
                                                        {error && <Text color="red.500">{error}</Text>}
                                                        {editing ? (
                                                            <VStack w={"100%"} spacing={2} align={"flex-start"}>
                                                                <Text fontWeight={"semibold"}>Address:</Text>
                                                                <Input w={"100%"}
                                                                    placeholder="Street"
                                                                    name="business.address.street"
                                                                    value={contractor?.business?.address?.street || ''}
                                                                    onChange={handleInputChange}
                                                                />
                                                                <HStack w={"100%"}>
                                                                    <Input w={"70%"}
                                                                        placeholder="City"
                                                                        name="business.address.city"
                                                                        value={contractor?.business?.address?.city || ''}
                                                                        onChange={handleInputChange}
                                                                    />
                                                                    <Select
                                                                        placeholder="Select state"
                                                                        name="business.address.state"
                                                                        value={contractor?.business?.address?.state || ''}
                                                                        onChange={handleInputChange}
                                                                        w="30%"
                                                                    >
                                                                        {states.map((state) => (
                                                                            <option key={state} value={state}>{state}</option>
                                                                        ))}
                                                                    </Select>
                                                                </HStack>
                                                                <HStack w={"100%"}>
                                                                    <Input w={"70%"}
                                                                        placeholder="Zip Code"
                                                                        name="business.address.zipCode"
                                                                        value={contractor?.business?.address?.zipCode || ''}
                                                                        onChange={handleInputChange}
                                                                    />
                                                                    <Input w={"30%"}
                                                                        placeholder="Country"
                                                                        name="business.address.country"
                                                                        value={'US'}
                                                                        onChange={handleInputChange}
                                                                    />
                                                                </HStack>
                                                            </VStack>
                                                        ) : (
                                                            <VStack w={"100%"} spacing={1} align={"flex-start"}>
                                                                <Text fontWeight={"semibold"}>Address:</Text>
                                                                <Text>{contractor?.business?.address?.street}</Text>
                                                                <Text>{contractor?.business?.address?.city}, {contractor?.business?.address?.state} {contractor?.business?.address?.zipCode}</Text>
                                                                <Input
                                                                    w={"30%"}
                                                                    placeholder="Country"
                                                                    name="business.address.country"
                                                                    value={'US'}
                                                                    onChange={handleInputChange}
                                                                />
                                                            </VStack>
                                                        )}
                                                        <Flex w={"100%"} justify={"space-between"} align="center">
                                                            <Text fontWeight={"semibold"}>Business Phone:</Text>
                                                            {editing ? (
                                                                <Input w={"70%"}
                                                                    name="business.phone"
                                                                    value={contractor?.business?.phone || ''}
                                                                    onChange={handleInputChange}
                                                                />
                                                            ) : (
                                                                <Text>{contractor?.business?.phone}</Text>
                                                            )}
                                                        </Flex>
                                                        <Flex w={"100%"} justify={"space-between"} align="center">
                                                            <Text fontWeight={"semibold"}>Business Email:</Text>
                                                            {editing ? (
                                                                <Input w={"70%"}
                                                                    name="business.email"
                                                                    value={contractor?.business?.email || ''}
                                                                    onChange={handleInputChange}
                                                                />
                                                            ) : (
                                                                <Text>{contractor?.business?.email}</Text>
                                                            )}
                                                        </Flex>
                                                        <Flex w={"100%"} justify={"space-between"} align="center">
                                                            <Text fontWeight={"semibold"}>Business Website:</Text>
                                                            {editing ? (
                                                                <Input w={"70%"}
                                                                    name="business.website"
                                                                    value={contractor?.business?.website || ''}
                                                                    onChange={handleInputChange}
                                                                />
                                                            ) : (
                                                                <Text>{contractor?.business?.website}</Text>
                                                            )}
                                                        </Flex>
                                                        <Flex w={"100%"} justify={"space-between"} align="center">
                                                            <Text fontWeight={"semibold"}>Years in Business:</Text>
                                                            {editing ? (
                                                                <Input w={"70%"}
                                                                    type="number"
                                                                    name="business.yearsInBusiness"
                                                                    value={contractor?.business?.yearsInBusiness || ''}
                                                                    onChange={handleInputChange}
                                                                />
                                                            ) : (
                                                                <Text>{contractor?.business?.yearsInBusiness}</Text>
                                                            )}
                                                        </Flex>
                                                        <Flex w={"100%"} justify={"space-between"} align="center">
                                                            <Text fontWeight={"semibold"}>Years of Experience:</Text>
                                                            {editing ? (
                                                                <Input w={"70%"}
                                                                    type="number"
                                                                    name="yearsOfExperience"
                                                                    value={contractor?.yearsOfExperience || ''}
                                                                    onChange={handleInputChange}
                                                                    placeholder="Enter total years of experience"
                                                                />
                                                            ) : (
                                                                <Text>{contractor?.yearsOfExperience}</Text>
                                                            )}
                                                        </Flex>
                                                    </VStack>
                                                )}
                                                {currentStep === 3 && (
                                                    <VStack w={"100%"} p={2} spacing={4} align={"flex-start"}>
                                                        <Heading size={"md"}>Additional Information</Heading>
                                                        <Flex w={"100%"} justify={"space-between"} align="center">
                                                            <Text fontWeight={"semibold"}>Services Offered:</Text>
                                                            {editing ? (
                                                                <Flex wrap="wrap" w="70%">
                                                                    {contractor?.servicesOffered?.map((service, index) => (
                                                                        <Tag
                                                                            key={service}
                                                                            m={1}
                                                                            variant="solid"
                                                                            colorScheme="blue"
                                                                            borderRadius="full"
                                                                        >
                                                                            {service}
                                                                            <TagCloseButton onClick={() => {
                                                                                setContractor(prev => ({
                                                                                    ...prev,
                                                                                    servicesOffered: prev.servicesOffered.filter((_, i) => i !== index)
                                                                                }));
                                                                            }} />
                                                                        </Tag>
                                                                    ))}
                                                                    <Input
                                                                        placeholder="Enter a service and press enter"
                                                                        onKeyDown={(e) => {
                                                                            if (e.key === 'Enter' && e.target.value.trim() !== '') {
                                                                                const newService = e.target.value.trim();
                                                                                setContractor(prev => ({
                                                                                    ...prev,
                                                                                    servicesOffered: [...prev.servicesOffered, newService]
                                                                                }));
                                                                                e.target.value = '';
                                                                            }
                                                                        }}
                                                                    />
                                                                </Flex>
                                                            ) : (
                                                                <Flex wrap="wrap">
                                                                    {contractor?.servicesOffered?.map(service => (
                                                                        <Tag key={service} m={1}>{service}</Tag>
                                                                    ))}
                                                                </Flex>
                                                            )}
                                                        </Flex>
                                                        <Flex w={"100%"} justify={"space-between"} align="center">
                                                            <Text fontWeight={"semibold"}>Warranties:</Text>
                                                            {editing ? (
                                                                <Flex wrap="wrap" w="70%">
                                                                    {contractor?.warranties?.map((warranty, index) => (
                                                                        <Tag
                                                                            key={warranty}
                                                                            m={1}
                                                                            variant="solid"
                                                                            colorScheme="green"
                                                                            borderRadius="full"
                                                                        >
                                                                            {warranty}
                                                                            <TagCloseButton onClick={() => {
                                                                                setContractor(prev => ({
                                                                                    ...prev,
                                                                                    warranties: prev.warranties.filter((_, i) => i !== index)
                                                                                }));
                                                                            }} />
                                                                        </Tag>
                                                                    ))}
                                                                    <Input
                                                                        placeholder="Enter a warranty and press enter"
                                                                        onKeyDown={(e) => {
                                                                            if (e.key === 'Enter' && e.target.value.trim() !== '') {
                                                                                const newWarranty = e.target.value.trim();
                                                                                setContractor(prev => ({
                                                                                    ...prev,
                                                                                    warranties: [...prev.warranties, newWarranty]
                                                                                }));
                                                                                e.target.value = '';
                                                                            }
                                                                        }}
                                                                    />
                                                                </Flex>
                                                            ) : (
                                                                <Flex wrap="wrap">
                                                                    {contractor?.warranties?.map(warranty => (
                                                                        <Tag key={warranty} m={1}>{warranty}</Tag>
                                                                    ))}
                                                                </Flex>
                                                            )}
                                                        </Flex>
                                                    </VStack>
                                                )}

                                                {currentStep === 4 && (
                                                    <VStack w={"100%"} spacing={4} align={"flex-start"}>
                                                        <Heading size={"md"}>Licensing Information</Heading>
                                                        {contractor.licenses.map((license, index) => (
                                                            <Box key={index} w={"100%"}>
                                                                <Input mb={2} placeholder="License Type" value={license.type} onChange={e => handleLicenseChange(index, 'type', e.target.value)} />
                                                                <Input mb={2} placeholder="License Number" value={license.number} onChange={e => handleLicenseChange(index, 'number', e.target.value)} />
                                                                <Input mb={2} type="date" placeholder="Expiry Date" value={license.expiryDate} onChange={e => handleLicenseChange(index, 'expiryDate', e.target.value)} />
                                                                <Textarea
                                                                    mb={2}
                                                                    placeholder="Certifications"
                                                                    value={license.certifications?.join(', ') || ''}
                                                                    onChange={e => handleLicenseChange(index, 'certifications', e.target.value.split(', '))}
                                                                />
                                                                <Button onClick={() => removeLicense(index)} colorScheme="red" size="sm" leftIcon={<FontAwesomeIcon icon={faMinus} />}>
                                                                    Remove
                                                                </Button>
                                                            </Box>
                                                        ))}
                                                        <Button onClick={addLicense} bg="#1DADE3" _hover={"blue"} color={"white"}
                                                            size="sm" leftIcon={<FontAwesomeIcon icon={faPlus} />}>
                                                            Add License
                                                        </Button>
                                                    </VStack>
                                                )}

                                                {currentStep === 5 && (
                                                    <VStack w={"100%"} spacing={4} align={"flex-start"}>
                                                        <Heading size={"md"}>Insurance Information</Heading>
                                                        {contractor.insurance.map((ins, index) => (
                                                            <Box key={index} w={"100%"}>
                                                                <Input mb={2} placeholder="Insurance Provider" value={ins.provider} onChange={(e) => handleInsuranceChange(index, 'provider', e.target.value)} />
                                                                <Input mb={2} placeholder="Insurance Type" value={ins.type} onChange={(e) => handleInsuranceChange(index, 'type', e.target.value)} />
                                                                <Input mb={2} placeholder="Policy Number" value={ins.policyNumber} onChange={(e) => handleInsuranceChange(index, 'policyNumber', e.target.value)} />
                                                                <Input mb={2} placeholder="Coverage Amount" value={ins.coverageAmount} onChange={(e) => handleInsuranceChange(index, 'coverageAmount', e.target.value)} />
                                                                <Input mb={2} type="date" placeholder="Expiry Date" value={ins.expiryDate} onChange={(e) => handleInsuranceChange(index, 'expiryDate', e.target.value)} />
                                                                <Button onClick={() => removeInsurance(index)} colorScheme="red" size="sm" leftIcon={<FontAwesomeIcon icon={faMinus} />}>
                                                                    Remove
                                                                </Button>
                                                            </Box>
                                                        ))}
                                                        <Button onClick={addInsurance} bg="#1DADE3" _hover={"blue"} color={"white"} size="sm" leftIcon={<FontAwesomeIcon icon={faPlus} />}>
                                                            Add Insurance
                                                        </Button>
                                                    </VStack>
                                                )}

                                                {currentStep === 6 && (
                                                    <VStack w={"100%"} spacing={4} align={"flex-start"}>
                                                        <Heading size={"md"}>Bond Information</Heading>
                                                        {contractor?.bonds?.map((bond, index) => (
                                                            <Box key={index} w={"100%"}>
                                                                <Input mb={2} placeholder="Bond Number" value={bond.bondNumber} onChange={e => handleBondChange(index, 'bondNumber', e.target.value)} />
                                                                <Input mb={2} placeholder="Bonding Company" value={bond.bondingCompany} onChange={e => handleBondChange(index, 'bondingCompany', e.target.value)} />
                                                                <Input mb={2} type="number" placeholder="Bond Amount" value={bond.amount} onChange={e => handleBondChange(index, 'bondAmount', e.target.value)} />
                                                                <Input mb={2} type="date" placeholder="Expiry Date" value={bond.expiryDate} onChange={e => handleBondChange(index, 'expiryDate', e.target.value)} />
                                                                <Button onClick={() => removeBond(index)} colorScheme="red" size="sm" leftIcon={<FontAwesomeIcon icon={faMinus} />}>
                                                                    Remove
                                                                </Button>
                                                            </Box>
                                                        ))}
                                                        <Button onClick={addBond} bg="#1DADE3" _hover={"blue"} color={"white"} size="sm" leftIcon={<FontAwesomeIcon icon={faPlus} />}>
                                                            Add Bond
                                                        </Button>
                                                    </VStack>
                                                )}

                                                <HStack w={"100%"} justify={"space-between"} mt={4}>
                                                    <Button onClick={() => setCurrentStep(currentStep - 1)} colorScheme="blue" leftIcon={<FontAwesomeIcon icon={faAnglesLeft} />}>
                                                        Back
                                                    </Button>
                                                    {currentStep === 6 ? (
                                                        <Button onClick={handleSaveAndCreateStripeAccount} bg="#1DADE3" _hover={"blue"} color={"white"} rightIcon={<FontAwesomeIcon icon={faAnglesRight} />}>
                                                            Submit
                                                        </Button>
                                                    ) : (
                                                        <Button onClick={() => setCurrentStep(currentStep + 1)} colorScheme="blue" rightIcon={<FontAwesomeIcon icon={faAnglesRight} />}>
                                                            Next
                                                        </Button>
                                                    )}
                                                </HStack>
                                            </Box>
                                        </VStack>
                                    )}
                                </>
                            )}
                        </Flex>
                    </Flex>
                )}
            </Flex>
        </Flex>
    );
}

export default BusinessAccount;