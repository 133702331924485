import React, { useState } from 'react';
import { Button, useToast, Input, FormControl, FormLabel, Flex, InputGroup, InputLeftElement, Text, useBreakpointValue } from '@chakra-ui/react';
import { loadStripe } from '@stripe/stripe-js';
import axios from 'axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBuildingColumns, faCreditCard } from '@fortawesome/free-solid-svg-icons';

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY);

const CheckoutRedirectButton = ({ bidTotal, paymentTotal, transferGroupId, title, bidId, projectId }) => {
    const toast = useToast();
    const [paymentAmount, setPaymentAmount] = useState('');
    const [loading, setLoading] = useState(false);
    const menuType = useBreakpointValue({ base: 'drawer', lg: 'regular' });

    const handleRedirectToBankCheckout = async () => {
        setLoading(true);
        if (Number(paymentTotal) + Number(paymentAmount) > Number(bidTotal)) {
            toast({
                title: 'Payment Error',
                description: 'The total payment exceeds the agreed bid total.',
                status: 'error',
                duration: 9000,
                isClosable: true,
            });
            setLoading(false);
            return;
        }
        try {
            const stripe = await stripePromise;
            const response = await axios.post(
                `${process.env.REACT_APP_API_URL}/api/bank/create-checkout-session`,
                { paymentAmount, transferGroupId, title, bidId, projectId },
                {
                    headers: {
                        'Authorization': `Bearer ${localStorage.getItem('token')}`,
                        'Content-Type': 'application/json'
                    }
                }
            );
            const result = await stripe.redirectToCheckout({
                sessionId: response.data.sessionId,
            });
            if (result.error) {
                toast({
                    title: 'Checkout failed',
                    description: result.error.message,
                    status: 'error',
                    duration: 9000,
                    isClosable: true,
                });
            }
        } catch (error) {
            console.error('Error redirecting to Stripe Checkout:', error);
            toast({
                title: 'Error',
                description: 'Failed to redirect to payment.',
                status: 'error',
                duration: 9000,
                isClosable: true,
            });
        }
        setLoading(false);
    };

    const handleRedirectToCardCheckout = async () => {
        setLoading(true);
        if (Number(paymentTotal) + Number(paymentAmount) > Number(bidTotal)) {
            toast({
                title: 'Payment Error',
                description: 'The total payment exceeds the agreed bid total.',
                status: 'error',
                duration: 9000,
                isClosable: true,
            });
            setLoading(false);
            return;
        }
        try {
            const stripe = await stripePromise;
            const response = await axios.post(
                `${process.env.REACT_APP_API_URL}/api/card/create-checkout-session`,
                { paymentAmount, transferGroupId, title, bidId, projectId },
                {
                    headers: {
                        'Authorization': `Bearer ${localStorage.getItem('token')}`,
                        'Content-Type': 'application/json'
                    }
                }
            );
            const result = await stripe.redirectToCheckout({
                sessionId: response.data.sessionId,
            });
            if (result.error) {
                toast({
                    title: 'Checkout failed',
                    description: result.error.message,
                    status: 'error',
                    duration: 9000,
                    isClosable: true,
                });
            }
        } catch (error) {
            console.error('Error redirecting to Stripe Checkout:', error);
            toast({
                title: 'Error',
                description: 'Failed to redirect to payment.',
                status: 'error',
                duration: 9000,
                isClosable: true,
            });
        }
        setLoading(false);
    };

    return (
        <Flex w={"100%"} align={"flex-end"} justify={"center"}>
            <Flex mt={2} w={"100%"} align={"flex-end"} justify={"space-evenly"}>
                <Flex w={{base: "50%", lg: "70%"}}>
                    <FormControl>
                        <FormLabel>Payment Amount</FormLabel>
                        <InputGroup>
                            <InputLeftElement pointerEvents='none' color='gray.300' fontSize='1.2em'>
                                $
                            </InputLeftElement>
                            <Input
                                type="number"
                                value={paymentAmount}
                                onChange={(e) => setPaymentAmount(e.target.value)}
                            />
                        </InputGroup>
                    </FormControl>
                </Flex>
                <Flex w={{base: "50%", lg: "30%"}}    justify={"center"}>
                    <Flex w={"50%"} justify={"center"}>
                        <Button ml={2} isLoading={loading} bg="#1DADE3" _hover={"blue"} color={"white"}  onClick={handleRedirectToCardCheckout} isDisabled={!paymentAmount}>
                            <FontAwesomeIcon icon={faCreditCard} /> <Text ml={2}>Card</Text>
                        </Button>
                    </Flex>
                    <Flex w={"50%"} justify={"center"}>
                        <Button ml={2} isLoading={loading} bg="#1DADE3" _hover={"blue"} color={"white"}  onClick={handleRedirectToBankCheckout} isDisabled={!paymentAmount}>
                            <FontAwesomeIcon icon={faBuildingColumns} /> <Text ml={2}>Bank</Text>
                        </Button>
                    </Flex>
                </Flex>
            </Flex>
        </Flex>
    );
};

export default CheckoutRedirectButton;