// Header.js

import React from 'react';
import { Flex, HStack, Image, useBreakpointValue } from '@chakra-ui/react';
import ExpansionBars from './SideExpansion';
import Logo from '../assets/UTAH LOGO.webp';
import { useNavigate } from 'react-router-dom';
import UserMenu from './UserMenu';

function Header() {
    const menuType = useBreakpointValue({ base: 'drawer', lg: 'regular' });
    const role = localStorage.getItem('role');
    const firstName = localStorage.getItem('firstName');
    const lastName = localStorage.getItem('lastName');
    const profilePic = localStorage.getItem('profilePic');
    const navigate = useNavigate();

    const handleGoHome = () => {
        navigate('/');
    };

    return (
        <Flex mt={{base: 4, lg: 0 }} w={"100%"}>
            {menuType === 'drawer' ? (
                <HStack w={"100%"} bg={"gray.700"} borderRadius={"xl"}>
                    <Flex ml={4} w={"100%"} justify={"space-between"} align={"center"}>
                        <Image onClick={handleGoHome} src={Logo} w={"auto"} h={"40px"} />
                        <Flex p={2} mr={4}>
                            <UserMenu role={role} firstName={firstName} lastName={lastName} profilePic={profilePic} />
                        </Flex>
                    </Flex>
                </HStack>
            ) : (
                <HStack w={"100%"} bg={"gray.700"} borderRadius={"xl"}>
                    <Flex ml={4} w={"100%"} justify={"space-between"} align={"center"}>
                        <ExpansionBars />
                        <Flex p={2} mr={4}>
                            <UserMenu role={role} firstName={firstName} lastName={lastName} profilePic={profilePic} />
                        </Flex>
                    </Flex>
                </HStack>
            )}
        </Flex>
    );
}

export default Header;