import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";
import { io } from 'socket.io-client';
import { useAtom } from 'jotai';
import { socketAtom } from '../socketAtom'
import axios from 'axios';
import { format, isValid, parseISO } from 'date-fns';
import { Box, Button, FormControl, FormLabel, Input, Textarea, useToast, Select, Flex, Heading, Image, HStack, Text, Badge, SimpleGrid, Accordion, AccordionItem, AccordionButton, AccordionPanel, AccordionIcon, Checkbox, useBreakpointValue, VStack, Menu, MenuButton, MenuList, MenuItem, Icon } from '@chakra-ui/react';
import Logo from '../assets/Light BG HM Logo.webp';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBuildingColumns, faChevronCircleDown, faChevronDown, faCircleCheck, faCircleDot, faFileSignature, faHandBackFist, faIdCard, faMagnifyingGlassMinus, faMagnifyingGlassPlus, faPaperPlane, faTriangleExclamation, faUserShield } from '@fortawesome/free-solid-svg-icons';
import ContractorSideMenu from './SideMenu';
import { useSocketInstance } from './SideMenu';
import Header from '../elements/Header';
import MessageComponent from '../elements/MessageComponent';
import SignatureCapture from '../elements/SignatureCapture';

const safelyFormatDate = (dateStr) => {
    const date = parseISO(dateStr);
    return isValid(date) ? format(date, 'MMMM dd, yyyy') : 'No date';
};

function CreateBid() {
    const { bidId } = useParams();
    const socket = useSocketInstance();
    const menuType = useBreakpointValue({ base: 'drawer', lg: 'regular' });
    const toast = useToast();
    const [hasBid, setHasBid] = useState(false);
    const [subtotal, setSubtotal] = useState(0);
    const [feeAmount, setFeeAmount] = useState(1);
    const [total, setTotal] = useState(0);
    const [changed, setChanged] = useState(false);
    const [documentId, setDocumentId] = useState('');
    const [isEditing, setIsEditing] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [isDisabled, setIsDisabled] = useState(false);
    const [isVetted, setisVetted] = useState(false);
    const [title, setTitle] = useState('');
    const [projectDescription, setProjectDescription] = useState('');
    const [description, setDescription] = useState('');
    const [ownerId, setOwnerId] = useState('');
    const [ownerFirstName, setOwnerFirstName] = useState('');
    const [ownerLastName, setOwnerLastName] = useState('');
    const [ownerPhone, setOwnerPhone] = useState('');
    const [estimateId, setEstimateId] = useState('');
    const [contractorFirstName, setContractorFirstName] = useState('');
    const [contractorLastName, setContractorLastName] = useState('');
    const [contractorBusiness, setContractorBusiness] = useState('');
    const [selectedImage, setSelectedImage] = useState('');
    const [projectAddress, setProjectAddress] = useState({
        street: '',
        city: '',
        state: '',
        zipCode: '',
        country: '',
    });
    const [contractorAddress, setContractorAddress] = useState({
        street: '',
        city: '',
        state: '',
        zipCode: '',
        country: 'USA',
    });
    const [contractorInsurance, setContractorInsurance] = useState([{
        provider: '',
        policyNumber: '',
        coverageAmount: '',
        expiryDate: '',
        type: ''
    }]);
    const [licenses, setLicenses] = useState([{
        type: '',
        number: '',
        expiryDate: '',
    }]);
    const [contractorBonds, setContractorBonds] = useState([]);
    const [projectId, setProjectId] = useState('');
    const [isActive, setIsActive] = useState(false);
    const [isSent, setIsSent] = useState(false);
    const [status, setStatus] = useState('open');
    const [projectStatus, setProjectStatus] = useState('open');
    const [contractorId, setContractorId] = useState('');
    const [items, setItems] = useState([]);
    const [newItem, setNewItem] = useState({
        itemNumber: 0,
        name: '',
        description: '',
        quantity: 1,
        rate: 0.0,
        tax: 0.0,
        amount: 0.0,
        hideDetails: false
    });
    const [changeOrders, setChangeOrders] = useState([]);
    const [projectImages, setProjectImages] = useState([]);
    const [images, setImages] = useState([]);
    const [documents, setDocuments] = useState([]);
    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');
    const [contractorLogo, setContractorLogo] = useState('');
    const [discount, setDiscount] = useState(0);
    const [discountAmount, setDiscountAmount] = useState(0);
    const [serviceCharge, setServiceCharge] = useState(0);
    const [expirationDate, setExpirationDate] = useState('');
    const [ownerSignature, setOwnerSignature] = useState('');
    const [ownerSignatureDate, setOwnerSignatureDate] = useState('');
    const [contractorSignature, setContractorSignature] = useState('');
    const [existingConversationId, setConversationId] = useState('');
    const [contractorSignatureDate, setContractorSignatureDate] = useState('');
    const [notes, setNotes] = useState('');
    const [projectType, setProjectType] = useState('');
    const [readyToStartTimeframe, setReadyToStartTimeframe] = useState('');
    const [paymentTerms, setPaymentTerms] = useState('1/2');
    const [upfrontAmount, setUpfrontAmount] = useState({
        amount: 0,
        description: '',
    });
    const [subTotalAmount, setSubTotalAmount] = useState(0);
    const [totalAmount, setTotalAmount] = useState(0);
    const [revisionHistory, setRevisionHistory] = useState([]);
    const [stages, setStages] = useState([{
        description: '',
        upfrontAmount: '',
        completionAmount: '',
    }]);
    const [stagesTotal, setStagesTotal] = useState(0);
    const [remainingBudget, setRemainingBudget] = useState(total);
    const token = localStorage.getItem('token');
    const role = localStorage.getItem('role');

    const formatInsuranceDates = (insurance) => {
        return insurance?.map(ins => ({
            ...ins,
            expiryDate: safelyFormatDate(ins?.expiryDate)
        }));
    };

    const formatLicenseDates = (licenses) => {
        return licenses?.map(license => ({
            ...license,
            expiryDate: safelyFormatDate(license?.expiryDate)
        }));
    };

    const formatBondDates = (bonds) => {
        return bonds?.map(bond => ({
            ...bond,
            expiryDate: safelyFormatDate(bond?.expiryDate)
        }));
    };

    const fetchBid = () => {
        if (bidId) {
            axios.get(`${process.env.REACT_APP_API_URL}/api/bid/${bidId}`, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            })
                .then((response) => {
                    const { project, contractor, bid, owner } = response.data;

                    setProjectId(project?._id || '');
                    setProjectDescription(project?.description || '');
                    setProjectAddress(project?.address || { street: '', city: '', state: '', zipCode: '', country: 'USA' });
                    setProjectStatus(project?.status || 'open');
                    setIsActive(project?.isActive || false);
                    setStartDate(project?.startDate || '');
                    setEndDate(project?.endDate || '');
                    setProjectType(project?.projectType || '');
                    setReadyToStartTimeframe(project?.readyToStartTimeframe || '');
                    setProjectImages(project?.images || []);
                    setFeeAmount(project?.platformPayment === true ? 0.98 : 1 || 1);

                    setOwnerId(owner?._id || '');
                    setOwnerFirstName(owner?.firstName || '');
                    setOwnerLastName(owner?.lastName || '');
                    setOwnerPhone(owner?.phone || '');

                    setContractorId(contractor?._id || '');
                    setContractorFirstName(contractor?.firstName || '');
                    setContractorLastName(contractor?.lastName || '');
                    setContractorBusiness(contractor?.business?.name || '');
                    setContractorAddress(contractor?.business?.address || { street: '', city: '', state: '', zipCode: '', country: 'USA' });
                    setContractorLogo(contractor?.business?.logo || '');
                    setisVetted(contractor?.isVetted || false);

                    // Arrays or objects should be handled carefully to avoid null pointers
                    setContractorInsurance(formatInsuranceDates(contractor?.insurance) || []);
                    setLicenses(formatLicenseDates(contractor?.licenses) || []);
                    setContractorBonds(formatBondDates(contractor?.bonds) || []);

                    setTitle(bid?.title || project?.title);
                    setDescription(bid?.description || '');
                    setEstimateId(bid?.estimateId || '');
                    setIsSent(bid?.isSent || false);
                    setItems(bid?.items || []);
                    setChangeOrders(bid?.changeOrders || []);
                    setImages(bid?.images || []);
                    setDocuments(bid?.documents || []);
                    setDiscount(bid?.discount || 0);
                    setExpirationDate(bid?.expirationDate || '');
                    setOwnerSignature(bid?.ownerSignature || '');
                    setOwnerSignatureDate(bid?.ownerSignatureDate || '');
                    setContractorSignature(bid?.contractorSignature || '');
                    setContractorSignatureDate(bid?.contractorSignatureDate || '');
                    setNotes(bid?.notes || '');
                    setPaymentTerms(bid?.paymentTerms || '1/2');
                    setStages(bid?.customStages || []);
                    setUpfrontAmount(bid?.upfrontAmount || { amount: 0, description: '' });
                    setSubTotalAmount(bid?.subTotalAmount || 0);
                    setTotalAmount(bid?.totalAmount || 0);
                    setRevisionHistory(bid?.revisionHistory || []);
                    setHasBid(bid?._id ? true : false);
                    setDocumentId(bid?._id);
                    setConversationId(bid?.conversationId || bidId);
                    setEndDate(bid?.endDate || '');
                    setStartDate(bid?.startDate || '');
                })
                .catch((error) => {
                    console.error('Error fetching bid details:', error);
                    toast({
                        title: 'Error',
                        description: error.response?.data?.message || error.message,
                        status: 'error',
                        duration: 3000,
                        isClosable: true,
                    });
                });
        }
    };

    const [, setSocket] = useAtom(socketAtom);

    useEffect(() => {
        const newSocket = io(process.env.REACT_APP_API_URL, {
            autoConnect: true,
            auth: {
                token: token,
            },
        });

        newSocket.on('connect_error', (error) => {
            console.error('Socket connection error:', error);
        });

        setSocket(newSocket);

        return () => newSocket.close();
    }, [setSocket, token]);

    useEffect(() => {
        fetchBid();
    }, [bidId, toast]);

    useEffect(() => {
        const handleBeforeUnload = (event) => {
            if (changed) {
                event.preventDefault();
                event.returnValue = '';
            }
        };

        window.addEventListener('beforeunload', handleBeforeUnload);

        return () => {
            window.removeEventListener('beforeunload', handleBeforeUnload);
        };
    }, [changed]);

    useEffect(() => {
        const total = stages.reduce((acc, stage) => {
            // Convert string to float to ensure correct arithmetic operations
            const upfrontAmount = parseFloat(stage.upfrontAmount) || 0;
            const completionAmount = parseFloat(stage.completionAmount) || 0;
            return acc + upfrontAmount + completionAmount;
        }, 0);
        setStagesTotal(total);
    }, [stages]);

    useEffect(() => {
        const totalSpent = stages.reduce((acc, stage) => {
            // Convert string to float to ensure correct arithmetic operations
            const upfrontAmount = parseFloat(stage.upfrontAmount) || 0;
            const completionAmount = parseFloat(stage.completionAmount) || 0;
            return acc + upfrontAmount + completionAmount;
        }, 0);
        setRemainingBudget(total - totalSpent);
    }, [stages, total]);

    const handleSubmit = async () => {
        const token = localStorage.getItem('token');

        if (!['1/2', 'Stages', 'UpFront', 'Completed'].includes(paymentTerms)) {
            toast({
                title: 'Error',
                description: "Invalid payment terms value.",
                status: 'error',
                duration: 3000,
                isClosable: true,
            });
            return;
        }

        let newSubtotal = 0;
        let newTotal = 0;

        if (items && items.length > 0) {
            items.forEach(item => {
                const quantity = parseFloat(item.quantity);
                const rate = parseFloat(item.rate);
                const tax = parseFloat(item.tax);
                if (!isNaN(quantity) && !isNaN(rate)) {
                    const itemSubTotal = quantity * rate;
                    newSubtotal += itemSubTotal;
                    if (!isNaN(tax)) {
                        newTotal += itemSubTotal * ((100 + tax) / 100);
                    }
                }
            });
        }

        const discountPercentage = parseFloat(discount);
        if (!isNaN(discountPercentage) && newSubtotal > 0) {
            newTotal -= newSubtotal * (discountPercentage / 100);
        }

        const newServiceCharge = (newSubtotal / feeAmount) - newSubtotal;
        newTotal += newServiceCharge;

        const newDiscountAmount = newSubtotal * (discount / 100);

        try {
            const bidData = {
                title,
                description,
                ownerId,
                conversationId: existingConversationId,
                ownerFirstName,
                ownerLastName,
                ownerPhone,
                estimateId,
                contractorFirstName,
                contractorLastName,
                contractorBusiness,
                isVetted,
                contractorAddress,
                contractorInsurance,
                contractorBonds,
                projectAddress,
                projectId,
                isActive,
                isSent,
                licenses,
                status,
                contractorId,
                items,
                changeOrders,
                images,
                documents,
                startDate,
                contractorLogo,
                endDate,
                discount,
                discountAmount: newDiscountAmount.toFixed(2),
                serviceCharge: newServiceCharge.toFixed(2),
                expirationDate,
                ownerSignature,
                ownerSignatureDate,
                contractorSignature,
                contractorSignatureDate,
                notes,
                paymentTerms,
                customStages: stages,
                upfrontAmount,
                subTotalAmount: newSubtotal.toFixed(2),
                totalAmount: newTotal.toFixed(2),
                revisionHistory,
            };

            const method = hasBid ? 'put' : 'post';
            const url = `${process.env.REACT_APP_API_URL}/api/bid/${documentId || ''}`;

            await axios({
                method: method,
                url: url,
                data: bidData,
                headers: {
                    Authorization: `Bearer ${token}`
                }
            })

            toast({
                title: 'Document Saved Successfully.',
                status: 'success',
                duration: 3000,
                isClosable: true,
            });
            setChanged(false);
            setIsEditing(false);
            fetchBid(bidId);
        } catch (error) {
            toast({
                title: 'Error submitting bid',
                description: error.response?.data?.message || error.message,
                status: 'error',
                duration: 3000,
                isClosable: true,
            });
        }
    };

    const handleEdit = () => {
        if (!isEditing) {
            setIsEditing(true);
        } else {
            setIsEditing(false);
        }
    }

    function formatPhoneNumber(phoneNumber) {
        const cleaned = ('' + phoneNumber).replace(/\D/g, '');
        const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
        if (match) {
            return `(${match[1]}) ${match[2]}-${match[3]}`;
        }
        return null;
    }

    const handleSend = async () => {
        const token = localStorage.getItem('token');

        try {
            const response = await axios.put(`${process.env.REACT_APP_API_URL}/api/send-bid/${documentId}`, {}, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            });
            if (response && response.status === 200) {
                toast({
                    title: 'Bid Sent',
                    description: "Your bid has been successfully sent.",
                    status: 'success',
                    duration: 5000,
                    isClosable: true,
                    position: "top"
                });
                setIsSent(true);

                const eventMessage = {
                    conversationId: existingConversationId,
                    message: `Proposal Sent`,
                    messageType: 'sent',
                };

                socket.emit('send-message', eventMessage);

            } else {
                throw new Error('Failed to send bid due to server error');
            }
        } catch (error) {

            toast({
                title: 'Error',
                description: error.response?.data?.message || "Failed to send bid. Please try again.",
                status: 'error',
                duration: 5000,
                isClosable: true,
                position: "top"
            });
        }
    };

    useEffect(() => {
        let newSubtotal = 0;
        let newTotal = 0;

        if (items && items.length > 0) {
            items.forEach(item => {
                if (item.quantity && item.rate) {
                    const itemSubTotal = item.quantity * item.rate;
                    newSubtotal += itemSubTotal;
                    if (item.tax !== undefined) {
                        newTotal += itemSubTotal * ((100 + item.tax) / 100);
                    }
                }
            });
        }

        if (discount && newSubtotal > 0) {
            newTotal -= (newSubtotal * (discount / 100));
        }
        const serviceCharge = (newSubtotal / feeAmount) - newSubtotal;
        newTotal += serviceCharge;

        setServiceCharge(serviceCharge.toFixed(2));
        setDiscountAmount((newSubtotal * (discount / 100)).toFixed(2));
        setSubtotal(newSubtotal.toFixed(2));
        setTotal(newTotal.toFixed(2));

        // Adjust upfront amount based on payment terms
        switch (paymentTerms) {
            case "1/2":
                setUpfrontAmount({ ...upfrontAmount, amount: (newTotal / 2).toFixed(2) });
                break;
            case "UpFront":
                setUpfrontAmount({ ...upfrontAmount, amount: newTotal.toFixed(2) });
                break;
            case "Completed":
                setUpfrontAmount({ amount: 0, description: '' }); // Nothing upfront
                break;
            case "Stages":
                if (!upfrontAmount.customized) {
                    setUpfrontAmount({ ...upfrontAmount, amount: (newTotal * 0.1).toFixed(2) });
                }
                break;  // This was missing
            default:
                setUpfrontAmount({ ...upfrontAmount, description: '' });
                break;
        }

    }, [items, discount, paymentTerms]);

    const handlePaymentTermsChange = (e) => {
        const newPaymentTerms = e.target.value;
        setPaymentTerms(newPaymentTerms);
        setChanged(true);
    };

    const handleMobilePaymentTermsChange = (newPaymentTerms) => {
        setPaymentTerms(newPaymentTerms);
        setChanged(true);
    };

    const handleAddItem = () => {
        if (!newItem.name || newItem.quantity <= 0 || newItem.rate <= 0 || newItem.tax < 0) {
            toast({
                title: "Invalid input",
                description: "Please fill all fields correctly.",
                status: "error",
                duration: 3000,
                isClosable: true,
            });
            return;
        }

        const itemAmount = calculateAmount(newItem.rate, newItem.quantity, newItem.tax);
        const newItemWithAmount = { ...newItem, itemNumber: items.length + 1, amount: itemAmount };
        setItems([...items, newItemWithAmount]);
        setNewItem({ itemNumber: 0, name: '', description: '', quantity: 0, rate: 0.0, tax: 0.0, amount: 0.0 });

        toast({
            title: 'Item added',
            status: 'success',
            duration: 3000,
            isClosable: true,
        });
    };

    function calculateAmount(rate, quantity, tax) {
        const taxDecimal = (100 - tax) / 100;
        return (rate * quantity) / taxDecimal;
    }

    const handleSignatureSave = async (signatureData) => {
        setIsLoading(true);
        const { blob, location } = signatureData;
        const formData = new FormData();
        formData.append('signature', blob);
        if (location) {
            formData.append('latitude', location.latitude);
            formData.append('longitude', location.longitude);
        }

        try {
            const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/updateBidWithSignature/${documentId}`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                    'Authorization': `Bearer ${localStorage.getItem('token')}`,
                },
            });
            if (response.data.success) {
                setContractorSignature(response.data.url, handleSubmit);
                setIsEditing(false);
                toast({
                    title: 'Signature Uploaded Successfully',
                    description: response.data.message,
                    status: 'success',
                    duration: 3000,
                    isClosable: true,
                });
            } else {
                toast({
                    title: 'Error Uploading Signature',
                    description: response.data.message || 'Failed to upload the signature.',
                    status: 'error',
                    duration: 3000,
                    isClosable: true,
                });
            }
        } catch (error) {
            console.error('Error uploading signature:', error);
            toast({
                title: 'Error submitting bid',
                description: error.response?.data?.message || error.message,
                status: 'error',
                duration: 3000,
                isClosable: true,
            });
        }
        setIsLoading(false);
    };

    const handleAddStage = () => {
        const newStage = {
            id: stages.length + 1,
            description: "",
            upfrontAmount: 0,
            completionAmount: 0
        };
        setStages([...stages, newStage]);
    };

    const handleStageChange = (index, field, value) => {
        const updatedStages = stages.map((stage, idx) => {
            if (idx === index) {
                let updatedValue = value;
                if (field === 'upfrontAmount' || field === 'completionAmount') {
                    // Initially allowing only numbers and decimal points
                    updatedValue = value.replace(/[^0-9.]/g, '');

                    // Eliminate multiple consecutive decimal points
                    updatedValue = updatedValue.replace(/\.{2,}/g, '.');

                    // If input ends with a decimal point, do not reset it to '0'
                    if (updatedValue !== '.' && updatedValue.slice(-1) !== '.') {
                        // Remove leading zeros unless followed by a decimal point
                        updatedValue = updatedValue.replace(/^0+(?!\.)/, '');
                    }

                    // Keep the input if it's just a decimal point or correct format
                    if (updatedValue === '.' || updatedValue.match(/^\d*\.?\d*$/)) {
                        return { ...stage, [field]: updatedValue };
                    }
                } else {
                    return { ...stage, [field]: updatedValue };
                }
            }
            return stage;
        });
        setStages(updatedStages);
        setChanged(true);
    };

    const handleDeleteItem = (index) => {
        const newItems = [...items];
        newItems.splice(index, 1);
        setItems(newItems);
        setChanged(true);
    };

    const formattedStartDate = safelyFormatDate(startDate);
    const formattedEndDate = safelyFormatDate(endDate);
    const formattedExpirationDate = safelyFormatDate(expirationDate);

    return (
        <Flex direction={"column"} w={"100%"} justify={"center"}>
            <Flex direction={{ base: "column", lg: "row" }} p={2} w="100%" align="flex-start" justify="flex-start">
                <ContractorSideMenu />
                <Flex m={{ base: 0, lg: 4 }} direction="column" w="100%" justify="center">
                    <Header />
                    {menuType === 'drawer' ? (
                        <Flex direction={"column"} w={"100"}>
                            {!isSent ? (
                                <Flex w={"100%"} ml={4} mt={4} borderRadius={"md"} justify={"space-between"} align={"center"}>
                                    <Flex mr={2} w={"100%"}>
                                        <Button size={"sm"} mr={2} bg="#1DADE3" _hover={"blue"} color={"white"} onClick={handleSubmit}>
                                            {changed ? "Save Document" : (
                                                <>
                                                    <FontAwesomeIcon icon={faCircleCheck} />
                                                </>
                                            )}
                                        </Button>
                                        <Button mr={4} shadow={"md"} _hover={{ cursor: "pointer" }} size={"sm"} colorScheme="gray" onClick={handleEdit}>
                                            {isEditing ? "Preview Document" : "Edit"}
                                        </Button>
                                        {contractorSignature.length > 0 && (
                                            <Button mr={4} border={"2px"} borderColor={"lemonchiffon"} shadow={"md"} _hover={{ cursor: "pointer", bg: "white", color: "#1DADE3" }} size={"sm"} bg={"gray.700"} color={'white'} onClick={handleSend}>
                                                <Text fontSize={"sm"} mr={2}>Send Proposal</Text><FontAwesomeIcon icon={faPaperPlane} />
                                            </Button>
                                        )}
                                    </Flex>
                                    {!contractorSignature.length > 0 && (
                                        <>
                                            {!isEditing && (
                                                <SignatureCapture isLoading={isLoading} onSignatureSave={handleSignatureSave} />
                                            )}
                                        </>
                                    )}

                                </Flex>
                            ) : (
                                <Flex mt={4}>
                                    <FontAwesomeIcon size='lg' icon={faCircleCheck} color='green' /> <Text fontSize={"sm"} ml={2} fontWeight={'semibold'}>Bid Sent! For Changes Complete a Change Order</Text>
                                </Flex>
                            )}
                            <Flex direction={"column"} mt={2} w={"100%"}>
                                <Flex direction={"column"} w={"100%"} justify={"flex-start"} align={"flex-start"}>
                                    <Flex w={"100%"} maxH={"100vh"} overflowY={"scroll"} border={"1px"} borderColor={"lightgrey"} shadow={"md"} borderRadius={"md"} direction={"column"} p={2}>
                                        <Flex direction={"column"} mb={2} w={"100%"}>
                                            <Flex w={"100%"} justify={"space-between"}>
                                                {contractorLogo ? (
                                                    <Flex w={"100%"} justify={"space-between"}>
                                                        <Image src={contractorLogo} maxW={"50%"} maxH={"auto"} />
                                                        <Flex direction={"column"} align={"flex-end"}>
                                                            <Heading size={"sm"}>
                                                                Proposal
                                                            </Heading>
                                                            <Text fontSize={"sm"} textAlign="right">Proposal ID # {estimateId}</Text>
                                                        </Flex>
                                                    </Flex>
                                                ) : (
                                                    <Flex w={"100%"} justify={"space-between"}>
                                                        <Image src={Logo} maxH={"40px"} />
                                                        <Flex direction={"column"} align={"flex-end"}>
                                                            <Heading size={"sm"}>
                                                                Proposal
                                                            </Heading>
                                                            <Text fontSize={"sm"} textAlign="right">Proposal ID # {estimateId}</Text>
                                                        </Flex>
                                                    </Flex>
                                                )}
                                            </Flex>
                                            <Flex direction={"column"} justify={"flex-start"} align={"flex-end"}>
                                                {isEditing && (
                                                    <FormControl mb={1}>
                                                        <Flex direction={"column"} align={"flex-end"} justify={"flex-end"}>
                                                        </Flex>

                                                        <FormControl hidden>
                                                            <Input type="checkbox" isChecked={isSent} onChange={(e) => {
                                                                setIsSent(e.target.checked);
                                                                setChanged(true);
                                                            }} />
                                                            <Input
                                                                value={projectAddress.street}
                                                                onChange={(e) => {
                                                                    setProjectAddress({ ...projectAddress, street: e.target.value });
                                                                    setChanged(true);
                                                                }}
                                                                placeholder="Street"
                                                            />
                                                            <Input
                                                                value={projectAddress.city}
                                                                onChange={(e) => {
                                                                    setProjectAddress({ ...projectAddress, city: e.target.value });
                                                                    setChanged(true);
                                                                }}
                                                                placeholder="City"
                                                                mt={2}
                                                            />
                                                            <Input
                                                                value={projectAddress.state}
                                                                onChange={(e) => {
                                                                    setProjectAddress({ ...projectAddress, state: e.target.value });
                                                                    setChanged(true);
                                                                }}
                                                                placeholder="State"
                                                                mt={2}
                                                            />
                                                            <Input
                                                                value={projectAddress.zipCode}
                                                                onChange={(e) => {
                                                                    setProjectAddress({ ...projectAddress, zipCode: e.target.value });
                                                                    setChanged(true);
                                                                }}
                                                                placeholder="Zip Code"
                                                                mt={2}
                                                            />
                                                            <Input
                                                                value={projectAddress.country}
                                                                onChange={(e) => {
                                                                    setProjectAddress({ ...projectAddress, country: e.target.value });
                                                                    setChanged(true);
                                                                }}
                                                                placeholder="Country"
                                                                mt={2}
                                                            />

                                                            <FormLabel mt={4}>Contractor Signature</FormLabel>
                                                            <Input
                                                                value={contractorSignature}
                                                                onChange={(e) => {
                                                                    setContractorSignature(e.target.value);
                                                                    setChanged(true);
                                                                }}
                                                            />
                                                            <FormLabel mt={4}>Contractor Signature Date</FormLabel>
                                                            <Input
                                                                type="date"
                                                                value={contractorSignatureDate}
                                                                onChange={(e) => {
                                                                    setContractorSignatureDate(e.target.value);
                                                                    setChanged(true);
                                                                }}
                                                            />
                                                        </FormControl>
                                                    </FormControl>
                                                )}
                                                <Flex>

                                                    {isEditing ? (
                                                        <></>
                                                    ) : (
                                                        <Flex direction={"column"} align={"flex-end"}>
                                                            {isSent ? (
                                                                <Badge colorScheme='blue'>Sent</Badge>
                                                            ) : (
                                                                <Badge colorScheme='red'>Not Sent</Badge>
                                                            )}

                                                            {isActive && (
                                                                <Badge colorScheme='green'>Accepted</Badge>
                                                            )}

                                                            {status === "open" ? (
                                                                <Badge colorScheme='blue'>Open</Badge>
                                                            ) : status === "in progress" ? (
                                                                <Badge colorScheme='red'>In Progress</Badge>
                                                            ) : status === "completed" ? (
                                                                <Badge colorScheme='green'>Completed</Badge>
                                                            ) : null}


                                                        </Flex>
                                                    )}
                                                </Flex>
                                            </Flex>
                                        </Flex>
                                        <Flex direction={"column"} mb={4} w={"100%"} justify={'space-between'}>
                                            <Flex w={"100%"} direction={"column"}>
                                                {isEditing ? (
                                                    <>
                                                        <FormControl>
                                                            <FormLabel >Contractor's Business Name</FormLabel>
                                                            <Input value={contractorBusiness} onChange={(e) => {
                                                                setContractorBusiness(e.target.value);
                                                                setChanged(true);
                                                            }}
                                                                placeholder="Business Name"
                                                            />
                                                            <FormLabel mt={4}>Contractor Address</FormLabel>
                                                            <Input
                                                                value={contractorAddress.street}
                                                                onChange={(e) => {
                                                                    setContractorAddress({ ...contractorAddress, street: e.target.value });
                                                                    setChanged(true);
                                                                }}
                                                                placeholder="Street"
                                                            />
                                                            <Input
                                                                value={contractorAddress.city}
                                                                onChange={(e) => {
                                                                    setContractorAddress({ ...contractorAddress, city: e.target.value });
                                                                    setChanged(true);
                                                                }}
                                                                placeholder="City"
                                                                mt={2}
                                                            />
                                                            <Input
                                                                value={contractorAddress.state}
                                                                onChange={(e) => {
                                                                    setContractorAddress({ ...contractorAddress, state: e.target.value });
                                                                    setChanged(true);
                                                                }}
                                                                placeholder="State"
                                                                mt={2}
                                                            />
                                                            <Input
                                                                value={contractorAddress.zipCode}
                                                                onChange={(e) => {
                                                                    setContractorAddress({ ...contractorAddress, zipCode: e.target.value });
                                                                    setChanged(true);
                                                                }}
                                                                placeholder="Zip Code"
                                                                mt={2}
                                                            />
                                                            <Input
                                                                value={contractorAddress.country}
                                                                onChange={(e) => {
                                                                    setContractorAddress({ ...contractorAddress, country: "USA" });
                                                                    setChanged(true);
                                                                }}
                                                                readOnly
                                                                placeholder="Country"
                                                                mt={2}
                                                            />
                                                            <Text mt={2}>Proposal ID #</Text>
                                                            <Input w={"150px"} value={estimateId} onChange={(e) => {
                                                                setEstimateId(e.target.value);
                                                                setChanged(true);
                                                            }} />
                                                            <Flex mt={2} shadow={!startDate ? "md" : ""} p={!startDate ? 2 : 0} bg={!startDate ? "red.300" : ""} borderRadius={!startDate ? "md" : ""} mb={1} justify={"space-between"} align={"center"}>
                                                                <Text fontSize={"sm"} color={!startDate ? "white" : ""}>Available Start Date:</Text>
                                                                <Input w={"50%"} size={"sm"} ml={1} type="date" value={startDate} onChange={(e) => {
                                                                    setStartDate(e.target.value);
                                                                    setChanged(true);
                                                                }} />
                                                            </Flex>
                                                            <Flex shadow={!endDate ? "md" : ""} p={!endDate ? 2 : 0} bg={!endDate ? "red.300" : ""} borderRadius={!endDate ? "md" : ""} mb={1} justify={"space-between"} align={"center"}>
                                                                <Text fontSize={"sm"} color={!endDate ? "white" : ""}>Estimated End Date:</Text>
                                                                <Input w={"50%"} size={"sm"} ml={1} type="date" value={endDate} onChange={(e) => {
                                                                    setEndDate(e.target.value);
                                                                    setChanged(true);
                                                                }} />
                                                            </Flex>
                                                            <Flex shadow={!expirationDate ? "md" : ""} p={!expirationDate ? 2 : 0} bg={!expirationDate ? "red.300" : ""} borderRadius={!expirationDate ? "md" : ""} mb={1} justify={"space-between"} align={"center"}>
                                                                <Text fontSize={"sm"} color={!expirationDate ? "white" : ""} mt={1}>Proposal Expiration Date:</Text>
                                                                <Input
                                                                    type="date"
                                                                    w={"50%"}
                                                                    size={"sm"}
                                                                    value={expirationDate}
                                                                    onChange={(e) => {
                                                                        setExpirationDate(e.target.value);
                                                                        setChanged(true);
                                                                    }}
                                                                />
                                                            </Flex>
                                                            <FormLabel>Title</FormLabel>
                                                            <Input value={title} onChange={(e) => {
                                                                setTitle(e.target.value);
                                                                setChanged(true);
                                                            }} />
                                                            <FormLabel mt={4}>Description</FormLabel>
                                                            <Textarea value={description} onChange={(e) => {
                                                                setDescription(e.target.value);
                                                                setChanged(true);
                                                            }} />
                                                            <Flex mt={4} w={"100%"} bg={"gray.700"} borderRadius={"md"} >
                                                                <Flex mr={4} w={"90%"} justify={"space-between"}>
                                                                    <Text fontSize={"sm"} ml={2} color={"white"}>Item</Text>
                                                                    <Text ml={6} fontSize={"sm"} color={"white"}>Qty</Text>
                                                                    <Text fontSize={"sm"} ml={2} color={"white"}>Rate</Text>
                                                                    <Text fontSize={"sm"} mr={1} color={"white"}>Amount</Text>
                                                                </Flex>
                                                                <Flex w={"10%"} justify={"space-between"}>

                                                                </Flex>
                                                            </Flex>
                                                            {items.map((item, index) => (
                                                                <>
                                                                    <Flex key={index} w={"100%"} borderRadius={"md"} p={2} alignItems="start">
                                                                        <Flex w={"90%"} justify={"space-between"}>
                                                                            <Flex direction={"column"}>
                                                                                <Text fontSize={"sm"} fontWeight={"semibold"}>{item.name}</Text>
                                                                                <Text fontSize={"sm"} mt={-1} fontWeight={"thin"}>{item.description}</Text>
                                                                            </Flex>
                                                                            {!item.hideDetails && <Text fontSize={"sm"} ml={2} mr={6}>{item.quantity}</Text>}
                                                                            {!item.hideDetails && <Text fontSize={"sm"} mr={6} ml={4}>{parseFloat(item.rate).toFixed(2)}</Text>}
                                                                            {!item.hideDetails && <Text fontSize={"sm"}>{typeof item.amount === 'number' ? item.amount.toFixed(2) : '0.00'}</Text>}
                                                                        </Flex>
                                                                        <Flex ml={4} w={"10%"} justify={"space-between"} alignItems="start">
                                                                            {isEditing && (
                                                                                <Button size={"xs"} colorScheme='red' onClick={() => handleDeleteItem(index)}>X</Button>
                                                                            )}
                                                                        </Flex>
                                                                    </Flex>
                                                                </>
                                                            ))}

                                                            <Flex mt={4} justify={"flex-end"} align={"flex-end"} w={"100%"}>
                                                                <Flex direction={"column"} align={"flex-end"} mr={4}>
                                                                    <Text fontSize={"sm"} mb={2}>Subtotal: ${subtotal}</Text>
                                                                    {discountAmount > 0 && (
                                                                        <Text fontSize={"sm"} mb={2}>Discount: -${discountAmount}</Text>
                                                                    )}
                                                                    <Text fontSize={"sm"} mb={2}>Service Charge: ${serviceCharge}</Text>
                                                                </Flex>
                                                            </Flex>

                                                            <Flex p={1} justify={"flex-end"} align={"flex-end"} w={"100%"} bg={"gray.700"} borderRadius={"md"} >
                                                                <Text fontSize={"sm"} mr={2} color={"white"}>Total: ${total}</Text>
                                                            </Flex>
                                                        </FormControl>
                                                        <Flex mt={4} w={"100%"}>
                                                            <Flex w={"100%"} direction="column" p={4} shadow="lg" borderRadius="md">
                                                                <Heading size="md" mb={4}>Add New Item</Heading>
                                                                <VStack align={"flex-start"}>
                                                                    <FormControl>
                                                                        <FormLabel>Name</FormLabel>
                                                                        <Input placeholder="Enter item name" value={newItem.name} onChange={(e) => {
                                                                            setNewItem({ ...newItem, name: e.target.value });
                                                                            setChanged(true);
                                                                        }} />
                                                                    </FormControl>
                                                                    <FormControl>
                                                                        <FormLabel>Description</FormLabel>
                                                                        <Input placeholder="Enter description" value={newItem.description} onChange={(e) => {
                                                                            setNewItem({ ...newItem, description: e.target.value });
                                                                            setChanged(true);
                                                                        }} />
                                                                    </FormControl>
                                                                </VStack>
                                                                <Flex direction={"column"} w={"100%"} justify={"space-evenly"}>
                                                                    <FormControl m={2}>
                                                                        <FormLabel>Quantity</FormLabel>
                                                                        <Input min={1} type="number" placeholder="Enter quantity" value={newItem.quantity}
                                                                            onChange={(e) => {
                                                                                setNewItem({
                                                                                    ...newItem,
                                                                                    quantity: parseInt(e.target.value, 10),
                                                                                    amount: calculateAmount(parseFloat(newItem.rate), parseInt(e.target.value, 10), parseFloat(newItem.tax))
                                                                                });
                                                                                setChanged(true);
                                                                            }} />
                                                                    </FormControl>
                                                                    <FormControl m={2}>
                                                                        <FormLabel>Rate</FormLabel>
                                                                        <Input type="number" placeholder="Enter rate" value={newItem.rate}
                                                                            onChange={(e) => {
                                                                                setNewItem({
                                                                                    ...newItem,
                                                                                    rate: parseFloat(e.target.value),
                                                                                    amount: calculateAmount(parseFloat(e.target.value), newItem.quantity, parseFloat(newItem.tax))
                                                                                });
                                                                                setChanged(true);
                                                                            }} />
                                                                    </FormControl>
                                                                    <FormControl m={2}>
                                                                        <FormLabel>Tax (%)</FormLabel>
                                                                        <Input type="number" placeholder="Enter tax percentage" value={newItem.tax}
                                                                            onChange={(e) => {
                                                                                setNewItem({
                                                                                    ...newItem,
                                                                                    tax: parseFloat(e.target.value),
                                                                                    amount: calculateAmount(newItem.rate, newItem.quantity, parseFloat(e.target.value))
                                                                                });
                                                                                setChanged(true);
                                                                            }} />
                                                                    </FormControl>

                                                                    <FormControl m={2}>
                                                                        <FormLabel>Amount</FormLabel>
                                                                        <Input type="number" placeholder="Enter amount" value={newItem.amount.toFixed(2)} readOnly onChange={(e) => {
                                                                            setNewItem({ ...newItem, amount: parseFloat(e.target.value) });
                                                                            setChanged(true);
                                                                        }} />
                                                                    </FormControl>

                                                                    <FormControl m={2}>
                                                                        <FormLabel>Hide Details</FormLabel>
                                                                        <Checkbox
                                                                            isChecked={newItem.hideDetails}
                                                                            onChange={(e) => {
                                                                                setNewItem({ ...newItem, hideDetails: e.target.checked });
                                                                                setChanged(true);
                                                                            }}
                                                                        />
                                                                    </FormControl>
                                                                </Flex>
                                                                <Button mt={4} bg="#1DADE3" _hover={"blue"} color={"white"} onClick={handleAddItem}>Add Item</Button>
                                                            </Flex>
                                                        </Flex>
                                                        <FormControl>
                                                            <Flex direction={"column"} mt={4} w={"100%"} justify={"space-between"}>
                                                                <Box>
                                                                    <FormControl>
                                                                        <FormLabel>Payment Terms</FormLabel>
                                                                        <Menu>
                                                                            <MenuButton w={"100%"} as={Button} rightIcon={<Icon as={FontAwesomeIcon} icon={faChevronDown} />}>
                                                                                {paymentTerms || 'Select payment terms'}
                                                                            </MenuButton>
                                                                            <MenuList w={"100%"}>
                                                                                <MenuItem onClick={() => handleMobilePaymentTermsChange('1/2')}>
                                                                                    50% Upfront, 50% Upon Completion
                                                                                </MenuItem>
                                                                                <MenuItem onClick={() => handleMobilePaymentTermsChange('UpFront')}>
                                                                                    Payment Upfront, in Full
                                                                                </MenuItem>
                                                                                <MenuItem onClick={() => handleMobilePaymentTermsChange('Completed')}>
                                                                                    Payment Due When Completed
                                                                                </MenuItem>
                                                                                <MenuItem onClick={() => handleMobilePaymentTermsChange('Stages')}>
                                                                                    Custom
                                                                                </MenuItem>
                                                                            </MenuList>
                                                                        </Menu>
                                                                    </FormControl>
                                                                </Box>
                                                                <Box mt={2} w={"200px"}>
                                                                    <FormLabel>Discount (%)</FormLabel>
                                                                    <Input
                                                                        type="number"
                                                                        value={discount}
                                                                        min={0}
                                                                        onChange={(e) => {
                                                                            setDiscount(parseFloat(e.target.value));
                                                                            setChanged(true);
                                                                        }}
                                                                        placeholder="Enter discount percentage"
                                                                    />
                                                                </Box>
                                                                {paymentTerms === "Stages" ? (
                                                                    <Box mt={2}>
                                                                        <Heading size={"sm"}>Stage Calculator:</Heading>
                                                                        <Flex shadow={"lg"} justify={"center"} bg={"gray.700"} borderRadius={"md"} align={"center"} direction={"column"} border={"1px"} mb={4} w={"100%"}>
                                                                            <Text fontSize={"sm"} mt={2} color={"white"}>Bid Total: ${total}</Text>
                                                                            <Flex w={"100%"} align={"center"} justify={"center"}>
                                                                                {Math.abs(remainingBudget.toFixed(2) - total) < 0.01 && (
                                                                                    <FontAwesomeIcon color="green.600" icon={faCircleCheck} size="lg" />
                                                                                )}
                                                                                <Text fontSize={"sm"} ml={2} mt={2} color={parseFloat(remainingBudget.toFixed(2)) < 0 ? "red.300" : "white"}>
                                                                                    Remaining: ${remainingBudget.toFixed(2)}
                                                                                </Text>
                                                                            </Flex>
                                                                        </Flex>
                                                                    </Box>
                                                                ) : (
                                                                    <Box mt={2} w={"100%"}>
                                                                        <FormLabel>Upfront Amount</FormLabel>
                                                                        <Input type="number" value={upfrontAmount.amount} readOnly />
                                                                        <Input
                                                                            value={upfrontAmount.description}
                                                                            onChange={(e) => {
                                                                                setUpfrontAmount({ ...upfrontAmount, description: e.target.value });
                                                                                setChanged(true);
                                                                            }}
                                                                            placeholder="Description"
                                                                            mt={2}
                                                                        />
                                                                    </Box>
                                                                )}
                                                            </Flex>
                                                            {paymentTerms === "Stages" && (
                                                                <Flex w={"100%"} mt={4} direction="column">
                                                                    {stages.map((stage, index) => (
                                                                        <Flex direction="column" w={"100%"} align={"center"} justify={"space-between"} key={stage.id}>
                                                                            <Box w="100%">
                                                                                <Text>Description:</Text>
                                                                                <Input
                                                                                    placeholder="Description"
                                                                                    value={stage.description}
                                                                                    onChange={(e) => {
                                                                                        handleStageChange(index, 'description', e.target.value);
                                                                                        setChanged(true);
                                                                                    }}
                                                                                />
                                                                            </Box>
                                                                            <Flex mt={2} w="100%" align={"center"} justify={"space-between"}>
                                                                                <Text>Due at Start:</Text>
                                                                                <Input
                                                                                    placeholder="Upfront Amount"
                                                                                    type="number"
                                                                                    step="0.01"
                                                                                    w={"220px"}
                                                                                    value={stage.upfrontAmount}
                                                                                    onChange={(e) => {
                                                                                        handleStageChange(index, 'upfrontAmount', e.target.value);
                                                                                    }}
                                                                                />
                                                                            </Flex>
                                                                            <Flex mt={2} w="100%" align={"center"} justify={"space-between"}>
                                                                                <Text>Due at End:</Text>
                                                                                <Input
                                                                                    placeholder="Completion Amount"
                                                                                    type="number"
                                                                                    step="0.01"
                                                                                    w={"220px"}
                                                                                    value={stage.completionAmount}
                                                                                    onChange={(e) => {
                                                                                        handleStageChange(index, 'completionAmount', e.target.value);
                                                                                    }}
                                                                                />
                                                                            </Flex>

                                                                            <Button colorScheme='red' mt={2} size={"sm"} onClick={() => { setStages(stages.filter(s => s.id !== stage.id)); setChanged(true); }}>Remove</Button>
                                                                        </Flex>
                                                                    ))}
                                                                    <Button m={2} size={"sm"} onClick={handleAddStage}>Add Stage</Button>
                                                                </Flex>
                                                            )}
                                                            <FormLabel mb={4} mt={4}>Notes</FormLabel>
                                                            <Textarea value={notes} onChange={(e) => {
                                                                setNotes(e.target.value);
                                                                setChanged(true);
                                                            }} />

                                                            {!isActive && role === "Contractor" && (
                                                                <Flex mt={4} align={"center"}>
                                                                    <Heading mr={2} size={"md"}>Contractor Signature</Heading>
                                                                    <FontAwesomeIcon icon={faFileSignature} />
                                                                </Flex>
                                                            )}
                                                        </FormControl>
                                                    </>
                                                ) : (
                                                    <Flex w={"100%"} justify={"space-between"}>
                                                        <Flex mb={2} w={"50%"} direction={"column"} align={"center"}>
                                                            {contractorBusiness && (
                                                                isVetted ? (
                                                                    <Flex w={"100%"} align="center">
                                                                        <FontAwesomeIcon size='xs' color='#1DADE3' icon={faCircleCheck} />
                                                                        <Text fontSize={"sm"} ml={1} fontWeight="semibold">
                                                                            {contractorBusiness}
                                                                        </Text>
                                                                    </Flex>
                                                                ) : (
                                                                    <Flex w={"100%"} align="center">
                                                                        <FontAwesomeIcon size='xs' color='lightgray' icon={faCircleCheck} />
                                                                        <Text fontSize={"sm"} ml={1} fontWeight="semibold">
                                                                            {contractorBusiness}
                                                                        </Text>
                                                                    </Flex>
                                                                )
                                                            )}
                                                            <Flex mt={1} w={"100%"} justify={"flex-start"}>
                                                                {licenses && licenses.length > 0 && (
                                                                    <Flex mr={2}>
                                                                        <FontAwesomeIcon color='#1DADE3' icon={faIdCard} />
                                                                    </Flex>
                                                                )}
                                                                {contractorInsurance && contractorInsurance.length > 0 && (
                                                                    <Flex mr={2}>
                                                                        <FontAwesomeIcon color='#1DADE3' icon={faUserShield} />
                                                                    </Flex>
                                                                )}
                                                                {contractorBonds && contractorBonds.length > 0 && (
                                                                    <Flex>
                                                                        <FontAwesomeIcon color='#1DADE3' icon={faBuildingColumns} />
                                                                    </Flex>
                                                                )}
                                                            </Flex>
                                                            <Flex direction={"column"} mt={1} w={"100%"} justify={"flex-start"}>
                                                                <Text fontSize={"sm"} fontWeight={"semibold"}>
                                                                    {contractorFirstName} {contractorLastName}
                                                                </Text>
                                                                <Text fontSize={"sm"}>
                                                                    {contractorAddress.street}
                                                                </Text>
                                                                <Text fontSize={"sm"}>
                                                                    {contractorAddress.city}, {contractorAddress.state} {contractorAddress.zipCode}
                                                                </Text>
                                                            </Flex>
                                                        </Flex>

                                                        <Flex w={"50%"} direction={"column"} justify={"flex-start"}>
                                                            <Flex w={"100%"} direction={"column"} align={"flex-end"} justify={"flex-end"}>
                                                                <Text fontSize={"sm"} fontWeight={"semibold"}>
                                                                    {ownerFirstName} {ownerLastName}
                                                                </Text>
                                                                <Text fontSize={"sm"}>{formatPhoneNumber(ownerPhone)}</Text>
                                                                {isActive && (
                                                                    <Text fontSize={"sm"}>
                                                                        {projectAddress.street}
                                                                    </Text>
                                                                )}
                                                                <Text fontSize={"sm"}>
                                                                    {projectAddress.city}, {projectAddress.state} {projectAddress.zipCode}
                                                                </Text>
                                                                <Text fontSize={"sm"}>Availability</Text>
                                                                <Text fontSize={"sm"}>{formattedStartDate}</Text><Text fontSize={"sm"}>{formattedEndDate}</Text>
                                                                <Text fontSize={"sm"}>Proposal Expiration</Text>
                                                                <Text fontSize={"sm"}>{formattedExpirationDate}</Text>
                                                            </Flex>
                                                        </Flex>
                                                    </Flex>
                                                )}
                                            </Flex>

                                        </Flex>
                                        <Flex direction={"column"}>
                                            {isEditing ? (
                                                <FormControl>
                                                    <FormControl hidden>
                                                        <FormLabel mt={4}>Owner ID</FormLabel>
                                                        <Input value={ownerId} onChange={(e) => {
                                                            setOwnerId(e.target.value);
                                                            setChanged(true);
                                                        }} />

                                                        <FormLabel mt={4}>Owner First Name</FormLabel>
                                                        <Input value={ownerFirstName} onChange={(e) => {
                                                            setOwnerFirstName(e.target.value);
                                                            setChanged(true);
                                                        }} />

                                                        <FormLabel mt={4}>Owner Last Name</FormLabel>
                                                        <Input value={ownerLastName} onChange={(e) => {
                                                            setOwnerLastName(e.target.value);
                                                            setChanged(true);
                                                        }} />

                                                        <FormLabel mt={4}>Total Amount</FormLabel>
                                                        <Input
                                                            type="number"
                                                            value={totalAmount}
                                                            onChange={(e) => {
                                                                setTotalAmount(parseFloat(e.target.value));
                                                                setChanged(true);
                                                            }}
                                                        />
                                                    </FormControl>
                                                </FormControl>
                                            ) : (
                                                <>
                                                    <Text fontSize={"sm"} fontWeight={"bold"}>{title}</Text>
                                                    <Text>{description}</Text>
                                                </>
                                            )}
                                        </Flex>
                                        {isEditing ? (
                                            <></>
                                        ) : (
                                            <>
                                                <Flex mt={4} w={"100%"} bg={"gray.700"} borderRadius={"md"} >
                                                    <Flex mr={4} w={"100%"} justify={"space-between"}>
                                                        <Flex w={"35%"} direction={"column"}>
                                                            <Text fontSize={"sm"} ml={2} color={"white"}>Item</Text>
                                                        </Flex>
                                                        <Text fontSize={"sm"} color={"white"}>Qty</Text>
                                                        <Text fontSize={"sm"} ml={2} color={"white"}>Rate</Text>
                                                        <Text fontSize={"sm"} mr={1} color={"white"}>Amount</Text>
                                                    </Flex>
                                                </Flex>
                                                {items.map((item, index) => (
                                                    <>
                                                        <Flex key={index} w={"100%"} borderRadius={"md"} p={2} alignItems="start">
                                                            <Flex w={"100%"} justify={"space-between"}>
                                                                <Flex w={"35%"} direction={"column"}>
                                                                    <Text fontSize={"sm"} fontWeight={"semibold"}>{item.name}</Text>
                                                                    <Text fontSize={"sm"} mt={-1} fontWeight={"thin"}>{item.description}</Text>
                                                                </Flex>
                                                                {!item.hideDetails && <Text fontSize={"sm"} ml={2} mr={6}>{item.quantity}</Text>}
                                                                {!item.hideDetails && <Text fontSize={"sm"} mr={6} ml={4}>{parseFloat(item.rate).toFixed(2)}</Text>}
                                                                {!item.hideDetails && <Text fontSize={"sm"}>{typeof item.amount === 'number' ? item.amount.toFixed(2) : '0.00'}</Text>}
                                                            </Flex>
                                                        </Flex>
                                                    </>
                                                ))}
                                            </>
                                        )}



                                        <Flex mt={4} justify={"flex-end"} align={"flex-end"} w={"100%"}>
                                            <Flex direction={"column"} align={"flex-end"} mr={4}>
                                                <Text fontSize={"sm"} mb={2}>Subtotal: ${subtotal}</Text>
                                                {discountAmount > 0 && (
                                                    <Text fontSize={"sm"} mb={2}>Discount: -${discountAmount}</Text>
                                                )}
                                                <Text fontSize={"sm"} mb={2}>Service Charge: ${serviceCharge}</Text>
                                            </Flex>
                                        </Flex>

                                        <Flex p={1} justify={"flex-end"} align={"flex-end"} w={"100%"} bg={"gray.700"} borderRadius={"md"} >
                                            <Text fontSize={"sm"} mr={2} color={"white"}>Total: ${total}</Text>
                                        </Flex>

                                        {isEditing ? (
                                            <>


                                            </>
                                        ) : (
                                            <Flex direction="column" w="100%">
                                                {paymentTerms === "Stages" ? (
                                                    <Flex w={"100%"} justify={"flex-end"}>
                                                        <Box
                                                            w="fit-content"
                                                            mt={4}
                                                            borderRadius="lg"
                                                            border="1px"
                                                            p={4}
                                                            bg="white"
                                                            boxShadow="md"
                                                            direction="column"
                                                        >
                                                            <Heading size="md" mb={4}>
                                                                Payment Terms:
                                                            </Heading>

                                                            {stages.map((stage, index) => (
                                                                <Flex key={stage.id} align="center" justify={"space-between"} mb={2}>
                                                                    <Flex align={"center"} mr={2}>
                                                                        <FontAwesomeIcon size='xs' icon={faCircleDot} />
                                                                        <Text fontSize={"sm"} ml={2}>{stage.description}</Text>
                                                                    </Flex>
                                                                    <Flex>
                                                                        <Box
                                                                            p={2}
                                                                            borderRadius="md"
                                                                            bg={stage.upfrontAmount > 0 ? "green.100" : "gray.100"}
                                                                            mr={2}
                                                                        >
                                                                            <Text fontSize={"sm"} color={stage.upfrontAmount > 0 ? "green.600" : "gray.600"}>
                                                                                Due at Start: ${stage.upfrontAmount}
                                                                            </Text>
                                                                        </Box>

                                                                        <Box
                                                                            p={2}
                                                                            borderRadius="md"
                                                                            bg={stage.completionAmount > 0 ? "blue.100" : "gray.100"}
                                                                        >
                                                                            <Text fontSize={"sm"} color={stage.completionAmount > 0 ? "blue.600" : "gray.600"}>
                                                                                Due at End: ${stage.completionAmount}
                                                                            </Text>
                                                                        </Box>
                                                                    </Flex>
                                                                </Flex>
                                                            ))}

                                                            <Flex w="100%" justify="flex-end" mt={4}>
                                                                <Box
                                                                    bg={
                                                                        stagesTotal < total
                                                                            ? "yellow.100"
                                                                            : stagesTotal > total
                                                                                ? "red.100"
                                                                                : "green.100"
                                                                    }
                                                                    p={4}
                                                                    borderRadius="lg"
                                                                    display="flex"
                                                                    alignItems="center"
                                                                >
                                                                    {Math.abs(stagesTotal - total) < 0.01 && (
                                                                        <FontAwesomeIcon color="green.600" icon={faCircleCheck} size="lg" />
                                                                    )}

                                                                    {Math.abs(stagesTotal - total) >= 0.01 && (
                                                                        <FontAwesomeIcon
                                                                            color={stagesTotal < total ? "yellow.600" : "red.600"}
                                                                            icon={faTriangleExclamation}
                                                                            size="lg"
                                                                        />
                                                                    )}

                                                                    <Text fontSize={"sm"} ml={2} fontWeight="bold">
                                                                        Total Stages Amount: ${stagesTotal.toFixed(2)}
                                                                    </Text>
                                                                </Box>
                                                            </Flex>
                                                        </Box>
                                                    </Flex>
                                                ) : paymentTerms === "1/2" ? (
                                                    <>
                                                        {upfrontAmount && (
                                                            <Flex w={"100%"} justify={"flex-end"}>
                                                                <Flex w={"fit-content"} mt={4} borderRadius={"lg"} direction={"column"} p={2} border={"1px"}>
                                                                    <Heading size={"sm"}>Payment Terms:</Heading>
                                                                    <Text>${upfrontAmount.amount} before work starts. ${upfrontAmount.amount} when completed</Text>
                                                                    <Text>{upfrontAmount.description}</Text>
                                                                </Flex>
                                                            </Flex>
                                                        )}
                                                    </>
                                                ) : paymentTerms === "UpFront" ? (
                                                    <>
                                                        {upfrontAmount && (
                                                            <Flex w={"100%"} justify={"flex-end"}>
                                                                <Flex w={"fit-content"} mt={4} borderRadius={"lg"} direction={"column"} p={2} border={"1px"}>
                                                                    <Heading size={"sm"}>Payment Terms:</Heading>
                                                                    <Text>${upfrontAmount.amount} before work starts.</Text>
                                                                    <Text>{upfrontAmount.description}</Text>
                                                                </Flex>
                                                            </Flex>
                                                        )}
                                                    </>
                                                ) : paymentTerms === "Completed" ? (
                                                    <>
                                                        {upfrontAmount && (
                                                            <Flex w={"100%"} justify={"flex-end"}>
                                                                <Flex w={"fit-content"} mt={4} borderRadius={"lg"} direction={"column"} p={2} border={"1px"}>
                                                                    <Heading size={"sm"}>Payment Terms:</Heading>
                                                                    <Text>${total} when completed</Text>
                                                                    <Text>{upfrontAmount.description}</Text>
                                                                </Flex>
                                                            </Flex>
                                                        )}
                                                    </>
                                                ) : (
                                                    null
                                                )}
                                                {notes && (
                                                    <Flex mt={4} borderRadius={"lg"} direction={"column"} p={2} bg={"gray.700"}>
                                                        <Heading color={"white"} size={"sm"}>Notes:</Heading>
                                                        <Text fontSize={"sm"} color={"white"}>
                                                            {notes}
                                                        </Text>
                                                    </Flex>
                                                )}
                                                <Accordion mt={4} allowToggle>
                                                    {contractorInsurance && contractorInsurance.length > 0 && (
                                                        <AccordionItem>
                                                            <h2>
                                                                <AccordionButton>
                                                                    <Box as='span' flex='1' textAlign='left'>
                                                                        Insurance
                                                                    </Box>
                                                                    <AccordionIcon />
                                                                </AccordionButton>
                                                            </h2>
                                                            <AccordionPanel pb={4}>
                                                                {contractorInsurance.map((insurance, index) => (
                                                                    <Box w="fit-content" mt={2} key={index} p={2} shadow="md" borderWidth="1px">
                                                                        <Text fontSize={"sm"} fontWeight="semibold">{insurance.provider}</Text>
                                                                        <Text>Policy Number: {insurance.policyNumber}</Text>
                                                                        <Text>Coverage Amount: ${new Intl.NumberFormat('en-US', { style: 'decimal', minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(insurance.coverageAmount)}</Text>
                                                                        <Text>Expiry Date: {new Date(insurance.expiryDate).toLocaleDateString()}</Text>
                                                                        <Text>Type: {insurance.type}</Text>
                                                                    </Box>
                                                                ))}
                                                            </AccordionPanel>
                                                        </AccordionItem>
                                                    )}

                                                    {contractorBonds && contractorBonds.length > 0 && (
                                                        <AccordionItem>
                                                            <h2>
                                                                <AccordionButton>
                                                                    <Box as='span' flex='1' textAlign='left'>
                                                                        Bonds
                                                                    </Box>
                                                                    <AccordionIcon />
                                                                </AccordionButton>
                                                            </h2>
                                                            <AccordionPanel pb={4}>
                                                                {contractorBonds.map((bond, index) => (
                                                                    <Box w="fit-content" mt={2} key={index} p={2} shadow="md" borderWidth="1px">
                                                                        <Text fontSize={"sm"} fontWeight="semibold">{bond.bondingCompany}</Text>
                                                                        <Text>Bond Number: {bond.bondNumber}</Text>
                                                                        <Text>Bond Amount: ${new Intl.NumberFormat('en-US', { style: 'decimal', minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(bond.amount)}</Text>
                                                                        <Text>Expiry Date: {new Date(bond.expiryDate).toLocaleDateString()}</Text>
                                                                    </Box>
                                                                ))}
                                                            </AccordionPanel>
                                                        </AccordionItem>
                                                    )}

                                                    {licenses && licenses.length > 0 && (
                                                        <AccordionItem>
                                                            <h2>
                                                                <AccordionButton>
                                                                    <Box as='span' flex='1' textAlign='left'>
                                                                        Licenses
                                                                    </Box>
                                                                    <AccordionIcon />
                                                                </AccordionButton>
                                                            </h2>
                                                            <AccordionPanel pb={4}>
                                                                {licenses.map((license, index) => (
                                                                    <Box w="fit-content" mt={2} key={index} p={2} shadow="md" borderWidth="1px">
                                                                        <Text fontSize={"sm"} fontWeight="semibold">{license.type}</Text>
                                                                        <Text>Number: {license.number}</Text>
                                                                        <Text>Expiry Date: {new Date(license.expiryDate).toLocaleDateString()}</Text>
                                                                    </Box>
                                                                ))}
                                                            </AccordionPanel>
                                                        </AccordionItem>
                                                    )}
                                                </Accordion>

                                                <Flex direction={"column"} p={2} w={"100%"} justify={"space-between"} align={"flex-start"}>
                                                    <Flex direction={"column"} w={"full"} justify={"flex-start"}>
                                                        <Text fontSize={"sm"} mt={2}>Contractor Signature</Text>
                                                        {contractorSignature && (
                                                            <>
                                                                <Image
                                                                    src={contractorSignature}
                                                                    alt="Contractor Signature"
                                                                    objectFit="contain"
                                                                    maxW="400px"
                                                                    h="auto"
                                                                />
                                                                {contractorSignatureDate && (
                                                                    <Text fontSize={"sm"} mt={2}>Date: {format(parseISO(contractorSignatureDate), 'MMMM dd, yyyy')}</Text>
                                                                )}
                                                            </>
                                                        )}
                                                    </Flex>
                                                    <Flex direction={"column"} w={"full"} justify={"flex-start"}>
                                                        <Text fontSize={"sm"} mt={2}>Home Owner Signature</Text>
                                                        {ownerSignature && (
                                                            <>
                                                                <Image
                                                                    src={ownerSignature}
                                                                    alt="Home Owner Signature"
                                                                    objectFit="contain"
                                                                    maxW="400px"
                                                                    h="auto"
                                                                />
                                                                <Text fontSize={"sm"} mt={2}>Date: {format(parseISO(ownerSignatureDate), 'MMMM dd, yyyy')}</Text>
                                                            </>
                                                        )}
                                                    </Flex>
                                                </Flex>
                                            </Flex>

                                        )}
                                        {/* Add form fields for revisionHistory */}

                                    </Flex>
                                </Flex>
                                <Flex maxH={"88vh"} overflowY={"scroll"} p={2} mt={{ base: 4, lg: 0 }} borderRadius={"lg"} direction={"column"} w={{ base: "100%", lg: "50%" }} justify={"space-between"}>
                                    <Flex direction={"column"} w={"100%"} justify={"flex-start"}>
                                        <Flex maxH={"50vh"} overflowY={"scroll"} border={"1px"} borderColor={"lightgrey"} shadow={"md"} borderRadius={"md"} direction={"column"} w={"100%"} mx="auto">
                                            <Box borderRadius={"lg"} key={projectId} p={5} shadow="md" borderWidth="1px">
                                                <Flex w={"100%"} justify={"space-between"} align={"flex-start"}>
                                                    <Box>
                                                        <Text fontSize={"sm"} fontWeight="bold">{title}</Text>
                                                        <Text>{formatPhoneNumber(ownerPhone)}</Text>
                                                    </Box>
                                                    <Flex direction={"column"} justify={"flex-end"}>
                                                        <Flex justify={"flex-end"}>
                                                            {projectStatus === "open" ? (
                                                                <Badge mr={1} colorScheme='blue'>Open</Badge>
                                                            ) : projectStatus === "in progress" ? (
                                                                <Badge mr={1} colorScheme='red'>In Progress</Badge>
                                                            ) : projectStatus === "completed" ? (
                                                                <Badge mr={1} colorScheme='green'>Completed</Badge>
                                                            ) : null}
                                                        </Flex>
                                                        <Flex mt={2} justify={"flex-end"}>
                                                            {projectType === "ready" ? (
                                                                <Flex color={"white"} align={"flex-end"} direction={"column"} justify={"flex-end"}>
                                                                    <Badge colorScheme='green'>{projectType}</Badge>
                                                                    {readyToStartTimeframe === "inDays" ? (
                                                                        <Text>Ready to start in days</Text>
                                                                    ) : readyToStartTimeframe === "inWeeks" ? (
                                                                        <Text>Ready to start in weeks</Text>
                                                                    ) : readyToStartTimeframe === "rightAway" ? (
                                                                        <Text>Ready to start right away</Text>
                                                                    ) : null}
                                                                </Flex>
                                                            ) : projectType === "estimate" ? (
                                                                <Badge colorScheme='orange'>Getting Estimates</Badge>
                                                            ) : projectType === "emergency" ? (
                                                                <Badge colorScheme='pink'>Immediately</Badge>
                                                            ) : null}
                                                        </Flex>
                                                    </Flex>
                                                </Flex>
                                                <Text fontSize={"sm"} mt={2}>{projectDescription}</Text>
                                                <Text fontSize={"sm"} mt={2}>{projectAddress.city}, {projectAddress.state}</Text>
                                                <Flex w={"100%"} justify={"center"}>
                                                    {projectImages && projectImages.map((image, index) => (
                                                        <SimpleGrid overflowY={"scroll"} maxH={"225px"} column={{ base: 2, lg: 4 }} spacing={2}>
                                                            <Box key={index} onClick={() => setSelectedImage(image)} cursor="pointer">
                                                                <Image src={image} w="200px" h="auto" objectFit="cover" alt={`Project Image ${index + 1}`} p={1} />
                                                            </Box>
                                                        </SimpleGrid>
                                                    ))}
                                                </Flex>
                                            </Box>
                                        </Flex>
                                        {selectedImage && (
                                            <Flex maxH={"50vh"} overflow={"hidden"} border={"1px"} borderColor={"lightgrey"} shadow={"md"} borderRadius={"md"} direction={"column"} w={"100%"} mx="auto" mt={4} align={"center"} justify={"center"}>
                                                <Flex p={1} w={"100%"} align={"center"} justify={"center"}>
                                                    <Text fontSize={"sm"} mr={2}>Grab, Drag & Zoom</Text>
                                                </Flex>
                                                <TransformWrapper
                                                    initialScale={1}
                                                    minScale={0.5}
                                                    maxScale={4}
                                                    limitToBounds={false}
                                                    centerOnInit={false}
                                                    wheel={{
                                                        step: 0.1,
                                                    }}
                                                    pinch={{
                                                        step: 0.1
                                                    }}
                                                >
                                                    <TransformComponent>
                                                        <Image
                                                            src={selectedImage}
                                                            alt="Selected Project Image"
                                                            style={{
                                                                width: "100%", // This ensures the image fits initially within the container.
                                                                height: "auto",
                                                                maxWidth: "none"
                                                            }}
                                                        />
                                                    </TransformComponent>
                                                </TransformWrapper>
                                            </Flex>
                                        )}
                                    </Flex>

                                    <MessageComponent conversationId={bidId} projectId={projectId} socket={socket} isDisabled={isDisabled} />

                                </Flex>
                            </Flex>
                        </Flex>
                    ) : (
                        <Flex direction={"column"} w={"100"}>
                            {!isSent ? (
                                <Flex w={"100%"} ml={4} mt={4} borderRadius={"md"} justify={"flex-start"} align={"center"}>

                                    <Button size={"sm"} mr={4} bg="#1DADE3" _hover={"blue"} color={"white"} onClick={handleSubmit}>
                                        {changed ? "Save Document" : (
                                            <>
                                                <FontAwesomeIcon icon={faCircleCheck} />
                                            </>
                                        )}
                                    </Button>
                                    <Button mr={4} shadow={"md"} _hover={{ cursor: "pointer" }} size={"sm"} colorScheme="gray" onClick={handleEdit}>
                                        {isEditing ? "Preview Document" : "Edit"}
                                    </Button>

                                    {contractorSignature.length > 0 ? (
                                        <Button border={"2px"} borderColor={"lemonchiffon"} shadow={"md"} _hover={{ cursor: "pointer", bg: "white", color: "#1DADE3" }} size={"sm"} bg={"gray.700"} color={'white'} onClick={handleSend}>
                                            <Text fontSize={"sm"} mr={2}>Send Bid</Text><FontAwesomeIcon icon={faPaperPlane} />
                                        </Button>
                                    ) : (
                                        <SignatureCapture isLoading={isLoading} onSignatureSave={handleSignatureSave} />
                                    )}

                                </Flex>
                            ) : (
                                <Flex mt={4}>
                                    <FontAwesomeIcon size='lg' icon={faCircleCheck} color='green' /> <Text fontSize={"sm"} ml={2} fontWeight={'semibold'}>Bid Sent! For Changes Complete a Change Order</Text>
                                </Flex>
                            )}
                            <Flex direction={{ base: "column", lg: "row" }} mt={2} w={"100%"} justify={"flex-start"}>
                                <Flex p={2} direction={"column"} w={"60%"} justify={"flex-start"} align={"flex-start"}>
                                    <Flex minW={"100%"} maxH={"88vh"} overflowY={"scroll"} border={"1px"} borderColor={"lightgrey"} shadow={"md"} borderRadius={"md"} direction={"column"} p={6} mx="auto">
                                        <Flex mb={2} w={"100%"} justify={'space-between'}>
                                            {contractorLogo ? (
                                                <Image src={contractorLogo} w={"auto"} maxH={"100px"} />
                                            ) : (
                                                <Flex direction={"column"} justify={"center"} align={"center"}>
                                                    <Image mr={2} src={Logo} maxH={"70px"} />
                                                    <Heading size={"md"}>
                                                        Utah Remodels
                                                    </Heading>
                                                </Flex>
                                            )}
                                            <Flex direction={"column"} justify={"flex-start"} align={"flex-end"}>
                                                <Heading>
                                                    Proposal
                                                </Heading>
                                                {isEditing ? (
                                                    <FormControl mb={1}>
                                                        <Flex align={"center"} justify={"flex-end"}>
                                                            <Text>Proposal ID #</Text>
                                                            <Input ml={1} size={"xs"} w={"150px"} value={estimateId} onChange={(e) => {
                                                                setEstimateId(e.target.value);
                                                                setChanged(true);
                                                            }} />
                                                        </Flex>

                                                        <FormControl hidden>
                                                            <Input type="checkbox" isChecked={isSent} onChange={(e) => {
                                                                setIsSent(e.target.checked);
                                                                setChanged(true);
                                                            }} />
                                                            <Input
                                                                value={projectAddress.street}
                                                                onChange={(e) => {
                                                                    setProjectAddress({ ...projectAddress, street: e.target.value });
                                                                    setChanged(true);
                                                                }}
                                                                placeholder="Street"
                                                            />
                                                            <Input
                                                                value={projectAddress.city}
                                                                onChange={(e) => {
                                                                    setProjectAddress({ ...projectAddress, city: e.target.value });
                                                                    setChanged(true);
                                                                }}
                                                                placeholder="City"
                                                                mt={2}
                                                            />
                                                            <Input
                                                                value={projectAddress.state}
                                                                onChange={(e) => {
                                                                    setProjectAddress({ ...projectAddress, state: e.target.value });
                                                                    setChanged(true);
                                                                }}
                                                                placeholder="State"
                                                                mt={2}
                                                            />
                                                            <Input
                                                                value={projectAddress.zipCode}
                                                                onChange={(e) => {
                                                                    setProjectAddress({ ...projectAddress, zipCode: e.target.value });
                                                                    setChanged(true);
                                                                }}
                                                                placeholder="Zip Code"
                                                                mt={2}
                                                            />
                                                            <Input
                                                                value={projectAddress.country}
                                                                onChange={(e) => {
                                                                    setProjectAddress({ ...projectAddress, country: e.target.value });
                                                                    setChanged(true);
                                                                }}
                                                                placeholder="Country"
                                                                mt={2}
                                                            />

                                                            <FormLabel mt={4}>Contractor Signature</FormLabel>
                                                            <Input
                                                                value={contractorSignature}
                                                                onChange={(e) => {
                                                                    setContractorSignature(e.target.value);
                                                                    setChanged(true);
                                                                }}
                                                            />
                                                            <FormLabel mt={4}>Contractor Signature Date</FormLabel>
                                                            <Input
                                                                type="date"
                                                                value={contractorSignatureDate}
                                                                onChange={(e) => {
                                                                    setContractorSignatureDate(e.target.value);
                                                                    setChanged(true);
                                                                }}
                                                            />
                                                        </FormControl>
                                                    </FormControl>
                                                ) : (
                                                    <Text>Proposal ID # {estimateId}</Text>
                                                )}
                                                <Flex>

                                                    {isEditing ? (
                                                        <Flex align={"center"}>
                                                            {isSent ? (
                                                                <Badge mr={1} colorScheme='blue'>Sent</Badge>
                                                            ) : (
                                                                <Badge mr={1} colorScheme='red'>Not Sent</Badge>
                                                            )}

                                                            {isActive && (
                                                                <Badge ml={1} mr={1} colorScheme='green'>Accepted</Badge>
                                                            )}
                                                        </Flex>
                                                    ) : (
                                                        <>
                                                            {isSent ? (
                                                                <Badge mr={1} colorScheme='blue'>Sent</Badge>
                                                            ) : (
                                                                <Badge mr={1} colorScheme='red'>Not Sent</Badge>
                                                            )}

                                                            {isActive && (
                                                                <Badge mr={1} colorScheme='green'>Accepted</Badge>
                                                            )}

                                                            {status === "open" ? (
                                                                <Badge colorScheme='blue'>Open</Badge>
                                                            ) : status === "in progress" ? (
                                                                <Badge colorScheme='red'>In Progress</Badge>
                                                            ) : status === "completed" ? (
                                                                <Badge colorScheme='green'>Completed</Badge>
                                                            ) : null}


                                                        </>
                                                    )}
                                                </Flex>
                                            </Flex>
                                        </Flex>
                                        <Flex mb={4} w={"100%"} justify={'space-between'}>
                                            <Flex w={"50%"} direction={"column"}>
                                                {isEditing ? (
                                                    <FormControl>
                                                        <FormLabel>Contractor's Business Name</FormLabel>
                                                        <Input value={contractorBusiness} onChange={(e) => {
                                                            setContractorBusiness(e.target.value);
                                                            setChanged(true);
                                                        }}
                                                            placeholder="Business Name"
                                                        />
                                                        <FormLabel mt={4}>Contractor Address</FormLabel>
                                                        <Input
                                                            value={contractorAddress.street}
                                                            onChange={(e) => {
                                                                setContractorAddress({ ...contractorAddress, street: e.target.value });
                                                                setChanged(true);
                                                            }}
                                                            placeholder="Street"
                                                        />
                                                        <Input
                                                            value={contractorAddress.city}
                                                            onChange={(e) => {
                                                                setContractorAddress({ ...contractorAddress, city: e.target.value });
                                                                setChanged(true);
                                                            }}
                                                            placeholder="City"
                                                            mt={2}
                                                        />
                                                        <Input
                                                            value={contractorAddress.state}
                                                            onChange={(e) => {
                                                                setContractorAddress({ ...contractorAddress, state: e.target.value });
                                                                setChanged(true);
                                                            }}
                                                            placeholder="State"
                                                            mt={2}
                                                        />
                                                        <Input
                                                            value={contractorAddress.zipCode}
                                                            onChange={(e) => {
                                                                setContractorAddress({ ...contractorAddress, zipCode: e.target.value });
                                                                setChanged(true);
                                                            }}
                                                            placeholder="Zip Code"
                                                            mt={2}
                                                        />
                                                        <Input
                                                            value={contractorAddress.country}
                                                            onChange={(e) => {
                                                                setContractorAddress({ ...contractorAddress, country: "USA" });
                                                                setChanged(true);
                                                            }}
                                                            readOnly
                                                            placeholder="Country"
                                                            mt={2}
                                                        />
                                                    </FormControl>
                                                ) : (
                                                    <>
                                                        <Flex align={"center"}>
                                                            {contractorBusiness && (
                                                                isVetted ? (
                                                                    <Flex mr={2} align="center">
                                                                        <FontAwesomeIcon size='xs' color='#1DADE3' icon={faCircleCheck} />
                                                                        <Text fontSize={"sm"} ml={1} mt={1} fontWeight="semibold">
                                                                            {contractorBusiness}
                                                                        </Text>
                                                                    </Flex>
                                                                ) : (
                                                                    <Flex mr={2} align="center">
                                                                        <FontAwesomeIcon size='xs' color='lightgray' icon={faCircleCheck} />
                                                                        <Text fontSize={"sm"} ml={1} mt={1} fontWeight="semibold">
                                                                            {contractorBusiness}
                                                                        </Text>
                                                                    </Flex>
                                                                )
                                                            )}

                                                            {licenses && licenses.length > 0 && (
                                                                <Flex mr={2}>
                                                                    <FontAwesomeIcon color='#1DADE3' icon={faIdCard} />
                                                                </Flex>
                                                            )}
                                                            {contractorInsurance && contractorInsurance.length > 0 && (
                                                                <Flex mr={2}>
                                                                    <FontAwesomeIcon color='#1DADE3' icon={faUserShield} />
                                                                </Flex>
                                                            )}
                                                            {contractorBonds && contractorBonds.length > 0 && (
                                                                <Flex>
                                                                    <FontAwesomeIcon color='#1DADE3' icon={faBuildingColumns} />
                                                                </Flex>
                                                            )}
                                                        </Flex>

                                                        <Text fontSize={"sm"} fontWeight={"semibold"}>
                                                            {contractorFirstName} {contractorLastName}
                                                        </Text>
                                                        <Text>
                                                            {contractorAddress.street}
                                                        </Text>
                                                        <Text>
                                                            {contractorAddress.city}, {contractorAddress.state} {contractorAddress.zipCode}
                                                        </Text>
                                                    </>
                                                )}
                                            </Flex>
                                            <Flex direction={"column"} justify={"flex-start"}>
                                                <Flex direction={"column"} align={"flex-end"} justify={"flex-end"}>
                                                    <>
                                                        <Text fontSize={"sm"} fontWeight={"semibold"}>
                                                            {ownerFirstName} {ownerLastName}
                                                        </Text>
                                                        <Text>{formatPhoneNumber(ownerPhone)}</Text>
                                                        {isActive && (
                                                            <Text>
                                                                {projectAddress.street}
                                                            </Text>
                                                        )}
                                                        <Text>
                                                            {projectAddress.city}, {projectAddress.state} {projectAddress.zipCode}
                                                        </Text>
                                                        <Text>Availability:</Text>
                                                    </>
                                                    {isEditing ? (
                                                        <>
                                                            <FormControl>
                                                                
                                                                <Flex shadow={!startDate ? "md" : ""} p={!startDate ? 2 : 0} bg={!startDate ? "red.300" : ""} borderRadius={!startDate ? "md" : ""} mb={1} align={"center"}>
                                                                    <Text fontSize={"sm"} color={!startDate ? "white" : ""}>Available Start Date:</Text>
                                                                    <Input size={"sm"} ml={1} type="date" value={startDate} onChange={(e) => {
                                                                        setStartDate(e.target.value);
                                                                        setChanged(true);
                                                                    }} />
                                                                </Flex>
                                                                <Flex shadow={!endDate ? "md" : ""} p={!endDate ? 2 : 0} bg={!endDate ? "red.300" : ""} borderRadius={!endDate ? "md" : ""} mb={1} align={"center"}>
                                                                    <Text fontSize={"sm"} color={!endDate ? "white" : ""}>Estimated End Date:</Text>
                                                                    <Input size={"sm"} ml={1} type="date" value={endDate} onChange={(e) => {
                                                                        setEndDate(e.target.value);
                                                                        setChanged(true);
                                                                    }} />
                                                                </Flex>
                                                                <Flex shadow={!expirationDate ? "md" : ""} p={!expirationDate ? 2 : 0} bg={!expirationDate ? "red.300" : ""} borderRadius={!expirationDate ? "md" : ""} mb={1} align={"center"}>
                                                                    <Text fontSize={"sm"} color={!expirationDate ? "white" : ""} mt={1}>Proposal Expiration Date:</Text>
                                                                    <Input
                                                                        type="date"
                                                                        size={"sm"}
                                                                        value={expirationDate}
                                                                        onChange={(e) => {
                                                                            setExpirationDate(e.target.value);
                                                                            setChanged(true);
                                                                        }}
                                                                    />
                                                                </Flex>
                                                            </FormControl>
                                                        </>
                                                    ) : (
                                                        <>
                                                            <HStack>
                                                                <Text>{formattedStartDate}</Text><Text> - {formattedEndDate}</Text>
                                                            </HStack>
                                                            <Text>Proposal Expiration Date:</Text>
                                                            <Text>{formattedExpirationDate}</Text>
                                                        </>
                                                    )}
                                                </Flex>
                                            </Flex>
                                        </Flex>
                                        <Flex direction={"column"}>
                                            {isEditing ? (
                                                <FormControl>
                                                    <FormLabel>Title</FormLabel>
                                                    <Input value={title} onChange={(e) => {
                                                        setTitle(e.target.value);
                                                        setChanged(true);
                                                    }} />
                                                    <FormLabel mt={4}>Description</FormLabel>
                                                    <Textarea value={description} onChange={(e) => {
                                                        setDescription(e.target.value);
                                                        setChanged(true);
                                                    }} />

                                                    <FormControl hidden>
                                                        <FormLabel mt={4}>Owner ID</FormLabel>
                                                        <Input value={ownerId} onChange={(e) => {
                                                            setOwnerId(e.target.value);
                                                            setChanged(true);
                                                        }} />

                                                        <FormLabel mt={4}>Owner First Name</FormLabel>
                                                        <Input value={ownerFirstName} onChange={(e) => {
                                                            setOwnerFirstName(e.target.value);
                                                            setChanged(true);
                                                        }} />

                                                        <FormLabel mt={4}>Owner Last Name</FormLabel>
                                                        <Input value={ownerLastName} onChange={(e) => {
                                                            setOwnerLastName(e.target.value);
                                                            setChanged(true);
                                                        }} />

                                                        <FormLabel mt={4}>Total Amount</FormLabel>
                                                        <Input
                                                            type="number"
                                                            value={totalAmount}
                                                            onChange={(e) => {
                                                                setTotalAmount(parseFloat(e.target.value));
                                                                setChanged(true);
                                                            }}
                                                        />
                                                    </FormControl>
                                                </FormControl>
                                            ) : (
                                                <>
                                                    <Text fontSize={"md"} fontWeight={"bold"}>{title}</Text>
                                                    <Text>{description}</Text>
                                                </>
                                            )}
                                        </Flex>
                                        {isEditing ? (
                                            <Flex mt={4} w={"100%"} bg={"gray.700"} borderRadius={"md"} >
                                                <Flex mr={8} w={"50%"} justify={"space-between"}>
                                                    <Text fontSize={"sm"} ml={6} color={"white"}>Item</Text>
                                                    <Text fontSize={"sm"} mr={8} color={"white"}>Qty</Text>
                                                </Flex>
                                                <Flex w={"50%"} justify={"space-between"}>
                                                    <Text fontSize={"sm"} color={"white"}>Rate</Text>
                                                    <Text fontSize={"sm"} color={"white"}>Tax (%)</Text>
                                                    <Text fontSize={"sm"} mr={8} color={"white"}>Amount</Text>
                                                </Flex>
                                            </Flex>
                                        ) : (
                                            <Flex mt={4} w={"100%"} bg={"gray.700"} borderRadius={"md"} >
                                                <Flex mr={4} w={"50%"} justify={"space-between"}>
                                                    <Text fontSize={"sm"} ml={6} color={"white"}>Item</Text>
                                                    <Text fontSize={"sm"} mr={4} color={"white"}>Qty</Text>
                                                </Flex>
                                                <Flex ml={4} w={"50%"} justify={"space-between"}>
                                                    <Text fontSize={"sm"} ml={4} color={"white"}>Rate</Text>
                                                    <Text fontSize={"sm"} ml={4} color={"white"}>Tax (%)</Text>
                                                    <Text fontSize={"sm"} mr={4} color={"white"}>Amount</Text>
                                                </Flex>
                                            </Flex>
                                        )}
                                        {items.map((item, index) => (
                                            <>
                                                <Flex key={index} w={"100%"} borderRadius={"md"} p={2} alignItems="start">
                                                    <Flex mr={4} w={"50%"} justify={"space-between"}>
                                                        <Flex direction={"column"} ml={4}>
                                                            <Text fontSize={"sm"} fontWeight={"semibold"}>{item.name}</Text>
                                                            <Text fontSize={"sm"} mt={-1} fontWeight={"thin"}>{item.description}</Text>
                                                        </Flex>
                                                        {!item.hideDetails && <Text fontSize={"sm"} mr={6}>{item.quantity}</Text>}
                                                    </Flex>
                                                    <Flex ml={4} w={"50%"} justify={"space-between"} alignItems="start">
                                                        {!item.hideDetails && <Text fontSize={"sm"} ml={4}>{parseFloat(item.rate).toFixed(2)}</Text>}
                                                        {!item.hideDetails && <Text>{parseFloat(item.tax).toFixed(2)}</Text>}
                                                        {!item.hideDetails && <Text fontSize={"sm"} mr={2}>{typeof item.amount === 'number' ? item.amount.toFixed(2) : '0.00'}</Text>}
                                                    </Flex>
                                                    {isEditing && (
                                                        <Button size={"xs"} colorScheme='red' onClick={() => handleDeleteItem(index)}>Delete</Button>
                                                    )}
                                                </Flex>
                                            </>
                                        ))}

                                        <Flex mt={4} justify={"flex-end"} align={"flex-end"} w={"100%"}>
                                            <Flex direction={"column"} align={"flex-end"} mr={4}>
                                                <Text fontSize={"sm"} mb={2}>Subtotal: ${subtotal}</Text>
                                                {discountAmount > 0 && (
                                                    <Text fontSize={"sm"} mb={2}>Discount: -${discountAmount}</Text>
                                                )}
                                                <Text fontSize={"sm"} mb={2}>Service Charge: ${serviceCharge}</Text>
                                            </Flex>
                                        </Flex>

                                        <Flex p={1} justify={"flex-end"} align={"flex-end"} w={"100%"} bg={"gray.700"} borderRadius={"md"} >
                                            <Text fontSize={"sm"} mr={2} color={"white"}>Total: ${total}</Text>
                                        </Flex>

                                        {isEditing ? (
                                            <>
                                                <Flex mt={4} w={"100%"}>
                                                    <Flex w={"100%"} direction="column" p={4} shadow="lg" borderRadius="md">
                                                        <Heading size="md" mb={4}>Add New Item</Heading>
                                                        <HStack align={"flex-start"}>
                                                            <FormControl>
                                                                <FormLabel>Name</FormLabel>
                                                                <Input placeholder="Enter item name" value={newItem.name} onChange={(e) => {
                                                                    setNewItem({ ...newItem, name: e.target.value });
                                                                    setChanged(true);
                                                                }} />
                                                            </FormControl>
                                                            <FormControl>
                                                                <FormLabel>Description</FormLabel>
                                                                <Input placeholder="Enter description" value={newItem.description} onChange={(e) => {
                                                                    setNewItem({ ...newItem, description: e.target.value });
                                                                    setChanged(true);
                                                                }} />
                                                            </FormControl>
                                                        </HStack>
                                                        <Flex w={"100%"} justify={"space-evenly"}>
                                                            <FormControl m={2}>
                                                                <FormLabel>Quantity</FormLabel>
                                                                <Input min={1} type="number" placeholder="Enter quantity" value={newItem.quantity}
                                                                    onChange={(e) => {
                                                                        setNewItem({
                                                                            ...newItem,
                                                                            quantity: parseInt(e.target.value, 10),
                                                                            amount: calculateAmount(parseFloat(newItem.rate), parseInt(e.target.value, 10), parseFloat(newItem.tax))
                                                                        });
                                                                        setChanged(true);
                                                                    }} />
                                                            </FormControl>
                                                            <FormControl m={2}>
                                                                <FormLabel>Rate</FormLabel>
                                                                <Input type="number" placeholder="Enter rate" value={newItem.rate}
                                                                    onChange={(e) => {
                                                                        setNewItem({
                                                                            ...newItem,
                                                                            rate: parseFloat(e.target.value),
                                                                            amount: calculateAmount(parseFloat(e.target.value), newItem.quantity, parseFloat(newItem.tax))
                                                                        });
                                                                        setChanged(true);
                                                                    }} />
                                                            </FormControl>
                                                            <FormControl m={2}>
                                                                <FormLabel>Tax (%)</FormLabel>
                                                                <Input type="number" placeholder="Enter tax percentage" value={newItem.tax}
                                                                    onChange={(e) => {
                                                                        setNewItem({
                                                                            ...newItem,
                                                                            tax: parseFloat(e.target.value),
                                                                            amount: calculateAmount(newItem.rate, newItem.quantity, parseFloat(e.target.value))
                                                                        });
                                                                        setChanged(true);
                                                                    }} />
                                                            </FormControl>

                                                            <FormControl m={2}>
                                                                <FormLabel>Amount</FormLabel>
                                                                <Input type="number" placeholder="Enter amount" value={newItem.amount.toFixed(2)} readOnly onChange={(e) => {
                                                                    setNewItem({ ...newItem, amount: parseFloat(e.target.value) });
                                                                    setChanged(true);
                                                                }} />
                                                            </FormControl>

                                                            <FormControl m={2}>
                                                                <FormLabel>Hide Details</FormLabel>
                                                                <Checkbox
                                                                    isChecked={newItem.hideDetails}
                                                                    onChange={(e) => {
                                                                        setNewItem({ ...newItem, hideDetails: e.target.checked });
                                                                        setChanged(true);
                                                                    }}
                                                                />
                                                            </FormControl>
                                                        </Flex>
                                                        <Button mt={4} bg="#1DADE3" _hover={"blue"} color={"white"} onClick={handleAddItem}>Add Item</Button>
                                                    </Flex>
                                                </Flex>
                                                <FormControl>
                                                    <Flex mt={4} w={"100%"} justify={"space-between"}>
                                                        <Box>
                                                            <FormLabel>Payment Terms</FormLabel>
                                                            <Select
                                                                value={paymentTerms}
                                                                onChange={handlePaymentTermsChange}
                                                            >
                                                                <option value="1/2">50% Upfront, 50% Upon Completion</option>
                                                                <option value="UpFront">Payment Upfront, in Full</option>
                                                                <option value="Completed">Payment Due When Completed</option>
                                                                <option value="Stages">Custom</option>
                                                            </Select>
                                                        </Box>
                                                        <Box w={"200px"}>
                                                            <FormLabel>Discount (%)</FormLabel>
                                                            <Input
                                                                type="number"
                                                                value={discount}
                                                                min={0}
                                                                onChange={(e) => {
                                                                    setDiscount(parseFloat(e.target.value));
                                                                    setChanged(true);
                                                                }}
                                                                placeholder="Enter discount percentage"
                                                            />
                                                        </Box>
                                                        {paymentTerms === "Stages" ? (
                                                            <Box>
                                                                <Heading size={"sm"}>Stage Calculator:</Heading>
                                                                <Flex shadow={"lg"} justify={"center"} bg={"gray.700"} borderRadius={"md"} align={"center"} direction={"column"} border={"1px"} mb={4} w={"400px"}>
                                                                    <Text fontSize={"sm"} mt={2} color={"white"}>Bid Total: ${total}</Text>
                                                                    <Flex w={"100%"} align={"center"} justify={"center"}>
                                                                        {Math.abs(remainingBudget.toFixed(2) - total) < 0.01 && (
                                                                            <FontAwesomeIcon color="green.600" icon={faCircleCheck} size="lg" />
                                                                        )}
                                                                        <Text fontSize={"sm"} ml={2} mt={2} color={parseFloat(remainingBudget.toFixed(2)) < 0 ? "red.300" : "white"}>
                                                                            Remaining: ${remainingBudget.toFixed(2)}
                                                                        </Text>
                                                                    </Flex>
                                                                </Flex>
                                                            </Box>
                                                        ) : (
                                                            <Box w={"400px"}>
                                                                <FormLabel>Upfront Amount</FormLabel>
                                                                <Input type="number" value={upfrontAmount.amount} readOnly />
                                                                <Input
                                                                    value={upfrontAmount.description}
                                                                    onChange={(e) => {
                                                                        setUpfrontAmount({ ...upfrontAmount, description: e.target.value });
                                                                        setChanged(true);
                                                                    }}
                                                                    placeholder="Description"
                                                                    mt={2}
                                                                />
                                                            </Box>
                                                        )}
                                                    </Flex>
                                                    {paymentTerms === "Stages" && (
                                                        <Flex mt={4} direction="column">
                                                            {stages.map((stage, index) => (
                                                                <Flex align={"center"} justify={"space-between"} key={stage.id}>
                                                                    <Box w="40%">
                                                                        <Text>Description:</Text>
                                                                        <Input
                                                                            placeholder="Description"
                                                                            value={stage.description}
                                                                            onChange={(e) => {
                                                                                handleStageChange(index, 'description', e.target.value);
                                                                                setChanged(true);
                                                                            }}
                                                                        />
                                                                    </Box>
                                                                    <Box w="20%">
                                                                        <Text>Due at Start:</Text>
                                                                        <Input
                                                                            placeholder="Upfront Amount"
                                                                            type="number"
                                                                            step="0.01"
                                                                            w="150px"
                                                                            value={stage.upfrontAmount}
                                                                            onChange={(e) => {
                                                                                handleStageChange(index, 'upfrontAmount', e.target.value);
                                                                            }}
                                                                        />
                                                                    </Box>
                                                                    <Box w="20%">
                                                                        <Text>Due at End:</Text>
                                                                        <Input
                                                                            placeholder="Completion Amount"
                                                                            type="number"
                                                                            step="0.01"
                                                                            w="150px"
                                                                            value={stage.completionAmount}
                                                                            onChange={(e) => {
                                                                                handleStageChange(index, 'completionAmount', e.target.value);
                                                                            }}
                                                                        />
                                                                    </Box>

                                                                    <Button size={"sm"} onClick={() => { setStages(stages.filter(s => s.id !== stage.id)); setChanged(true); }}>Remove</Button>
                                                                </Flex>
                                                            ))}
                                                            <Button m={2} size={"sm"} onClick={handleAddStage}>Add Stage</Button>
                                                        </Flex>
                                                    )}
                                                    <FormLabel mb={4} mt={4}>Notes</FormLabel>
                                                    <Textarea value={notes} onChange={(e) => {
                                                        setNotes(e.target.value);
                                                        setChanged(true);
                                                    }} />

                                                    {!isActive && role === "Contractor" && (
                                                        <Flex mt={4} align={"center"}>
                                                            <Heading mr={2} size={"md"}>Contractor Signature</Heading>
                                                            <FontAwesomeIcon icon={faFileSignature} />
                                                        </Flex>
                                                    )}
                                                </FormControl>

                                            </>
                                        ) : (
                                            <Flex direction="column" w="100%">
                                                {paymentTerms === "Stages" ? (
                                                    <Flex w={"100%"} justify={"flex-end"}>
                                                        <Box
                                                            w="fit-content"
                                                            mt={4}
                                                            borderRadius="lg"
                                                            border="1px"
                                                            p={4}
                                                            bg="white"
                                                            boxShadow="md"
                                                            direction="column"
                                                        >
                                                            <Heading size="md" mb={4}>
                                                                Payment Terms:
                                                            </Heading>

                                                            {stages.map((stage, index) => (
                                                                <Flex key={stage.id} align="center" justify={"space-between"} mb={2}>
                                                                    <Flex align={"center"} mr={2}>
                                                                        <FontAwesomeIcon size='xs' icon={faCircleDot} />
                                                                        <Text fontSize={"sm"} ml={2}>{stage.description}</Text>
                                                                    </Flex>
                                                                    <Flex>
                                                                        <Box
                                                                            p={2}
                                                                            borderRadius="md"
                                                                            bg={stage.upfrontAmount > 0 ? "green.100" : "gray.100"}
                                                                            mr={2}
                                                                        >
                                                                            <Text fontSize={"sm"} color={stage.upfrontAmount > 0 ? "green.600" : "gray.600"}>
                                                                                Due at Start: ${stage.upfrontAmount}
                                                                            </Text>
                                                                        </Box>

                                                                        <Box
                                                                            p={2}
                                                                            borderRadius="md"
                                                                            bg={stage.completionAmount > 0 ? "blue.100" : "gray.100"}
                                                                        >
                                                                            <Text fontSize={"sm"} color={stage.completionAmount > 0 ? "blue.600" : "gray.600"}>
                                                                                Due at End: ${stage.completionAmount}
                                                                            </Text>
                                                                        </Box>
                                                                    </Flex>
                                                                </Flex>
                                                            ))}

                                                            <Flex w="100%" justify="flex-end" mt={4}>
                                                                <Box
                                                                    bg={
                                                                        stagesTotal < total
                                                                            ? "yellow.100"
                                                                            : stagesTotal > total
                                                                                ? "red.100"
                                                                                : "green.100"
                                                                    }
                                                                    p={4}
                                                                    borderRadius="lg"
                                                                    display="flex"
                                                                    alignItems="center"
                                                                >
                                                                    {Math.abs(stagesTotal - total) < 0.01 && (
                                                                        <FontAwesomeIcon color="green.600" icon={faCircleCheck} size="lg" />
                                                                    )}

                                                                    {Math.abs(stagesTotal - total) >= 0.01 && (
                                                                        <FontAwesomeIcon
                                                                            color={stagesTotal < total ? "yellow.600" : "red.600"}
                                                                            icon={faTriangleExclamation}
                                                                            size="lg"
                                                                        />
                                                                    )}

                                                                    <Text fontSize={"sm"} ml={2} fontWeight="bold">
                                                                        Total Stages Amount: ${stagesTotal.toFixed(2)}
                                                                    </Text>
                                                                </Box>
                                                            </Flex>
                                                        </Box>
                                                    </Flex>
                                                ) : paymentTerms === "1/2" ? (
                                                    <>
                                                        {upfrontAmount && (
                                                            <Flex w={"100%"} justify={"flex-end"}>
                                                                <Flex w={"fit-content"} mt={4} borderRadius={"lg"} direction={"column"} p={2} border={"1px"}>
                                                                    <Heading size={"sm"}>Payment Terms:</Heading>
                                                                    <Text>${upfrontAmount.amount} before work starts. ${upfrontAmount.amount} when completed</Text>
                                                                    <Text>{upfrontAmount.description}</Text>
                                                                </Flex>
                                                            </Flex>
                                                        )}
                                                    </>
                                                ) : paymentTerms === "UpFront" ? (
                                                    <>
                                                        {upfrontAmount && (
                                                            <Flex w={"100%"} justify={"flex-end"}>
                                                                <Flex w={"fit-content"} mt={4} borderRadius={"lg"} direction={"column"} p={2} border={"1px"}>
                                                                    <Heading size={"sm"}>Payment Terms:</Heading>
                                                                    <Text>${upfrontAmount.amount} before work starts.</Text>
                                                                    <Text>{upfrontAmount.description}</Text>
                                                                </Flex>
                                                            </Flex>
                                                        )}
                                                    </>
                                                ) : paymentTerms === "Completed" ? (
                                                    <>
                                                        {upfrontAmount && (
                                                            <Flex w={"100%"} justify={"flex-end"}>
                                                                <Flex w={"fit-content"} mt={4} borderRadius={"lg"} direction={"column"} p={2} border={"1px"}>
                                                                    <Heading size={"sm"}>Payment Terms:</Heading>
                                                                    <Text>${total} when completed</Text>
                                                                    <Text>{upfrontAmount.description}</Text>
                                                                </Flex>
                                                            </Flex>
                                                        )}
                                                    </>
                                                ) : (
                                                    null
                                                )}
                                                {notes && (
                                                    <Flex mt={4} borderRadius={"lg"} direction={"column"} p={2} bg={"gray.700"}>
                                                        <Heading color={"white"} size={"sm"}>Notes:</Heading>
                                                        <Text fontSize={"sm"} color={"white"}>
                                                            {notes}
                                                        </Text>
                                                    </Flex>
                                                )}
                                                <Accordion mt={4} allowToggle>
                                                    {contractorInsurance && contractorInsurance.length > 0 && (
                                                        <AccordionItem>
                                                            <h2>
                                                                <AccordionButton>
                                                                    <Box as='span' flex='1' textAlign='left'>
                                                                        Insurance
                                                                    </Box>
                                                                    <AccordionIcon />
                                                                </AccordionButton>
                                                            </h2>
                                                            <AccordionPanel pb={4}>
                                                                {contractorInsurance.map((insurance, index) => (
                                                                    <Box w="fit-content" mt={2} key={index} p={2} shadow="md" borderWidth="1px">
                                                                        <Text fontSize={"sm"} fontWeight="semibold">{insurance.provider}</Text>
                                                                        <Text>Policy Number: {insurance.policyNumber}</Text>
                                                                        <Text>Coverage Amount: ${new Intl.NumberFormat('en-US', { style: 'decimal', minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(insurance.coverageAmount)}</Text>
                                                                        <Text>Expiry Date: {new Date(insurance.expiryDate).toLocaleDateString()}</Text>
                                                                        <Text>Type: {insurance.type}</Text>
                                                                    </Box>
                                                                ))}
                                                            </AccordionPanel>
                                                        </AccordionItem>
                                                    )}

                                                    {contractorBonds && contractorBonds.length > 0 && (
                                                        <AccordionItem>
                                                            <h2>
                                                                <AccordionButton>
                                                                    <Box as='span' flex='1' textAlign='left'>
                                                                        Bonds
                                                                    </Box>
                                                                    <AccordionIcon />
                                                                </AccordionButton>
                                                            </h2>
                                                            <AccordionPanel pb={4}>
                                                                {contractorBonds.map((bond, index) => (
                                                                    <Box w="fit-content" mt={2} key={index} p={2} shadow="md" borderWidth="1px">
                                                                        <Text fontSize={"sm"} fontWeight="semibold">{bond.bondingCompany}</Text>
                                                                        <Text>Bond Number: {bond.bondNumber}</Text>
                                                                        <Text>Bond Amount: ${new Intl.NumberFormat('en-US', { style: 'decimal', minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(bond.amount)}</Text>
                                                                        <Text>Expiry Date: {new Date(bond.expiryDate).toLocaleDateString()}</Text>
                                                                    </Box>
                                                                ))}
                                                            </AccordionPanel>
                                                        </AccordionItem>
                                                    )}

                                                    {licenses && licenses.length > 0 && (
                                                        <AccordionItem>
                                                            <h2>
                                                                <AccordionButton>
                                                                    <Box as='span' flex='1' textAlign='left'>
                                                                        Licenses
                                                                    </Box>
                                                                    <AccordionIcon />
                                                                </AccordionButton>
                                                            </h2>
                                                            <AccordionPanel pb={4}>
                                                                {licenses.map((license, index) => (
                                                                    <Box w="fit-content" mt={2} key={index} p={2} shadow="md" borderWidth="1px">
                                                                        <Text fontSize={"sm"} fontWeight="semibold">{license.type}</Text>
                                                                        <Text>Number: {license.number}</Text>
                                                                        <Text>Expiry Date: {new Date(license.expiryDate).toLocaleDateString()}</Text>
                                                                    </Box>
                                                                ))}
                                                            </AccordionPanel>
                                                        </AccordionItem>
                                                    )}
                                                </Accordion>

                                                <Flex p={2} w={"100%"} justify={"space-between"} align={"flex-start"}>
                                                    <Flex direction={"column"} w={"full"} justify={"flex-start"}>
                                                        <Text fontSize={"sm"} mt={2}>Contractor Signature</Text>
                                                        {contractorSignature && (
                                                            <>
                                                                <Image
                                                                    src={contractorSignature}
                                                                    alt="Contractor Signature"
                                                                    objectFit="contain"
                                                                    maxW="400px"
                                                                    h="auto"
                                                                />
                                                                {contractorSignatureDate && (
                                                                    <Text fontSize={"sm"} mt={2}>Date: {format(parseISO(contractorSignatureDate), 'MMMM dd, yyyy')}</Text>
                                                                )}
                                                            </>
                                                        )}
                                                    </Flex>
                                                    <Flex direction={"column"} w={"full"} justify={"flex-start"}>
                                                        <Text fontSize={"sm"} mt={2}>Home Owner Signature</Text>
                                                        {ownerSignature && (
                                                            <>
                                                                <Image
                                                                    src={ownerSignature}
                                                                    alt="Home Owner Signature"
                                                                    objectFit="contain"
                                                                    maxW="400px"
                                                                    h="auto"
                                                                />
                                                                <Text fontSize={"sm"} mt={2}>Date: {format(parseISO(ownerSignatureDate), 'MMMM dd, yyyy')}</Text>
                                                            </>
                                                        )}
                                                    </Flex>
                                                </Flex>
                                            </Flex>

                                        )}
                                        {/* Add form fields for revisionHistory */}

                                    </Flex>
                                </Flex>
                               
                            </Flex>
                            <MessageComponent conversationId={bidId} projectId={projectId} socket={socket} isDisabled={isDisabled} />
                        </Flex>
                    )}
                </Flex>
            </Flex>
        </Flex >
    );
}

export default CreateBid;