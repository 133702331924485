import React, { useState } from 'react';
import { useAtom } from 'jotai';
import { socketAtom } from '../socketAtom';
import { Button, useToast, Input, FormControl, FormLabel, Flex, InputGroup, InputLeftElement, useBreakpointValue } from '@chakra-ui/react';
import axios from 'axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPaperPlane } from '@fortawesome/free-solid-svg-icons';

const TransferButton = ({ destinationAccountId, orderId, totalTransferred, bid, onTransferSuccess, payoutsRemaining }) => {
    const [socket] = useAtom(socketAtom);
    const toast = useToast();
    const [amount, setAmount] = useState('');
    const menuType = useBreakpointValue({ base: 'drawer', lg: 'regular' });
    const [loading, setLoading] = useState(false);
    const conversationId = bid.conversationId;
    const handleTransfer = async () => {
        setLoading(true);
        const numericAmount = parseFloat(amount);
        console.log('numericAmount', numericAmount);
        if (numericAmount <= 0 || isNaN(numericAmount)) {
            toast({
                title: 'Invalid Amount',
                description: 'Please enter a valid amount greater than zero.',
                status: 'error',
                duration: 5000,
                isClosable: true,
            });
            setLoading(false);
            return;
        }

        if ((numericAmount) > payoutsRemaining.toFixed(2)) {
            toast({
                title: 'Transfer Exceeds Allowed Total',
                description: `You cannot transfer more than the total amount of $${(payoutsRemaining).toFixed(2)}.`,
                status: 'error',
                duration: 5000,
                isClosable: true,
            });
            setLoading(false);
            return;
        }

        try {
            const headers = {
                Authorization: `Bearer ${localStorage.getItem('token')}`
            };

            const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/transfer-funds`, {
                amount: numericAmount,
                destinationAccountId,
                orderId,
                total: bid.totalAmount
            }, {
                headers: headers
            });

            onTransferSuccess(response.data);

            toast({
                title: 'Transfer Successful',
                description: `Funds transferred successfully!!`,
                status: 'success',
                duration: 5000,
                isClosable: true,
            });

            const eventMessage = {
                conversationId: conversationId,
                message: `$${numericAmount.toFixed(2)} has been paid to ${bid.contractorBusiness}.`,
                messageType: 'transfer',
            };

            socket.emit('send-message', eventMessage);

            setAmount('');
        } catch (error) {
            console.error('Error transferring funds:', error);
            toast({
                title: 'Transfer Failed',
                description: error.response?.data?.error || 'Failed to transfer funds.',
                status: 'error',
                duration: 5000,
                isClosable: true,
            });
        } finally {
            setLoading(false);
        }
    };

    return (
        <Flex mt={2} w={"100%"} align={"flex-end"} justify={"space-evenly"}>
            <Flex w={{ base: "80%", md: "60%", lg: "70%" }}>
                <FormControl>
                    <FormLabel>Transfer Amount</FormLabel>
                    <InputGroup>
                        <InputLeftElement pointerEvents='none' color='gray.300' fontSize='1.2em'>
                            $
                        </InputLeftElement>
                        <Input
                            type="number"
                            value={amount}
                            onChange={(e) => setAmount(e.target.value)}
                            placeholder="Enter amount to transfer"
                        />
                    </InputGroup>
                </FormControl>
            </Flex>
            {menuType === 'drawer' ? (
                <Flex w={{ base: "15%", md: "40%", lg: "25%" }} justify={"flex-end"}>
                    <Button isLoading={loading} bg="#1DADE3" _hover={"blue"} color={"white"} onClick={handleTransfer} isDisabled={!amount || parseFloat(amount) > payoutsRemaining.toFixed(2)}>
                    <FontAwesomeIcon icon={faPaperPlane} />
                    </Button>
                </Flex>
            ) : (
                <Flex w={{ base: "50%", md: "40%", lg: "25%" }} justify={"flex-end"}>
                    <Button isLoading={loading} bg="#1DADE3" _hover={"blue"} color={"white"} onClick={handleTransfer} isDisabled={!amount || parseFloat(amount) > payoutsRemaining.toFixed(2)}>
                        Transfer Funds
                    </Button>
                </Flex>
            )}
        </Flex>
    );
};

export default TransferButton;
