import React, { useRef, useState } from 'react';
import SignaturePad from 'react-signature-canvas';
import axios from 'axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTriangleExclamation } from '@fortawesome/free-solid-svg-icons';
import { Button, Flex, HStack, Input, Text, useColorModeValue, useToast, Modal, ModalOverlay, ModalContent, ModalHeader, ModalCloseButton, ModalBody, ModalFooter, useBreakpointValue, Spacer } from '@chakra-ui/react';

function SignatureCapture({ onSignatureSave, isLoading }) {
    const sigPad = useRef(null);
    const menuType = useBreakpointValue({ base: 'drawer', lg: 'regular' });
    const [location, setLocation] = useState(null);
    const [verified, setVerified] = useState(false);
    const [requested, setRequested] = useState(false);
    const [isModalOpen, setModalOpen] = useState(false);
    const [locationsPermissions, setLocationPermissions] = useState(true);
    const [code, setCode] = useState('');
    const token = localStorage.getItem('token');
    const toast = useToast();

    const clear = () => sigPad.current.clear();

    const getLocation = () => {
        return new Promise((resolve) => {
            if (navigator.geolocation) {
                navigator.geolocation.getCurrentPosition(
                    (position) => {
                        const location = {
                            latitude: position.coords.latitude,
                            longitude: position.coords.longitude
                        };
                        setLocation(location);
                        resolve(location);
                    },
                    () => {
                        resolve(null);
                    }
                );
            } else {
                resolve(null);
            }
        });
    };

    const sendVerification = () => {
        setRequested(true);
        axios.post(`${process.env.REACT_APP_API_URL}/api/send-verification`, {}, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        })
            .then(response => {


            })
            .catch(error => {
                console.error('Error sending verification:', error);
            });
    };

    const verifyCode = () => {
        axios.post(`${process.env.REACT_APP_API_URL}/api/verify-code`, { code }, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        })
            .then(response => {
                setVerified(true);
                setModalOpen(true);
                toast({
                    title: "Verification Success",
                    description: "Your code was verified successfully",
                    status: "success",
                    duration: 9000,
                    isClosable: true
                });
            })
            .catch(error => {
                console.error('Verification error:', error);
                toast({
                    title: "Verification Failed",
                    description: "Failed to verify the code",
                    status: "error",
                    duration: 9000,
                    isClosable: true
                });
            });
    };

    const dataURLtoBlob = (dataurl) => {
        var arr = dataurl.split(','), mime = arr[0].match(/:(.*?);/)[1],
            bstr = atob(arr[1]), n = bstr.length, u8arr = new Uint8Array(n);
        while (n--) {
            u8arr[n] = bstr.charCodeAt(n);
        }
        return new Blob([u8arr], { type: mime });
    };

    const save = async () => {
        try {
            const location = await getLocation();
            const dataUrl = sigPad.current.getTrimmedCanvas().toDataURL('image/png');
            const blob = dataURLtoBlob(dataUrl);
            const signatureData = { blob };

            if (location) {
                signatureData.location = location;
            }

            onSignatureSave(signatureData);
            setVerified(false);
        } catch (error) {
            console.error('Error saving signature with location:', error);
        }
    };

    const bgColorUser = useColorModeValue('white', 'black');
    const textColor = useColorModeValue('black', 'white');

    return (
        <Flex direction={"column"} w={"100%"} align={"flex-start"} justify={{base: "flex-start", lg: "center"}}>
            <HStack justify={{base: "flex-start", lg: "center"}}>
                {!locationsPermissions && (
                    <Flex align={"center"}>
                        <FontAwesomeIcon color='orange' icon={faTriangleExclamation} />
                        <Text ml={2} color={"orange"} fontSize={"lg"} fontWeight={"semibold"}>Please turn on location permissions.</Text>
                    </Flex>
                )}
                {!verified && (
                    <Flex direction={{base: "column", lg: "row"}} justify={"flex-end"} align={"center"}>
                        {requested && (
                            <Input
                                placeholder="Enter verification code"
                                value={code}
                                onChange={(e) => setCode(e.target.value)}
                                w={{base: "200px", lg: "400px"}}
                                mr={2}
                            />
                        )}
                        {!requested && (
                            <Button border={"2px"} shadow={"md"} borderColor={"lemonchiffon"} mr={2} size={{base: "xs", lg: "sm"}} onClick={sendVerification}>Sign Proposal</Button>
                        )}
                        {requested && (
                            <Button mr={2} size={"sm"} bg="#1DADE3" _hover={"blue"} color={"white"}  onClick={verifyCode}>Verify Code</Button>
                        )}
                    </Flex>
                )}
            </HStack>
            <Modal size='xl' isOpen={isModalOpen} onClose={() => setModalOpen(false)}>
                <ModalOverlay />
                <ModalContent w={"100%"}>
                    <ModalHeader>Please Sign on Pad</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody w={"100%"}>
                        <Flex p={1} bg={"gray.700"} direction={"column"} maxH={{base: "200px", lg: "200px"}} w={"100%"} borderRadius={"lg"}>
                            <SignaturePad backgroundColor={bgColorUser} penColor={textColor} ref={sigPad} canvasProps={{ className: 'signatureCanvas' }} />
                            <Flex mt={2} w={"100%"} justify={"flex-end"}>
                                <Button size={"sm"} mr={4} colorScheme="gray" onClick={clear}>Clear</Button>
                                <Button size={"sm"} bg="#1DADE3" _hover={"blue"} color={"white"}  isDisabled={!verified} isLoading={isLoading} onClick={save}>Save</Button>
                            </Flex>
                        </Flex>
                    </ModalBody>
                    <ModalFooter>
                    </ModalFooter>
                </ModalContent>
            </Modal>
        </Flex>
    );
}

export default SignatureCapture;
