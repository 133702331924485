import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useAtom } from 'jotai';
import { socketAtom } from '../socketAtom';
import { io } from 'socket.io-client';
import Logo from '../assets/Horizontal LOGO.webp';
import axios from 'axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Flex, Text, Heading, Image, Badge, HStack, Box, useToast, Button, useBreakpointValue, Alert, AlertIcon, VStack, Spacer, Divider } from '@chakra-ui/react';
import ContractorSideMenu from '../contractor/SideMenu';
import Header from '../elements/Header';
import { faBuildingColumns, faBullhorn, faChartLine, faCircleCheck, faCircleDot, faCommentDollar, faCommentsDollar, faDumbbell, faFileSignature, faIdCard, faMagnifyingGlassDollar, faMobileScreenButton, faPlus, faPlusCircle, faTowerBroadcast, faUserShield } from '@fortawesome/free-solid-svg-icons';
import CreateStripeAccountButton from '../Admin/AccountCreation';

const CreateAccount = () => {
    const [socket] = useAtom(socketAtom);
    const isSmallScreen = useBreakpointValue({ base: true, md: false });
    const { userId } = useParams();
    const [contractor, setContractor] = useState({});
    const [isLoading, setIsLoading] = useState(false);
    const toast = useToast();
    const token = localStorage.getItem('token');

    const [, setSocket] = useAtom(socketAtom);

    useEffect(() => {
        const newSocket = io(process.env.REACT_APP_API_URL, {
            autoConnect: true,
            auth: {
                token: token,
            },
        });

        newSocket.on('connect_error', (error) => {
            console.error('Socket connection error:', error);
        });

        setSocket(newSocket);

        return () => newSocket.close();
    }, [setSocket, token]);

    useEffect(() => {
        fetchData();
    }, [userId, token]);

    const fetchData = async () => {
        const headers = { Authorization: `Bearer ${token}` };
        try {
            const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/contractor${userId}`, { headers });
            const { contractor } = response.data;
            setContractor(contractor);
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };

    return (
        <Flex direction={"column"} w={"100%"} justify={"center"}>
            <Flex p={2} w={"100%"} align={"flex-start"} justify={"flex-start"}>
                <Flex m={4} direction={"column"} w={"100%"} justify={"center"}>
                    <Flex mt={4} justify={"center"} align={"center"} direction={"column"} w={"100"}>
                        <Heading as="h1" size="xl" textAlign="center">Create Payment Account</Heading>
                        <Flex w={"100%"} justify={"center"}>
                            <Image src={Logo} maxH={"300px"} h={"auto"} w={"auto"} />
                        </Flex>
                        <Text mb={8}>The Stripe payment account allows you to receive payments from customers directly.</Text>
                        <CreateStripeAccountButton />
                    </Flex>
                </Flex>
            </Flex>
        </Flex>
    );
}

export default CreateAccount; 