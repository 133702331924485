import React, { useEffect } from 'react';
import { useAtom } from 'jotai';
import io from 'socket.io-client';
import { socketAtom } from './socketAtom';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { extendTheme, ChakraProvider } from '@chakra-ui/react';
import SignupForm from './SignUp/SignupForm';
import Home from './HomeOwner/CreateProject';
import NotFound from './SignUp/NotFound';
import EmailVerification from './SignUp/EmailVerification';
import LoginForm from './Login/Login';
import CheckEmail from './SignUp/CheckEmail';
import ProtectedRoute from './elements/ProtectedRoutes';
import ContractorHome from './contractor/ContractorHome';
import { SidebarProvider } from './elements/SidebarContext';
import BrowseProjects from './contractor/Browse';
import CreateProject from './HomeOwner/CreateProject';
import BidSubmission from './contractor/BidSubmission';
import BidManagement from './HomeOwner/BidManagement';
import Verification from './Admin/Verification';
import ActiveProject from './contractor/ActiveProject';
import stripePromise from './elements/StripeClient';
import { Elements } from '@stripe/react-stripe-js';
import AICreateProject from './HomeOwner/Home';
import { ChatProvider } from './contexts/ChatContext';
import Landing from './SignUp/Welcome';
import WelcomeProject from './elements/WelcomeProject';
import PublicProfile from './contractor/PublicProfile';
import Subscriptions from './contractor/SubscriptionPage';
import ResetPassword from './SignUp/ResetPassword';
import RecommendedProjects from './contractor/Recommended';
import CreateAccount from './contractor/CreateAccount';
import BusinessAccount from './Admin/BusinessAccount';
import TermsOfService from './elements/TermsOfService';
import ContractorDetails from './contractor/ContractorDetails';
import PageNotFound from './elements/PageNotFound404';
import BrokenPage from './elements/BrokenPage';
import AdminSendUrl from './Admin/StripeAccount';
import CreateBid from './contractor/CreateBid';
import Handoff from './contractor/Handoff';
import Hi from './SignUp/Trailor';
import Referral from './SignUp/Referral';
import RapidResponsePage from './elements/RapidResponsePage';

const theme = extendTheme({
  components: {
    Heading: {
      baseStyle: {
        fontFamily: "'Montserrat', sans-serif",
        fontWeight: "bold",
      },
    },
    Text: {
      baseStyle: {
        fontFamily: "'Open Sans', sans-serif",
        lineHeight: 1.6,
      },
    },
    Link: {
      baseStyle: {
        fontFamily: "'Open Sans', sans-serif",
        color: "#0072c6",
        textDecoration: "none",
        _hover: {
          textDecoration: "underline",
        },
      },
    },
    Button: {
      baseStyle: {
        fontFamily: "'Open Sans', sans-serif",
        fontWeight: "bold",
        borderRadius: "4px",
        _hover: {
          opacity: 0.8,
        },
      },
    },
  },
  breakpoints: {
    xs: "20em",
    sm: "40em",
    md: "68em",
    lg: "92em",
    xl: "124em",
  },
});

function App() {
  const [socket, setSocket] = useAtom(socketAtom);
  const token = localStorage.getItem('token');

  useEffect(() => {
    const newSocket = io(process.env.REACT_APP_API_URL, {
      autoConnect: true,
      auth: {
        token: token,
      },
    });

    newSocket.on('connect_error', (error) => {
      console.error('Socket connection error:', error);
    });

    setSocket(newSocket);

    return () => newSocket.close();
  }, [setSocket, token]);

  return (
    <ChakraProvider theme={theme}>
      <Elements stripe={stripePromise}>
        <ChatProvider>
          <Router>
            <Routes>
              <Route path="/" element={<Landing />} />
              <Route path="/hi" element={<Hi />} />
              <Route path="/:referenceId" element={<Referral />} />
              <Route path="*" element={<PageNotFound />} />
              <Route path="/signup" element={<SignupForm socket={socket} />} />
              <Route path="/check-email" element={<CheckEmail />} />
              <Route path="/verify-email" element={<EmailVerification />} />
              <Route path="/login" element={<LoginForm />} />
              <Route path="/terms-of-service" element={<TermsOfService />} />
              <Route path="/welcome-project" element={<WelcomeProject />} />
              <Route path="/rapid-response" element={<RapidResponsePage />} />
              <Route path="/reset-password/:token" element={<ResetPassword />} />


              {/* Protected Contractor Routes */}
              <Route path="/contractor/dashboard" element={<SidebarProvider><ProtectedRoute><ContractorHome /></ProtectedRoute></SidebarProvider>} />
              <Route path="/broken" element={<SidebarProvider><ProtectedRoute><BrokenPage /></ProtectedRoute></SidebarProvider>} />
              <Route path="/messages" element={<SidebarProvider><ProtectedRoute><ContractorHome /></ProtectedRoute></SidebarProvider>} />
              <Route path="/contractor-details" element={<SidebarProvider><ProtectedRoute><ContractorDetails /></ProtectedRoute></SidebarProvider>} />
              <Route path="/bids/:bidId" element={<SidebarProvider><ProtectedRoute><BidSubmission /></ProtectedRoute></SidebarProvider>} />
              <Route path="/handoff/:handoffId" element={<SidebarProvider><ProtectedRoute><Handoff /></ProtectedRoute></SidebarProvider>} />
              <Route path="/active-projects/:bidId/:projectId" element={<SidebarProvider><ProtectedRoute><ActiveProject /></ProtectedRoute></SidebarProvider>} />
              <Route path="/projects" element={<SidebarProvider><ProtectedRoute><ContractorHome /></ProtectedRoute></SidebarProvider>} />
              <Route path="/browse" element={<SidebarProvider><ProtectedRoute><BrowseProjects /></ProtectedRoute></SidebarProvider>} />
              <Route path="/verification" element={<SidebarProvider><ProtectedRoute><Verification /></ProtectedRoute></SidebarProvider>} />
              <Route path="/buy-credits" element={<SidebarProvider><ProtectedRoute><Verification /></ProtectedRoute></SidebarProvider>} />
              <Route path="/contractor/:userId" element={<SidebarProvider><ProtectedRoute><PublicProfile /></ProtectedRoute></SidebarProvider>} />
              <Route path="/recommended-projects" element={<SidebarProvider><ProtectedRoute><RecommendedProjects /></ProtectedRoute></SidebarProvider>} />
              <Route path="/create-account" element={<SidebarProvider><ProtectedRoute><CreateAccount /></ProtectedRoute></SidebarProvider>} />
              <Route path="/create-business" element={<SidebarProvider><ProtectedRoute><BusinessAccount /></ProtectedRoute></SidebarProvider>} />
              <Route path="/stripe-account" element={<SidebarProvider><ProtectedRoute><AdminSendUrl /></ProtectedRoute></SidebarProvider>} />
              <Route path="/create-bid" element={<SidebarProvider><ProtectedRoute><CreateBid /></ProtectedRoute></SidebarProvider>} />


              {/* Protected Hoeowner Routes */}
              <Route path="/create-project" element={<SidebarProvider><ProtectedRoute><CreateProject socket={socket} /></ProtectedRoute></SidebarProvider>} />
              <Route path="/subscriptions" element={<SidebarProvider><ProtectedRoute><Subscriptions socket={socket} /></ProtectedRoute></SidebarProvider>} />
              <Route path="/home" element={<SidebarProvider><ProtectedRoute><AICreateProject socket={socket} /></ProtectedRoute></SidebarProvider>} />
              <Route path="/projects/:projectId" element={<SidebarProvider><ProtectedRoute><BidManagement /></ProtectedRoute></SidebarProvider>} />

            </Routes>
          </Router>
        </ChatProvider>
      </Elements>
    </ChakraProvider>
  );
}

export default App;