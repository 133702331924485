import React, { useState } from 'react';
import axios from 'axios';
import House from '../assets/Horizontal LOGO.webp';
import { useParams, useNavigate } from 'react-router-dom';
import {
    VStack, Input, Button, FormControl, FormLabel, useToast, Flex, Container, Image
} from '@chakra-ui/react';

function ResetPassword() {
    const { token } = useParams(); // Get the token from URL params
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const navigate = useNavigate();
    const toast = useToast();

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (password !== confirmPassword) {
            toast({
                title: 'Error',
                description: "Passwords do not match.",
                status: 'error',
                duration: 9000,
                isClosable: true,
            });
            return;
        }

        try {
            const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/reset-password/confirm`, { token, password });
            toast({
                title: 'Password Reset Successful',
                description: response.data.message,
                status: 'success',
                duration: 9000,
                isClosable: true,
            });

            navigate('/login');
        } catch (error) {
            toast({
                title: 'Password Reset Failed',
                description: error.response.data.message,
                status: 'error',
                duration: 9000,
                isClosable: true,
            });
        }
    };

    return (
        <Container maxW="md" mt={10}>
            <Flex mb={8} w={"100%"} justify={"center"}>
                <Image src={House} h={"auto"} w={"300px"} />
            </Flex>
            <VStack as="form" onSubmit={handleSubmit} spacing={4}>
                <FormControl isRequired>
                    <FormLabel>New Password</FormLabel>
                    <Input
                        type="password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        placeholder="Enter new password"
                    />
                </FormControl>
                <FormControl isRequired>
                    <FormLabel>Confirm New Password</FormLabel>
                    <Input
                        type="password"
                        value={confirmPassword}
                        onChange={(e) => setConfirmPassword(e.target.value)}
                        placeholder="Confirm new password"
                    />
                </FormControl>
                <Button type="submit" colorScheme="blue">Reset Password</Button>
            </VStack>
        </Container>
    );
}

export default ResetPassword;
