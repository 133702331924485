import React, { useState, useEffect } from 'react';
import { io } from 'socket.io-client';
import { useAtom } from 'jotai';
import { useNavigate } from 'react-router-dom';
import { socketAtom } from '../socketAtom';
import { Container, Heading, Text, Box, UnorderedList, ListItem, Link, Checkbox, Button, Icon, Flex, SimpleGrid } from '@chakra-ui/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleCheck, faExternalLink, faExternalLinkAlt, faInfoCircle, faLock, faPaperPlane, faSearch, faSearchDollar, faStar, faUser, faWarning } from '@fortawesome/free-solid-svg-icons';
import { faCreditCard } from '@fortawesome/free-regular-svg-icons';
import Header from '../elements/Header';
import ContractorSideMenu from './SideMenu';
import axios from 'axios';
import Recommended from '../elements/RecommendedElements';
import HandoffForm from '../elements/HandoffForm';
import HandoffList from '../elements/HandoffList';

function ContractorHome() {
    const navigate = useNavigate();
    const [, setSocket] = useAtom(socketAtom);
    const [contractor, setContractor] = useState({});
    const [loading, setLoading] = useState(false);
    const token = localStorage.getItem('token');

    useEffect(() => {
        const newSocket = io(process.env.REACT_APP_API_URL, {
            autoConnect: true,
            auth: {
                token: token,
            },
        });

        newSocket.on('connect_error', (error) => {
            console.error('Socket connection error:', error);
        });

        setSocket(newSocket);

        return () => newSocket.close();
    }, [setSocket, token]);

    useEffect(() => {
        const getContractor = async () => {
            setLoading(true);
            try {
                const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/contractor`, {
                    headers: { Authorization: `Bearer ${token}` }
                });
                const fetchedData = response.data;
                setContractor(fetchedData);
                setLoading(false);
            } catch (error) {
                console.error('Error fetching contractor data:', error);
                setLoading(false);
            }
        };
        getContractor();
    }, [token]);

    const handleCreateBusiness = () => {
        navigate('/create-business');
    };

    const handleSubscription = () => {
        navigate('/subscriptions');
    };

    return (
        <Flex direction={"column"} w={"100%"} justify={"center"}>
            <Flex direction={{ base: "column", lg: "row" }} p={2} w="100%" align="flex-start" justify="flex-start">
                <ContractorSideMenu />
                <Flex m={{ base: 0, lg: 4 }} direction="column" w="100%" justify="center">
                    <Header />
                    <Flex mt={8} direction={"column"} w={"100%"} align={"center"} justify={"center"}>
                        <SimpleGrid h={"fit-content"} borderRadius={"lg"} w="100%" columns={{ base: 1, lg: 2 }} spacing={4}>
                            <Flex p={2} borderRadius="md" bg="gray.700" w="100%" align="flex-start" justify="center">
                                <Recommended />
                            </Flex>

                            <Flex h={"fit-content"} p={2} borderRadius="md" bg="gray.700" w="100%" align="center" justify="center">
                                <HandoffList state={'Utah'} />
                            </Flex>
                        </SimpleGrid>
                        <SimpleGrid h={"fit-content"} mt={4} borderRadius={"lg"} w="100%" columns={{ base: 1, lg: 3 }} spacing={4}>
                            <Flex h={"fit-content"} p={2} borderRadius="md" bg="gray.700" w="100%" align="center" justify="center">
                                <HandoffForm />
                            </Flex>
                            {/*
                            <Flex borderRadius="md" p={4} bg="yellow" w="100%" h="100px" align="center" justify="center">
                                <Text>Test 2</Text>
                            </Flex>
                            */}
                            {/* To Do */}
                            {contractor.business && contractor.destinationAccountId && contractor.subscription !== 'Free' && contractor.isVetted ? (
                                <></>
                            ) : (
                                <Box h={"fit-content"} bg="gray.700" p={4} borderRadius="md">
                                    <Heading as="h2" size="xl" mb={4} color="white">
                                        To Do
                                    </Heading>
                                    <Box ml={6}>
                                        <Box mb={4}>
                                            <Heading as="h3" size="lg" color={contractor.business ? "green" : 'gray'} mb={2}>
                                                <FontAwesomeIcon icon={faUser} /> Create Business Profile
                                            </Heading>
                                            {!contractor.business && (
                                                <Flex onClick={handleCreateBusiness} alignItems="center">
                                                    <Text color={"blue.600"} mr={2}>Finish Creating Business</Text>
                                                </Flex>
                                            )}
                                        </Box>
                                        <Box mb={4}>
                                            <Heading as="h3" size="lg" color={contractor.destinationAccountId ? "green" : 'gray'} mb={2}>
                                                <FontAwesomeIcon icon={faCreditCard} /> Setup Stripe Account
                                            </Heading>
                                            {!contractor.destinationAccountId && (
                                                <Box>
                                                    <Flex alignItems="center">
                                                        <Text color={"white"} mr={2}>Schedule a call</Text>
                                                        <Link href="https://calendly.com/cameron-pvai/30min" isExternal>
                                                            <FontAwesomeIcon icon={faExternalLinkAlt} /> Calendly
                                                        </Link>
                                                    </Flex>
                                                    <Flex alignItems="center">
                                                        <Icon as={FontAwesomeIcon} icon={faPaperPlane} color={"blue.500"} />
                                                        <Link ml={2} mr={4} href="mailto:Hi@UtahRemodels.com" isExternal >
                                                            or send us an email if you are having problems Hi@UtahRemodels.com
                                                        </Link>
                                                    </Flex>
                                                </Box>
                                            )}
                                        </Box>
                                        <Box mb={4}>
                                            <Heading as="h3" size="lg" color={contractor.subscription !== 'Free' ? "green" : 'gray'} mb={2}>
                                                <FontAwesomeIcon icon={faCircleCheck} /> Select Subscription
                                            </Heading>
                                            {contractor.subscription === 'Free' && (
                                                <Flex onClick={handleSubscription} alignItems="center">
                                                    <Text color={"blue.600"} mr={2}>Select Subscription</Text>
                                                </Flex>
                                            )}
                                        </Box>
                                        <Box mb={4}>
                                            <Heading as="h3" size="lg" color={contractor.isVetted ? "green" : "gray"} mb={2}>
                                                <FontAwesomeIcon icon={faLock} /> Get Verified
                                            </Heading>
                                            {!contractor.isVetted && (
                                                <Flex alignItems="center">
                                                    <Text color={"white"} mr={2}>Schedule a call to get verified</Text>
                                                    <Link href="https://calendly.com/cameron-pvai/30min" isExternal>
                                                        <FontAwesomeIcon icon={faExternalLinkAlt} /> Calendly
                                                    </Link>
                                                </Flex>
                                            )}
                                        </Box>
                                    </Box>
                                </Box>
                            )}
                        </SimpleGrid>
                    </Flex>
                </Flex>
            </Flex>
        </Flex>
    );
}

export default ContractorHome;
