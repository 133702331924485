import React, { useState } from 'react';
import { useAtom } from 'jotai';
import { socketAtom } from '../socketAtom';
import { Box, Button, Input, FormControl, FormLabel, Textarea, Flex, Heading, Select, InputGroup, InputLeftElement, useBreakpointValue } from '@chakra-ui/react';
import InputMask from 'react-input-mask';

const HandoffForm = () => {
    const [socket] = useAtom(socketAtom);
    const menuType = useBreakpointValue({ base: 'drawer', lg: 'regular' });
    const [formData, setFormData] = useState({
        homeownerFirstName: '',
        homeownerLastName: '',
        phone: '',
        email: '',
        street: '',
        city: '',
        state: '',
        zipCode: '',
        country: 'US',
        leadDescription: '',
        leadPrice: 50,
    });

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value,
        });
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        const { homeownerFirstName, homeownerLastName, phone, email, street, city, state, zipCode, country, leadDescription, leadPrice } = formData;

        const handoffData = {
            homeowner: {
                firstName: homeownerFirstName,
                lastName: homeownerLastName,
                phone,
                email,
                address: { street, city, state, zipCode, country }
            },
            leadDescription,
            leadPrice,
        };

        socket.emit('createHandoff', handoffData, (response) => {
            if (response.success) {
                alert('Handoff created successfully!');
                // Reset the form fields
                setFormData({
                    homeownerFirstName: '',
                    homeownerLastName: '',
                    phone: '',
                    email: '',
                    street: '',
                    city: '',
                    state: '',
                    zipCode: '',
                    country: 'US',
                    leadDescription: '',
                    leadPrice: 50,
                });
            } else {
                alert('Error creating handoff: ' + response.message);
            }
        });
    };

    const states = ['Alabama', 'Alaska', 'Arizona', 'Arkansas', 'California', 'Colorado', 'Connecticut', 'Delaware', 'Florida', 'Georgia',
        'Hawaii', 'Idaho', 'Illinois', 'Indiana', 'Iowa', 'Kansas', 'Kentucky', 'Louisiana', 'Maine', 'Maryland', 'Massachusetts', 'Michigan',
        'Minnesota', 'Mississippi', 'Missouri', 'Montana', 'Nebraska', 'Nevada', 'New Hampshire', 'New Jersey', 'New Mexico', 'New York',
        'North Carolina', 'North Dakota', 'Ohio', 'Oklahoma', 'Oregon', 'Pennsylvania', 'Rhode Island', 'South Carolina', 'South Dakota',
        'Tennessee', 'Texas', 'Utah', 'Vermont', 'Virginia', 'Washington', 'West Virginia', 'Wisconsin', 'Wyoming'
    ];

    return (
        <Box h={"fit-content"} w={"100%"} p={4} borderRadius="md">
            {menuType === 'drawer' ? (
                <>
                    <Heading color={"white"} mb={4} size={"md"}>Homeowner Information</Heading>
                    <form onSubmit={handleSubmit}>
                        <Flex direction={"column"}>

                            <FormControl mr={4} mb={4}>
                                <FormLabel color={"white"}>First Name</FormLabel>
                                <Input color={"white"} name="homeownerFirstName" value={formData.homeownerFirstName} onChange={handleChange} required />
                            </FormControl>
                            <FormControl mb={4}>
                                <FormLabel color={"white"}>Last Name</FormLabel>
                                <Input color={"white"} name="homeownerLastName" value={formData.homeownerLastName} onChange={handleChange} required />
                            </FormControl>

                            <FormControl mr={4} mb={4}>
                                <FormLabel color={"white"}>Phone</FormLabel>
                                <Input as={InputMask} mask="(999) 999-9999" color={"white"} name="phone" value={formData.phone} onChange={handleChange} required />
                            </FormControl>
                            <FormControl mb={4}>
                                <FormLabel color={"white"}>Email</FormLabel>
                                <Input color={"white"} name="email" value={formData.email} onChange={handleChange} required />
                            </FormControl>

                            <FormControl mb={4}>
                                <FormLabel color={"white"}>Lead Price</FormLabel>
                                <InputGroup>
                                    <InputLeftElement pointerEvents='none' color='gray.300' fontSize='1.2em'>
                                        $
                                    </InputLeftElement>
                                    <Input color={"white"} type="number" name="leadPrice" value={formData.leadPrice} onChange={handleChange} required />
                                </InputGroup>
                            </FormControl>
                        </Flex>
                        <FormControl mb={4}>
                            <FormLabel color={"white"}>Street</FormLabel>
                            <Input color={"white"} name="street" value={formData.street} onChange={handleChange} required />
                        </FormControl>

                        <FormControl mr={4} mb={4}>
                            <FormLabel color={"white"}>City</FormLabel>
                            <Input color={"white"} name="city" value={formData.city} onChange={handleChange} required />
                        </FormControl>
                        <FormControl mr={4} mb={4}>
                            <FormLabel color={"white"}>Zip Code</FormLabel>
                            <Input color={"white"} name="zipCode" value={formData.zipCode} onChange={handleChange} required />
                        </FormControl>
                        <FormControl mb={2}>
                            <FormLabel color={"white"}>State</FormLabel>
                            <Select color={"white"} placeholder="Select state" name="state" value={formData.state} onChange={handleChange}>
                                {states.map(state => (
                                    <option key={state} value={state}>{state}</option>
                                ))}
                            </Select>
                        </FormControl>

                        <Flex>
                            <FormControl mb={4}>
                                <FormLabel color={"white"}>Country</FormLabel>
                                <Input color={"white"} disabled name="country" value={formData.country} onChange={handleChange} required />
                            </FormControl>
                        </Flex>
                        <FormControl mb={4}>
                            <FormLabel color={"white"}>Lead Description</FormLabel>
                            <Textarea color={"white"} name="leadDescription" value={formData.leadDescription} onChange={handleChange} required />
                        </FormControl>
                        <Flex justify={"flex-end"} w={"100%"}>
                            <Button type="submit" colorScheme="blue">Create Handoff</Button>
                        </Flex>
                    </form>
                </>
            ) : (
                <>
                    <Heading color={"white"} mb={4} size={"md"}>Homeowner Information</Heading>
                    <form onSubmit={handleSubmit}>
                        <Flex>
                            <FormControl mr={4} mb={4}>
                                <FormLabel color={"white"}>First Name</FormLabel>
                                <Input color={"white"} name="homeownerFirstName" value={formData.homeownerFirstName} onChange={handleChange} required />
                            </FormControl>
                            <FormControl mb={4}>
                                <FormLabel color={"white"}>Last Name</FormLabel>
                                <Input color={"white"} name="homeownerLastName" value={formData.homeownerLastName} onChange={handleChange} required />
                            </FormControl>
                        </Flex>
                        <Flex>
                            <Flex mr={4} w={"70%"}>
                                <FormControl w={"fit-content"} mr={4} mb={4}>
                                    <FormLabel color={"white"}>Phone</FormLabel>
                                    <Input as={InputMask} mask="(999) 999-9999" color={"white"} name="phone" value={formData.phone} onChange={handleChange} required />
                                </FormControl>
                                <FormControl mb={4}>
                                    <FormLabel color={"white"}>Email</FormLabel>
                                    <Input color={"white"} name="email" value={formData.email} onChange={handleChange} required />
                                </FormControl>
                            </Flex>
                            <Flex w={"30%"}>
                                <FormControl mb={4}>
                                    <FormLabel color={"white"}>Lead Price</FormLabel>
                                    <InputGroup>
                                        <InputLeftElement pointerEvents='none' color='gray.300' fontSize='1.2em'>
                                            $
                                        </InputLeftElement>
                                        <Input color={"white"} type="number" name="leadPrice" value={formData.leadPrice} onChange={handleChange} required />
                                    </InputGroup>
                                </FormControl>
                            </Flex>
                        </Flex>
                        <FormControl mb={4}>
                            <FormLabel color={"white"}>Street</FormLabel>
                            <Input color={"white"} name="street" value={formData.street} onChange={handleChange} required />
                        </FormControl>
                        <Flex>
                            <FormControl mr={4} mb={4}>
                                <FormLabel color={"white"}>City</FormLabel>
                                <Input color={"white"} name="city" value={formData.city} onChange={handleChange} required />
                            </FormControl>
                            <FormControl mr={4} mb={4}>
                                <FormLabel color={"white"}>Zip Code</FormLabel>
                                <Input color={"white"} name="zipCode" value={formData.zipCode} onChange={handleChange} required />
                            </FormControl>
                            <FormControl mb={2}>
                                <FormLabel color={"white"}>State</FormLabel>
                                <Select color={"white"} placeholder="Select state" name="state" value={formData.state} onChange={handleChange}>
                                    {states.map(state => (
                                        <option key={state} value={state}>{state}</option>
                                    ))}
                                </Select>
                            </FormControl>
                        </Flex>
                        <Flex>
                            <FormControl mb={4}>
                                <FormLabel color={"white"}>Country</FormLabel>
                                <Input color={"white"} disabled name="country" value={formData.country} onChange={handleChange} required />
                            </FormControl>
                        </Flex>
                        <FormControl mb={4}>
                            <FormLabel color={"white"}>Lead Description</FormLabel>
                            <Textarea color={"white"} name="leadDescription" value={formData.leadDescription} onChange={handleChange} required />
                        </FormControl>
                        <Flex justify={"flex-end"} w={"100%"}>
                            <Button type="submit" colorScheme="blue">Create Handoff</Button>
                        </Flex>
                    </form>
                </>
            )}
        </Box>
    );
};

export default HandoffForm;
