import React, { useState } from 'react';
import {
    Box,
    Button,
    Flex,
    FormControl,
    FormLabel,
    Input,
    useToast,
} from '@chakra-ui/react';
import axios from 'axios';

const AdminSendUrl = () => {
    const [url, setUrl] = useState('');
    const [email, setEmail] = useState('');
    const toast = useToast();

    const handleSubmit = async (event) => {
        event.preventDefault();
        try {
            const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/send-url`, { url, email });
            toast({
                title: "Success",
                description: response.data.message,
                status: "success",
                duration: 5000,
                isClosable: true,
            });
        } catch (error) {
            toast({
                title: "Error",
                description: error.response.data.message,
                status: "error",
                duration: 5000,
                isClosable: true,
            });
        }
    };

    return (
        <Flex w={"100%"} justify={"center"} p={8}>
            <Box w={"30%"}>
                <form onSubmit={handleSubmit}>
                    <FormControl>
                        <FormLabel htmlFor='email'>Email</FormLabel>
                        <Input
                            id='email'
                            type='email'
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            placeholder='Enter recipient email'
                        />
                        <FormLabel htmlFor='url'>URL</FormLabel>
                        <Input
                            id='url'
                            type='text'
                            value={url}
                            onChange={(e) => setUrl(e.target.value)}
                            placeholder='Enter URL to send'
                        />
                    </FormControl>
                    <Flex w={"100%"} justify={"flex-end"}>
                        <Button mt={4} colorScheme='blue' type='submit'>
                            Send URL
                        </Button>
                    </Flex>
                </form>
            </Box>
        </Flex>
    );
};

export default AdminSendUrl;
