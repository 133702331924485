import React, { useState, useEffect, useCallback } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { io } from 'socket.io-client';
import { useAtom } from 'jotai';
import { socketAtom } from '../socketAtom';
import Logo from '../assets/Horizontal LOGO.webp';
import {
    Flex, Text, Heading, Box, Button, Input, Textarea, Stack, Tag, TagLabel, useToast,
    TagCloseButton, FormControl, FormLabel, VStack, InputGroup, InputLeftElement, useColorMode,
    Image, HStack, Select, Checkbox, CheckboxGroup, RadioGroup, Radio, Icon, useRadio, useRadioGroup, SimpleGrid, Circle, Accordion, AccordionItem, AccordionButton, AccordionIcon, AccordionPanel, Spinner, Spacer, InputRightElement, IconButton, Progress, ProgressLabel, Tooltip, Switch, useBreakpointValue, Menu, MenuButton, MenuList, MenuItem
} from '@chakra-ui/react';
import 'react-datepicker/dist/react-datepicker.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faBath, faBorderAll, faBroom, faCalendarAlt, faCheckCircle, faExclamationTriangle, faHammer, faHouseDamage, faPalette, faPlug, faSeedling, faTree, faUtensils, faWindowRestore, faWrench,
    faSnowflake,
    faHardHat,
    faCouch,
    faScrewdriver,
    faRug,
    faPaintRoller,
    faDrumSteelpan,
    faFan,
    faHome,
    faToolbox,
    faLeaf,
    faPaintBrush,
    faSwimmer,
    faRulerCombined,
    faWarehouse,
    faWater,
    faThermometerHalf,
    faVectorSquare,
    faSoap,
    faWindowMaximize,
    faTrowelBricks,
    faHouseChimneyWindow,
    faTools,
    faCogs,
    faIgloo,
    faSwimmingPool,
    faBolt,
    faShieldAlt,
    faTape,
    faAnglesRight,
    faAnglesLeft,
    faFileInvoiceDollar,
    faLock,
    faRightLong,
    faDownLong,
    faChevronDown,
    faRulerHorizontal,
    faDoorOpen,
    faCloudSun,
    faDungeon,
    faTrowel,
} from '@fortawesome/free-solid-svg-icons';
import { CalendarIcon, ViewIcon, ViewOffIcon } from '@chakra-ui/icons';

function RadioCard(props) {
    const { getInputProps, getCheckboxProps } = useRadio(props);
    const input = getInputProps();
    const checkbox = getCheckboxProps();

    const iconMap = {
        emergency: faExclamationTriangle
    };

    return (
        <Box as="label">
            <input {...input} />
            <Box
                {...checkbox}
                cursor="pointer"
                borderWidth="1px"
                borderRadius="md"
                boxShadow="md"
                _checked={{
                    bg: "#1DADE3",
                    color: "white",
                    borderColor: "white",
                }}
                px={5}
                py={3}
            >
                <VStack>
                    <FontAwesomeIcon icon={iconMap[props.value]} size="2x" />
                    <Text fontWeight="bold">{props.title}</Text>
                    <Text fontSize="sm">{props.children}</Text>
                </VStack>
            </Box>
        </Box>
    );
}

function ServiceRadioCard({ serviceTitles, serviceDescriptions, ...props }) {
    const { getInputProps, getCheckboxProps } = useRadio(props);
    const input = getInputProps();
    const checkbox = getCheckboxProps();

    const iconMap = {
        plumbing: faWrench,
        kitchens: faUtensils,
        bathrooms: faBath,
        cleaning: faBroom,
        lawncare: faSeedling,
        treeService: faTree,
        windows: faHouseChimneyWindow,
        roofing: faHouseDamage,
        electrical: faPlug,
        remodels: faHammer,
        decksPorches: faRulerHorizontal,
        fences: faDungeon,
        cooling: faSnowflake,
        architecture: faHardHat,
        cabinets: faBorderAll,
        carpentry: faScrewdriver,
        carpet: faRug,
        masonry: faTrowelBricks,
        driveways: faDrumSteelpan,
        drywall: faTrowel,
        flooring: faRulerCombined,
        foundations: faHome,
        garages: faWarehouse,
        gutters: faWater,
        handyman: faToolbox,
        HVAC: faThermometerHalf,
        landscape: faLeaf,
        painting: faPaintRoller,
        siding: faTape,
        poolsSpas: faSwimmer,
        doors: faDoorOpen,
        lowVoltage: faBolt,
        security: faShieldAlt,
        poolBuilding: faSwimmingPool,
        automation: faCogs,
        welding: faTools,
        insulation: faIgloo,
        tiling: faBorderAll,
    };

    return (
        <Box as="label" cursor="pointer">
            <input {...input} />
            <Flex
                {...checkbox}
                direction="column"
                justify={"center"}
                w={"fit-content"}
                color={'lightgray'}
                align="center"
                borderRadius="md"
                p={4}
                transition="all 0.2s"
                _checked={{
                    borderColor: 'white',
                    bg: '#1DADE3',
                    color: 'white',
                    transform: 'translateY(-2px)',
                    boxShadow: 'lg',
                }}
                _hover={{
                    boxShadow: 'lg',
                    transform: 'translateY(-2px)',
                }}
            >
                <Circle p={4}>
                    <FontAwesomeIcon icon={iconMap[props.value]} size="lg" />
                </Circle>
                <Text fontWeight="bold" mt={2}>
                    {serviceTitles[props.value]}
                </Text>
            </Flex>
        </Box>
    );
}

function RapidResponsePage() {
    const [currentStep, setCurrentStep] = useState(1);
    const menuType = useBreakpointValue({ base: 'drawer', lg: 'regular' });
    const [formData, setFormData] = useState({
        title: "",
        description: "",
        trade: "",
        tradesNeeded: [],
        street: "",
        city: "",
        service: "",
        platformPayment: true,
        state: "",
        zipCode: "",
        country: "USA",
        imageUrls: [],
        projectType: "emergency",
        readyToStartTimeframe: "",
        firstName: '',
        lastName: '',
        email: '',
        phone: '',
        password: '',
        role: 'Home Owner',
    });
    const [isLoading, setIsLoading] = useState(false);
    const [passwordStrength, setPasswordStrength] = useState({
        minLength: false,
        hasNumber: false,
        hasUpper: false,
        hasLower: false,
        hasSpecialChar: false
    });
    const [showPassword, setShowPassword] = useState(false);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [suggestions, setSuggestions] = useState([]);
    const [isUploading, setIsUploading] = useState(false);
    const [images] = useState([]);
    const [socket, setSocket] = useAtom(socketAtom);
    const navigate = useNavigate();
    const { colorMode } = useColorMode();
    const toast = useToast();
    const token = localStorage.getItem('token');

    useEffect(() => {
        const newSocket = io(process.env.REACT_APP_API_URL, {
            autoConnect: true,
            auth: {
                token: token,
            },
        });
        newSocket.on('connect_error', (error) => {
            console.error('Socket connection error:', error);
        });
        setSocket(newSocket);
        return () => newSocket.close();
    }, [token]);

    const handleChange = useCallback((e) => {
        if (e && e.target) {
            const { name, value } = e.target;  // Define 'name' and 'value' at this level to ensure they are accessible throughout the function

            // Set form data
            if (name === "readyToStartTimeframe") {
                setFormData(prev => ({ ...prev, readyToStartTimeframe: value }));
            } else {
                setFormData(prev => ({ ...prev, [name]: value }));
            }

            // Password validation
            if (name === "password") {
                const minLength = value.length >= 8;
                const hasNumber = /\d/.test(value);
                const hasUpper = /[A-Z]/.test(value);
                const hasLower = /[a-z]/.test(value);
                const hasSpecialChar = /[\W_]/.test(value);
                setPasswordStrength({
                    minLength,
                    hasNumber,
                    hasUpper,
                    hasLower,
                    hasSpecialChar
                });
            }
        }
    }, []);

    const formatPhoneNumber = (value) => {
        const phoneNumber = value.replace(/[^\d]/g, '');
        const phoneNumberLength = phoneNumber.length;
        if (phoneNumberLength < 4) return phoneNumber;
        if (phoneNumberLength < 7) {
            return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3)}`;
        }
        return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3, 6)}-${phoneNumber.slice(6, 10)}`;
    };

    // Fetch trade suggestions as user types
    const handleTradeChange = (e) => {
        const newTrade = e.target.value;
        setFormData(prev => ({ ...prev, trade: newTrade }));
        if (newTrade.length > 2) {  // Start suggesting after 2 characters
            fetchTradeSuggestions(newTrade);
        } else {
            setSuggestions([]);
        }
    };

    const selectSuggestion = (suggestion) => {
        setFormData(prev => ({
            ...prev,
            tradesNeeded: [...prev.tradesNeeded, suggestion],
            trade: ''  // Clear the input after selection
        }));
        setSuggestions([]);
    };

    const removeTradeNeeded = (index) => {
        setFormData(prev => ({
            ...prev,
            tradesNeeded: prev.tradesNeeded.filter((_, i) => i !== index)
        }));
    };

    const handleFileChange = useCallback(async (event) => {
        const files = Array.from(event.target.files);
        setIsUploading(true);
        const fileData = new FormData();
        files.forEach(file => {
            fileData.append('file', file);
        });

        try {
            const res = await axios.post(`${process.env.REACT_APP_API_URL}/api/upload`, fileData, {
                headers: { 'Content-Type': 'multipart/form-data' }
            });

            // Check if the upload was successful and the response includes a URL
            if (!res.data.error && res.data.url) {
                const newImageUrl = res.data.url;
                console.log("Upload successful, image URL:", newImageUrl);
                setFormData(prev => ({
                    ...prev,
                    imageUrls: [...prev.imageUrls, newImageUrl] // Append the new URL to the existing array
                }));
            } else {
                console.error('Upload failed or unexpected response:', res.data);
            }
        } catch (error) {
            console.error('Failed to upload images:', error);
        } finally {
            setIsUploading(false);
        }
    }, []);

    const handleImageRemove = useCallback((index) => {
        setFormData(prev => ({
            ...prev,
            imageUrls: prev.imageUrls.filter((_, i) => i !== index)
        }));
    }, []);

    const titles = {
        emergency: "Rapid Response",
    };

    const descriptions = {
        emergency: "Needs immediate attention or emergency service.",
    };

    const { getRootProps: getProjectTypeRootProps, getRadioProps: getProjectTypeRadioProps } = useRadioGroup({
        name: "projectType",
        onChange: value => setFormData({ ...formData, projectType: value })
    });

    const { getRootProps: getServiceRootProps, getRadioProps: getServiceRadioProps } = useRadioGroup({
        name: "service",
        onChange: value => setFormData({ ...formData, service: value })
    });

    const projectTypeGroup = getProjectTypeRootProps();
    const serviceGroup = getServiceRootProps();

    const serviceTitles = {
        architecture: "Builders",
        automation: "Home Automation",
        bathrooms: "Bathrooms",
        cabinets: "Cabinets & Countertops",
        carpentry: "Carpentry",
        carpet: "Carpet",
        cleaning: "Cleaning",
        cooling: "Cooling",
        decksPorches: "Decks & Porches",
        doors: "Windows & Doors",
        drywall: "Drywall",
        electrical: "Electrical",
        fences: "Fences",
        flooring: "Flooring",
        foundations: "Foundations",
        garages: "Garages, Doors, Openers",
        gutters: "Gutters",
        handyman: "Handyman Services",
        HVAC: "Heating & Cooling",
        insulation: "Insulation",
        kitchens: "Kitchens",
        landscape: "Landscape",
        lawncare: "Lawncare",
        lowVoltage: "Low Voltage",
        masonry: "Concrete, Brick & Stone",
        painting: "Painting",
        plumbing: "Plumbing",
        poolsSpas: "Spas",
        poolBuilding: "Pool Building",
        remodels: "Remodels",
        roofing: "Roofing",
        security: "Security Systems Installation",
        siding: "Siding",
        tiling: "Tiling",
        treeService: "Trees",
        welding: "Welding",
        windows: "Windows"
    };

    const serviceDescriptions = {
        plumbing: "....",
        electrical: "....",
        cleaning: "....",
    };

    // Popular services
    const popularServices = [
        'plumbing',
        'electrical',
        'cooling',
        'kitchens',
        'bathrooms',
        'remodels',
        'cleaning',
        'lawncare',
        'treeService',
        'windows',
        'roofing',
        'painting'
    ];

    // Other services
    const otherServices = Object.keys(serviceTitles).filter(
        (service) => !popularServices.includes(service)
    );

    const fetchTradeSuggestions = async (value) => {
        if (value.length > 1) {
            try {
                const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/search/trades?query=${value}`);
                setSuggestions(response.data);
            } catch (error) {
                console.error('Failed to fetch trade suggestions:', error);
                setSuggestions([]);
            }
        } else {
            setSuggestions([]);
        }
    };

    useEffect(() => {
        if (formData.trade.length > 1) {
            const delayDebounceFn = setTimeout(() => {
                fetchTradeSuggestions(formData.trade);
            }, 300);
            return () => clearTimeout(delayDebounceFn);
        }
    }, [formData.trade]);

    const states = ['Alabama', 'Alaska', 'Arizona', 'Arkansas', 'California', 'Colorado', 'Connecticut', 'Delaware', 'Florida', 'Georgia',
        'Hawaii', 'Idaho', 'Illinois', 'Indiana', 'Iowa', 'Kansas', 'Kentucky', 'Louisiana', 'Maine', 'Maryland', 'Massachusetts', 'Michigan',
        'Minnesota', 'Mississippi', 'Missouri', 'Montana', 'Nebraska', 'Nevada', 'New Hampshire', 'New Jersey', 'New Mexico', 'New York',
        'North Carolina', 'North Dakota', 'Ohio', 'Oklahoma', 'Oregon', 'Pennsylvania', 'Rhode Island', 'South Carolina', 'South Dakota',
        'Tennessee', 'Texas', 'Utah', 'Vermont', 'Virginia', 'Washington', 'West Virginia', 'Wisconsin', 'Wyoming'
    ];

    const togglePasswordVisibility = () => setShowPassword(!showPassword);

    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsSubmitting(true);
        setIsLoading(true);

        const userInfo = {
            firstName: formData.firstName,
            lastName: formData.lastName,
            email: formData.email,
            phone: formData.phone,
            password: formData.password,
            role: formData.role,
            referralId: localStorage.getItem('referral'),
        };

        const address = {
            street: formData.street,
            city: formData.city,
            state: formData.state,
            zipCode: formData.zipCode,
            country: formData.country,
        };

        try {
            // First, submit the user data
            const userResponse = await axios.post(`${process.env.REACT_APP_API_URL}/api/users/signup`, userInfo);
            const ownerId = userResponse.data.ownerId;
            const lastName = userResponse.data.lastName;
            const firstName = userResponse.data.firstName;
            const phone = userResponse.data.phone;
            console.log('phone', phone);

            // Prepare project data with ownerId
            const projectData = {
                ...formData,
                ownerId,
                firstName,
                lastName,
                phone,
                address,
                images: formData.imageUrls,
                tradesNeeded: formData.tradesNeeded.map(trade => trade.toLowerCase())
            };

            switch (formData.projectType) {
                case 'estimate':
                    handleEstimateProject(projectData);
                    break;

                case 'ready':
                    await handleReadyProject(projectData);
                    break;

                case 'emergency':
                    handleEmergencyProject(projectData);
                    break;

                default:
                    console.error('Invalid project type');
            }
            toast({
                title: 'Project created successfully.',
                status: 'success',
                duration: 5000,
                isClosable: true,
            });
            setIsLoading(false);
        } catch (error) {
            setCurrentStep(6)
            setIsLoading(false);
            console.error('Error occurred:', error);

            let errorMessage = 'An error occurred while creating the project.';

            if (error.response) {
                // The request was made and the server responded with a status code
                // that falls out of the range of 2xx
                console.log(error.response.data);
                console.log(error.response.status);
                console.log(error.response.headers);

                if (error.response.status === 400) {
                    errorMessage = 'Invalid request data. Please check your input and try again.';
                } else if (error.response.status === 401) {
                    errorMessage = 'Unauthorized. Please log in and try again.';
                } else if (error.response.status === 500) {
                    errorMessage = 'Internal server error. Please try again later.';
                }
            } else if (error.request) {
                // The request was made but no response was received
                console.log(error.request);
                errorMessage = 'No response received from the server. Please try again later.';
            } else {
                // Something happened in setting up the request that triggered an Error
                console.log('Error', error.message);
            }

            toast({
                title: 'Error',
                description: errorMessage,
                status: 'error',
                duration: 9000,
                isClosable: true,
            });
        } finally {
            setIsSubmitting(false);
            setIsLoading(false);
        }
    };

    const handleEstimateProject = async (projectData) => {
        const projectResponse = await axios.post(`${process.env.REACT_APP_API_URL}/api/welcome-project`, projectData);

        if (projectResponse.status === 201) {
            toast({
                title: 'Project created successfully.',
                status: 'success',
                duration: 9000,
                isClosable: true,
            });
            navigate('/check-email');
            setIsSubmitting(false);
        } else {
            setCurrentStep(6)
            throw new Error('Project creation failed');
        }
    };

    const handleReadyProject = async (projectData) => {
        const projectResponse = await axios.post(`${process.env.REACT_APP_API_URL}/api/welcome-project`, projectData);

        if (projectResponse.status === 201) {
            toast({
                title: 'Project created successfully.',
                status: 'success',
                duration: 9000,
                isClosable: true,
            });
            navigate('/check-email');
            setIsSubmitting(false);
        } else {
            setCurrentStep(6)
            throw new Error('Project creation failed');
        }
    };

    const handleEmergencyProject = async (projectData) => {
        const projectResponse = await axios.post(`${process.env.REACT_APP_API_URL}/api/immediate/welcome-project`, projectData);

        if (projectResponse.status === 201) {
            toast({
                title: 'Project created successfully.',
                status: 'success',
                duration: 9000,
                isClosable: true,
            });

            try {
                const { data: session } = await axios.post(`${process.env.REACT_APP_API_URL}/api/welcome/emergency-create-session`, {
                    amount: 0, // Amount in cents ($5)
                    title: "Emergency Project Fee",
                    transfer_group: projectResponse.data.project._id
                }, {
                    headers: {
                        'Content-Type': 'application/json'
                    }
                });

                // Redirect to Stripe Checkout
                window.location = session.url;
            } catch (error) {
                console.error('Failed to create checkout session:', error.message);
                setCurrentStep(6)
            }

            setIsSubmitting(false);
        } else {
            setCurrentStep(6)
            throw new Error('Project creation failed');
        }
    };

    const handleStateChange = (selectedState) => {
        setFormData(prevFormData => ({
            ...prevFormData,
            state: selectedState
        }));
    };

    const handleGoHome = () => {
        navigate('/');
    };

    const progressLabelColor = colorMode === 'dark' ? 'gray.700' : 'white';
    const progressBarBg = colorMode === 'dark' ? 'gray.600' : 'gray.500';


    return (
        <Flex direction={"column"} w={"100%"} justify={"center"}>
            <Flex direction={{ base: "column", lg: "row" }} p={2} w="100%" align="flex-start" justify="flex-start">
                <Flex m={{ base: 0, lg: 4 }} direction="column" w="100%" justify="center">
                    {menuType === 'drawer' ? (
                        <>
                            <Flex mt={4} align={"center"} w={"100%"} justify={"center"} >
                                <Flex align={"center"} w={"70%"} justify={"flex-start"} >
                                    <Image onClick={handleGoHome} src={Logo} w={"auto"} h={"50px"} />
                                </Flex>
                            </Flex>
                            <Flex p={1} w="100%" align="flex-start" justify="flex-start">
                                <Flex m={1} direction="column" w="100%" justify="center">
                                    <Flex direction="column" align="center" p={1}>

                                        <Box mt={2} width="100%" maxWidth="800px">
                                            <VStack spacing={2} align="stretch">
                                                <Flex direction="column" w="100%" justify="center">
                                                    {currentStep === 1 && (
                                                        <Flex direction={"column"} mt={8} h={"700px"} overflowY={"auto"}>
                                                            <FormControl>
                                                                <Heading mb={2} size={"lg"}>Get Help Quick!</Heading>
                                                                <VStack {...getProjectTypeRootProps}>
                                                                    {["emergency"].map((value) => (
                                                                        <RadioCard
                                                                            key={value}
                                                                            {...getProjectTypeRadioProps({ name: "projectType", value })}
                                                                            title={titles[value]}
                                                                        >
                                                                            {descriptions[value]}
                                                                        </RadioCard>
                                                                    ))}
                                                                </VStack>
                                                                
                                                                {formData.projectType === 'emergency' && (
                                                                    <Box mt={8} w={"100%"} justify={"center"}>
                                                                        <Text mt={4} fontWeight={"semibold"}>Rapid Response: Get the Right Contractor, Right Now!</Text>
                                                                        <Text mt={4}>Need a help quickly? We've got you covered. Let us connect you with a vast network of trusted professionals in just a few clicks.</Text>
                                                                        
                                                                    </Box>

                                                                )}
                                                            </FormControl>
                                                            <Spacer />
                                                            <Flex w={"100%"} align={"center"} justify={"flex-end"} borderRadius={"lg"} p={2} bg={"gray.700"}>
                                                                <Button onClick={() => setCurrentStep(formData.projectType === 'estimate' ? 3 : 2)} bg="#1DADE3" _hover={"blue"} color={"white"} >
                                                                    <Text mr={2}>Next</Text>
                                                                    <FontAwesomeIcon icon={faAnglesRight} />
                                                                </Button>
                                                            </Flex>
                                                        </Flex>
                                                    )}

                                                    {currentStep === 2 && (
                                                        <Flex direction={"column"} mt={8} h={"700px"} overflowY={"auto"}>
                                                            <FormControl>
                                                                <Heading mb={2} size={"lg"}>Secure Payment</Heading>
                                                                {formData.projectType !== 'estimate' && (
                                                                    <Flex mb={4} mt={2} w="100%" justify="center">
                                                                        <Flex direction="column" p={4} borderRadius="lg" bg="gray.700" w="100%" align="center">
                                                                            <Heading size="md" color="white" mb={4}>
                                                                                Boost Trust with Our Secure Payment System
                                                                            </Heading>

                                                                            <VStack justify="space-evenly" w="75%" mb={8}>
                                                                                <Box p={4} borderColor="white" borderRadius="lg" borderWidth="1px">
                                                                                    <Flex w="100%" justify="space-between" align="center">
                                                                                        <FontAwesomeIcon color={"lime"} icon={faLock} /> <Heading mt={1} ml={2} color="white" size="md">Funds Added: $1200</Heading>
                                                                                    </Flex>
                                                                                    <Progress
                                                                                        value={100}  // Representing the full amount added
                                                                                        colorScheme='green'
                                                                                        size="lg"
                                                                                        bg={progressBarBg}
                                                                                        mb={2}
                                                                                        mt={4}
                                                                                        borderRadius={8}
                                                                                        hasStripe
                                                                                        isAnimated
                                                                                    >
                                                                                        <ProgressLabel color={progressLabelColor} fontSize="md">$1200</ProgressLabel>
                                                                                    </Progress>
                                                                                </Box>
                                                                                <FontAwesomeIcon size="2xl" color="white" icon={faDownLong} />
                                                                                <Box p={4} borderColor="white" borderRadius="lg" borderWidth="1px">
                                                                                    <Flex w="100%" justify="space-between" align="flex-end">
                                                                                        <Heading color="white" size="md">
                                                                                            Funds Released: $950
                                                                                        </Heading>
                                                                                    </Flex>
                                                                                    <Progress
                                                                                        value={79}  // Calculation based on the amount released from the total
                                                                                        colorScheme='green'
                                                                                        size="lg"
                                                                                        bg={progressBarBg}
                                                                                        mb={2}
                                                                                        mt={4}
                                                                                        borderRadius={8}
                                                                                        hasStripe
                                                                                        isAnimated
                                                                                    >
                                                                                        <ProgressLabel color={progressLabelColor} fontSize="md">$950</ProgressLabel>
                                                                                    </Progress>
                                                                                </Box>
                                                                            </VStack>
                                                                            <Text color="white" mb={8}>
                                                                                Secure and transparent financial transactions are at the heart of our service.
                                                                                Safely deposit funds into an project account and release payments to contractors
                                                                                only upon your approval. Opt for convenient bank transfers or card payments.
                                                                            </Text>
                                                                            <Tooltip bg="black" label="A 2% service fee applies, with additional payment processing fees" placement="auto-start">
                                                                                <FormControl display="flex" alignItems="center">
                                                                                    <FormLabel htmlFor="platform-payment-switch" mb="0" color="white" mr={4}>
                                                                                        Use Our Secure Payment System
                                                                                    </FormLabel>
                                                                                    <Switch
                                                                                        id="platform-payment-switch"
                                                                                        isChecked={formData.platformPayment}
                                                                                        onChange={(e) => setFormData(prev => ({ ...prev, platformPayment: e.target.checked }))}
                                                                                        sx={{
                                                                                            '.chakra-switch__track': {
                                                                                                _checked: {
                                                                                                    bg: "#1DADE3",
                                                                                                }
                                                                                            }
                                                                                        }}
                                                                                    />
                                                                                </FormControl>
                                                                            </Tooltip>
                                                                        </Flex>
                                                                    </Flex>
                                                                )}
                                                            </FormControl>
                                                            <Spacer />
                                                            <Flex w={"100%"} align={"center"} justify={"space-between"} borderRadius={"lg"} p={2} bg={"gray.700"}>
                                                                <Button onClick={() => setCurrentStep(currentStep - 1)}>
                                                                    <FontAwesomeIcon icon={faAnglesLeft} />
                                                                    <Text ml={2}>Back</Text>
                                                                </Button>
                                                                <Button onClick={() => setCurrentStep(currentStep + 1)} bg="#1DADE3" _hover={"blue"} color={"white"} >
                                                                    <Text mr={2}>Next</Text>
                                                                    <FontAwesomeIcon icon={faAnglesRight} />
                                                                </Button>
                                                            </Flex>
                                                        </Flex>
                                                    )}

                                                    {currentStep === 3 && (
                                                        <Flex direction={"column"} mt={8} h={"700px"} overflowY={"auto"}>
                                                            <Heading mb={2} size={"lg"}>Select a Category</Heading>
                                                            <Flex p={1} bg={"gray.700"} borderRadius={"lg"}>
                                                                <FormControl isRequired>
                                                                    <FormLabel color={"white"}>Popular Services:</FormLabel>
                                                                    <SimpleGrid {...serviceGroup} columns={{ base: 3 }} spacing={2}>
                                                                        {popularServices.map((value) => (
                                                                            <ServiceRadioCard
                                                                                key={value}
                                                                                value={value}
                                                                                {...getServiceRadioProps({ value })}
                                                                                serviceTitles={serviceTitles}
                                                                                serviceDescriptions={serviceDescriptions}
                                                                            />
                                                                        ))}
                                                                    </SimpleGrid>
                                                                </FormControl>
                                                            </Flex>

                                                            <Accordion mt={4} allowToggle>
                                                                <AccordionItem>
                                                                    <Heading size={"sm"}>
                                                                        <AccordionButton borderRadius={"md"} _expanded={{ bg: 'gray.700', color: 'white' }}>
                                                                            <Box as='span' flex='1' textAlign='left'>
                                                                                More Services
                                                                            </Box>
                                                                            <AccordionIcon />
                                                                        </AccordionButton>
                                                                    </Heading>
                                                                    <AccordionPanel h={"550px"} overflowY={"auto"} borderRadius={'lg'} mt={2} bg={"gray.700"} pb={4}>
                                                                        <SimpleGrid {...serviceGroup} columns={{ base: 3 }} spacing={2}>
                                                                            {otherServices.map((value) => (
                                                                                <ServiceRadioCard
                                                                                    key={value}
                                                                                    value={value}
                                                                                    {...getServiceRadioProps({ value })}
                                                                                    serviceTitles={serviceTitles}
                                                                                    serviceDescriptions={serviceDescriptions}
                                                                                />
                                                                            ))}
                                                                        </SimpleGrid>
                                                                    </AccordionPanel>
                                                                </AccordionItem>
                                                            </Accordion>
                                                            {/*
                                                            <FormControl mt={8} mb={8} isRequired>
                                                                <FormLabel>Skills:</FormLabel>
                                                                <Flex direction={"column"} position="relative">
                                                                    <Input
                                                                        placeholder="Add a trade"
                                                                        value={formData.trade}
                                                                        onChange={handleTradeChange}
                                                                        autoComplete="off"
                                                                    />
                                                                    <Flex direction="column" position="absolute" mt="12" w="full" zIndex="10" bg="white" boxShadow="md">
                                                                        {suggestions.map((suggestion, index) => (
                                                                            <Button
                                                                                key={suggestion._id}
                                                                                variant="ghost"
                                                                                justifyContent="start"
                                                                                onClick={() => selectSuggestion(suggestion.trade)}
                                                                                _hover={{ bg: "gray.100" }}
                                                                            >
                                                                                {suggestion.trade}
                                                                            </Button>
                                                                        ))}
                                                                    </Flex>
                                                                    <Flex direction="column" mt={2}>
                                                                        <Flex flexWrap="wrap" gap="2">
                                                                            {formData.tradesNeeded.map((trade, index) => (
                                                                                <Tag size="lg" key={index} borderRadius="full" mb={1}>
                                                                                    <TagLabel>{trade}</TagLabel>
                                                                                    <TagCloseButton onClick={() => removeTradeNeeded(index)} />
                                                                                </Tag>
                                                                            ))}
                                                                        </Flex>
                                                                    </Flex>
                                                                </Flex>
                                                            </FormControl>
                                                                            */}
                                                            <Spacer />
                                                            <Flex w={"100%"} align={"center"} justify={"space-between"} borderRadius={"lg"} p={2} bg={"gray.700"}>
                                                                <Button onClick={() => setCurrentStep(formData.projectType === 'estimate' ? 1 : 2)}>
                                                                    <FontAwesomeIcon icon={faAnglesLeft} />
                                                                    <Text ml={2}>Back</Text>
                                                                </Button>
                                                                <Button onClick={() => setCurrentStep(currentStep + 1)} bg="#1DADE3" _hover={"blue"} color={"white"} >
                                                                    <Text mr={2}>Next</Text>
                                                                    <FontAwesomeIcon icon={faAnglesRight} />
                                                                </Button>
                                                            </Flex>
                                                        </Flex>
                                                    )}

                                                    {currentStep === 4 && (
                                                        <Flex direction={"column"} mt={8} h={"700px"} overflowY={"auto"}>
                                                            <Flex justify={"space-between"} w={"100%"}>
                                                                <Box w={"100%"}>
                                                                    <FormControl isRequired>
                                                                        <Heading mb={2} size={"lg"}>How Can We Help?</Heading>
                                                                        <FormLabel mt={8}>Tell us about your project</FormLabel>
                                                                        <Input placeholder="e.g., Kitchen Remodeling, Bathroom Renovation" name="title" value={formData.title} onChange={handleChange} />
                                                                    </FormControl>
                                                                    <FormControl isRequired mt={8}>
                                                                        <FormLabel>Can you give more details?</FormLabel>
                                                                        <Textarea placeholder="Describe what you would like done, any specific requirements, materials, etc." name="description" value={formData.description} onChange={handleChange} />
                                                                    </FormControl>
                                                                </Box>
                                                            </Flex>
                                                            <Spacer />
                                                            <Flex w={"100%"} align={"center"} justify={"space-between"} borderRadius={"lg"} p={2} bg={"gray.700"}>
                                                                <Button onClick={() => setCurrentStep(currentStep - 1)}>
                                                                    <FontAwesomeIcon icon={faAnglesLeft} />
                                                                    <Text ml={2}>Back</Text>
                                                                </Button>
                                                                <Button onClick={() => setCurrentStep(currentStep + 1)} bg="#1DADE3" _hover={"blue"} color={"white"} >
                                                                    <Text mr={2}>Next</Text>
                                                                    <FontAwesomeIcon icon={faAnglesRight} />
                                                                </Button>
                                                            </Flex>
                                                        </Flex>
                                                    )}

                                                    {currentStep === 5 && (
                                                        <Flex direction={"column"} mt={8} h={"700px"} overflowY={"auto"}>
                                                            <FormControl>
                                                                <Heading mb={2} size={"lg"}>Images Are Helpful</Heading>
                                                                {isUploading && (
                                                                    <Flex w={"100%"} justify={"center"}>
                                                                        <Spinner color='gray.700' size={"xl"} />
                                                                    </Flex>
                                                                )}

                                                                {!isUploading && (
                                                                    <>
                                                                        <FormLabel mt={4}>Images:</FormLabel>
                                                                        <Input type="file" accept="image/*" onChange={handleFileChange} multiple />
                                                                    </>
                                                                )}

                                                                <Flex wrap="wrap" mt={4} spacing={4}>
                                                                    {formData.imageUrls.map((url, index) => (
                                                                        <Box>
                                                                            <Flex w={"100%"} mb={-8} ml={-2} justify={"flex-end"}>
                                                                                <Button
                                                                                    size="xs"
                                                                                    shadow={"lg"}
                                                                                    colorScheme="red"
                                                                                    onClick={() => handleImageRemove(index)}
                                                                                >
                                                                                    X
                                                                                </Button>
                                                                            </Flex>
                                                                            <Box key={index} p={2} boxShadow="lg" lineHeight="tight" borderRadius="md" overflow="hidden">
                                                                                <Image src={url} alt="Uploaded preview" boxSize="140px" objectFit="cover" />
                                                                            </Box>
                                                                        </Box>
                                                                    ))}
                                                                </Flex>
                                                            </FormControl>
                                                            <Spacer />
                                                            <Flex w={"100%"} align={"center"} justify={"space-between"} borderRadius={"lg"} p={2} bg={"gray.700"}>
                                                                <Button onClick={() => setCurrentStep(currentStep - 1)}>
                                                                    <FontAwesomeIcon icon={faAnglesLeft} />
                                                                    <Text ml={2}>Back</Text>
                                                                </Button>
                                                                <Button onClick={() => setCurrentStep(currentStep + 1)} bg="#1DADE3" _hover={"blue"} color={"white"} >
                                                                    <Text mr={2}>Next</Text>
                                                                    <FontAwesomeIcon icon={faAnglesRight} />
                                                                </Button>
                                                            </Flex>
                                                        </Flex>
                                                    )}

                                                    {currentStep === 6 && (
                                                        <Flex direction={"column"} mt={8} h={"700px"} overflowY={"auto"}>
                                                            <FormControl>
                                                                <Heading mb={2} size={"lg"}>Address</Heading>
                                                                <Text color={"red"} fontSize={'small'}>For your privacy, we will only share your street address with a contractor you have hired.</Text>
                                                                <FormLabel mt={4}>Street</FormLabel>
                                                                <Input name="street" value={formData.street} onChange={handleChange} />
                                                            </FormControl>
                                                            <Flex direction={"column"} justify={"space-evenly"} w={"100%"}>
                                                                <FormControl mt={2}>
                                                                    <FormLabel>City</FormLabel>
                                                                    <Input name="city" value={formData.city} onChange={handleChange} />
                                                                </FormControl>
                                                                <FormControl mt={2}>
                                                                    <FormLabel>State</FormLabel>
                                                                    <Menu>
                                                                        <MenuButton w={"100%"} as={Button} rightIcon={<Icon as={FontAwesomeIcon} icon={faChevronDown} />}>
                                                                            {formData.state || 'Select state'}
                                                                        </MenuButton>
                                                                        <MenuList w={"100%"} maxHeight="500px" overflowY="auto">
                                                                            {states.map(state => (
                                                                                <MenuItem key={state} onClick={() => handleStateChange(state)}>
                                                                                    {state}
                                                                                </MenuItem>
                                                                            ))}
                                                                        </MenuList>
                                                                    </Menu>
                                                                </FormControl>
                                                                <FormControl mt={2}>
                                                                    <FormLabel>Zip Code</FormLabel>
                                                                    <Input name="zipCode" value={formData.zipCode} onChange={handleChange} />
                                                                </FormControl>
                                                            </Flex>
                                                            <Spacer />
                                                            <Flex w={"100%"} align={"center"} justify={"space-between"} borderRadius={"lg"} p={2} bg={"gray.700"}>
                                                                <Button onClick={() => setCurrentStep(currentStep - 1)}>
                                                                    <FontAwesomeIcon icon={faAnglesLeft} />
                                                                    <Text ml={2}>Back</Text>
                                                                </Button>
                                                                <Button isLoading={isLoading} onClick={(e) => {
                                                                    setCurrentStep(currentStep + 1);
                                                                }} bg="#1DADE3" _hover={"blue"} color={"white"} >
                                                                    <Text mr={2}>Next</Text>
                                                                    <FontAwesomeIcon icon={faAnglesRight} />
                                                                </Button>
                                                            </Flex>
                                                        </Flex>
                                                    )}

                                                    {currentStep === 7 && (
                                                        <Flex direction={"column"} mt={8} h={"700px"} overflowY={"auto"}>
                                                            <Heading mb={2} size={"lg"}>Create Your Account</Heading>
                                                            <HStack mt={4}>
                                                                <FormControl id="firstName" isRequired>
                                                                    <FormLabel>First Name</FormLabel>
                                                                    <Input
                                                                        name="firstName"
                                                                        value={formData.firstName}
                                                                        onChange={handleChange}
                                                                        placeholder="John"
                                                                    />
                                                                </FormControl>
                                                                <FormControl id="lastName" isRequired>
                                                                    <FormLabel>Last Name</FormLabel>
                                                                    <Input
                                                                        name="lastName"
                                                                        value={formData.lastName}
                                                                        onChange={handleChange}
                                                                        placeholder="Doe"
                                                                    />
                                                                </FormControl>
                                                            </HStack>
                                                            <FormControl mt={4} id="email" isRequired>
                                                                <FormLabel>Email</FormLabel>
                                                                <Input
                                                                    type="email"
                                                                    name="email"
                                                                    value={formData.email}
                                                                    onChange={handleChange}
                                                                    placeholder="john@example.com"
                                                                />
                                                            </FormControl>
                                                            <VStack align={"flex-start"} mt={4}>
                                                                <FormControl id="phone" isRequired>
                                                                    <FormLabel>Phone Number</FormLabel>
                                                                    <Input
                                                                        type="phone"
                                                                        name="phone"
                                                                        value={formData.phone}
                                                                        onChange={handleChange}
                                                                        placeholder="(555) 555-5555"
                                                                    />
                                                                </FormControl>
                                                                <FormControl isRequired>
                                                                    <FormLabel>Password</FormLabel>
                                                                    <InputGroup>
                                                                        <Input
                                                                            type={showPassword ? "text" : "password"}
                                                                            name="password"
                                                                            value={formData.password}
                                                                            onChange={handleChange}
                                                                            placeholder="Enter password"
                                                                        />
                                                                        <InputRightElement>
                                                                            <IconButton
                                                                                aria-label={showPassword ? "Hide password" : "Show password"}
                                                                                icon={showPassword ? <ViewOffIcon /> : <ViewIcon />}
                                                                                onClick={togglePasswordVisibility}
                                                                                size="sm"
                                                                                variant="ghost"
                                                                            />
                                                                        </InputRightElement>
                                                                    </InputGroup>
                                                                    <Flex w={"100%"} justify={"flex-start"}>
                                                                        <VStack p={4} bg={"gray.700"} borderRadius={"lg"} align="start" mt={2}>
                                                                            <PasswordCriteria isMet={passwordStrength.minLength}>At least 8 characters</PasswordCriteria>
                                                                            <PasswordCriteria isMet={passwordStrength.hasNumber}>Includes a number</PasswordCriteria>
                                                                            <PasswordCriteria isMet={passwordStrength.hasUpper}>Includes an uppercase letter</PasswordCriteria>
                                                                            <PasswordCriteria isMet={passwordStrength.hasLower}>Includes a lowercase letter</PasswordCriteria>
                                                                            <PasswordCriteria isMet={passwordStrength.hasSpecialChar}>Includes a special character</PasswordCriteria>
                                                                        </VStack>
                                                                    </Flex>
                                                                </FormControl>
                                                            </VStack>
                                                            <Spacer />
                                                            <Flex w={"100%"} mt={2} align={"center"} justify={"space-between"} borderRadius={"lg"} p={2} bg={"gray.700"}>
                                                                <Button onClick={() => setCurrentStep(6)}>
                                                                    <FontAwesomeIcon icon={faAnglesLeft} />
                                                                    <Text ml={2}>Back</Text>
                                                                </Button>
                                                                <Button isLoading={isLoading} onClick={(e) => {
                                                                    handleSubmit(e);
                                                                }} bg="#1DADE3" _hover={"blue"} color={"white"} >
                                                                    <Text mr={2}>Submit</Text>
                                                                    <FontAwesomeIcon icon={faAnglesRight} />
                                                                </Button>
                                                            </Flex>
                                                        </Flex>
                                                    )}
                                                </Flex>
                                            </VStack>
                                        </Box>
                                    </Flex>
                                </Flex>
                            </Flex>
                        </>
                    ) : (
                        <>
                            <Flex mt={4} align={"center"} w={"100%"} justify={"center"} >
                                <Flex align={"center"} w={"70%"} justify={"flex-start"} >
                                    <Image onClick={handleGoHome} src={Logo} w={"auto"} h={"50px"} />
                                </Flex>
                            </Flex>
                            <Flex p={2} w="100%" align="flex-start" justify="flex-start">
                                <Flex m={4} direction="column" w="100%" justify="center">
                                    <Flex direction="column" align="center" p={8}>

                                        <Box mt={8} width="100%" maxWidth="800px">
                                            <VStack spacing={6} align="stretch">
                                                <Flex direction="column" w="100%" justify="center">
                                                    {currentStep === 1 && (
                                                        <Flex direction={"column"} maxH={"950px"} minh={"550px"} overflowY={"auto"}>
                                                            <FormControl>
                                                                <Heading mb={2} size={"lg"}>Get Help Quickly!</Heading>
                                                                <HStack {...getProjectTypeRootProps}>
                                                                    {["emergency"].map((value) => (
                                                                        <RadioCard
                                                                            key={value}
                                                                            {...getProjectTypeRadioProps({ name: "projectType", value })}
                                                                            title={titles[value]}
                                                                        >
                                                                            {descriptions[value]}
                                                                        </RadioCard>
                                                                    ))}
                                                                </HStack>
                                                                {formData.projectType === 'ready' && (
                                                                    <Flex w={"100%"} justify={"center"}>
                                                                        <FormControl mt={4}>
                                                                            <FormLabel>How soon are you ready to start?</FormLabel>
                                                                            <RadioGroup
                                                                                value={formData.readyToStartTimeframe}
                                                                                onChange={(value) => setFormData({ ...formData, readyToStartTimeframe: value })}
                                                                            >
                                                                                <HStack p={4} borderRadius={"lg"} shadow={"lg"} bg={"gray.700"} spacing={6}>
                                                                                    <Radio _checked={{ bg: "#1DADE3" }} value="rightAway"><Text color={"white"}>I'm Ready!</Text></Radio>
                                                                                    <Radio _checked={{ bg: "#1DADE3" }} value="inDays"><Text color={"white"}>Days</Text></Radio>
                                                                                    <Radio _checked={{ bg: "#1DADE3" }} value="inWeeks"><Text color={"white"}>Weeks</Text></Radio>
                                                                                </HStack>
                                                                            </RadioGroup>
                                                                        </FormControl>
                                                                    </Flex>
                                                                )}
                                                                {formData.projectType === 'emergency' && (
                                                                    <Box mt={8} w={"100%"} justify={"center"}>
                                                                        <Text mt={4} fontWeight={"semibold"}>Rapid Response: Get the Right Contractor, Right Now!</Text>
                                                                        <Text mt={4}>Need a help quickly? We've got you covered. Let us connect you with a vast network of trusted professionals in just a few clicks.</Text>
                                                                        
                                                                    </Box>

                                                                )}
                                                            </FormControl>
                                                            <Spacer />
                                                            <Flex w={"100%"} align={"center"} justify={"flex-end"} borderRadius={"lg"} p={2} bg={"gray.700"}>
                                                                <Button
                                                                    onClick={() => setCurrentStep(formData.projectType === 'estimate' ? 3 : 2)}
                                                                    bg="#1DADE3"
                                                                    _hover={"blue"}
                                                                    color={"white"}
                                                                >
                                                                    <Text mr={2}>Next</Text>
                                                                    <FontAwesomeIcon icon={faAnglesRight} />
                                                                </Button>
                                                            </Flex>
                                                        </Flex>
                                                    )}

                                                    {currentStep === 2 && (
                                                        <Flex direction={"column"} maxH={"950px"} minh={"550px"} overflowY={"auto"}>
                                                            <FormControl>
                                                                <Heading mb={2} size={"lg"}>Secure Payments</Heading>
                                                                {formData.projectType !== 'estimate' && (
                                                                    <Flex mb={4} w="100%" justify="center">
                                                                        <Flex direction="column" p={4} borderRadius="lg" bg="gray.700" w="100%" align="center">
                                                                            <Heading size="md" color="white" mb={4}>
                                                                                Boost Trust with Our Secure Payment System
                                                                            </Heading>

                                                                            <HStack justify="space-evenly" w="75%" mb={8}>
                                                                                <Box p={4} borderColor="white" borderRadius="lg" borderWidth="1px">
                                                                                    <Flex w="100%" justify="space-between" align="center">
                                                                                        <FontAwesomeIcon color={"lime"} icon={faLock} /> <Heading mt={1} ml={2} color="white" size="md">Funds Added: $1200</Heading>
                                                                                    </Flex>
                                                                                    <Progress
                                                                                        value={100}  // Representing the full amount added
                                                                                        colorScheme='blue'
                                                                                        size="lg"
                                                                                        bg={progressBarBg}
                                                                                        mb={2}
                                                                                        mt={4}
                                                                                        borderRadius={8}
                                                                                        hasStripe
                                                                                        isAnimated
                                                                                    >
                                                                                        <ProgressLabel color={progressLabelColor} fontSize="md">$1200</ProgressLabel>
                                                                                    </Progress>
                                                                                </Box>
                                                                                <FontAwesomeIcon size="2xl" color="white" icon={faRightLong} />
                                                                                <Box p={4} borderColor="white" borderRadius="lg" borderWidth="1px">
                                                                                    <Flex w="100%" justify="space-between" align="flex-end">
                                                                                        <Heading color="white" size="md">
                                                                                            Funds Released: $950
                                                                                        </Heading>
                                                                                    </Flex>
                                                                                    <Progress
                                                                                        value={79}  // Calculation based on the amount released from the total
                                                                                        colorScheme='blue'
                                                                                        size="lg"
                                                                                        bg={progressBarBg}
                                                                                        mb={2}
                                                                                        mt={4}
                                                                                        borderRadius={8}
                                                                                        hasStripe
                                                                                        isAnimated
                                                                                    >
                                                                                        <ProgressLabel color={progressLabelColor} fontSize="md">$950</ProgressLabel>
                                                                                    </Progress>
                                                                                </Box>
                                                                            </HStack>
                                                                            <Text color="white" mb={8}>
                                                                                Secure and transparent financial transactions are at the heart of our service.
                                                                                Safely deposit funds into an project account and release payments to contractors
                                                                                only upon your approval. Opt for convenient bank transfers or card payments.
                                                                            </Text>
                                                                            <Tooltip bg="black" label="A 2% service fee applies, with additional payment processing fees" placement="auto-start">
                                                                                <FormControl display="flex" alignItems="center">
                                                                                    <FormLabel htmlFor="platform-payment-switch" mb="0" color="white" mr={4}>
                                                                                        Use Our Secure Payment System
                                                                                    </FormLabel>
                                                                                    <Switch
                                                                                        id="platform-payment-switch"
                                                                                        isChecked={formData.platformPayment}
                                                                                        onChange={(e) => setFormData(prev => ({ ...prev, platformPayment: e.target.checked }))}
                                                                                        sx={{
                                                                                            '.chakra-switch__track': {
                                                                                                _checked: {
                                                                                                    bg: "#1DADE3",
                                                                                                }
                                                                                            }
                                                                                        }}
                                                                                    />
                                                                                </FormControl>
                                                                            </Tooltip>
                                                                        </Flex>
                                                                    </Flex>
                                                                )}
                                                            </FormControl>
                                                            <Spacer />
                                                            <Flex w={"100%"} align={"center"} justify={"space-between"} borderRadius={"lg"} p={2} bg={"gray.700"}>
                                                                <Button onClick={() => setCurrentStep(1)}>
                                                                    <FontAwesomeIcon icon={faAnglesLeft} />
                                                                    <Text ml={2}>Back</Text>
                                                                </Button>
                                                                <Button onClick={() => setCurrentStep(3)} bg="#1DADE3" _hover={"blue"} color={"white"} >
                                                                    <Text mr={2}>Next</Text>
                                                                    <FontAwesomeIcon icon={faAnglesRight} />
                                                                </Button>
                                                            </Flex>
                                                        </Flex>
                                                    )}

                                                    {currentStep === 3 && (
                                                        <Flex minh={"550px"} direction={"column"}>
                                                            <Heading mb={2} size={"lg"}>Select a Category</Heading>
                                                            <Flex p={4} bg={"gray.700"} borderRadius={"lg"}>
                                                                <FormControl isRequired>
                                                                    <FormLabel color={"white"}>Popular Services:</FormLabel>
                                                                    <SimpleGrid {...serviceGroup} columns={{ base: 1, sm: 2, md: 4, lg: 6 }} spacing={4}>
                                                                        {popularServices.map((value) => (
                                                                            <ServiceRadioCard
                                                                                key={value}
                                                                                value={value}
                                                                                {...getServiceRadioProps({ value })}
                                                                                serviceTitles={serviceTitles}
                                                                                serviceDescriptions={serviceDescriptions}
                                                                            />
                                                                        ))}
                                                                    </SimpleGrid>
                                                                </FormControl>
                                                            </Flex>

                                                            <Accordion mt={4} allowToggle>
                                                                <AccordionItem>
                                                                    <Heading size={"sm"}>
                                                                        <AccordionButton borderRadius={"md"} _expanded={{ bg: 'gray.700', color: 'white' }}>
                                                                            <Box as='span' flex='1' textAlign='left'>
                                                                                More Services
                                                                            </Box>
                                                                            <AccordionIcon />
                                                                        </AccordionButton>
                                                                    </Heading>
                                                                    <AccordionPanel h={"550px"} overflowY={"auto"} borderRadius={'lg'} mt={2} bg={"gray.700"} pb={4}>
                                                                        <SimpleGrid {...serviceGroup} columns={{ base: 1, sm: 2, md: 4, lg: 6 }} spacing={4}>
                                                                            {otherServices.map((value) => (
                                                                                <ServiceRadioCard
                                                                                    key={value}
                                                                                    value={value}
                                                                                    {...getServiceRadioProps({ value })}
                                                                                    serviceTitles={serviceTitles}
                                                                                    serviceDescriptions={serviceDescriptions}
                                                                                />
                                                                            ))}
                                                                        </SimpleGrid>
                                                                    </AccordionPanel>
                                                                </AccordionItem>
                                                            </Accordion>

                                                            {/*
                                                            <FormControl mt={8} isRequired>
                                                                <FormLabel>Skills:</FormLabel>
                                                                <Flex direction={"column"} position="relative">
                                                                    <Input
                                                                        placeholder="Add a trade"
                                                                        value={formData.trade}
                                                                        onChange={handleTradeChange}
                                                                        autoComplete="off"
                                                                    />
                                                                    <Flex direction="column" position="absolute" mt="12" w="full" zIndex="10" bg="white" boxShadow="md">
                                                                        {suggestions.map((suggestion, index) => (
                                                                            <Button
                                                                                key={suggestion._id}
                                                                                variant="ghost"
                                                                                justifyContent="start"
                                                                                onClick={() => selectSuggestion(suggestion.trade)}
                                                                                _hover={{ bg: "gray.100" }}
                                                                            >
                                                                                {suggestion.trade}
                                                                            </Button>
                                                                        ))}
                                                                    </Flex>
                                                                    <Flex direction="column" mt={2}>
                                                                        <Flex flexWrap="wrap" gap="2">
                                                                            {formData.tradesNeeded.map((trade, index) => (
                                                                                <Tag size="lg" key={index} borderRadius="full" mb={1}>
                                                                                    <TagLabel>{trade}</TagLabel>
                                                                                    <TagCloseButton onClick={() => removeTradeNeeded(index)} />
                                                                                </Tag>
                                                                            ))}
                                                                        </Flex>
                                                                    </Flex>
                                                                </Flex>
                                                            </FormControl>
                                                                            */}
                                                            <Spacer />
                                                            <Flex w={"100%"} align={"center"} justify={"space-between"} borderRadius={"lg"} p={2} bg={"gray.700"}>
                                                                <Button onClick={() => setCurrentStep(formData.projectType === 'estimate' ? 1 : 2)}>
                                                                    <FontAwesomeIcon icon={faAnglesLeft} />
                                                                    <Text ml={2}>Back</Text>
                                                                </Button>
                                                                <Button onClick={() => setCurrentStep(4)} bg="#1DADE3" _hover={"blue"} color={"white"} >
                                                                    <Text mr={2}>Next</Text>
                                                                    <FontAwesomeIcon icon={faAnglesRight} />
                                                                </Button>
                                                            </Flex>
                                                        </Flex>
                                                    )}

                                                    {currentStep === 4 && (
                                                        <Flex direction={"column"} h={"550px"} overflowY={"auto"}>
                                                            <Flex justify={"space-between"} w={"100%"}>
                                                                <Box w={"100%"}>
                                                                    <FormControl isRequired>
                                                                    <Heading mb={4} size={"lg"}>How Can We Help?</Heading>
                                                                        <FormLabel>Tell us about your project</FormLabel>
                                                                        <Input placeholder="e.g., Kitchen Remodeling, Bathroom Renovation" name="title" value={formData.title} onChange={handleChange} />
                                                                    </FormControl>
                                                                    <FormControl isRequired mt={8}>
                                                                        <FormLabel>Can you give more details?</FormLabel>
                                                                        <Textarea placeholder="Describe what you would like done, any specific requirements, materials, etc." name="description" value={formData.description} onChange={handleChange} />
                                                                    </FormControl>
                                                                </Box>
                                                            </Flex>
                                                            <Spacer />
                                                            <Flex w={"100%"} align={"center"} justify={"space-between"} borderRadius={"lg"} p={2} bg={"gray.700"}>
                                                                <Button onClick={() => setCurrentStep(3)}>
                                                                    <FontAwesomeIcon icon={faAnglesLeft} />
                                                                    <Text ml={2}>Back</Text>
                                                                </Button>
                                                                <Button onClick={() => setCurrentStep(5)} bg="#1DADE3" _hover={"blue"} color={"white"} >
                                                                    <Text mr={2}>Next</Text>
                                                                    <FontAwesomeIcon icon={faAnglesRight} />
                                                                </Button>
                                                            </Flex>
                                                        </Flex>
                                                    )}

                                                    {currentStep === 5 && (
                                                        <Flex direction={"column"} h={"550px"} overflowY={"auto"}>
                                                            <FormControl>
                                                            <Heading mb={4} size={"lg"}>Images Are Helpful</Heading>
                                                                {isUploading && (
                                                                    <Flex w={"100%"} justify={"center"}>
                                                                        <Spinner color='gray.700' size={"xl"} />
                                                                    </Flex>
                                                                )}

                                                                {!isUploading && (
                                                                    <>
                                                                        <FormLabel>Images:</FormLabel>
                                                                        <Input type="file" accept="image/*" onChange={handleFileChange} multiple />
                                                                    </>
                                                                )}

                                                                <Flex wrap="wrap" mt={4} spacing={4}>
                                                                    {formData.imageUrls.map((url, index) => (
                                                                        <Box>
                                                                            <Flex w={"100%"} mb={-8} ml={-2} justify={"flex-end"}>
                                                                                <Button
                                                                                    size="xs"
                                                                                    shadow={"lg"}
                                                                                    colorScheme="red"
                                                                                    onClick={() => handleImageRemove(index)}
                                                                                >
                                                                                    X
                                                                                </Button>
                                                                            </Flex>
                                                                            <Box key={index} p={2} boxShadow="lg" lineHeight="tight" borderRadius="md" overflow="hidden">
                                                                                <Image src={url} alt="Uploaded preview" boxSize="150px" objectFit="cover" />
                                                                            </Box>
                                                                        </Box>
                                                                    ))}
                                                                </Flex>
                                                            </FormControl>
                                                            <Spacer />
                                                            <Flex w={"100%"} align={"center"} justify={"space-between"} borderRadius={"lg"} p={2} bg={"gray.700"}>
                                                                <Button onClick={() => setCurrentStep(4)}>
                                                                    <FontAwesomeIcon icon={faAnglesLeft} />
                                                                    <Text ml={2}>Back</Text>
                                                                </Button>
                                                                <Button onClick={() => setCurrentStep(6)} bg="#1DADE3" _hover={"blue"} color={"white"} >
                                                                    <Text mr={2}>Next</Text>
                                                                    <FontAwesomeIcon icon={faAnglesRight} />
                                                                </Button>
                                                            </Flex>
                                                        </Flex>
                                                    )}

                                                    {currentStep === 6 && (
                                                        <Flex direction={"column"} h={"550px"} overflowY={"auto"}>
                                                            <FormControl>
                                                            <Heading size={"lg"}>Address</Heading>
                                                            <Text mb={4} fontSize={"small"} color={"red"}>For your privacy, we will only share your street address with a contractor you have hired.</Text>
                                                                <FormLabel>Street</FormLabel>
                                                                <Input name="street" value={formData.street} onChange={handleChange} />
                                                            </FormControl>
                                                            <Flex mt={4} justify={"space-evenly"} w={"100%"}>
                                                                <FormControl m={2}>
                                                                    <FormLabel>City</FormLabel>
                                                                    <Input name="city" value={formData.city} onChange={handleChange} />
                                                                </FormControl>
                                                                <FormControl m={2}>
                                                                    <FormLabel>State</FormLabel>
                                                                    <Select placeholder="Select state" name="state" value={formData.state} onChange={handleChange}>
                                                                        {states.map(state => (
                                                                            <option key={state} value={state}>{state}</option>
                                                                        ))}
                                                                    </Select>
                                                                </FormControl>
                                                                <FormControl m={2}>
                                                                    <FormLabel>Zip Code</FormLabel>
                                                                    <Input name="zipCode" value={formData.zipCode} onChange={handleChange} />
                                                                </FormControl>
                                                            </Flex>
                                                            <Spacer />
                                                            <Flex w={"100%"} align={"center"} justify={"space-between"} borderRadius={"lg"} p={2} bg={"gray.700"}>
                                                                <Button onClick={() => setCurrentStep(5)}>
                                                                    <FontAwesomeIcon icon={faAnglesLeft} />
                                                                    <Text ml={2}>Back</Text>
                                                                </Button>
                                                                <Button onClick={() => setCurrentStep(7)} bg="#1DADE3" _hover={"blue"} color={"white"} >
                                                                    <Text mr={2}>Next</Text>
                                                                    <FontAwesomeIcon icon={faAnglesRight} />
                                                                </Button>
                                                            </Flex>
                                                        </Flex>
                                                    )}

                                                    {currentStep === 7 && (
                                                        <Flex direction={"column"} h={"600px"} overflowY={"auto"}>
                                                            <Heading mb={2} size={"lg"}>Create Your Account</Heading>
                                                            <HStack>
                                                                <FormControl id="firstName" isRequired>
                                                                    <FormLabel>First Name</FormLabel>
                                                                    <Input
                                                                        name="firstName"
                                                                        value={formData.firstName}
                                                                        onChange={handleChange}
                                                                        placeholder="John"
                                                                    />
                                                                </FormControl>
                                                                <FormControl id="lastName" isRequired>
                                                                    <FormLabel>Last Name</FormLabel>
                                                                    <Input
                                                                        name="lastName"
                                                                        value={formData.lastName}
                                                                        onChange={handleChange}
                                                                        placeholder="Doe"
                                                                    />
                                                                </FormControl>
                                                            </HStack>
                                                            <FormControl mt={4} id="email" isRequired>
                                                                <FormLabel>Email</FormLabel>
                                                                <Input
                                                                    type="email"
                                                                    name="email"
                                                                    value={formData.email}
                                                                    onChange={handleChange}
                                                                    placeholder="john@example.com"
                                                                />
                                                            </FormControl>
                                                            <HStack align={"flex-start"} mt={4}>
                                                                <FormControl id="phone" isRequired>
                                                                    <FormLabel>Phone Number</FormLabel>
                                                                    <Input
                                                                        type="phone"
                                                                        name="phone"
                                                                        value={formData.phone}
                                                                        onChange={handleChange}
                                                                        placeholder="(555) 555-5555"
                                                                    />
                                                                </FormControl>
                                                                <FormControl isRequired>
                                                                    <FormLabel>Password</FormLabel>
                                                                    <InputGroup>
                                                                        <Input
                                                                            type={showPassword ? "text" : "password"}
                                                                            name="password"
                                                                            value={formData.password}
                                                                            onChange={handleChange}
                                                                            placeholder="Enter password"
                                                                        />
                                                                        <InputRightElement>
                                                                            <IconButton
                                                                                aria-label={showPassword ? "Hide password" : "Show password"}
                                                                                icon={showPassword ? <ViewOffIcon /> : <ViewIcon />}
                                                                                onClick={togglePasswordVisibility}
                                                                                size="sm"
                                                                                variant="ghost"
                                                                            />
                                                                        </InputRightElement>
                                                                    </InputGroup>
                                                                    <Flex w={"100%"} justify={"flex-start"}>
                                                                        <VStack p={4} bg={"gray.700"} borderRadius={"lg"} align="start" mt={2}>
                                                                            <PasswordCriteria isMet={passwordStrength.minLength}>At least 8 characters</PasswordCriteria>
                                                                            <PasswordCriteria isMet={passwordStrength.hasNumber}>Includes a number</PasswordCriteria>
                                                                            <PasswordCriteria isMet={passwordStrength.hasUpper}>Includes an uppercase letter</PasswordCriteria>
                                                                            <PasswordCriteria isMet={passwordStrength.hasLower}>Includes a lowercase letter</PasswordCriteria>
                                                                            <PasswordCriteria isMet={passwordStrength.hasSpecialChar}>Includes a special character</PasswordCriteria>
                                                                        </VStack>
                                                                    </Flex>
                                                                </FormControl>
                                                            </HStack>
                                                            <Spacer />
                                                            <Flex mt={2} w={"100%"} align={"center"} justify={"space-between"} borderRadius={"lg"} p={2} bg={"gray.700"}>
                                                                <Button onClick={() => setCurrentStep(6)}>
                                                                    <FontAwesomeIcon icon={faAnglesLeft} />
                                                                    <Text ml={2}>Back</Text>
                                                                </Button>
                                                                <Button onClick={(e) => {
                                                                    handleSubmit(e);
                                                                    setCurrentStep(currentStep + 1);
                                                                }} bg="#1DADE3" _hover={"blue"} color={"white"} >
                                                                    <Text mr={2}>Submit</Text>
                                                                    <FontAwesomeIcon icon={faAnglesRight} />
                                                                </Button>
                                                            </Flex>
                                                        </Flex>
                                                    )}
                                                </Flex>
                                            </VStack>
                                        </Box>
                                    </Flex>
                                </Flex>
                            </Flex>
                        </>
                    )}
                </Flex>
            </Flex>
        </Flex>
    );
}

function PasswordCriteria({ isMet, children }) {
    return (
        <Text color={isMet ? 'limegreen' : 'white'}>
            {isMet ? '✔' : '✖'} {children}
        </Text>
    );
}

export default RapidResponsePage;