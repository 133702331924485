import { useParams, useNavigate, Link } from 'react-router-dom';
import axios from 'axios';
import { Helmet } from 'react-helmet';
import Logo from '../assets/Horizontal LOGO.webp';
import Footer from '../assets/HM Logo Dark.webp';
import HomeImage from '../assets/homepageimage.jpg';
import ContractorImage from '../assets/contractor.jpg';
import RenovationImage from '../assets/homepageimage.jpg';
import EmergencyImage from '../assets/plumber.jpg';
import HomeImage2 from '../assets/Remodel.jpg';
import {
    Box, Button, Text, Link as ChakraLink,
    Flex, useToast, Heading, Image, SimpleGrid, Input, Stack, useDisclosure,
    Drawer, DrawerBody, DrawerHeader, DrawerOverlay, DrawerContent, DrawerCloseButton, useBreakpointValue, HStack, Icon, Select, Menu, MenuList, MenuItem, MenuButton, Divider
} from '@chakra-ui/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faBath, faBorderAll, faBroom, faHouseDamage, faPalette, faPlug, faSeedling, faTree, faUtensils, faWrench,
    faSnowflake, faBars,
    faHardHat,
    faCouch,
    faScrewdriver,
    faRug,
    faPaintRoller,
    faDrumSteelpan,
    faFan,
    faHome,
    faToolbox,
    faLeaf,
    faSwimmer,
    faRulerCombined,
    faWarehouse,
    faWater,
    faThermometerHalf,
    faWindowMaximize,
    faTrowelBricks,
    faHouseChimneyWindow,
    faTools,
    faCogs,
    faIgloo,
    faSwimmingPool,
    faBolt,
    faShieldAlt,
    faTape, faClipboardCheck, faHammer, faScrewdriverWrench, faAnglesRight, faPaperPlane, faPhone, faClock, faChevronDown, faScroll, faUserCheck, faFileContract, faArrowRight, faUserShield, faBuildingColumns, faIdCard, faCircleCheck, faFileInvoiceDollar
} from '@fortawesome/free-solid-svg-icons';
import RandomContractors from '../elements/HomePageContractors';
import RapidResponseAlert from '../elements/RapidResponse';
import { useEffect } from 'react';
import { useState } from 'react';
import { useRef } from 'react';

const Referral = () => {
    const { referenceId } = useParams();
    const [selectedService, setSelectedService] = useState(null);
    const navigate = useNavigate();
    const { isOpen, onOpen, onClose } = useDisclosure();
    const menuType = useBreakpointValue({ base: 'drawer', lg: 'regular' });
    useEffect(() => {
        if (referenceId) {
            localStorage.setItem('referral', referenceId);
            console.log('Referral ID saved to localStorage:', referenceId);
        }
    }, [referenceId]);
    const [formData, setFormData] = useState({
        zipCode: '',
        service: "",
    });
    const hasFetched = useRef(false);

    useEffect(() => {
        if (!hasFetched.current) {
            console.log('Effect running'); // This should log once if everything is correct

            const addPageView = async () => {
                try {
                    await axios.post(`${process.env.REACT_APP_API_URL}/api/addPageView`, {
                        pageUrl: '/referral',
                        variant: 'A'
                    });
                    console.log('Page view added');
                } catch (error) {
                    console.error('Error adding page view:', error);
                }
            };

            addPageView();
            hasFetched.current = true;
        }
    }, []);
    const handleMenuClick = () => {
        onOpen();
    };

    const navigateTo = (path) => {
        navigate(path);
        onClose(); // Close drawer after navigation
    };

    const ServiceBox = ({ icon, title, onSelect, isSelected }) => (
        <Box
            p={2}
            _hover={{ bg: "blue.100", cursor: "pointer", transform: "translateY(-4px)", transition: "all 0.4s", shadow: "md" }}
            borderRadius={"lg"}
            textAlign="center"
            transform={isSelected ? "translateY(-4px)" : ""}
            shadow={isSelected ? "md" : ""}
            onClick={handleButtonClick}
            bg={isSelected ? "blue.100" : "white"}
            width={{ base: "100%", md: "auto" }}
        >
            <FontAwesomeIcon size='xl' icon={icon} />
            <Heading size="md" mt={4}>{title}</Heading>
        </Box>
    );

    const handleButtonClick = () => {
        navigate('/welcome-project');
    };

    const handleGoHome = () => {
        navigate('/');
    };

    const handleInfoClick = () => {
        navigate('/welcome-project', { state: { zipCode: formData.zipCode, service: formData.service } });
    };

    const handleSignUp = () => {
        navigate('/signup');
    };

    const iconMap = {
        plumbing: faWrench,
        kitchens: faUtensils,
        bathrooms: faBath,
        cleaning: faBroom,
        lawncare: faSeedling,
        treeService: faTree,
        windows: faHouseChimneyWindow,
        roofing: faHouseDamage,
        electrical: faPlug,
        remodels: faHammer,
        decksPorches: faPalette,
        fences: faBorderAll,
        cooling: faSnowflake,
        architecture: faHardHat,
        cabinets: faCouch,
        carpentry: faScrewdriver,
        carpet: faRug,
        masonry: faTrowelBricks,
        driveways: faDrumSteelpan,
        drywall: faFan,
        flooring: faRulerCombined,
        foundations: faHome,
        garages: faWarehouse,
        gutters: faWater,
        handyman: faToolbox,
        HVAC: faThermometerHalf,
        landscape: faLeaf,
        painting: faPaintRoller,
        siding: faTape,
        poolsSpas: faSwimmer,
        doors: faWindowMaximize,
        lowVoltage: faBolt,
        security: faShieldAlt,
        poolBuilding: faSwimmingPool,
        automation: faCogs,
        welding: faTools,
        insulation: faIgloo,
        tiling: faBorderAll,
    };

    const DrawerMenu = () => (
        <Drawer isOpen={isOpen} placement="left" onClose={onClose}>
            <DrawerOverlay />
            <DrawerContent>
                <DrawerCloseButton />
                <DrawerHeader>Menu</DrawerHeader>
                <DrawerBody>
                    <Flex
                        align={"center"}
                        justify={"center"}
                        w={"100%"}
                        mb={{ base: 4, md: 0 }}
                    >
                        <Image onClick={handleGoHome} alt="Utah Remodels Logo" src={Logo} w={"auto"} h={"auto"} />
                    </Flex>
                    <Stack direction="column" spacing={4}>
                        <Button onClick={() => navigateTo('/welcome-project')}>Find Local Experts</Button>
                        <Button onClick={() => navigateTo('/signup')}>Join Our Trusted Network</Button>
                        <Button onClick={() => navigateTo('/login')}>Login</Button>
                        <Button onClick={() => navigateTo('/signup')}>Sign Up</Button>
                    </Stack>
                </DrawerBody>
            </DrawerContent>
        </Drawer>
    );

    const RegularMenu = () => (
        <>
            {/* Header */}
            <Flex
                position={"sticky"}
                mt={2}
                mb={2}
                top={0}
                zIndex={2}
                py={4}
                align={"center"}
                justify={"space-between"}
                w={"100%"}
                p={{ base: 4, lg: 0 }}
                direction={{ base: "column", md: "row" }}
            >
                <Flex
                    align={"center"}
                    justify={"center"}
                    w={"100%"}
                    mb={{ base: 4, md: 0 }}
                >
                    <Image onClick={handleGoHome} alt="Utah Remodels Logo" mr={{ base: 0, lg: -8 }} src={Logo} ml={8} w={"auto"} h={"80px"} />
                </Flex>
                <Stack
                    direction={{ base: "column", md: "row" }}
                    align={"center"}
                    justify={"flex-end"}
                    w={{ base: "100%", lg: "100%" }}
                    mr={8}
                >
                    <Menu isLazy>
                        <MenuButton><Text mr={2} as={Link} to="/signup" fontWeight="semibold" style={{ textDecoration: 'none', color: 'inherit' }}>Popular Services</Text><FontAwesomeIcon icon={faChevronDown} /></MenuButton>
                        <MenuList>
                            {/* MenuItems are not rendered unless Menu is open */}
                            <MenuItem onClick={handleButtonClick}><FontAwesomeIcon icon={faWrench} /><Text ml={2}>Plumbing</Text></MenuItem>
                            <MenuItem onClick={handleButtonClick}><FontAwesomeIcon icon={faBroom} /><Text ml={2}>Cleaning</Text></MenuItem>
                            <MenuItem onClick={handleButtonClick}><FontAwesomeIcon icon={faSeedling} /><Text ml={2}>Lawn Care</Text></MenuItem>
                            <MenuItem onClick={handleButtonClick}><FontAwesomeIcon icon={faHouseChimneyWindow} /><Text ml={2}>Windows</Text></MenuItem>
                            <MenuItem onClick={handleButtonClick}><FontAwesomeIcon icon={faPlug} /><Text ml={2}>Electrical</Text></MenuItem>
                            <MenuItem onClick={handleButtonClick}><FontAwesomeIcon icon={faSnowflake} /><Text ml={2}>HVAC</Text></MenuItem>
                            <MenuItem onClick={handleButtonClick}><FontAwesomeIcon icon={faToolbox} /><Text ml={2}>Basement Finish</Text></MenuItem>

                        </MenuList>
                    </Menu>
                    <Text ml={{ base: 1, md: 2, lg: 8 }} mr={{ base: 1, md: 2, lg: 8 }} as={Link} to="/signup" fontWeight="semibold" style={{ textDecoration: 'none', color: 'inherit' }}>
                        Find Local Experts
                    </Text>
                    <Text mr={{ base: 1, md: 2, lg: 8 }} as={Link} to="/signup" fontWeight="semibold" style={{ textDecoration: 'none', color: 'inherit' }}>
                        Join Our Trusted Network
                    </Text>
                    <Text mr={{ base: 1, md: 2, lg: 8 }} as={Link} to="/login" fontWeight="semibold" style={{ textDecoration: 'none', color: 'inherit' }}>
                        Login
                    </Text>
                    <Button
                        _hover={{ cursor: "pointer", bg: "blue.400" }}
                        bg="#1DADE3" color={"white"}
                        onClick={handleSignUp}
                    >
                        <Text fontWeight={"semibold"}>Get Started</Text>
                    </Button>
                </Stack>
            </Flex>
        </>
    );


    return (
        <Flex direction={"column"} w={"100%"} align={"center"} justify={"flex-start"}>
            <Helmet>
                <title>Find Local Experts for Every Home Improvement Project | UtahRemodels.com</title>
                <meta name="description" content="Connect with trusted and professional local experts for all your home improvement needs in Utah. Get free quotes today!" />
                <script type="application/ld+json">
                    {`
                        {
                            "@context": "http://schema.org",
                            "@type": "ProfessionalService",
                            "name": "Utah Remodels",
                            "url": "http://www.utahremodels.com",
                            "logo": "http://www.utahremodels.com/logo192.png",
                            "sameAs": [
                                "https://www.facebook.com/profile.php?id=61559654526526",
                                "https://linktr.ee/utahremodels.com"
                            ],
                            "serviceArea": {
                                "@type": "State",
                                "name": "Utah"
                            },
                            "description": "Simplifying the search for Utah's Home Improvement Pros.",
                            "telephone": "+13852104177"
                        }
                    `}
                </script>
            </Helmet>
            {/* Header with Hamburger Menu */}
            <Flex
                position="sticky"
                top={0}
                zIndex={5}
                bg="white"
                direction={"column"}
                align="center"
                justify="space-between"
                w="100%"
                p={{ base: 4, lg: 0 }}
            >

                {menuType === 'drawer' ? (
                    <Flex>
                        <Flex w={"100%"} justify={"center"}>
                            <Image src={Logo} w={"80%"} h={"auto"} />
                        </Flex>
                        <Button onClick={handleMenuClick} variant="ghost">
                            <FontAwesomeIcon icon={faBars} size="2x" />
                        </Button>
                        <DrawerMenu />
                    </Flex>
                ) : (
                    <>
                        <RegularMenu />
                    </>
                )}
            </Flex>

            {menuType === 'drawer' ? (
                <Box
                    p={1}
                    h={"fit-content"}
                    align={"center"}
                    justify={"flex-end"}
                    direction={"column"}
                    bg={"#1DADE3"}
                    w={"100%"}
                    mt={{ base: 4, md: 0 }}
                >

                    <Flex direction={"column"} align={"center"}>
                        <Flex align={"center"}>
                            <FontAwesomeIcon color='white' icon={faClock} />
                            <Text ml={2} mt={1} as={Link} to="/welcome-project" color='white'>Find Local Experts 24/7</Text>
                        </Flex>
                        <Flex align={"center"}>
                            <Icon as={FontAwesomeIcon} icon={faPaperPlane} color="white" />
                            <ChakraLink ml={2} mt={1} href="mailto:Hi@UtahRemodels.com" isExternal color="white">
                                Hi@UtahRemodels.com
                            </ChakraLink>
                        </Flex>
                        <Flex align={"center"}>
                            <FontAwesomeIcon icon={faPhone} color="white" />
                            <ChakraLink ml={2} mt={1} href="tel:+13852104177" isExternal color="white">
                                (385) 210-4177
                            </ChakraLink>
                        </Flex>
                    </Flex>
                </Box>
            ) : (
                <Flex
                    p={1}
                    h={"50px"}
                    align={"center"}
                    justify={"flex-end"}
                    bg={"#1DADE3"}
                    w={"100%"}
                    mt={{ base: 4, md: 0 }}
                >

                    <Flex direction={{ md: "column", lg: "row" }} align={"center"} ml={{ base: 1, md: 2, lg: 2 }} spacing={{ base: 1, md: 2, lg: 4 }}>
                        <FontAwesomeIcon color='white' icon={faClock} />
                        <Text as={Link} to="/welcome-project" mr={{ base: 1, md: 2, lg: 8 }} ml={{ base: 1, md: 2, lg: 4 }} color='white' mt={1}>Find Local Experts 24/7</Text>

                        <Icon as={FontAwesomeIcon} icon={faPaperPlane} color="white" />
                        <ChakraLink ml={2} mr={4} href="mailto:Hi@UtahRemodels.com" isExternal color="white">
                            Hi@UtahRemodels.com
                        </ChakraLink>
                        <FontAwesomeIcon icon={faPhone} color="white" />
                        <ChakraLink mr={8} ml={2} href="tel:+13852104177" isExternal color="white">
                            (385) 210-4177
                        </ChakraLink>
                    </Flex>
                </Flex>
            )}

            {/* Hero Section */}
            <Flex
                backgroundImage={HomeImage2}
                backgroundSize="cover"
                backgroundPosition="center"
                width="100%"
                height={"700px"}
                overflow="hidden"
                alignItems="center"
                justifyContent="center"
                position="relative"
            >
                <RapidResponseAlert />
                <Box
                    position="absolute"
                    top="50%"
                    left="50%"
                    transform="translate(-50%, -50%)"
                    textAlign="center"
                    zIndex={5}
                    w={{ base: "100%", lg: "40%" }}
                >
                    <Input
                        placeholder="Enter your zipcode"
                        width={{ base: "80%", md: "400px" }}
                        mb={{ base: 1, lg: 4 }}
                        bg={"whiteAlpha.900"}
                        size="lg"
                        borderRadius="full"
                        boxShadow="md"
                        value={formData.zipCode}
                        onChange={(e) => setFormData({ ...formData, zipCode: e.target.value })}
                    />
                    <Button
                        _hover={{ bg: "blue.400" }}
                        bg="#1DADE3"
                        color="white"
                        ml={{ base: 0, lg: 4 }}
                        size="lg"
                        mb={{ base: 4, lg: 0 }}
                        borderRadius="full"
                        boxShadow="md"
                        onClick={handleInfoClick}
                    >
                        Find Local Experts
                    </Button>

                    <Box w={"100%"} p={{ base: 2, lg: 4 }}>
                        <Box w={{ base: "100%" }} p={{ base: 2, lg: 4 }} borderRadius={"md"} bg={"whiteAlpha.800"}>
                            <Heading>Popular Services</Heading>
                            <SimpleGrid mt={4} columns={{ base: 2, md: 3, lg: 6 }} spacing={6}>
                                <ServiceBox
                                    icon={iconMap.plumbing}
                                    title="Plumbing"
                                    onSelect={() => setFormData({ ...formData, service: 'plumbing' })}
                                    isSelected={formData.service === 'plumbing'}
                                />

                                <ServiceBox
                                    icon={iconMap.cleaning}
                                    title="Cleaning"
                                    onSelect={() => setFormData({ ...formData, service: 'cleaning' })}
                                    isSelected={formData.service === 'cleaning'}
                                />

                                <ServiceBox
                                    icon={iconMap.lawncare}
                                    title="Lawn Care"
                                    onSelect={() => setFormData({ ...formData, service: 'lawncare' })}
                                    isSelected={formData.service === 'lawncare'}
                                />

                                <ServiceBox
                                    icon={iconMap.windows}
                                    title="Windows"
                                    onSelect={() => setFormData({ ...formData, service: 'windows' })}
                                    isSelected={formData.service === 'windows'}
                                />

                                <ServiceBox
                                    icon={iconMap.electrical}
                                    title="Electrical"
                                    onSelect={() => setFormData({ ...formData, service: 'electrical' })}
                                    isSelected={formData.service === 'electrical'}
                                />

                                <ServiceBox
                                    icon={iconMap.cooling}
                                    title="HVAC"
                                    onSelect={() => setFormData({ ...formData, service: 'cooling' })}
                                    isSelected={formData.service === 'cooling'}
                                />
                            </SimpleGrid>
                            <Flex mt={4} w={"100%"} justify={"flex-end"}>
                                <Button
                                    _hover={{ cursor: "pointer", bg: "blue.400" }}
                                    bg="#1DADE3" color={"white"}
                                    onClick={handleInfoClick}
                                >
                                    <Text>See More</Text>
                                    <FontAwesomeIcon icon={faAnglesRight} />
                                </Button>
                            </Flex>
                        </Box>
                    </Box>
                </Box>
                <Box
                    position="absolute"
                    top={0}
                    left={0}
                    width="100%"
                    height="100%"
                    backgroundColor="rgba(0, 0, 0, 0.5)"
                    zIndex={0}
                />
            </Flex>
            <Flex mb={8} w={"100%"} justify={"center"}>
                <Flex justify={"center"} w={"100%"}>
                    <Box mt={4} border={"1px"} shadow={"lg"} borderColor={"lightgray"} borderRadius={"lg"} p={{ base: 4, lg: 2 }} w={{ base: "100%", lg: "50%" }} textAlign="center">
                        <Flex direction={{ base: "column", lg: "row" }} align={"center"} mt={4} bg={"#1DADE3"} borderRadius={"md"} p={2}>
                            <Heading color={"white"} size="lg" mr={2}>
                                Contractor Information at Your Fingertips
                            </Heading>
                            <Divider mr={{ base: 0, lg: 4 }} mt={{ base: 2, lg: 0 }} mb={{ base: 2, lg: 0 }} w={{ base: "80%", lg: "0" }} h={{ base: "1px", lg: "80px" }} border={"1px"} color={"white"} orientation={{ base: "horizontal", lg: "vertical" }} />

                            <Text color={"white"} textAlign="left">
                                Discover all you need to know about our contractors with just a click! We bring transparency right to your fingertips, displaying clear and accessible information for each contractor. Explore their details freely and with ease.
                            </Text>
                        </Flex>
                        <Heading mt={8} size={"md"}>View at a glance:</Heading>
                        <Flex direction={{ base: 'column', lg: 'row' }} w={"100%"} justify={"space-bewtween"} align="center" >
                            <Box m={2} h={"120px"} w={"100%"} bg={"#1DADE3"} borderRadius={"lg"} p={2}>
                                <Flex w={"100%"} justify={"center"}>
                                    <Flex w={"50%"} justify={"space-evenly"}>
                                        <FontAwesomeIcon size='xl' color='white' icon={faUserCheck} />
                                        <FontAwesomeIcon size='xl' color='white' icon={faArrowRight} />
                                        <FontAwesomeIcon color='lightgreen' size='xl' icon={faUserCheck} />
                                    </Flex>
                                </Flex>
                                <Text color='white' fontWeight={"semibold"} mt={2}>Background Checked</Text>
                                <Text color='white' fontSize="sm">Contractors which have completed our background checks for your security.</Text>
                            </Box>
                            <Box m={2} h={"120px"} w={"100%"} bg={"#1DADE3"} borderRadius={"lg"} p={2}>
                                <Flex w={"100%"} justify={"center"}>
                                    <Flex w={"50%"} justify={"space-evenly"}>
                                        <FontAwesomeIcon size='xl' color='white' icon={faIdCard} />
                                        <FontAwesomeIcon size='xl' color='white' icon={faArrowRight} />
                                        <FontAwesomeIcon color='lightgreen' size='xl' icon={faIdCard} />
                                    </Flex>
                                </Flex>
                                <Text color='white' fontWeight={"semibold"} mt={2}>Licensed</Text>
                                <Text color='white' fontSize="sm">License IDs are prominently displayed for easy verification.</Text>
                            </Box>
                            <Box m={2} h={"120px"} w={"100%"} bg={"#1DADE3"} borderRadius={"lg"} p={2}>
                                <Flex w={"100%"} justify={"center"}>
                                    <Flex w={"50%"} justify={"space-evenly"}>
                                        <FontAwesomeIcon size='xl' color='white' icon={faUserShield} />
                                        <FontAwesomeIcon size='xl' color='white' icon={faArrowRight} />
                                        <FontAwesomeIcon color='lightgreen' size='xl' icon={faUserShield} />
                                    </Flex>
                                </Flex>
                                <Text color='white' fontWeight={"semibold"} mt={2}>Insured</Text>
                                <Text color='white' fontSize="sm">Insurance details are provided upfront for your peace of mind.</Text>
                            </Box>

                            <Box m={2} h={"120px"} w={"100%"} bg={"#1DADE3"} borderRadius={"lg"} p={2}>
                                <Flex w={"100%"} justify={"center"}>
                                    <Flex w={"50%"} justify={"space-evenly"}>
                                        <FontAwesomeIcon size='xl' color='white' icon={faBuildingColumns} />
                                        <FontAwesomeIcon size='xl' color='white' icon={faArrowRight} />
                                        <FontAwesomeIcon color='lightgreen' size='xl' icon={faBuildingColumns} />
                                    </Flex>
                                </Flex>
                                <Text color='white' fontWeight={"semibold"} mt={2}>Bonded</Text>
                                <Text color='white' fontSize="sm">Bond information is transparent and easy to find.</Text>
                            </Box>
                        </Flex>
                    </Box>
                </Flex>
            </Flex>

            {/* Features Section */}
            <Stack
                direction={{ base: "column", lg: "row" }}
                w={{ base: "100%", lg: "50%" }}
                justifyContent="space-around"
                alignItems="flex-start"
                py={20}
                p={2}
                spacing={8}
                mt={4}
                mb={8}
            >

                <Box m={{ base: 0, lg: 4 }} w={{ base: "100%", lg: "50%" }} textAlign="center">
                    <FontAwesomeIcon size='xl' icon={faFileInvoiceDollar} />
                    <Heading size="lg" mt={4}>
                        Predictable Pricing, No Surprises
                    </Heading>
                    <Text mt={2}>
                        Enjoy the transparency of fixed price proposals. Compare offers from trusted contractors and choose the best one without worrying about unexpected hourly rates. Secure your budget and your peace of mind.
                    </Text>
                </Box>

                <Box m={{ base: 0, lg: 4 }} w={{ base: "100%", lg: "50%" }} textAlign="center">
                    <FontAwesomeIcon size='xl' icon={faScrewdriverWrench} />
                    <Heading size="lg" mt={4}>
                        Seamless Home Renovations
                    </Heading>
                    <Text mt={2}>
                        Transform your home with ease from start to finish. Our streamlined process ensures your home remodeling journey is smooth and enjoyable, helping you get the home improvements you've always envisioned.
                    </Text>
                </Box>

            </Stack>

            <RandomContractors />

            <Flex mt={8} p={2} direction={"column"} borderRadius={"lg"} w={"fit-content"} bg={"gray.700"}>
                {/* Contractor Highlight Section */}
                <Flex mb={{ base: 4, lg: 0 }} w="100%" justify="center">
                    <Flex w={"100%"} justify="center">
                        <Flex
                            backgroundImage={ContractorImage}
                            backgroundSize="cover"
                            backgroundPosition="center"
                            justifyContent="center"
                            alignItems="center"
                            borderRadius="lg"
                            h={{ base: "400px", md: "300px" }}  // Adjusted height for mobile
                            maxH="500px"
                            shadow="lg"
                            m={2}
                            p={4}  // Reduced padding for mobile
                            position="relative"
                        >
                            <Box
                                position="absolute"
                                top={0}
                                left={0}
                                width="100%"
                                height="100%"
                                backgroundColor="rgba(0, 0, 0, 0.5)"
                                borderRadius="lg"
                            />
                            <Box
                                bg="rgba(255, 255, 255, 0.88)"
                                p={4}  // Adjusted padding for better text display on mobile
                                borderRadius="md"
                                maxW={{ base: "100%", md: "700px" }}  // Adjusted width for mobile
                                zIndex={1}
                            >
                                <Heading fontSize={{ base: "lg", md: "2xl" }}>Utah's Premier Contractors</Heading>
                                <Text fontSize={{ base: "sm", md: "md" }} mt={2}>
                                    Each contractor in our network is rigorously vetted for superior quality, unparalleled reliability, and exceptional customer service. Enjoy the assurance that comes with engaging true professionals.
                                </Text>
                                <Text fontSize={{ base: "sm", md: "md" }} mt={2} fontWeight="bold">
                                    Our seasoned contractors have hundreds of successfully completed projects and thousands of hours of on-the-job experience, ensuring top-tier results for your home improvements.
                                </Text>
                                <Button size="sm" colorScheme="orange" mt={4} onClick={handleInfoClick}>
                                    Meet Our Contractors
                                </Button>

                            </Box>
                        </Flex>
                    </Flex>
                </Flex>

                {/* Renovation Highlight Section */}
                <Flex mt={{ base: 4, lg: 0 }} mb={{ base: 4, lg: 0 }} w="100%" justify="center">
                    <Flex w={"100%"} justify="center">
                        <Flex
                            backgroundImage={RenovationImage}
                            backgroundSize="cover"
                            backgroundPosition="center"
                            justifyContent="center"
                            alignItems="center"
                            borderRadius="lg"
                            h={{ base: "300px", md: "300ox" }}  // Adjusted height for mobile
                            maxH="500px"
                            shadow="lg"
                            m={2}
                            p={4}  // Reduced padding for mobile
                            position="relative"
                        >
                            <Box
                                position="absolute"
                                top={0}
                                left={0}
                                width="100%"
                                height="100%"
                                backgroundColor="rgba(0, 0, 0, 0.5)"
                                borderRadius="lg"
                            />
                            <Box
                                bg="rgba(255, 255, 255, 0.88)"
                                p={4}  // Adjusted padding for better text display on mobile
                                borderRadius="md"
                                maxW={{ base: "100%", md: "700px" }}  // Adjusted width for mobile
                                zIndex={1}
                            >
                                <Heading fontSize={{ base: "lg", md: "2xl" }}>Renovate with Confidence</Heading>
                                <Text fontSize={{ base: "sm", md: "md" }} mt={2}>
                                    Our advanced project management tools keep you in the loop at every stage. Enjoy transparency, timely updates, and the simplicity of knowing your project is on track.
                                </Text>
                                <Text fontSize={{ base: "sm", md: "md" }} mt={2} fontWeight="bold">
                                    Stay informed with real-time updates every step of the way.
                                </Text>
                                <Button size="sm" colorScheme="orange" mt={4} onClick={handleInfoClick}>
                                    Renovate Now
                                </Button>
                            </Box>
                        </Flex>
                    </Flex>
                </Flex>

                {/* Emergency Services Section */}
                <Flex mt={{ base: 4, lg: 0 }} w="100%" justify="center">
                    <Flex w={"100%"} justify="center">
                        <Flex
                            backgroundImage={`url(${EmergencyImage})`}
                            backgroundSize="cover"
                            backgroundPosition="center"
                            justifyContent="center"
                            alignItems="center"
                            borderRadius="lg"
                            h={{ base: "330px", md: "300px" }}  // Adjusted height for mobile
                            maxH="500px"
                            shadow="lg"
                            p={4}  // Reduced padding for mobile
                            m={2}
                            mt={{ base: 0, lg: 4 }}
                            position="relative"
                        >
                            <Box
                                position="absolute"
                                top={0}
                                left={0}
                                width="100%"
                                height="100%"
                                backgroundColor="rgba(0, 0, 0, 0.5)"
                                borderRadius="lg"
                            />
                            <Box
                                bg="rgba(255, 255, 255, 0.88)"
                                p={4}  // Adjusted padding for better text display on mobile
                                borderRadius="md"
                                maxW={{ base: "100%", md: "700px" }}  // Adjusted width for mobile
                                zIndex={1}
                            >
                                <Heading fontSize={{ base: "lg", md: "2xl" }}>24/7 Emergency Repairs</Heading>
                                <Text fontSize={{ base: "sm", md: "md" }} mt={2}>
                                    From burst pipes to power outages, we've got you covered. When emergencies strike, our platform connects you quickly to skilled professionals ready to resolve the issue efficiently.
                                </Text>
                                <Text fontSize={{ base: "sm", md: "md" }} mt={2} fontWeight="bold">
                                    Contact us any time, day or night, for fast access to emergency repair services.
                                </Text>
                                <Button size="sm" colorScheme="orange" mt={4} onClick={handleInfoClick}>
                                    Get Emergency Help
                                </Button>
                            </Box>
                        </Flex>
                    </Flex>
                </Flex>
            </Flex>

            {/* Footer */}
            <Stack
                direction={{ base: "column", md: "row" }}
                bg="gray.700"
                color="white"
                justifyContent="center"
                alignItems="center"
                py={8}
                mt={8}
                w={"100%"}
                spacing={8}
            >
                <Image src={Footer} w={{ base: "80%", lg: "20%" }} h={"auto"} />
                <Box>
                    <Heading>Utah Remodels</Heading>
                    <Text>© 2024 Utah Remodels. All rights reserved.</Text>
                </Box>
            </Stack>
        </Flex >
    );
};

export default Referral;