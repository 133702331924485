import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useAtom } from 'jotai';
import { socketAtom } from '../socketAtom';
import { io } from 'socket.io-client';
import Logo from '../assets/Horizontal LOGO.webp';
import axios from 'axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Flex, Text, Heading, Image, Badge, HStack, Box, useToast, Button, useBreakpointValue, Alert, AlertIcon, VStack, Spacer, Divider } from '@chakra-ui/react';
import ContractorSideMenu from '../contractor/SideMenu';
import Header from '../elements/Header';
import { faBuildingColumns, faBullhorn, faChartLine, faCircleCheck, faCircleDot, faCommentDollar, faCommentsDollar, faDumbbell, faFileSignature, faIdCard, faMagnifyingGlassDollar, faMobileScreenButton, faPlus, faPlusCircle, faTowerBroadcast, faUserShield } from '@fortawesome/free-solid-svg-icons';

const Subscriptions = () => {
    const [socket] = useAtom(socketAtom);
    const navigate = useNavigate();
    const isSmallScreen = useBreakpointValue({ base: true, md: false });
    const menuType = useBreakpointValue({ base: 'drawer', lg: 'regular' });
    const { userId } = useParams();
    const [contractor, setContractor] = useState({});
    const [isLoading, setIsLoading] = useState(false);
    const toast = useToast();
    const token = localStorage.getItem('token');

    const [, setSocket] = useAtom(socketAtom);

    useEffect(() => {
        const newSocket = io(process.env.REACT_APP_API_URL, {
            autoConnect: true,
            auth: {
                token: token,
            },
        });

        newSocket.on('connect_error', (error) => {
            console.error('Socket connection error:', error);
        });

        setSocket(newSocket);

        return () => newSocket.close();
    }, [setSocket, token]);

    useEffect(() => {
        fetchData();
    }, [userId, token]);

    const fetchData = async () => {
        const headers = { Authorization: `Bearer ${token}` };
        try {
            const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/contractor${userId}`, { headers });
            const { contractor } = response.data;
            setContractor(contractor);
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };

    const FeatureHeading = ({ title }) => (
        <Heading color="gray.700" size="md" mb={2}>
            {title}
        </Heading>
    );

    const Feature = ({ title, description }) => (
        <>
            <Flex justify={"flex-start"} mb={2} align={"center"}>
                <FontAwesomeIcon size='md' color="green" icon={faPlus} />
                <Text ml={2} mt={1} color="gray.700" fontWeight="semibold" fontSize="md">
                    {title}
                </Text>
            </Flex>
            <Text color="gray.500" fontSize="sm" mb={4}>
                {description}
            </Text>
        </>
    );

    const handleBasicSubscription = async () => {
        try {
            const token = localStorage.getItem('token');
            const response = await axios.put(`${process.env.REACT_APP_API_URL}/api/users/subscription`, { subscription: 'Basic' }, {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            });
            console.log('Subscription updated:', response.data);
            navigate('/contractor/dashboard')
        } catch (error) {
            console.error('Error updating subscription:', error);
        }
    };

    const handleExplorerSubscription = () => {
        window.open('https://buy.stripe.com/test_3csdSTgKi4HzeA0aEL');
    };

    const handleEnhancedSubscription = () => {
        window.open('https://buy.stripe.com/eVa4jK8ln8rRe9a5kl');
    };

    const handleUnleashedSubscription = () => {
        window.open('https://buy.stripe.com/test_00g4ij2Ts1vngI85kq');
    };

    const handleEliteSubscription = () => {
        window.open('https://buy.stripe.com/8wM3fG0SV23t1mocMM');
    };

    return (
        <Flex direction={"column"} w={"100%"} justify={"center"}>
            <Flex p={2} w={"100%"} align={"flex-start"} justify={"flex-start"}>
                {menuType === 'drawer' ? (
                    <Flex m={4} direction={"column"} w={"100%"} justify={"center"}>
                        <Flex mt={4} justify={"center"} align={"center"} direction={"column"} w={"100"}>
                            <Alert status='success'>
                                <AlertIcon />
                                <Box w={"100%"}>
                                    <Text>
                                        <Text as="span" fontWeight="semibold">Grand Opening Promo:</Text> Join now to
                                        <Text as="span" mx={1} fontWeight="semibold">lock in your Elite subscription for only $18/month .</Text>
                                        Get premium features to elevate your business. Offer valid for a limited time—don’t miss out!
                                    </Text>
                                </Box>
                            </Alert>

                            <Flex
                                align="flex-start"
                                justify="center"
                                direction={"column"}
                                p={2}
                                mt={4}
                                w="100%"
                                gap={6}
                                px={isSmallScreen ? 4 : 0}
                            >
                                <Flex border={"2px"} borderColor={"green"} p={2} borderRadius={"lg"} bg={"gray.700"} direction={"column"}>
                                    <Flex w={"100%"} justify={"center"}>
                                        <Heading size={"md"} color={"white"}>Lead Generation</Heading>
                                    </Flex>
                                    <Flex align={"flex-end"} mt={4}>
                                        {/* Starter Tier */}
                                        <Box
                                            p={6}
                                            m={1}
                                            borderWidth="1px"
                                            borderRadius="lg"
                                            maxW="400px"
                                            bgColor="white"
                                            boxShadow="md"
                                            transition="box-shadow 0.2s ease-in-out"
                                            _hover={{ boxShadow: 'lg' }}
                                        >
                                            <Heading color="gray.700" size="md" mb={2}>
                                                Basic - Free
                                            </Heading>
                                            <Flex justify={"center"} mb={4} align={"center"}>
                                                <FontAwesomeIcon color="gray.700" icon={faMagnifyingGlassDollar} />
                                                <Text ml={4} color="gray.700" fontWeight="semibold">
                                                    Generate Leads.
                                                </Text>
                                            </Flex>
                                            <Text color="gray.700" fontWeight="semibold" fontSize="md">
                                                Real-time Leads
                                            </Text>
                                            <Text color="gray.500" fontSize="sm" mb={4}>
                                                Access real-time leads tailored to your needs and grow your business with the right
                                                opportunities.
                                            </Text>
                                            <Button
                                                bg="#1DADE3" _hover={"blue"} color={"white"}
                                                onClick={handleBasicSubscription}
                                                aria-label="Subscribe to Explorer Tier"
                                            >
                                                Choose Basic
                                            </Button>
                                        </Box>
                                    </Flex>
                                </Flex>

                                <Flex border={"2px"} borderColor={"green"} p={2} borderRadius={"lg"} bg={"gray.700"} direction={"column"}>
                                    <Flex w={"100%"} justify={"center"}>
                                        <Heading size={"md"} color={"white"}>Johnny on the Spot</Heading>
                                    </Flex>
                                    <Flex align={"flex-end"} mt={4}>

                                        {/* Professional Tier */}
                                        <Box
                                            p={6}
                                            m={1}
                                            borderWidth="1px"
                                            borderRadius="lg"
                                            flex="1 1 300px"
                                            maxW="400px"
                                            bgColor="white"
                                            boxShadow="md"
                                            transition="box-shadow 0.2s ease-in-out"
                                            _hover={{ boxShadow: 'lg' }}
                                        >
                                            <Heading color="gray.700" size="md" mb={2}>
                                                Enhanced - $48 /mo.
                                            </Heading>
                                            <Text mb={4} fontWeight={"thin"} mt={-2}>60 day free-trial</Text>
                                            <Flex justify={"center"} mb={4} align={"center"}>
                                                <FontAwesomeIcon size='lg' color="gray.700" icon={faCommentsDollar} />
                                                <Text ml={4} color="gray.700" fontWeight="semibold">
                                                    Ideal for Aggressive Lead Generation.
                                                </Text>
                                            </Flex>
                                            <Flex justify={"flex-start"} mb={2} align={"center"}>
                                                <FontAwesomeIcon size='md' color="green" icon={faPlus} />
                                                <Text ml={2} mt={1} color="gray.700" fontWeight="semibold" fontSize="md">
                                                    Advanced AI-Matching
                                                </Text>
                                            </Flex>
                                            <Text color="gray.500" fontSize="sm" mb={4}>
                                                Never miss an opportunity. Let AI find, match and notify you when the best projects are added.
                                            </Text>
                                            <Flex justify={"flex-start"} mb={2} align={"center"}>
                                                <FontAwesomeIcon size='md' color="green" icon={faPlus} />
                                                <Text ml={2} mt={1} color="gray.700" fontWeight="semibold" fontSize="md">
                                                    Priority Notifications
                                                </Text>
                                            </Flex>
                                            <Text color="gray.500" fontSize="sm" mb={4}>
                                                Get instant email and platform alerts for urgent projects directly from homeowners.
                                            </Text>
                                            <Flex justify={"flex-start"} mb={2} align={"center"}>
                                                <FontAwesomeIcon size='md' color="green" icon={faPlus} />
                                                <Text ml={2} mt={1} color="gray.700" fontWeight="semibold" fontSize="md">
                                                    Custom Business Profile
                                                </Text>
                                            </Flex>
                                            <Text color="gray.500" fontSize="sm" mb={4}>
                                                Enhance your visibility by customizing your profile to highlight your unique strengths and
                                                past projects.
                                            </Text>
                                            <Text color="gray.700" fontWeight="semibold" fontSize="md">
                                                Real-time Leads
                                            </Text>
                                            <Text color="gray.500" fontSize="sm" mb={4}>
                                                Access real-time leads tailored to your needs and grow your business with the right
                                                opportunities.
                                            </Text>

                                            <Button
                                                bg="#1DADE3" _hover={"blue"} color={"white"}
                                                onClick={handleEnhancedSubscription}
                                                aria-label="Subscribe to Unleashed Tier"
                                            >
                                                Choose Enhanced
                                            </Button>
                                        </Box>
                                    </Flex>
                                </Flex>

                                <Flex border={"2px"} borderColor={"green"} p={2} borderRadius={"lg"} bg={"gray.700"} direction={"column"}>
                                    <Flex mb={4} w={"100%"} justify={"center"}>
                                        <Heading size={"md"} color={"white"}>Get Noticed</Heading>
                                    </Flex>


                                    {/* Premium Tier */}
                                    <Box
                                        p={2}
                                        borderColor={"limegreen"}
                                        m={1}
                                        borderWidth="5px"
                                        borderRadius="lg"
                                        flex="1 1 300px"
                                        maxW="400px"
                                        bgColor="white"
                                        boxShadow="md"
                                        transition="box-shadow 0.2s ease-in-out"
                                        _hover={{ boxShadow: 'lg' }}
                                    >
                                        <Heading mt={-2} color="gray.700" size="md" mb={2}>
                                            <Box w={"100%"}>
                                                <Flex align={"center"} justify={"space-between"}><Text>Elite </Text><Badge color={"white"} bg='limegreen'>Most Popular</Badge></Flex>
                                                <Flex w={"100%"} align={"center"} justify={"space-between"}>
                                                    <Text color="limegreen">Kickoff Offer: $18 /mo.</Text>
                                                </Flex>
                                                <Text mb={4} fontWeight={"thin"} mt={-2}>60 day free-trial</Text>
                                            </Box>

                                            <Flex>
                                                <Text fontSize={"md"}>Regular Price:</Text>
                                                <Text fontSize={"md"} ml={1} as="s" color="red.400">$105/mo.</Text>
                                            </Flex>
                                        </Heading>

                                        <Flex justify={"center"} mb={4} align={"center"}>
                                            <FontAwesomeIcon color="gray.700" icon={faBullhorn} />
                                            <Text ml={4} color="gray.700" fontWeight="semibold">
                                                Advertisements & Branding
                                            </Text>
                                        </Flex>
                                        <Flex justify={"flex-start"} mb={2} align={"center"}>
                                            <FontAwesomeIcon size='md' color="green" icon={faPlus} />
                                            <Text ml={2} mt={1} color="gray.700" fontWeight="semibold" fontSize="md">
                                                Exclusive Advertising
                                            </Text>
                                        </Flex>
                                        <Text color="gray.500" fontSize="sm" mb={4}>
                                            Receive targeted promotions to boost your visibility and attract more homeowners.
                                        </Text>
                                        <Text color="gray.700" fontWeight="semibold" fontSize="md">
                                            Advanced AI-Matching
                                        </Text>
                                        <Text color="gray.500" fontSize="sm" mb={4}>
                                            Never miss an opportunity. Let AI find, match and notify you when the best projects are added.
                                        </Text>
                                        <Text color="gray.700" fontWeight="semibold" fontSize="md">
                                            Priority Notifications
                                        </Text>
                                        <Text color="gray.500" fontSize="sm" mb={4}>
                                            Get instant email and platform alerts for urgent projects directly from homeowners.
                                        </Text>
                                        <Text color="gray.700" fontWeight="semibold" fontSize="md" mb={2}>
                                            Custom Business Profile
                                        </Text>
                                        <Text color="gray.500" fontSize="sm" mb={4}>
                                            Enhance your visibility by customizing your profile to highlight your unique strengths and
                                            past projects.
                                        </Text>
                                        <Text color="gray.700" fontWeight="semibold" fontSize="md">
                                            Real-time Leads
                                        </Text>
                                        <Text color="gray.500" fontSize="sm" mb={4}>
                                            Access real-time leads tailored to your needs and grow your business with the right
                                            opportunities.
                                        </Text>

                                        <Button
                                            bg="#1DADE3" _hover={"blue"} color={"white"}
                                            onClick={handleEliteSubscription}
                                            aria-label="Subscribe to Elite Tier"
                                        >
                                            Choose Elite
                                        </Button>
                                    </Box>
                                </Flex>
                            </Flex>

                            <Divider mt={8} />

                            <Flex
                                align="center"
                                justify="center"
                                mt={8}
                                bg={"gray.700"}
                                p={6}
                                w="100%"
                                flexWrap="wrap"
                                gap={6}
                                borderRadius={"md"}
                                shadow={"lg"}
                                px={isSmallScreen ? 4 : 0}
                            >
                                <Box
                                    p={6}
                                    borderWidth="1px"
                                    borderRadius="lg"
                                    w="95%"
                                    bgColor="white"
                                    boxShadow="md"
                                    transition="box-shadow 0.2s ease-in-out"
                                    _hover={{ boxShadow: 'lg' }}
                                >
                                    <FeatureHeading title="Universal Platform Benefits" />

                                    <Feature
                                        title="Real-time Messages"
                                        description="Communicate with your customers efficiently. Never miss a message with SMS and email notifications when you receive a new message."
                                    />

                                    <Feature
                                        title="Event Timeline"
                                        description="We generate a timeline of events that keeps track of the progress for each project, providing clear communication."
                                    />

                                    <Feature
                                        title="Proposal Generation Tools"
                                        description="Use our platform to generate beautiful custom proposals for your customers."
                                    />

                                    <Feature
                                        title="Transparent Deposit and Payment Processing"
                                        description="Customers deposit funds into a project and then pay out the contractors. **We build in a service fee of 2% on every proposal."
                                    />
                                </Box>
                            </Flex>
                        </Flex>
                    </Flex>
                ) : (
                    <Flex m={4} direction={"column"} w={"100%"} justify={"center"}>
                        <Flex mt={4} justify={"center"} align={"center"} direction={"column"} w={"100"}>
                            <Alert status='success'>
                                <AlertIcon />
                                <Text>
                                    <Text as="span" fontWeight="semibold">Grand Opening Promo:</Text> Join now to
                                    <Text as="span" mx={1} fontWeight="semibold">lock in your Elite subscription for only $18/month.</Text>
                                    Get premium features to elevate your business. Offer valid for a limited time—don’t miss out!
                                </Text>
                            </Alert>

                            <Flex
                                align="flex-start"
                                justify="center"
                                p={2}
                                mt={4}
                                w="100%"
                                gap={6}
                                px={isSmallScreen ? 4 : 0}
                            >
                                <Flex border={"2px"} borderColor={"green"} p={2} borderRadius={"lg"} bg={"gray.700"} direction={"column"}>
                                    <Flex w={"100%"} justify={"center"}>
                                        <Heading size={"md"} color={"white"}>Lead Generation</Heading>
                                    </Flex>
                                    <Flex align={"flex-end"} mt={4}>
                                        {/* Starter Tier */}
                                        <Box
                                            p={6}
                                            m={1}
                                            borderWidth="1px"
                                            borderRadius="lg"
                                            maxW="400px"
                                            bgColor="white"
                                            boxShadow="md"
                                            transition="box-shadow 0.2s ease-in-out"
                                            _hover={{ boxShadow: 'lg' }}
                                        >
                                            <Heading color="gray.700" size="md" mb={2}>
                                                Basic - Free
                                            </Heading>
                                            <Flex justify={"center"} mb={4} align={"center"}>
                                                <FontAwesomeIcon color="gray.700" icon={faMagnifyingGlassDollar} />
                                                <Text ml={4} color="gray.700" fontWeight="semibold">
                                                    Generate Leads.
                                                </Text>
                                            </Flex>
                                            <Text color="gray.700" fontWeight="semibold" fontSize="md">
                                                Real-time Leads
                                            </Text>
                                            <Text color="gray.500" fontSize="sm" mb={4}>
                                                Access real-time leads tailored to your needs and grow your business with the right
                                                opportunities.
                                            </Text>
                                            <Button
                                                bg="#1DADE3" _hover={"blue"} color={"white"}
                                                onClick={handleBasicSubscription}
                                                aria-label="Subscribe to Explorer Tier"
                                            >
                                                Choose Basic
                                            </Button>
                                        </Box>
                                    </Flex>
                                </Flex>

                                <Flex border={"2px"} borderColor={"green"} p={2} borderRadius={"lg"} bg={"gray.700"} direction={"column"}>
                                    <Flex w={"100%"} justify={"center"}>
                                        <Heading size={"md"} color={"white"}>Johnny on the Spot</Heading>
                                    </Flex>
                                    <Flex align={"flex-end"} mt={4}>

                                        {/* Professional Tier */}
                                        <Box
                                            p={6}
                                            m={1}
                                            borderWidth="1px"
                                            borderRadius="lg"
                                            flex="1 1 300px"
                                            maxW="400px"
                                            bgColor="white"
                                            boxShadow="md"
                                            transition="box-shadow 0.2s ease-in-out"
                                            _hover={{ boxShadow: 'lg' }}
                                        >
                                            <Heading color="gray.700" size="md" mb={2}>
                                                Enhanced - $48 /mo.
                                            </Heading>
                                            <Text mb={4} fontWeight={"thin"} mt={-2}>60 day free-trial</Text>
                                            <Flex justify={"center"} mb={4} align={"center"}>
                                                <FontAwesomeIcon size='lg' color="gray.700" icon={faCommentsDollar} />
                                                <Text ml={4} color="gray.700" fontWeight="semibold">
                                                    Ideal for Aggressive Lead Generation.
                                                </Text>
                                            </Flex>
                                            <Flex justify={"flex-start"} mb={2} align={"center"}>
                                                <FontAwesomeIcon size='md' color="green" icon={faPlus} />
                                                <Text ml={2} mt={1} color="gray.700" fontWeight="semibold" fontSize="md">
                                                    Advanced AI-Matching
                                                </Text>
                                            </Flex>
                                            <Text color="gray.500" fontSize="sm" mb={4}>
                                                Never miss an opportunity. Let AI find, match and notify you when the best projects are added.
                                            </Text>
                                            <Flex justify={"flex-start"} mb={2} align={"center"}>
                                                <FontAwesomeIcon size='md' color="green" icon={faPlus} />
                                                <Text ml={2} mt={1} color="gray.700" fontWeight="semibold" fontSize="md">
                                                    Priority Notifications
                                                </Text>
                                            </Flex>
                                            <Text color="gray.500" fontSize="sm" mb={4}>
                                                Get instant email and platform alerts for urgent projects directly from homeowners.
                                            </Text>
                                            <Flex justify={"flex-start"} mb={2} align={"center"}>
                                                <FontAwesomeIcon size='md' color="green" icon={faPlus} />
                                                <Text ml={2} mt={1} color="gray.700" fontWeight="semibold" fontSize="md">
                                                    Custom Business Profile
                                                </Text>
                                            </Flex>
                                            <Text color="gray.500" fontSize="sm" mb={4}>
                                                Enhance your visibility by customizing your profile to highlight your unique strengths and
                                                past projects.
                                            </Text>
                                            <Text color="gray.700" fontWeight="semibold" fontSize="md">
                                                Real-time Leads
                                            </Text>
                                            <Text color="gray.500" fontSize="sm" mb={4}>
                                                Access real-time leads tailored to your needs and grow your business with the right
                                                opportunities.
                                            </Text>

                                            <Button
                                                bg="#1DADE3" _hover={"blue"} color={"white"}
                                                onClick={handleEnhancedSubscription}
                                                aria-label="Subscribe to Unleashed Tier"
                                            >
                                                Choose Enhanced
                                            </Button>
                                        </Box>
                                    </Flex>
                                </Flex>

                                <Flex border={"2px"} borderColor={"green"} p={2} borderRadius={"lg"} bg={"gray.700"} direction={"column"}>
                                    <Flex mb={4} w={"100%"} justify={"center"}>
                                        <Heading size={"md"} color={"white"}>Get Noticed</Heading>
                                    </Flex>


                                    {/* Premium Tier */}
                                    <Box
                                        p={6}
                                        borderColor={"limegreen"}
                                        m={1}
                                        borderWidth="5px"
                                        borderRadius="lg"
                                        flex="1 1 300px"
                                        maxW="400px"
                                        bgColor="white"
                                        boxShadow="md"
                                        transition="box-shadow 0.2s ease-in-out"
                                        _hover={{ boxShadow: 'lg' }}
                                    >
                                        <Heading mt={-2} color="gray.700" size="md" mb={2}>
                                            <Flex>
                                                <Text>Elite - </Text>
                                                <Text ml={1} color="limegreen">$18 /month - Kickoff Offer</Text>
                                            </Flex>
                                            <Badge color={"white"} mt={-2} bg='limegreen'>Most Popular</Badge>
                                            <Flex>
                                                <Text>Regular Price:</Text>
                                                <Text ml={1} as="s" color="red.400">$105/mo.</Text>
                                            </Flex>
                                        </Heading>
                                        <Text mb={4} fontWeight={"thin"} mt={-2}>60 day free-trial</Text>

                                        <Flex justify={"center"} mb={4} align={"center"}>
                                            <FontAwesomeIcon color="gray.700" icon={faBullhorn} />
                                            <Text ml={4} color="gray.700" fontWeight="semibold">
                                                Advertisements & Branding
                                            </Text>
                                        </Flex>
                                        <Flex justify={"flex-start"} mb={2} align={"center"}>
                                            <FontAwesomeIcon size='md' color="green" icon={faPlus} />
                                            <Text ml={2} mt={1} color="gray.700" fontWeight="semibold" fontSize="md">
                                                Exclusive Advertising
                                            </Text>
                                        </Flex>
                                        <Text color="gray.500" fontSize="sm" mb={4}>
                                            Receive targeted promotions to boost your visibility and attract more homeowners.
                                        </Text>
                                        <Text color="gray.700" fontWeight="semibold" fontSize="md">
                                            Advanced AI-Matching
                                        </Text>
                                        <Text color="gray.500" fontSize="sm" mb={4}>
                                            Never miss an opportunity. Let AI find, match and notify you when the best projects are added.
                                        </Text>
                                        <Text color="gray.700" fontWeight="semibold" fontSize="md">
                                            Priority Notifications
                                        </Text>
                                        <Text color="gray.500" fontSize="sm" mb={4}>
                                            Get instant email and platform alerts for urgent projects directly from homeowners.
                                        </Text>
                                        <Text color="gray.700" fontWeight="semibold" fontSize="md" mb={2}>
                                            Custom Business Profile
                                        </Text>
                                        <Text color="gray.500" fontSize="sm" mb={4}>
                                            Enhance your visibility by customizing your profile to highlight your unique strengths and
                                            past projects.
                                        </Text>
                                        <Text color="gray.700" fontWeight="semibold" fontSize="md">
                                            Real-time Leads
                                        </Text>
                                        <Text color="gray.500" fontSize="sm" mb={4}>
                                            Access real-time leads tailored to your needs and grow your business with the right
                                            opportunities.
                                        </Text>

                                        <Button
                                            bg="#1DADE3" _hover={"blue"} color={"white"}
                                            onClick={handleEliteSubscription}
                                            aria-label="Subscribe to Elite Tier"
                                        >
                                            Choose Elite
                                        </Button>
                                    </Box>
                                </Flex>
                            </Flex>

                            <Divider mt={8} />

                            <Flex
                                align="center"
                                justify="center"
                                mt={8}
                                bg={"gray.700"}
                                p={6}
                                w="50%"
                                flexWrap="wrap"
                                gap={6}
                                borderRadius={"md"}
                                shadow={"lg"}
                                px={isSmallScreen ? 4 : 0}
                            >
                                <Box
                                    p={6}
                                    borderWidth="1px"
                                    borderRadius="lg"
                                    w="95%"
                                    bgColor="white"
                                    boxShadow="md"
                                    transition="box-shadow 0.2s ease-in-out"
                                    _hover={{ boxShadow: 'lg' }}
                                >
                                    <FeatureHeading title="Universal Platform Benefits" />

                                    <Feature
                                        title="Real-time Messages"
                                        description="Communicate with your customers efficiently. Never miss a message with SMS and email notifications when you receive a new message."
                                    />

                                    <Feature
                                        title="Event Timeline"
                                        description="We generate a timeline of events that keeps track of the progress for each project, providing clear communication."
                                    />

                                    <Feature
                                        title="Proposal Generation Tools"
                                        description="Use our platform to generate beautiful custom proposals for your customers."
                                    />

                                    <Feature
                                        title="Transparent Deposit and Payment Processing"
                                        description="Customers deposit funds into a project and then pay out the contractors. **We build in a service fee of 2% on every proposal."
                                    />
                                </Box>
                            </Flex>
                        </Flex>
                    </Flex>
                )}
            </Flex>
        </Flex>
    );
}

export default Subscriptions; 