// SideMenu.js

import React, { useEffect, useRef, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useAtom } from 'jotai';
import { socketAtom } from '../socketAtom';
import axios from 'axios';
import { Flex, Image, Heading, VStack, Divider, Text, Box, Button, Icon, useBreakpointValue, useDisclosure, Drawer, DrawerBody, DrawerHeader, DrawerOverlay, DrawerContent, DrawerCloseButton, useColorMode, useToast, } from '@chakra-ui/react';
import Logo from '../assets/UTAH LOGO.webp';
import Explorer from '../assets/Explorer.png';
import Basic from '../assets/Basic.png';
import Enhanced from '../assets/Enhanced.png';
import Unleashed from '../assets/Unleashed.png';
import Elite from '../assets/Elite.png';
import BigLogo from '../assets/HM Logo Dark.webp';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCommentDollar, faComments, faEnvelopeOpenText, faFileLines, faHammer, faMagnifyingGlassDollar, faMoneyBillTransfer, faPenRuler, faUserCog, faUserShield, faBars, faHomeAlt, faCopy } from '@fortawesome/free-solid-svg-icons';
import { useSidebar } from '../elements/SidebarContext';
import CreateStripeAccountButton from '../Admin/AccountCreation';

function ContractorSideMenu() {
    const [socket] = useAtom(socketAtom);
    const { isOpen, onOpen, onClose } = useDisclosure();
    const { colorMode } = useColorMode();
    const menuType = useBreakpointValue({ base: 'drawer', lg: 'regular' });
    const { expanded } = useSidebar();
    const [dashboardNotifications, setDashboardNotifications] = useState(0);
    const [messageNotifications, setMessageNotifications] = useState(0);
    const [unseenProjectCount, setUnseenProjectCount] = useState(0);
    const [bidNotifications, setBidNotifications] = useState(0);
    const [projectNotifications, setProjectNotifications] = useState(0);
    const [bids, setBids] = useState([]);
    const [handoffs, setHandoffs] = useState([]);
    const [user, setUser] = useState({});
    const [projects, setProjects] = useState([]);
    const [completedBids, setCompletedBids] = useState([]);
    const [acceptedBids, setAcceptedBids] = useState([]);
    const [homeownerAcceptedBids, setHomeownerAcceptedBids] = useState([]);
    const [subscription, setSubscription] = useState('');
    const role = localStorage.getItem('role');
    const token = localStorage.getItem('token');
    const navigate = useNavigate();
    const location = useLocation();
    const toast = useToast();

    const handleMenuClick = () => {
        if (isOpen) {
            onClose();
        } else {
            onOpen();
        }
    };

    const navigateTo = (path) => {
        navigate(path);
        onClose(); // Close drawer after navigation
    };

    const fetchSubscription = async () => {
        const headers = { Authorization: `Bearer ${token}` };
        try {
            const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/active-subscription`, { headers });
            const user = response.data.user;
            setSubscription(user.subscription);
            setUser(user);

            const unseenCount = user.recommendedProjects.reduce((acc, project) => {
                console.log(project); // Log each project to see its structure
                return acc + (!project.seen ? 1 : 0);
            }, 0);

            setUnseenProjectCount(unseenCount);
            console.log('Unseen projects set to:', unseenCount);

        } catch (error) {
            console.error('Error fetching active subscription:', error);
        }
    };

    const fetchUnreadMessageCount = async () => {
        const headers = { Authorization: `Bearer ${token}` };
        // Create a Set of accepted bid conversation IDs for quick lookup.
        const acceptedBidIds = new Set(acceptedBids.map(bid => bid._id));

        // Filter out bids that are accepted from the conversation ID list.
        const conversationIds = bids.filter(bid => !acceptedBidIds.has(bid._id)).map(bid => bid._id).join(',');

        try {
            const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/unread-messages?conversationIds=${conversationIds}`, { headers });
            const countsByConversationId = response.data.reduce((acc, curr) => {
                acc[curr._id] = curr.unreadCount;
                return acc;
            }, {});
            setMessageNotifications(countsByConversationId);
        } catch (error) {
            console.error('Error fetching unread message counts:', error);
        }
    };

    const fetchUnreadProjectMessageCount = async () => {
        const headers = { Authorization: `Bearer ${token}` };
        // Create an array of accepted bid conversation IDs from the Set.
        const conversationIdsArray = Array.from(new Set(homeownerAcceptedBids.map(bid => bid.conversationId)));
        const conversationIdsString = conversationIdsArray.join(',');

        try {
            const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/unread-messages?conversationIds=${conversationIdsString}`, { headers });
            const countsByConversationId = response.data.reduce((acc, curr) => {
                acc[curr._id] = curr.unreadCount;
                return acc;
            }, {});
            setMessageNotifications(countsByConversationId);
        } catch (error) {
            console.error('Error fetching unread message counts:', error);
        }
    };

    const fetchUnreadCompletedMessageCount = async () => {
        const headers = { Authorization: `Bearer ${token}` };
        // Create a Set of accepted bid conversation IDs for quick lookup.
        const completedBidIds = new Set(completedBids.map(bid => bid._id));

        // Filter out bids that are accepted from the conversation ID list.
        const conversationIds = bids.filter(bid => !completedBidIds.has(bid._id)).map(bid => bid._id).join(',');

        try {
            const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/unread-messages?conversationIds=${conversationIds}`, { headers });
            const countsByConversationId = response.data.reduce((acc, curr) => {
                acc[curr._id] = curr.unreadCount;
                return acc;
            }, {});
            setMessageNotifications(countsByConversationId);
        } catch (error) {
            console.error('Error fetching unread message counts:', error);
        }
    };

    useEffect(() => {
        if (bids.length > 0 || acceptedBids.length > 0 || projects.length > 0) {
            fetchUnreadMessageCount();
        }
    }, [socket, bids]);

    useEffect(() => {
        if (homeownerAcceptedBids.length > 0) {
            fetchUnreadProjectMessageCount();
        }
    }, [socket, homeownerAcceptedBids]);

    useEffect(() => {
        if (completedBids.length > 0) {
            fetchUnreadCompletedMessageCount();
        }
    }, [socket, completedBids]);

    useEffect(() => {
        const fetchUnreadMessages = async () => {
            socket.on('receive-message', async (data) => {
                if (!acceptedBids.find(bid => bid._id === data.conversationId)) {
                    try {
                        const headers = { Authorization: `Bearer ${token}` };
                        const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/unread-messages?conversationIds=${data.conversationId}`, { headers });
                        const newCount = response.data.find(item => item._id === data.conversationId)?.unreadCount;

                        setMessageNotifications(prev => ({
                            ...prev,
                            [data.conversationId]: newCount
                        }));
                    } catch (error) {
                        console.error('Error fetching updated unread message counts:', error);
                    }
                }
            });
        };

        fetchUnreadMessages();

        return () => {
            socket.off('receive-message');
        };
    }, [socket, bids, token, acceptedBids]);

    useEffect(() => {
        const fetchUnreadCompletedMessages = async () => {
            socket.on('receive-message', async (data) => {
                if (!completedBids.find(bid => bid._id === data.conversationId)) {
                    try {
                        const headers = { Authorization: `Bearer ${token}` };
                        const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/unread-messages?conversationIds=${data.conversationId}`, { headers });
                        const newCount = response.data.find(item => item._id === data.conversationId)?.unreadCount;

                        setMessageNotifications(prev => ({
                            ...prev,
                            [data.conversationId]: newCount
                        }));
                    } catch (error) {
                        console.error('Error fetching updated unread message counts:', error);
                    }
                }
            });
        };

        fetchUnreadCompletedMessages();

        return () => {
            socket.off('receive-message');
        };
    }, [socket, bids, token, completedBids]);

    useEffect(() => {
        fetchSubscription();
    }, []);

    const handleBasicSubscription = () => {
        window.open('https://buy.stripe.com/fZe2bCatv4bBc12bIM');
    };

    const handleExplorerSubscription = () => {
        window.open('https://buy.stripe.com/test_3csdSTgKi4HzeA0aEL');
    };

    const handleEnhancedSubscription = () => {
        window.open('https://buy.stripe.com/eVa4jK8ln8rRe9a5kl');
    };

    const handleUnleashedSubscription = () => {
        window.open('https://buy.stripe.com/test_00g4ij2Ts1vngI85kq');
    };

    const handleEliteSubscription = () => {
        window.open('https://buy.stripe.com/8wM3fG0SV23t1mocMM');
    };

    const handleBidNotifications = (count) => {
        setBidNotifications(count);
    };

    const handleError = (error) => {
        console.error('Notification Error:', error);
    };

    useEffect(() => {
        if (socket) {
            socket.emit('requestMessageNotifications');
        }
    }, [socket]);

    useEffect(() => {
        if (socket) {
            socket.on('newBidPlaced', (newBid) => {
                socket.emit('requestDetailedBids');
            });

            return () => {
                socket.off('newBidPlaced');
            };
        }
    }, [socket]);

    useEffect(() => {
        if (socket) {
            // Emit the correct event name as expected by the server to request bid notifications
            socket.emit('requestBidNotifications');

            // Listening to the correct event names as emitted by the server
            socket.on('bidNotifications', handleBidNotifications);
            socket.on('bidNotificationsError', handleError);

            return () => {
                socket.off('bidNotifications', handleBidNotifications);
                socket.off('bidNotificationsError', handleError);
            };
        }
    }, [socket]);

    useEffect(() => {
        if (role === 'Contractor') {
            socket.on('detailedBids', setBids);
            socket.emit('requestDetailedBids');
            return () => {
                socket.off('detailedBids');
            };
        }
    }, [socket]);

    useEffect(() => {
        if (role === 'Contractor') {
            socket.on('detailedHandoff', (data) => {
                setHandoffs(Array.isArray(data) ? data : []);
            });
            socket.emit('requestDetailedHandoffs');
            return () => {
                socket.off('detailedHandoff');
            };
        }
    }, [socket, role]);

    useEffect(() => {
        if (socket) {
            const handleAcceptedBids = (bids) => {
                setAcceptedBids(bids);
            };
            const handleCompletedBids = (bids) => {
                setCompletedBids(bids);
            };

            socket.on('acceptedBids', handleAcceptedBids);
            socket.on('completedBids', handleCompletedBids);

            socket.emit('requestAcceptedBids');

            return () => {
                socket.off('acceptedBids', handleAcceptedBids);
                socket.off('completedBids', handleCompletedBids);
            };
        }
    }, [socket]);

    useEffect(() => {
        if (socket) {
            socket.on('homeownerAcceptedBids', (data) => {
                setHomeownerAcceptedBids(data);
            });
            socket.emit('requestHomeownerAcceptedBids');
            return () => {
                socket.off('homeownerAcceptedBids');
            };
        }
    }, [socket]);

    useEffect(() => {
        if (role === 'Home Owner') {
            socket.on('detailedProjects', setProjects);
            socket.emit('requestDetailedProjects');
            return () => {
                socket.off('detailedProjects');
            };
        }
    }, [socket]);

    // React: Handle incoming notification updates
    useEffect(() => {
        console.log('Effect running. Role:', role);

        const handleNotificationUpdate = (update) => {
            console.log('Project Notifications Updated:', update);
            setProjects(prevProjects => {
                return prevProjects.map(project => {
                    if (project._id === update.projectId) {
                        // Update the specific project's notifications count
                        return {
                            ...project,
                            notifications: update.notifications,
                            bids: project.bids.map(bid => {
                                if (bid.contractor === update.contractorId) {
                                    return {
                                        ...bid,
                                        bidNotifictions: update.bidNotifications
                                    };
                                }
                                return bid;
                            })
                        };
                    }
                    return project;
                });
            });
        };

        socket.on('notificationUpdate', handleNotificationUpdate);

        return () => {
            console.log('Cleaning up listener for notificationUpdate');
            socket.off('notificationUpdate', handleNotificationUpdate);
        };
    }, [socket, role, setProjects]);

    useEffect(() => {
        console.log('Effect running. Role:', role);

        if (role === 'Home Owner') {

            const handleNewProject = (newProject) => {
                console.log('New project received:', newProject);
                setProjects(prevProjects => [...prevProjects, newProject]);
            };

            socket.on('newProject', handleNewProject);

            return () => {
                console.log('Cleaning up listener for newProject');
                socket.off('newProject', handleNewProject);
            };
        }
    }, [socket, role]);

    const navigateToBidDetails = async (bid) => {
        setMessageNotifications(0);
        navigate(`/bids/${bid._id}`);
    };

    const navigateToActiveProjects = async (acceptedBid) => {
        setMessageNotifications(0);
        navigate(`/active-projects/${acceptedBid._id}/${acceptedBid.projectId}`);
    };

    const navigateToBrowse = () => {
        navigate(`/browse`)

    }

    const navigateToHandoffDetails = async (handoff) => {
        navigate(`/handoff/${handoff._id}`);
    };

    const navigateToDashboard = () => {
        navigate(`/contractor/dashboard`)

    }

    const navigateToCreateProject = () => {
        navigate(`/create-project`)

    }

    const navigateToStripeSubscription = () => {
        window.open('https://billing.stripe.com/p/login/7sI02E8Tk7sZfTi5kk', '_blank');
    };

    const navigateToSubscription = () => {
        navigate(`/subscriptions`)
    }

    const navigateToVerification = () => {
        navigate(`/verification`)
    }

    const navigateToProjectDetails = (project) => {
        navigate(`/projects/${project._id}`);
    };

    const navigateToRecommendedProjects = () => {
        navigate('/recommended-projects');
    };

    const handleExpressDashboard = async (accountId) => {
        const headers = {
            'Authorization': `Bearer ${token}`, // Ensure 'token' is defined and valid
            'Content-Type': 'application/json'
        };

        try {
            const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/create-dashboard-link/${accountId}`, { headers });

            // Assuming the server returns the URL to redirect to
            if (response.data && response.data.url) {
                window.open(response.data.url, '_blank');
            } else {
                throw new Error("No dashboard URL returned");
            }
        } catch (error) {
            console.error('Error accessing dashboard:', error);
        }
    };

    const handleGoHome = () => {
        const verified = localStorage.getItem('verified');
        console.log('verified', verified);
        if (verified === 'true') {
            const role = localStorage.getItem('role');
            if (role === 'Contractor') {
                navigate('/contractor/dashboard');
            } else {
                navigate('/create-project');
            }
        } else {
            navigate('/');
        }
    };

    const projectItems = projects?.map(project => (
        <Flex w={"100%"} p={2} key={project._id} onClick={() => navigateToProjectDetails(project)} _hover={{ bg: "whiteAlpha.300", cursor: "pointer" }} align={"center"} justify={"space-between"} borderRadius={"md"}>

            <FontAwesomeIcon icon={faHammer} color={"lightgray"} />
            <Flex w={"80%"} justify={"flex-start"} align={"center"}>
                <Text ml={4} size={"sm"} color={location.pathname === `/projects/${project._id}` ? "white" : "darkgray"}>
                    {project.title?.length > 15 ? `${project.title.slice(0, 15)}...` : project.title}
                </Text>
            </Flex>

            <Flex w={"20%"} mr={5} justify={"flex-end"} align={"center"}>
                {project.notifications > 0 && (
                    <Flex align="center" justify="center" position="relative">
                        <FontAwesomeIcon size='lg' icon={faComments} color="white" />

                        <Box
                            position="absolute"
                            top="-1"
                            right="-1"
                            px={1}
                            fontSize="xs"
                            color="white"
                            bgColor="green"
                            borderRadius="full"
                        >
                            {project.notifications}
                        </Box>
                    </Flex>
                )}
            </Flex>
        </Flex>
    ));

    const drawerRef = useRef();

    const handleOutsideClick = (event) => {
        if (drawerRef.current && !drawerRef.current.contains(event.target)) {
            onClose();
        }
    };

    useEffect(() => {
        document.addEventListener("mousedown", handleOutsideClick);
        return () => {
            document.removeEventListener("mousedown", handleOutsideClick);
        };
    }, []);

    const copyReferralLink = () => {
        const userId = localStorage.getItem('id');
        if (userId) {
            const referralLink = `http://utahremodels.com/${userId}`;
            navigator.clipboard.writeText(referralLink).then(() => {
                toast({
                    title: "Referral link copied!",
                    description: "The link has been copied to your clipboard.",
                    status: "success",
                    duration: 3000,
                    isClosable: true,
                });
            }).catch(err => {
                console.error('Failed to copy: ', err);
                toast({
                    title: "Failed to copy",
                    description: "There was an error copying the link. Please try again.",
                    status: "error",
                    duration: 3000,
                    isClosable: true,
                });
            });
        } else {
            toast({
                title: "User ID not found",
                description: "Please make sure you're logged in.",
                status: "warning",
                duration: 3000,
                isClosable: true,
            });
        }
    };

    const DrawerMenu = () => (
        <Drawer isOpen={isOpen} placement="left" onClose={onClose}>
            <DrawerOverlay />
            <DrawerContent ref={drawerRef} ml={6} borderRadius={"lg"} zIndex={9999}>
                <DrawerCloseButton />
                <DrawerHeader>Menu</DrawerHeader>
                <DrawerBody>
                    <VStack p={2} w={"100%"} bg={"gray.700"} borderRadius={"xl"}>
                        <Flex w={"100%"} align={"center"} justify={"center"}>
                            <Flex align={"center"} justify={"center"}>
                                <Flex direction={"column"} mt={2} align={"center"} justify={"center"}>
                                    <Image src={BigLogo} onClick={handleGoHome} w={"fit-content"} maxH={"full"} />
                                </Flex>
                            </Flex>
                        </Flex>
                        <Divider borderColor={"white"} w={"100%"} mb={4} />
                        {user.role === 'Contractor' && user.subscription === 'Free' ? (
                            <Flex p={4} direction="column" align="center" justify="flex-start" w="100%">
                                <VStack spacing={4}>
                                    {/* Basic Tier */}
                                    <Flex p={5} direction={"column"} justify={"center"} align={"center"} borderWidth="1px" borderRadius="lg" w="100%" _hover={{ bg: "gray.600" }}>
                                        <Heading color="white" size="md">Basic</Heading>
                                        <Image m={2} src={Basic} w={"80%"} h={"auto"} />
                                        <Button mt={4} colorScheme="blue" onClick={handleBasicSubscription}>Choose Basic</Button>
                                    </Flex>

                                    {/* Basic Tier */}
                                    <Flex p={5} direction={"column"} justify={"center"} align={"center"} borderWidth="1px" borderRadius="lg" w="100%" _hover={{ bg: "gray.600" }}>
                                        <Heading color="white" size="md">Enhanced</Heading>
                                        <Image m={2} src={Enhanced} w={"80%"} h={"auto"} />
                                        <Button mt={4} colorScheme="blue" onClick={handleEnhancedSubscription}>Choose Enhanced</Button>
                                    </Flex>

                                    {/* Premium Tier */}
                                    <Flex p={5} direction={"column"} justify={"center"} align={"center"} borderWidth="1px" borderRadius="lg" w="100%" _hover={{ bg: "gray.600" }}>
                                        <Heading color={"white"} size="md">Elite</Heading>
                                        <Image m={2} src={Elite} w={"80%"} h={"auto"} />
                                        <Button mt={4} colorScheme="blue" onClick={handleEliteSubscription}>Choose Elite</Button>
                                    </Flex>
                                </VStack>
                            </Flex>
                        ) : (
                            <>
                                {role === 'Contractor' && (
                                    <>
                                        <Flex onClick={navigateToDashboard} _hover={{ bg: "whiteAlpha.300", cursor: "pointer" }} w={"100%"} align={"center"} justify={"flex-start"} borderRadius={"lg"} h={"fit-content"}>
                                            <Flex ml={2} w={"70%"} p={2} align={"center"} justify={"flex-start"}>
                                                <FontAwesomeIcon icon={faHomeAlt} color={location.pathname === '/contractor/dashboard' ? "white" : "darkgray"} />
                                                <Text ml={4} mt={1} size={"sm"} color={location.pathname === '/contractor/dashboard' ? "white" : "darkgray"}>
                                                    Dashboard
                                                </Text>
                                            </Flex>
                                        </Flex>

                                        <Flex w={"100%"} justify={"flex-start"}>
                                            <Text fontWeight={"semibold"} fontSize={"sm"} color={"lightgray"}>Projects</Text>
                                        </Flex>

                                        {user.recommendedProjects?.length > 0 && (
                                            <Flex border={unseenProjectCount > 0 ? '2px' : 0} borderColor={unseenProjectCount > 0 ? 'white' : ''} bg={unseenProjectCount > 0 ? 'blackAlpha.600' : ''} onClick={navigateToRecommendedProjects} _hover={{ bg: "whiteAlpha.300", cursor: "pointer" }} w={"100%"} align={"center"} justify={"space-between"} borderRadius={"lg"} h={"fit-content"}>
                                                <Flex ml={2} w={"100%"} p={2} align={"center"} justify={"flex-start"}>
                                                    <FontAwesomeIcon icon={faCommentDollar} color={location.pathname === '/recommended-projects' ? "white" : unseenProjectCount > 0 ? "white" : "darkgray"} />
                                                    <Text ml={4} size={"sm"} color={location.pathname === '/recommended-projects' ? "white" : unseenProjectCount > 0 ? "white" : "darkgray"}>
                                                        {`${user.firstName}'s Leads`?.length > 30 ? `${user.firstName}'s Leads`.slice(0, 27) + '...' : `${user.firstName}'s Leads`}
                                                    </Text>
                                                </Flex>

                                                {unseenProjectCount > 0 && (
                                                    <Flex w={"20%"} mr={5} justify={"flex-end"} align={"center"}>
                                                        <Flex align="center" justify="center" position="relative">
                                                            <Box
                                                                position="absolute"
                                                                px={3}
                                                                fontSize="xs"
                                                                color="white"
                                                                bgColor="green"
                                                                borderRadius="full"
                                                            >
                                                                {unseenProjectCount}
                                                            </Box>
                                                        </Flex>
                                                    </Flex>
                                                )}
                                            </Flex>
                                        )}

                                        <Flex onClick={navigateToBrowse} _hover={{ bg: "whiteAlpha.300", cursor: "pointer" }} w={"100%"} align={"center"} justify={"flex-start"} borderRadius={"lg"} h={"fit-content"}>
                                            <Flex ml={2} w={"70%"} p={2} align={"center"} justify={"flex-start"}>
                                                <FontAwesomeIcon icon={faMagnifyingGlassDollar} color={location.pathname === '/browse' ? "white" : "darkgray"} />
                                                <Text ml={4} size={"sm"} color={location.pathname === '/browse' ? "white" : "darkgray"}>
                                                    Browse Projects
                                                </Text>
                                            </Flex>
                                        </Flex>
                                    </>
                                )}
                                {(role === 'Home Owner') && homeownerAcceptedBids && (
                                    <Flex w={"100%"} direction={"column"} maxHeight={"40vh"} overflowY={"scroll"}>
                                        {homeownerAcceptedBids.length > 0 && (
                                            <Flex mt={4} w={"100%"} justify={"flex-start"}>
                                                <Text fontWeight={"semibold"} fontSize={"sm"} color={"lightgray"}>Active Projects</Text>
                                            </Flex>
                                        )}

                                        {homeownerAcceptedBids.map(acceptedBid => (
                                            <Flex w={"100%"} align={"center"} justify={"space-between"} _hover={{ bg: "whiteAlpha.300", cursor: "pointer" }} borderRadius={"md"}>
                                                <Flex w={"100%"} p={2} key={acceptedBid._id} onClick={() => navigateToActiveProjects(acceptedBid)} align={"center"} justify={"flex-start"} borderRadius={"md"}>
                                                    <FontAwesomeIcon icon={faFileLines} color={"lightgray"} />
                                                    <Text ml={4} size={"sm"} color={location.pathname === `/active-projects/${acceptedBid._id}/${acceptedBid.projectId}` ? "white" : (projectNotifications > 0 ? "lightgray" : "darkgray")}>
                                                        {acceptedBid.title.length > 20
                                                            ? `${acceptedBid.title.slice(0, 20)}...`
                                                            : acceptedBid.title}{" "}
                                                    </Text>
                                                </Flex>
                                                {messageNotifications[acceptedBid.conversationId] > 0 && (
                                                    <Flex mr={4} justify={"center"} align={"center"}>
                                                        <Flex align="center" justify="center" position="relative">
                                                            <FontAwesomeIcon size='lg' icon={faComments} color="white" />
                                                            {messageNotifications[acceptedBid.conversationId] > 0 && (
                                                                <Box
                                                                    position="absolute"
                                                                    top="-1"
                                                                    right="-1"
                                                                    px={1}
                                                                    fontSize="xs"
                                                                    color="white"
                                                                    bgColor="green"
                                                                    borderRadius="full"
                                                                >
                                                                    {messageNotifications[acceptedBid.conversationId]}
                                                                </Box>
                                                            )}
                                                        </Flex>
                                                    </Flex>
                                                )}
                                            </Flex>
                                        ))}
                                    </Flex>
                                )}
                                {(role === 'Contractor') ? (
                                    <Flex w={"100%"} direction={"column"} maxHeight={"40vh"} overflowY={"scroll"}>
                                        {acceptedBids.length > 0 && (
                                            <Flex mt={4} w={"100%"} justify={"flex-start"}>
                                                <Text fontWeight={"semibold"} fontSize={"sm"} color={"lightgray"}>Active Projects</Text>
                                            </Flex>
                                        )}

                                        {acceptedBids.map(acceptedBid => (
                                            <Flex w={"100%"} align={"center"} justify={"space-between"} _hover={{ bg: "whiteAlpha.300", cursor: "pointer" }} borderRadius={"md"}>
                                                <Flex w={"100%"} p={2} key={acceptedBid._id} onClick={() => navigateToActiveProjects(acceptedBid)} align={"center"} justify={"flex-start"} borderRadius={"md"}>
                                                    <FontAwesomeIcon icon={faFileLines} color={"lightgray"} />
                                                    <Text ml={4} size={"sm"} color={location.pathname === `/active-projects/${acceptedBid._id}/${acceptedBid.projectId}` ? "white" : (projectNotifications > 0 ? "lightgray" : "darkgray")}>
                                                        {acceptedBid.title.length > 20
                                                            ? `${acceptedBid.title.slice(0, 20)}...`
                                                            : acceptedBid.title}{" "}
                                                    </Text>
                                                </Flex>
                                                {messageNotifications[acceptedBid.conversationId] > 0 && (
                                                    <Flex mr={4} justify={"center"} align={"center"}>
                                                        <Flex align="center" justify="center" position="relative">
                                                            <FontAwesomeIcon size='lg' icon={faComments} color="white" />
                                                            {messageNotifications[acceptedBid.conversationId] > 0 && (
                                                                <Box
                                                                    position="absolute"
                                                                    top="-1"
                                                                    right="-1"
                                                                    px={1}
                                                                    fontSize="xs"
                                                                    color="white"
                                                                    bgColor="green"
                                                                    borderRadius="full"
                                                                >
                                                                    {messageNotifications[acceptedBid.conversationId]}
                                                                </Box>
                                                            )}
                                                        </Flex>
                                                    </Flex>
                                                )}
                                            </Flex>
                                        ))}

                                        {completedBids.length > 0 && (
                                            <Flex mt={4} w={"100%"} justify={"flex-start"}>
                                                <Text fontWeight={"semibold"} fontSize={"sm"} color={"lightgray"}>Completed Projects</Text>
                                            </Flex>
                                        )}

                                        {completedBids.map(completedBids => (
                                            <Flex w={"100%"} align={"center"} justify={"space-between"} _hover={{ bg: "whiteAlpha.300", cursor: "pointer" }} borderRadius={"md"}>
                                                <Flex w={"100%"} p={2} key={completedBids._id} onClick={() => navigateToActiveProjects(completedBids)} align={"center"} justify={"flex-start"} borderRadius={"md"}>
                                                    <FontAwesomeIcon icon={faFileLines} color={"lightgray"} />
                                                    <Text ml={4} size={"sm"} color={location.pathname === `/active-projects/${completedBids._id}/${completedBids.projectId}` ? "white" : (projectNotifications > 0 ? "lightgray" : "darkgray")}>
                                                        {completedBids.title.length > 20
                                                            ? `${completedBids.title.slice(0, 20)}...`
                                                            : completedBids.title}{" "}
                                                    </Text>
                                                </Flex>
                                                {messageNotifications[completedBids.conversationId] > 0 && (
                                                    <Flex mr={4} justify={"center"} align={"center"}>
                                                        <Flex align="center" justify="center" position="relative">
                                                            <FontAwesomeIcon size='lg' icon={faComments} color="white" />
                                                            {messageNotifications[completedBids.conversationId] > 0 && (
                                                                <Box
                                                                    position="absolute"
                                                                    top="-1"
                                                                    right="-1"
                                                                    px={1}
                                                                    fontSize="xs"
                                                                    color="white"
                                                                    bgColor="green"
                                                                    borderRadius="full"
                                                                >
                                                                    {messageNotifications[completedBids.conversationId]}
                                                                </Box>
                                                            )}
                                                        </Flex>
                                                    </Flex>
                                                )}
                                            </Flex>
                                        ))}
                                    </Flex>
                                ) : (projectItems.length > 0 ? (
                                    <Flex w={"100%"} direction={"column"} justify={"flex-start"} maxHeight={"40vh"} overflowY={"scroll"}>
                                        <Flex mt={4} w={"100%"} justify={"flex-start"}>
                                            <Text fontWeight={"semibold"} fontSize={"sm"} color={"lightgray"}>Projects & Bids</Text>
                                        </Flex>
                                        {projectItems}
                                    </Flex>
                                ) : null
                                )}

                                {role === 'Contractor' && (
                                    <>
                                        {bids.length > 0 && (
                                            <Flex mt={4} w={"100%"} justify={"flex-start"}>
                                                <Text fontWeight={"semibold"} fontSize={"sm"} color={"lightgray"}>Bids</Text>
                                            </Flex>
                                        )}

                                        <Flex w={"100%"} direction={"column"} maxHeight={"20vh"} overflowY={"scroll"}>

                                            {bids.map(bid => (
                                                <Flex
                                                    w="100%"
                                                    align="center"
                                                    justify="space-between"
                                                    _hover={{ bg: "whiteAlpha.300", cursor: "pointer" }}
                                                    borderRadius="md"
                                                >
                                                    {bid.status === "accepted" || bid.status === 'rejected' ? (
                                                        null
                                                    ) : bid.status === 'pending' ? (
                                                        <>
                                                            <Flex
                                                                w="100%"
                                                                p={2}
                                                                key={bid._id}
                                                                onClick={() => navigateToBidDetails(bid)}
                                                                align="center"
                                                                justify="flex-start"
                                                                borderRadius="md"
                                                            >
                                                                <FontAwesomeIcon icon={faFileLines} color="lightgray" />
                                                                <Text
                                                                    ml={4}
                                                                    size="sm"
                                                                    color={location.pathname === `/bids/${bid._id}` ? "white" : (projectNotifications > 0 ? "lightgray" : "darkgray")}
                                                                >
                                                                    {bid.projectName.length > 20
                                                                        ? `${bid.projectName.slice(0, 20)}...`
                                                                        : bid.projectName}
                                                                </Text>
                                                            </Flex>
                                                            {messageNotifications[bid._id] > 0 && (
                                                                <Flex mr={4} justify="center" align="center">
                                                                    <Flex align="center" justify="center" position="relative">
                                                                        <FontAwesomeIcon size="lg" icon={faComments} color="white" />
                                                                        {messageNotifications[bid._id] > 0 && (
                                                                            <Box
                                                                                position="absolute"
                                                                                top="-1"
                                                                                right="-1"
                                                                                px={1}
                                                                                fontSize="xs"
                                                                                color="white"
                                                                                bgColor="green"
                                                                                borderRadius="full"
                                                                            >
                                                                                {messageNotifications[bid._id]}
                                                                            </Box>
                                                                        )}
                                                                    </Flex>
                                                                </Flex>
                                                            )}
                                                        </>
                                                    ) : null}
                                                </Flex>
                                            ))}
                                        </Flex>

                                        {handoffs.length > 0 && (
                                            <Flex mt={4} w={"100%"} justify={"flex-start"}>
                                                <Text fontWeight={"semibold"} fontSize={"sm"} color={"lightgray"}>Handoffs</Text>
                                            </Flex>
                                        )}

                                        <Flex w={"100%"} direction={"column"} maxHeight={"20vh"} overflowY={"scroll"}>

                                            {handoffs?.map(handoff => (
                                                <Flex
                                                    w="100%"
                                                    align="center"
                                                    justify="space-between"
                                                    _hover={{ bg: "whiteAlpha.300", cursor: "pointer" }}
                                                    borderRadius="md"
                                                    key={handoff?._id}
                                                >
                                                    <Flex
                                                        w="100%"
                                                        p={2}
                                                        onClick={() => navigateToHandoffDetails(handoff)}
                                                        align="center"
                                                        justify="flex-start"
                                                        borderRadius="md"
                                                    >
                                                        <FontAwesomeIcon icon={faFileLines} color="lightgray" />
                                                        <Text
                                                            ml={4}
                                                            size="sm"
                                                            color="darkgray"
                                                        >
                                                            {handoff.leadDescription.length > 20
                                                                ? `${handoff?.leadDescription.slice(0, 20)}...`
                                                                : handoff?.leadDescription}
                                                        </Text>
                                                    </Flex>
                                                </Flex>
                                            ))}

                                        </Flex>
                                    </>
                                )}
                                <Flex w={"100%"} justify={"flex-start"}>
                                    <Text fontWeight={"semibold"} fontSize={"sm"} color={"lightgray"}>Adminstrative</Text>
                                </Flex>

                                {role === 'Home Owner' && (
                                    <Flex onClick={navigateToCreateProject} _hover={{ bg: "whiteAlpha.300", cursor: "pointer" }} w={"100%"} align={"center"} justify={"flex-start"} borderRadius={"lg"} h={"fit-content"}>
                                        <Flex ml={2} w={"70%"} p={2} align={"center"} justify={"flex-start"}>
                                            <FontAwesomeIcon icon={faPenRuler} color={location.pathname === '/create-project' ? "white" : "darkgray"} />
                                            <Text ml={4} size={"sm"} color={location.pathname === '/create-project' ? "white" : "darkgray"}>
                                                Create Project
                                            </Text>
                                        </Flex>
                                    </Flex>
                                )}

                                {user.destinationAccountId && (
                                    <Flex onClick={() => handleExpressDashboard(user.destinationAccountId)} _hover={{ bg: "whiteAlpha.300", cursor: "pointer" }} w={"100%"} align={"center"} justify={"flex-start"} borderRadius={"lg"} h={"fit-content"}>
                                        <Flex ml={2} w={"70%"} p={2} align={"center"} justify={"flex-start"}>
                                            <FontAwesomeIcon icon={faMoneyBillTransfer} color={"darkgray"} />
                                            <Text ml={4} size={"sm"} color={"darkgray"}>
                                                Payments
                                            </Text>
                                        </Flex>
                                    </Flex>
                                )}

                                {role === 'Contractor' && subscription !== 'Basic' ? (
                                    <Flex onClick={navigateToStripeSubscription} _hover={{ bg: "whiteAlpha.300", cursor: "pointer" }} w={"100%"} align={"center"} justify={"flex-start"} borderRadius={"lg"} h={"fit-content"}>
                                        <Flex ml={2} w={"70%"} p={2} align={"center"} justify={"flex-start"}>
                                            <FontAwesomeIcon icon={faUserCog} color={"darkgray"} />
                                            <Text ml={4} size={"sm"} color={"darkgray"}>
                                                Manage Subscription
                                            </Text>
                                        </Flex>
                                    </Flex>
                                ) : (role === 'Contractor' ? (
                                    <Flex onClick={navigateToSubscription} _hover={{ bg: "whiteAlpha.300", cursor: "pointer" }} w={"100%"} align={"center"} justify={"flex-start"} borderRadius={"lg"} h={"fit-content"}>
                                        <Flex ml={2} w={"70%"} p={2} align={"center"} justify={"flex-start"}>
                                            <FontAwesomeIcon icon={faUserCog} color={"darkgray"} />
                                            <Text ml={4} size={"sm"} color={"darkgray"}>
                                                Manage Subscription
                                            </Text>
                                        </Flex>
                                    </Flex>
                                ) : null
                                )}

                                {role === 'Contractor' && (
                                    <Flex onClick={navigateToVerification} _hover={{ bg: "whiteAlpha.300", cursor: "pointer" }} w={"100%"} align={"center"} justify={"flex-start"} borderRadius={"lg"} h={"fit-content"}>
                                        <Flex ml={2} w={"70%"} p={2} align={"center"} justify={"flex-start"}>
                                            <FontAwesomeIcon icon={faUserShield} color={location.pathname === '/verification' ? "white" : "darkgray"} />
                                            <Text ml={4} size={"sm"} color={location.pathname === '/verification' ? "white" : "darkgray"}>
                                                Business Information
                                            </Text>
                                        </Flex>
                                    </Flex>
                                )}

                                {user.destinationAccountId && (
                                    <Flex
                                        onClick={copyReferralLink}
                                        _hover={{ bg: "whiteAlpha.300", cursor: "pointer" }}
                                        w={"100%"}
                                        align={"center"}
                                        justify={"flex-start"}
                                        borderRadius={"lg"}
                                        h={"fit-content"}
                                    >
                                        <Flex ml={2} w={"70%"} p={2} align={"center"} justify={"flex-start"}>
                                            <FontAwesomeIcon icon={faCopy} color={"darkgray"} />
                                            <Text ml={4} size={"sm"} color={"darkgray"}>
                                                Referral Link
                                            </Text>
                                        </Flex>
                                    </Flex>
                                )}

                                {!user.destinationAccountId && (
                                    <Flex _hover={{ bg: "whiteAlpha.300", cursor: "pointer" }} w={"100%"} align={"center"} justify={"flex-start"} borderRadius={"lg"} h={"fit-content"}>
                                        <CreateStripeAccountButton />
                                    </Flex>
                                )}
                            </>
                        )}
                    </VStack>
                </DrawerBody>
            </DrawerContent>
        </Drawer>
    );

    return (
        <Flex direction={"column"} m={{ base: 0, lg: 4 }} h={{ base: "100%", lg: "fit-content" }} overflowY={"scroll"} w={menuType === 'drawer' ? "100%" : expanded ? "12%" : "3%"} justify={"center"} position="sticky" top="5" zIndex={menuType === 'drawer' ? 1 : "sticky"}>
            <>
                {menuType === 'drawer' ? (
                    <Flex p={2} bg={colorMode === 'light' ? "white" : "gray.700"} borderRadius={"md"} shadow={"lg"} w={"100%"} align={"center"} justify={"space-between"}>
                        <Heading>Utah Remodels</Heading>
                        <Button onClick={handleMenuClick} variant="ghost">
                            <FontAwesomeIcon icon={faBars} size="2x" />
                        </Button>
                        <DrawerMenu />
                    </Flex>
                ) : (
                    <>
                        {expanded ? (
                            <VStack p={2} w={"100%"} bg={"gray.700"} borderRadius={"xl"}>
                                <Flex w={"100%"} align={"center"} justify={"center"}>
                                    <Flex align={"center"} justify={"center"}>
                                        <Flex direction={"column"} mt={2} align={"center"} justify={"center"}>
                                            <Image src={BigLogo} onClick={handleGoHome} w={"fit-content"} maxH={"full"} />
                                        </Flex>
                                    </Flex>
                                </Flex>
                                <Divider borderColor={"white"} w={"100%"} mb={4} />
                                {role === 'Contractor' && (
                                    <>
                                        <Flex onClick={navigateToDashboard} _hover={{ bg: "whiteAlpha.300", cursor: "pointer" }} w={"100%"} align={"center"} justify={"flex-start"} borderRadius={"lg"} h={"fit-content"}>
                                            <Flex ml={2} w={"70%"} p={2} align={"center"} justify={"flex-start"}>
                                                <FontAwesomeIcon icon={faHomeAlt} color={location.pathname === '/contractor/dashboard' ? "white" : "darkgray"} />
                                                <Text ml={4} mt={1} size={"sm"} color={location.pathname === '/contractor/dashboard' ? "white" : "darkgray"}>
                                                    Dashboard
                                                </Text>
                                            </Flex>
                                        </Flex>

                                        <Flex w={"100%"} justify={"flex-start"}>
                                            <Text fontWeight={"semibold"} fontSize={"sm"} color={"lightgray"}>Projects</Text>
                                        </Flex>

                                        {user.recommendedProjects?.length > 0 && (
                                            <Flex border={unseenProjectCount > 0 ? '2px' : 0} borderColor={unseenProjectCount > 0 ? 'white' : ''} bg={unseenProjectCount > 0 ? 'blackAlpha.600' : ''} onClick={navigateToRecommendedProjects} _hover={{ bg: "whiteAlpha.300", cursor: "pointer" }} w={"100%"} align={"center"} justify={"space-between"} borderRadius={"lg"} h={"fit-content"}>
                                                <Flex ml={2} w={"100%"} p={2} align={"center"} justify={"flex-start"}>
                                                    <FontAwesomeIcon icon={faCommentDollar} color={location.pathname === '/recommended-projects' ? "white" : unseenProjectCount > 0 ? "white" : "darkgray"} />
                                                    <Text ml={4} size={"sm"} color={location.pathname === '/recommended-projects' ? "white" : unseenProjectCount > 0 ? "white" : "darkgray"}>
                                                        {`${user.firstName}'s Leads`?.length > 30 ? `${user.firstName}'s Leads`.slice(0, 27) + '...' : `${user.firstName}'s Leads`}
                                                    </Text>
                                                </Flex>

                                                {unseenProjectCount > 0 && (
                                                    <Flex w={"20%"} mr={5} justify={"flex-end"} align={"center"}>
                                                        <Flex align="center" justify="center" position="relative">
                                                            <Box
                                                                position="absolute"
                                                                px={3}
                                                                fontSize="xs"
                                                                color="white"
                                                                bgColor="green"
                                                                borderRadius="full"
                                                            >
                                                                {unseenProjectCount}
                                                            </Box>
                                                        </Flex>
                                                    </Flex>
                                                )}
                                            </Flex>
                                        )}

                                        <Flex onClick={navigateToBrowse} _hover={{ bg: "whiteAlpha.300", cursor: "pointer" }} w={"100%"} align={"center"} justify={"flex-start"} borderRadius={"lg"} h={"fit-content"}>
                                            <Flex ml={2} w={"70%"} p={2} align={"center"} justify={"flex-start"}>
                                                <FontAwesomeIcon icon={faMagnifyingGlassDollar} color={location.pathname === '/browse' ? "white" : "darkgray"} />
                                                <Text ml={4} size={"sm"} color={location.pathname === '/browse' ? "white" : "darkgray"}>
                                                    Browse Projects
                                                </Text>
                                            </Flex>
                                        </Flex>
                                    </>
                                )}
                                {(role === 'Home Owner') && homeownerAcceptedBids && (
                                    <Flex w={"100%"} direction={"column"} maxHeight={"40vh"} overflowY={"scroll"}>
                                        {homeownerAcceptedBids.length > 0 && (
                                            <Flex mt={4} w={"100%"} justify={"flex-start"}>
                                                <Text fontWeight={"semibold"} fontSize={"sm"} color={"lightgray"}>Active Projects</Text>
                                            </Flex>
                                        )}

                                        {homeownerAcceptedBids.map(acceptedBid => (
                                            <Flex w={"100%"} align={"center"} justify={"space-between"} _hover={{ bg: "whiteAlpha.300", cursor: "pointer" }} borderRadius={"md"}>
                                                <Flex w={"100%"} p={2} key={acceptedBid._id} onClick={() => navigateToActiveProjects(acceptedBid)} align={"center"} justify={"flex-start"} borderRadius={"md"}>
                                                    <FontAwesomeIcon icon={faFileLines} color={"lightgray"} />
                                                    <Text ml={4} size={"sm"} color={location.pathname === `/active-projects/${acceptedBid._id}/${acceptedBid.projectId}` ? "white" : (projectNotifications > 0 ? "lightgray" : "darkgray")}>
                                                        {acceptedBid.title.length > 20
                                                            ? `${acceptedBid.title.slice(0, 20)}...`
                                                            : acceptedBid.title}{" "}
                                                    </Text>
                                                </Flex>
                                                {messageNotifications[acceptedBid.conversationId] > 0 && (
                                                    <Flex mr={4} justify={"center"} align={"center"}>
                                                        <Flex align="center" justify="center" position="relative">
                                                            <FontAwesomeIcon size='lg' icon={faComments} color="white" />
                                                            {messageNotifications[acceptedBid.conversationId] > 0 && (
                                                                <Box
                                                                    position="absolute"
                                                                    top="-1"
                                                                    right="-1"
                                                                    px={1}
                                                                    fontSize="xs"
                                                                    color="white"
                                                                    bgColor="green"
                                                                    borderRadius="full"
                                                                >
                                                                    {messageNotifications[acceptedBid.conversationId]}
                                                                </Box>
                                                            )}
                                                        </Flex>
                                                    </Flex>
                                                )}
                                            </Flex>
                                        ))}
                                    </Flex>
                                )}
                                {(role === 'Contractor') ? (
                                    <Flex w={"100%"} direction={"column"} maxHeight={"40vh"} overflowY={"scroll"}>
                                        {acceptedBids.length > 0 && (
                                            <Flex mt={4} w={"100%"} justify={"flex-start"}>
                                                <Text fontWeight={"semibold"} fontSize={"sm"} color={"lightgray"}>Active Projects</Text>
                                            </Flex>
                                        )}

                                        {acceptedBids.map(acceptedBid => (
                                            <Flex w={"100%"} align={"center"} justify={"space-between"} _hover={{ bg: "whiteAlpha.300", cursor: "pointer" }} borderRadius={"md"}>
                                                <Flex w={"100%"} p={2} key={acceptedBid._id} onClick={() => navigateToActiveProjects(acceptedBid)} align={"center"} justify={"flex-start"} borderRadius={"md"}>
                                                    <FontAwesomeIcon icon={faFileLines} color={"lightgray"} />
                                                    <Text ml={4} size={"sm"} color={location.pathname === `/active-projects/${acceptedBid._id}/${acceptedBid.projectId}` ? "white" : (projectNotifications > 0 ? "lightgray" : "darkgray")}>
                                                        {acceptedBid.title.length > 20
                                                            ? `${acceptedBid.title.slice(0, 20)}...`
                                                            : acceptedBid.title}{" "}
                                                    </Text>
                                                </Flex>
                                                {messageNotifications[acceptedBid.conversationId] > 0 && (
                                                    <Flex mr={4} justify={"center"} align={"center"}>
                                                        <Flex align="center" justify="center" position="relative">
                                                            <FontAwesomeIcon size='lg' icon={faComments} color="white" />
                                                            {messageNotifications[acceptedBid.conversationId] > 0 && (
                                                                <Box
                                                                    position="absolute"
                                                                    top="-1"
                                                                    right="-1"
                                                                    px={1}
                                                                    fontSize="xs"
                                                                    color="white"
                                                                    bgColor="green"
                                                                    borderRadius="full"
                                                                >
                                                                    {messageNotifications[acceptedBid.conversationId]}
                                                                </Box>
                                                            )}
                                                        </Flex>
                                                    </Flex>
                                                )}
                                            </Flex>
                                        ))}

                                        {completedBids.length > 0 && (
                                            <Flex mt={4} w={"100%"} justify={"flex-start"}>
                                                <Text fontWeight={"semibold"} fontSize={"sm"} color={"lightgray"}>Completed Projects</Text>
                                            </Flex>
                                        )}

                                        {completedBids.map(completedBids => (
                                            <Flex w={"100%"} align={"center"} justify={"space-between"} _hover={{ bg: "whiteAlpha.300", cursor: "pointer" }} borderRadius={"md"}>
                                                <Flex w={"100%"} p={2} key={completedBids._id} onClick={() => navigateToActiveProjects(completedBids)} align={"center"} justify={"flex-start"} borderRadius={"md"}>
                                                    <FontAwesomeIcon icon={faFileLines} color={"lightgray"} />
                                                    <Text ml={4} size={"sm"} color={location.pathname === `/active-projects/${completedBids._id}/${completedBids.projectId}` ? "white" : (projectNotifications > 0 ? "lightgray" : "darkgray")}>
                                                        {completedBids.title.length > 20
                                                            ? `${completedBids.title.slice(0, 20)}...`
                                                            : completedBids.title}{" "}
                                                    </Text>
                                                </Flex>
                                                {messageNotifications[completedBids.conversationId] > 0 && (
                                                    <Flex mr={4} justify={"center"} align={"center"}>
                                                        <Flex align="center" justify="center" position="relative">
                                                            <FontAwesomeIcon size='lg' icon={faComments} color="white" />
                                                            {messageNotifications[completedBids.conversationId] > 0 && (
                                                                <Box
                                                                    position="absolute"
                                                                    top="-1"
                                                                    right="-1"
                                                                    px={1}
                                                                    fontSize="xs"
                                                                    color="white"
                                                                    bgColor="green"
                                                                    borderRadius="full"
                                                                >
                                                                    {messageNotifications[completedBids.conversationId]}
                                                                </Box>
                                                            )}
                                                        </Flex>
                                                    </Flex>
                                                )}
                                            </Flex>
                                        ))}
                                    </Flex>
                                ) : (projectItems.length > 0 ? (
                                    <Flex w={"100%"} direction={"column"} justify={"flex-start"} maxHeight={"40vh"} overflowY={"scroll"}>
                                        <Flex mt={4} w={"100%"} justify={"flex-start"}>
                                            <Text fontWeight={"semibold"} fontSize={"sm"} color={"lightgray"}>Projects & Bids</Text>
                                        </Flex>
                                        {projectItems}
                                    </Flex>
                                ) : null
                                )}

                                {role === 'Contractor' && (
                                    <>
                                        {bids.length > 0 && (
                                            <Flex mt={4} w={"100%"} justify={"flex-start"}>
                                                <Text fontWeight={"semibold"} fontSize={"sm"} color={"lightgray"}>Bids</Text>
                                            </Flex>
                                        )}

                                        <Flex w={"100%"} direction={"column"} maxHeight={"20vh"} overflowY={"scroll"}>

                                            {bids.map(bid => (
                                                <Flex
                                                    w="100%"
                                                    align="center"
                                                    justify="space-between"
                                                    _hover={{ bg: "whiteAlpha.300", cursor: "pointer" }}
                                                    borderRadius="md"
                                                >
                                                    {bid.status === "accepted" || bid.status === 'rejected' ? (
                                                        null
                                                    ) : bid.status === 'pending' ? (
                                                        <>
                                                            <Flex
                                                                w="100%"
                                                                p={2}
                                                                key={bid._id}
                                                                onClick={() => navigateToBidDetails(bid)}
                                                                align="center"
                                                                justify="flex-start"
                                                                borderRadius="md"
                                                            >
                                                                <FontAwesomeIcon icon={faFileLines} color="lightgray" />
                                                                <Text
                                                                    ml={4}
                                                                    size="sm"
                                                                    color={location.pathname === `/bids/${bid._id}` ? "white" : (projectNotifications > 0 ? "lightgray" : "darkgray")}
                                                                >
                                                                    {bid.projectName.length > 20
                                                                        ? `${bid.projectName.slice(0, 20)}...`
                                                                        : bid.projectName}
                                                                </Text>
                                                            </Flex>
                                                            {messageNotifications[bid._id] > 0 && (
                                                                <Flex mr={4} justify="center" align="center">
                                                                    <Flex align="center" justify="center" position="relative">
                                                                        <FontAwesomeIcon size="lg" icon={faComments} color="white" />
                                                                        {messageNotifications[bid._id] > 0 && (
                                                                            <Box
                                                                                position="absolute"
                                                                                top="-1"
                                                                                right="-1"
                                                                                px={1}
                                                                                fontSize="xs"
                                                                                color="white"
                                                                                bgColor="green"
                                                                                borderRadius="full"
                                                                            >
                                                                                {messageNotifications[bid._id]}
                                                                            </Box>
                                                                        )}
                                                                    </Flex>
                                                                </Flex>
                                                            )}
                                                        </>
                                                    ) : null}
                                                </Flex>
                                            ))}
                                        </Flex>

                                        {handoffs.length > 0 && (
                                            <Flex mt={4} w={"100%"} justify={"flex-start"}>
                                                <Text fontWeight={"semibold"} fontSize={"sm"} color={"lightgray"}>Handoffs</Text>
                                            </Flex>
                                        )}

                                        <Flex w={"100%"} direction={"column"} maxHeight={"20vh"} overflowY={"scroll"}>

                                            {handoffs?.map(handoff => (
                                                <Flex
                                                    w="100%"
                                                    align="center"
                                                    justify="space-between"
                                                    _hover={{ bg: "whiteAlpha.300", cursor: "pointer" }}
                                                    borderRadius="md"
                                                    key={handoff?._id}
                                                >
                                                    <Flex
                                                        w="100%"
                                                        p={2}
                                                        onClick={() => navigateToHandoffDetails(handoff)}
                                                        align="center"
                                                        justify="flex-start"
                                                        borderRadius="md"
                                                    >
                                                        <FontAwesomeIcon icon={faFileLines} color="lightgray" />
                                                        <Text
                                                            ml={4}
                                                            size="sm"
                                                            color="darkgray"
                                                        >
                                                            {handoff.leadDescription.length > 20
                                                                ? `${handoff?.leadDescription.slice(0, 20)}...`
                                                                : handoff?.leadDescription}
                                                        </Text>
                                                    </Flex>
                                                </Flex>
                                            ))}

                                        </Flex>
                                    </>
                                )}
                                <Flex w={"100%"} justify={"flex-start"}>
                                    <Text fontWeight={"semibold"} fontSize={"sm"} color={"lightgray"}>Adminstrative</Text>
                                </Flex>

                                {role === 'Home Owner' && (
                                    <Flex onClick={navigateToCreateProject} _hover={{ bg: "whiteAlpha.300", cursor: "pointer" }} w={"100%"} align={"center"} justify={"flex-start"} borderRadius={"lg"} h={"fit-content"}>
                                        <Flex ml={2} w={"70%"} p={2} align={"center"} justify={"flex-start"}>
                                            <FontAwesomeIcon icon={faPenRuler} color={location.pathname === '/create-project' ? "white" : "darkgray"} />
                                            <Text ml={4} size={"sm"} color={location.pathname === '/create-project' ? "white" : "darkgray"}>
                                                Create Project
                                            </Text>
                                        </Flex>
                                    </Flex>
                                )}

                                {user.destinationAccountId && (
                                    <Flex onClick={() => handleExpressDashboard(user.destinationAccountId)} _hover={{ bg: "whiteAlpha.300", cursor: "pointer" }} w={"100%"} align={"center"} justify={"flex-start"} borderRadius={"lg"} h={"fit-content"}>
                                        <Flex ml={2} w={"70%"} p={2} align={"center"} justify={"flex-start"}>
                                            <FontAwesomeIcon icon={faMoneyBillTransfer} color={"darkgray"} />
                                            <Text ml={4} size={"sm"} color={"darkgray"}>
                                                Payments
                                            </Text>
                                        </Flex>
                                    </Flex>
                                )}

                                {role === 'Contractor' && subscription !== 'Basic' ? (
                                    <Flex onClick={navigateToStripeSubscription} _hover={{ bg: "whiteAlpha.300", cursor: "pointer" }} w={"100%"} align={"center"} justify={"flex-start"} borderRadius={"lg"} h={"fit-content"}>
                                        <Flex ml={2} w={"70%"} p={2} align={"center"} justify={"flex-start"}>
                                            <FontAwesomeIcon icon={faUserCog} color={"darkgray"} />
                                            <Text ml={4} size={"sm"} color={"darkgray"}>
                                                Manage Subscription
                                            </Text>
                                        </Flex>
                                    </Flex>
                                ) : (role === 'Contractor' ? (
                                    <Flex onClick={navigateToSubscription} _hover={{ bg: "whiteAlpha.300", cursor: "pointer" }} w={"100%"} align={"center"} justify={"flex-start"} borderRadius={"lg"} h={"fit-content"}>
                                        <Flex ml={2} w={"70%"} p={2} align={"center"} justify={"flex-start"}>
                                            <FontAwesomeIcon icon={faUserCog} color={"darkgray"} />
                                            <Text ml={4} size={"sm"} color={"darkgray"}>
                                                Manage Subscription
                                            </Text>
                                        </Flex>
                                    </Flex>
                                ) : null
                                )}

                                {role === 'Contractor' && (
                                    <Flex onClick={navigateToVerification} _hover={{ bg: "whiteAlpha.300", cursor: "pointer" }} w={"100%"} align={"center"} justify={"flex-start"} borderRadius={"lg"} h={"fit-content"}>
                                        <Flex ml={2} w={"70%"} p={2} align={"center"} justify={"flex-start"}>
                                            <FontAwesomeIcon icon={faUserShield} color={location.pathname === '/verification' ? "white" : "darkgray"} />
                                            <Text ml={4} size={"sm"} color={location.pathname === '/verification' ? "white" : "darkgray"}>
                                                Business Information
                                            </Text>
                                        </Flex>
                                    </Flex>
                                )}

                                {user.destinationAccountId && (
                                    <Flex
                                        onClick={copyReferralLink}
                                        _hover={{ bg: "whiteAlpha.300", cursor: "pointer" }}
                                        w={"100%"}
                                        align={"center"}
                                        justify={"flex-start"}
                                        borderRadius={"lg"}
                                        h={"fit-content"}
                                    >
                                        <Flex ml={2} w={"70%"} p={2} align={"center"} justify={"flex-start"}>
                                            <FontAwesomeIcon icon={faCopy} color={"darkgray"} />
                                            <Text ml={4} size={"sm"} color={"darkgray"}>
                                                Referral Link
                                            </Text>
                                        </Flex>
                                    </Flex>
                                )}

                                {!user.destinationAccountId && (
                                    <Flex _hover={{ bg: "whiteAlpha.300", cursor: "pointer" }} w={"100%"} align={"center"} justify={"flex-start"} borderRadius={"lg"} h={"fit-content"}>
                                        <CreateStripeAccountButton />
                                    </Flex>
                                )}
                            </VStack>
                        ) : (
                            <>
                                {role === 'Contractor' && subscription !== 'Free' ? (
                                    <VStack p={2} w={"100%"} bg={"gray.700"} borderRadius={"lg"}>
                                        <Flex w={"100%"} align={"center"} justify={"center"}>
                                            <Flex mt={2} align={"center"} justify={"center"}>
                                                <Image src={Logo} onClick={handleGoHome} w={"fit-content"} maxH={"100px"} />
                                            </Flex>
                                        </Flex>
                                        <Divider borderColor={"white"} w={"90%"} />

                                        <Flex onClick={navigateToDashboard} _hover={{ bg: "whiteAlpha.300", cursor: "pointer" }} w={"100%"} align={"center"} justify={"center"} bg={dashboardNotifications > 0 ? "whiteAlpha.400" : ""} borderRadius={"lg"} h={"fit-content"}>
                                            <Flex p={2} align={"center"} justify={"center"}>
                                                <FontAwesomeIcon icon={faHomeAlt} color={location.pathname === '/contractor/dashboard' ? "white" : "darkgray"} />
                                            </Flex>
                                        </Flex>

                                        <Flex onClick={navigateToBrowse} _hover={{ bg: "whiteAlpha.300", cursor: "pointer" }} w={"100%"} align={"center"} justify={"center"} bg={dashboardNotifications > 0 ? "whiteAlpha.400" : ""} borderRadius={"lg"} h={"fit-content"}>
                                            <Flex p={2} align={"center"} justify={"center"}>
                                                <FontAwesomeIcon icon={faMagnifyingGlassDollar} color={location.pathname === '/browse' ? "white" : "darkgray"} />
                                            </Flex>
                                        </Flex>

                                        <Flex onClick={() => handleExpressDashboard(user.destinationAccountId)} _hover={{ bg: "whiteAlpha.300", cursor: "pointer" }} w={"100%"} align={"center"} justify={"center"} bg={dashboardNotifications > 0 ? "whiteAlpha.400" : ""} borderRadius={"lg"} h={"fit-content"}>
                                            <Flex p={2} align={"center"} justify={"center"}>
                                                <FontAwesomeIcon icon={faMoneyBillTransfer} color={"darkgray"} />
                                            </Flex>
                                        </Flex>

                                        <Flex onClick={navigateToStripeSubscription} _hover={{ bg: "whiteAlpha.300", cursor: "pointer" }} w={"100%"} align={"center"} justify={"center"} bg={dashboardNotifications > 0 ? "whiteAlpha.400" : ""} borderRadius={"lg"} h={"fit-content"}>
                                            <Flex p={2} align={"center"} justify={"center"}>
                                                <FontAwesomeIcon icon={faUserCog} color={"darkgray"} />
                                            </Flex>
                                        </Flex>

                                        <Flex onClick={navigateToVerification} _hover={{ bg: "whiteAlpha.300", cursor: "pointer" }} w={"100%"} align={"center"} justify={"center"} bg={dashboardNotifications > 0 ? "whiteAlpha.400" : ""} borderRadius={"lg"} h={"fit-content"}>
                                            <Flex p={2} align={"center"} justify={"center"}>
                                                <FontAwesomeIcon icon={faUserShield} color={location.pathname === '/verification' ? "white" : "darkgray"} />
                                            </Flex>
                                        </Flex>

                                        <Flex
                                            onClick={copyReferralLink}
                                            _hover={{ bg: "whiteAlpha.300", cursor: "pointer" }}
                                            w={"100%"}
                                            align={"center"}
                                            justify={"center"}
                                            borderRadius={"lg"}
                                            h={"fit-content"}
                                        >
                                            <Flex p={2} align={"center"} justify={"center"}>
                                                <FontAwesomeIcon icon={faCopy} color={"darkgray"} />
                                            </Flex>
                                        </Flex>
                                    </VStack>
                                ) : role === 'Home Owner' ? (
                                    <VStack p={2} w={"100%"} bg={"gray.700"} borderRadius={"lg"}>
                                        <Flex w={"100%"} align={"center"} justify={"center"}>
                                            <Flex mt={2} align={"center"} justify={"center"}>
                                                <Image src={Logo} onClick={handleGoHome} w={"fit-content"} maxH={"100px"} />
                                            </Flex>
                                        </Flex>
                                        <Divider borderColor={"white"} w={"90%"} />

                                        <Flex onClick={navigateToCreateProject} _hover={{ bg: "whiteAlpha.300", cursor: "pointer" }} w={"100%"} align={"center"} justify={"center"} borderRadius={"lg"} h={"fit-content"}>
                                            <Flex p={2} align={"center"} justify={"center"}>
                                                <FontAwesomeIcon icon={faPenRuler} color={location.pathname === '/create-project' ? "white" : "darkgray"} />
                                            </Flex>
                                        </Flex>

                                    </VStack>
                                ) : (
                                    null
                                )}
                            </>
                        )}
                    </>
                )}
            </>
        </Flex>
    );
}

export default ContractorSideMenu;

export const useSocketInstance = () => {
    const [socket] = useAtom(socketAtom);
    return socket;
};