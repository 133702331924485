import React, { useState, useEffect, useRef } from 'react';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import { useAtom } from 'jotai';
import { socketAtom } from '../socketAtom';
import { io } from 'socket.io-client';
import interactionPlugin from '@fullcalendar/interaction';
import Logo from '../assets/Horizontal LOGO.webp';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import { format, parseISO, isSameDay, isToday, startOfDay, endOfDay } from 'date-fns';
import {
    Flex, Text, Heading, Image, HStack, Box, useToast, Checkbox,
    Modal, ModalOverlay, ModalContent, ModalHeader, ModalFooter, ModalBody,
    ModalCloseButton, useDisclosure, Button, Input, Textarea, FormLabel,
    FormControl, Accordion, AccordionItem, AccordionButton,
    AccordionIcon, AccordionPanel, useColorMode, VStack, Spacer, Badge, NumberInput, NumberInputField, NumberInputStepper, NumberIncrementStepper, NumberDecrementStepper, Icon, useBreakpointValue
} from '@chakra-ui/react';
import ContractorSideMenu from '../contractor/SideMenu';
import Header from '../elements/Header';
import MessageComponent from '../elements/MessageComponent';
import { useSocketInstance } from '../contractor/SideMenu';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faStar as farStar } from '@fortawesome/free-regular-svg-icons';
import { faBell, faBellSlash, faBuildingColumns, faCheck, faCircleCheck, faCircleDot, faCircleExclamation, faHammer, faHouseChimney, faIdCard, faSquare, faSquareCheck, faUserShield, faStar as fasStar } from '@fortawesome/free-solid-svg-icons';
import FinanceDetails from '../elements/FinancialDetails';
import { FaStar } from 'react-icons/fa';

const ActiveProject = () => {
    const [socket] = useAtom(socketAtom);
    const { bidId, projectId } = useParams();
    const { isOpen, onOpen, onClose } = useDisclosure();
    const [bid, setBid] = useState({});
    const [contractor, setContractor] = useState({});
    const menuType = useBreakpointValue({ base: 'drawer', lg: 'regular' });
    const [isReviewModalOpen, setReviewModalOpen] = useState(false);
    const [items, setItems] = useState([]);
    const [project, setProject] = useState({});
    const [conversationId, setConversationId] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [calendarDate, setCalendarDate] = useState(new Date());
    const [events, setEvents] = useState([]);
    const [selectedDate, setSelectedDate] = useState(null);
    const [hasDateError, setHasDateError] = useState(false);
    const toast = useToast();
    const token = localStorage.getItem('token');
    const role = localStorage.getItem('role');
    const amount = 100;
    const transferGroupId = projectId;
    const eventContainerRef = useRef(null);

    const [, setSocket] = useAtom(socketAtom);

    useEffect(() => {
        const newSocket = io(process.env.REACT_APP_API_URL, {
            autoConnect: true,
            auth: {
                token: token,
            },
        });

        newSocket.on('connect_error', (error) => {
            console.error('Socket connection error:', error);
        });

        setSocket(newSocket);

        return () => newSocket.close();
    }, [setSocket, token]);

    useEffect(() => {
        fetchData();
    }, [bidId, projectId, token]);

    const fetchData = async () => {
        setIsLoading(true);
        const headers = { Authorization: `Bearer ${token}` };
        try {
            const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/active/${bidId}/${projectId}`, { headers });
            const bidData = response.data.bid || {};
            const contractorData = response.data.contractor || {};
            const projectData = response.data.project || {};
            const itemsData = bidData.items || [];
            const eventsData = bidData.events || [];
            const parsedEvents = eventsData.map(event => ({
                ...event,
                startDate: new Date(event.startDate),
                endDate: new Date(event.endDate)
            })).sort((a, b) => a.startDate - b.startDate); // This line sorts the events

            setEvents(parsedEvents);
            setBid(bidData);
            setContractor(contractorData);
            setConversationId(bidData.conversationId);
            setProject(projectData);
            setItems(itemsData);

        } catch (error) {
            console.error('Error fetching data:', error);
            toast({
                title: "Error Fetching Data",
                description: "There was an error fetching the project details.",
                status: "error",
                duration: 9000,
                isClosable: true
            });
        } finally {
            setIsLoading(false);
        }
    };

    const toggleItemCompletion = async (index) => {
        const newItems = [...items];
        const item = newItems[index];
        item.completed = !item.completed;
        setItems(newItems);

        const headers = { Authorization: `Bearer ${token}` };

        try {
            await axios.patch(`${process.env.REACT_APP_API_URL}/api/check/${bidId}/${item._id}`, {
                completed: item.completed
            }, { headers });
        } catch (error) {
            console.error('Error updating item completion:', error);
            toast({
                title: "Update Failed",
                description: "Failed to update item completion status.",
                status: "error",
                duration: 9000,
                isClosable: true
            });
        }

        fetchData();
    };

    const onDateChange = (date, isEventClick = false) => {
        setCalendarDate(date);
        if (isEventClick) {
            setSelectedDate(date);
        } else {
            setSelectedDate(null);
        }
    };

    const handleSaveEvent = async (eventData) => {
        const headers = {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json'
        };

        // Ensure the dates are Date objects before calling toISOString
        if (!(eventData.startDate instanceof Date && eventData.endDate instanceof Date)) {
            console.error('Event data has invalid date objects.');
            return;
        }

        const formattedEventData = {
            ...eventData,
            startDate: eventData.startDate.toISOString(),
            endDate: eventData.endDate.toISOString(),
        };

        try {
            const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/bids/${bidId}/events`, formattedEventData, { headers });
            fetchData(); // Refresh data

            const eventMessage = {
                conversationId: bid.conversationId, // ensure you have this available from context or props
                message: `New event created: ${eventData.title}`,
                messageType: 'event',
            };

            socket.emit('send-message', eventMessage);

            toast({
                title: "Event Created",
                description: "The event was successfully created.",
                status: "success",
                duration: 9000,
                isClosable: true
            });
        } catch (error) {
            console.error('Error creating event:', error);
            toast({
                title: "Failed to Create Event",
                description: "There was an error creating the event.",
                status: "error",
                duration: 9000,
                isClosable: true
            });
        }
    };

    // Helper function to throttle calls
    function throttle(func, limit) {
        let inThrottle;
        return function () {
            const args = arguments;
            const context = this;
            if (!inThrottle) {
                func.apply(context, args);
                inThrottle = true;
                setTimeout(() => inThrottle = false, limit);
            }
        }
    }

    const safeParseISO = (dateStr) => {
        try {
            if (typeof dateStr === 'string') {
                return new Date(dateStr);
            }
            console.error('Expected a valid ISO string, received:', dateStr);
            return null;
        } catch (error) {
            console.error('Error parsing date:', error);
            return null;
        }
    };

    const ReviewModal = ({ isOpen, onClose, onSave }) => {
        const [publicRating, setPublicRating] = useState(0);
        const [privateRating, setPrivateRating] = useState(0);
        const [publicComment, setPublicComment] = useState('');
        const [privateComment, setPrivateComment] = useState('');
        const [images, setImages] = useState([]);

        const handleImageChange = (e) => {
            if (e.target.files && e.target.files.length > 0) {
                setImages([...e.target.files]);
            }
        };

        const handleSubmit = () => {
            const reviewData = {
                public: {
                    rating: publicRating,
                    comment: publicComment
                },
                private: {
                    rating: privateRating,
                    comment: privateComment
                }
            };
            onSave(reviewData, images);
            onClose();
        };

        return (
            <Modal isOpen={isOpen} onClose={onClose}>
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>Submit a Review</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                        <FormControl isRequired>
                            <FormLabel>Public Review</FormLabel>
                            <Box>
                                {[...Array(5)].map((_, index) => (
                                    <Icon
                                        key={index}
                                        as={FaStar}
                                        color={index < publicRating ? 'yellow.500' : 'gray.300'}
                                        onClick={() => setPublicRating(index + 1)}
                                        cursor="pointer"
                                        boxSize={8}
                                        mr={1}
                                    />
                                ))}
                            </Box>
                            <Textarea placeholder="Public comment" mt={4} value={publicComment} onChange={e => setPublicComment(e.target.value)} />
                            <FormLabel mt={4}>Upload Images</FormLabel>
                            <Input type="file" onChange={handleImageChange} multiple accept="image/*" />
                        </FormControl>
                        <FormControl mt={4} isRequired>
                            <FormLabel>Private Review</FormLabel>
                            <Box>
                                {[...Array(5)].map((_, index) => (
                                    <Icon
                                        key={index}
                                        as={FaStar}
                                        color={index < privateRating ? 'yellow.500' : 'gray.300'}
                                        onClick={() => setPrivateRating(index + 1)}
                                        cursor="pointer"
                                        boxSize={8}
                                        mr={1}
                                    />
                                ))}
                            </Box>
                            <Textarea placeholder="Private comment" mt={4} value={privateComment} onChange={e => setPrivateComment(e.target.value)} />
                        </FormControl>
                    </ModalBody>
                    <ModalFooter>
                        <Button colorScheme="blue" mr={3} onClick={handleSubmit}>
                            Submit Review
                        </Button>
                        <Button onClick={onClose}>Cancel</Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>
        );
    };

    const handleReviewSave = async (reviewData, images) => {
        const formData = new FormData();

        // Check if images are defined and is an array
        if (images && Array.isArray(images)) {
            images.forEach(image => {
                formData.append('images', image);
            });
        }

        // Append other review data as stringified JSON
        formData.append('reviewData', JSON.stringify({
            publicRating: reviewData.public.rating,
            privateRating: reviewData.private.rating,
            publicComment: reviewData.public.comment,
            privateComment: reviewData.private.comment
        }));

        const headers = {
            Authorization: `Bearer ${token}`,
        };

        try {
            const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/reviews/${bidId}`, formData, { headers });
            toast({
                title: "Review Submitted",
                description: "Your review has been successfully submitted.",
                status: "success",
                duration: 9000,
                isClosable: true
            });
        } catch (error) {
            console.error('Error submitting review:', error);
            toast({
                title: "Failed to Submit Review",
                description: "There was an error submitting the review.",
                status: "error",
                duration: 9000,
                isClosable: true
            });
        }
    };

    const CreateEventModal = ({ isOpen, onClose, onSave }) => {
        const [eventDetails, setEventDetails] = useState({
            title: '',
            description: '',
            startDate: '',
            startTime: '',
            endDate: '',
            endTime: '',
            notifyOwner: false,
            notifyContractor: false,
        });


        const handleChange = (e) => {
            setEventDetails({ ...eventDetails, [e.target.name]: e.target.value });
        };

        const handleSubmit = () => {
            if (!eventDetails.startDate || !eventDetails.startTime || !eventDetails.endDate || !eventDetails.endTime) {
                toast({
                    title: "Missing Data",
                    description: "Please fill in all date and time fields before saving the event.",
                    status: "warning",
                    duration: 5000,
                    isClosable: true,
                });
                return;
            }
            // Combine date and time into full ISO string and parse as Date
            const startDateTime = new Date(`${eventDetails.startDate}T${eventDetails.startTime}`);
            const endDateTime = new Date(`${eventDetails.endDate}T${eventDetails.endTime}`);

            if (!eventDetails.startDate || !eventDetails.startTime || !eventDetails.endDate || !eventDetails.endTime || isNaN(startDateTime) || isNaN(endDateTime)) {
                setHasDateError(true);
                toast({
                    title: "Invalid Date",
                    description: "Please ensure the dates and times are correctly filled.",
                    status: "error",
                    duration: 5000,
                    isClosable: true
                });
                return;
            } else {
                setHasDateError(false);
            }

            if (isNaN(startDateTime) || isNaN(endDateTime)) {
                toast({
                    title: "Invalid Date",
                    description: "Please ensure the dates and times are correctly filled.",
                    status: "error",
                    duration: 5000,
                    isClosable: true
                });
                return;
            }

            const eventData = {
                title: eventDetails.title,
                description: eventDetails.description,
                startDate: startDateTime,
                endDate: endDateTime,
                notifyOwner: eventDetails.notifyOwner,
                notifyContractor: eventDetails.notifyContractor,
            };

            onSave(eventData);
            onClose();
        };

        return (
            <Modal isOpen={isOpen} onClose={onClose}>
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>Create a new Event</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                        <FormControl isRequired>
                            <FormLabel>Title</FormLabel>
                            <Input name="title" onChange={handleChange} value={eventDetails.title} />
                        </FormControl>
                        <FormControl isRequired mt={4}>
                            <FormLabel>Description</FormLabel>
                            <Textarea name="description" onChange={handleChange} value={eventDetails.description} />
                        </FormControl>
                        <FormControl isRequired mt={4}>
                            <FormLabel>Start Date</FormLabel>
                            <Input borderColor={hasDateError && !eventDetails.startDate ? "red.500" : "gray.200"} type="date" name="startDate" onChange={handleChange} value={eventDetails.startDate} />
                        </FormControl>
                        <FormControl isRequired mt={4}>
                            <FormLabel>Start Time</FormLabel>
                            <Input borderColor={hasDateError && !eventDetails.startTime ? "red.500" : "inherit"} type="time" name="startTime" onChange={handleChange} value={eventDetails.startTime} />
                        </FormControl>
                        <FormControl isRequired mt={4}>
                            <FormLabel>End Date</FormLabel>
                            <Input borderColor={hasDateError && !eventDetails.endDate ? "red.500" : "inherit"} type="date" name="endDate" onChange={handleChange} value={eventDetails.endDate} />
                        </FormControl>
                        <FormControl isRequired mt={4}>
                            <FormLabel>End Time</FormLabel>
                            <Input borderColor={hasDateError && !eventDetails.endTime ? "red.500" : "inherit"} type="time" name="endTime" onChange={handleChange} value={eventDetails.endTime} />
                        </FormControl>
                        <FormControl mt={4}>
                            <FormLabel>Notify Home Owner?</FormLabel>
                            <Checkbox
                                name="notifyOwner"
                                isChecked={eventDetails.notifyOwner === true}
                                onChange={(e) => setEventDetails({ ...eventDetails, notifyOwner: e.target.checked })}
                            />
                        </FormControl>
                        <FormControl mt={4}>
                            <FormLabel>Notify Contractor Owner?</FormLabel>
                            <Checkbox
                                name="notifyContractor"
                                isChecked={eventDetails.notifyContractor === true}
                                onChange={(e) => setEventDetails({ ...eventDetails, notifyContractor: e.target.checked })}
                            />
                        </FormControl>
                    </ModalBody>
                    <ModalFooter>
                        <Button colorScheme="blue" mr={3} onClick={handleSubmit}>
                            Save
                        </Button>
                        <Button onClick={onClose}>Cancel</Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>
        );
    };

    const handleDeleteEvent = async (eventId, eventTitle) => {
        const headers = {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json'
        };

        try {
            await axios.delete(`${process.env.REACT_APP_API_URL}/api/bids/${bidId}/events/${eventId}`, { headers });
            fetchData(); // Refresh data after successful deletion

            const eventMessage = {
                conversationId: bid.conversationId,
                message: `Event Deleted: ${eventTitle}`,
                messageType: 'deletedEvent',
            };

            socket.emit('send-message', eventMessage);

            toast({
                title: "Event Deleted",
                description: "The event has been deleted successfully.",
                status: "success",
                duration: 5000,
                isClosable: true
            });
        } catch (error) {
            console.error('Error deleting event:', error);
            toast({
                title: "Failed to Delete Event",
                description: "An error occurred while deleting the event.",
                status: "error",
                duration: 5000,
                isClosable: true
            });
        }
    };

    const EventCard = ({ event, isActive, handleEventClick }) => {
        const startDate = new Date(event.startDate);
        const formattedStartDate = format(startDate, "MMMM do, yyyy 'at' h:mm a");
        const isEventToday = isToday(startDate);
        const eventBgColor = isActive ? "#1DADE3" : (isEventToday ? "blue" : "gray.700");

        const onDeleteEvent = () => {
            // Call the handleDeleteEvent from the ActiveProject component
            handleDeleteEvent(event._id, event.title);
        };

        return (
            <Flex
                id={`event-${format(new Date(event.startDate), "yyyy-MM-dd")}`}
                shadow="lg"
                mb={2}
                direction="column"
                w="100%"
                bg={isActive ? "blue" : eventBgColor} // Use conditional styling based on isActive
                p={2}
                borderRadius="lg"
                cursor="pointer"
                onClick={() => handleEventClick(event)} // Handle click to enable highlighting and scrolling
            >
                <Flex mb={2} w={"100%"} justify={"space-between"}>
                    <Text fontWeight={"semibold"} mr={2} color={"white"}>
                        {event.title}
                    </Text>
                    <Text color={"lightgray"}>{formattedStartDate}</Text>
                </Flex>
                <Text mr={2} color={"white"}>
                    {event.description}
                </Text>
                <Flex w={"100%"} justify={"flex-end"}>
                    <Flex mr={4} align={"center"} justify={"flex-end"}>
                        {event.notifyContractor ? (
                            <Text mr={1} color={"white"}>
                                <FontAwesomeIcon color="white" icon={faHammer} />: <FontAwesomeIcon color="white" icon={faBell} />
                            </Text>
                        ) : (
                            <>
                                <Text mr={1} color={"white"}>
                                    <FontAwesomeIcon icon={faHammer} />:
                                </Text>
                                <FontAwesomeIcon color="gray" icon={faBellSlash} />
                            </>
                        )}
                    </Flex>
                    <Flex align={"center"} justify={"flex-end"}>
                        {event.notifyOwner ? (
                            <Text mr={1} color={"white"}>
                                <FontAwesomeIcon color="white" icon={faHouseChimney} />: <FontAwesomeIcon color="white" icon={faBell} />
                            </Text>
                        ) : (
                            <>
                                <Text mr={1} color={"white"}>
                                    <FontAwesomeIcon icon={faHouseChimney} />:
                                </Text>
                                <FontAwesomeIcon color="gray" icon={faBellSlash} />
                            </>
                        )}
                    </Flex>
                    <Button ml={2} colorScheme="red" size="xs" onClick={() => onDeleteEvent(event)}>
                        Delete
                    </Button>
                </Flex>
            </Flex>
        );
    };

    // Helper function to group events by date
    const groupEventsByDate = (events) => {
        return events.reduce((acc, event) => {
            const date = format(new Date(event.startDate), "MMMM d, yyyy");
            if (!acc[date]) {
                acc[date] = [];
            }
            acc[date].push(event);
            return acc;
        }, {});
    };

    const toggleStageStatus = async (bidId, stageId, statusType) => {
        const headers = {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json'
        };

        const url = `${process.env.REACT_APP_API_URL}/api/toggle-stage/${bidId}/${stageId}`;
        const update = {
            isStarted: statusType === 'isStarted' ? !bid.customStages.find(stage => stage._id === stageId).isStarted : bid.customStages.find(stage => stage._id === stageId).isStarted,
            isCompleted: statusType === 'isCompleted' ? !bid.customStages.find(stage => stage._id === stageId).isCompleted : bid.customStages.find(stage => stage._id === stageId).isCompleted
        };

        try {
            const response = await axios.patch(url, update, { headers });
            fetchData(); // Re-fetch the updated data
        } catch (error) {
            console.error("Error updating stage status:", error);
            toast({
                title: "Update Failed",
                description: "Failed to update stage status.",
                status: "error",
                duration: 9000,
                isClosable: true
            });
        }
    };

    const { colorMode } = useColorMode();
    const bgColor = { light: 'white', dark: '' };
    const color = { light: 'gray.600', dark: 'whiteAlpha.900' };

    const formattedEvents = events.map(event => ({
        id: event._id,
        title: event.title,
        start: new Date(event.startDate),  // Ensure dates are parsed to Date objects
        end: new Date(event.endDate),
        extendedProps: {
            notifyOwner: event.notifyOwner,
            notifyContractor: event.notifyContractor
        }
    }));

    const handleEventClick = (clickInfo) => {
        // FullCalendar gives us a clickInfo object, which contains an event property
        const event = clickInfo.event;

        if (event && event.start) {
            const startDate = new Date(event.start); // This should be a Date object, but let's instantiate to be sure
            setSelectedDate(startDate);
            scrollToEvent(startDate);
        } else {
            console.error("The clicked event does not have a valid start date:", event);
        }
    };

    function formatPhoneNumber(phoneNumber) {
        const cleaned = ('' + phoneNumber).replace(/\D/g, '');
        const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
        if (match) {
            return `(${match[1]}) ${match[2]}-${match[3]}`;
        }
        return null;
    }

    const handleDateClick = (dateInfo) => {
        const clickedDate = startOfDay(new Date(dateInfo.date));
        setSelectedDate(clickedDate);
        scrollToEvent(clickedDate);
    };

    const handleContractorApproval = async (bidId) => {
        const headers = {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json'
        };

        const url = `${process.env.REACT_APP_API_URL}/api/contractor-approval/${bidId}`;

        try {
            const response = await axios.patch(url, {}, { headers });
            fetchData();

            const eventMessage = {
                conversationId: bid.conversationId,
                message: `Contractor Marked Job as Complete`,
                messageType: 'contractorApproved',
            };

            socket.emit('send-message', eventMessage);

        } catch (error) {
            console.error("Error updating stage status:", error);
            toast({
                title: "Update Failed",
                description: "Failed to update stage status.",
                status: "error",
                duration: 9000,
                isClosable: true
            });
        }
    };

    const handleHomeownerApproval = async (bidId) => {
        const headers = {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json'
        };

        const url = `${process.env.REACT_APP_API_URL}/api/homeowner-approval/${bidId}`;

        try {
            const response = await axios.patch(url, {}, { headers });
            fetchData();

            setReviewModalOpen(true);

            const eventMessage = {
                conversationId: bid.conversationId,
                message: `Owner Marked Job as Complete`,
                messageType: 'homeownerApproved',
            };

            socket.emit('send-message', eventMessage);

        } catch (error) {
            console.error("Error updating stage status:", error);
            toast({
                title: "Update Failed",
                description: "Failed to update stage status.",
                status: "error",
                duration: 9000,
                isClosable: true
            });
        }
    };

    const scrollToEvent = (date) => {
        if (!(date instanceof Date && !isNaN(date))) {
            console.error("Invalid date provided:", date);
            return;
        }
        const startOfClickedDate = startOfDay(date);
        const formattedDate = format(startOfClickedDate, "yyyy-MM-dd");
        const eventElement = document.querySelector(`#event-${formattedDate}`);
        if (eventElement) {
            eventElement.scrollIntoView({ behavior: 'smooth', block: 'start' });
        } else {
            console.error("No element found for date:", formattedDate);
        }
    };

    const safeFormatDate = (dateString, formatString) => {
        if (!dateString) return 'N/A';
        const date = parseISO(dateString);
        return format(date, formatString);
    };

    console.log('bid.contractorApproval.approved', bid?.contractorApproval?.approved);

    return (
        <Flex direction={"column"} w={"100%"} justify={"center"}>
            <Flex direction={{ base: "column", lg: "row" }} p={2} w="100%" align="flex-start" justify="flex-start">
                <ContractorSideMenu />
                <Flex m={{ base: 0, lg: 4 }} direction="column" w="100%" justify="center">
                    <Header />
                    {menuType === 'drawer' ? (
                        <>
                            <Flex mt={1} direction={"column"} w={"100"}>
                                <Flex direction={"column"} justify={"space-between"} w={"100"}>
                                    <Flex justify={"center"} w={"100%"}>
                                        <Flex w={"100%"}>
                                            <Flex maxH={"100vh"} overflowY={"scroll"} border={"1px"} borderColor={"lightgrey"} shadow={"md"} borderRadius={"md"} direction={"column"} p={2} w={"100%"}>
                                                <Flex direction={"column"} w={"100%"} justify={'space-between'}>
                                                    <Flex direction={"column"} w={"100%"} justify={"space-between"}>
                                                        <>
                                                            {bid.contractorLogo ? (
                                                                <Flex w={"100%"} justify={"space-between"}>
                                                                    <Image src={bid.contractorLogo} maxW={"50%"} h={"auto"} />
                                                                    <Flex direction={"column"} align={"flex-end"}>
                                                                        <Heading size={"sm"}>
                                                                            Proposal
                                                                        </Heading>
                                                                        <Text fontSize={"sm"} textAlign="right">Proposal ID # {bid.estimateId}</Text>
                                                                    </Flex>
                                                                </Flex>
                                                            ) : (
                                                                <Flex w={"100%"} justify={"space-between"}>
                                                                    <Image src={Logo} maxH={"40px"} />
                                                                    <Flex direction={"column"} align={"flex-end"}>
                                                                        <Heading size={"sm"}>
                                                                            Proposal
                                                                        </Heading>
                                                                        <Text fontSize={"sm"} textAlign="right">Proposal ID # {bid.estimateId}</Text>
                                                                    </Flex>
                                                                </Flex>
                                                            )}
                                                        </>
                                                        <Flex align={"flex-end"} justify={"flex-end"} direction={"column"}>
                                                            {bid.status === "open" ? (
                                                                <Badge mr={1} colorScheme='blue'>Open</Badge>
                                                            ) : bid.status === "in progress" ? (
                                                                <Badge mr={1} colorScheme='red'>In Progress</Badge>
                                                            ) : bid.status === "completed" ? (
                                                                <Badge mr={1} colorScheme='green'>Completed</Badge>
                                                            ) : null}
                                                        </Flex>
                                                    </Flex>
                                                </Flex>
                                                <Flex mb={4} w={"100%"} justify={'space-between'}>
                                                    <Flex w={"50%"} direction={"column"}>
                                                        <Flex direction={"column"} align={"flex-start"}>
                                                            {bid.contractorBusiness && (
                                                                bid.isVetted ? (
                                                                    <Flex mr={2} align="center">
                                                                        <FontAwesomeIcon size='xs' color='green' icon={faCircleCheck} />
                                                                        <Text fontSize={"sm"} ml={1} mt={1} fontWeight="semibold">
                                                                            {bid.contractorBusiness}
                                                                        </Text>
                                                                    </Flex>

                                                                ) : (

                                                                    <Flex mr={2} align="center">
                                                                        <FontAwesomeIcon size='xs' color='lightgray' icon={faCircleCheck} />
                                                                        <Text fontSize={"sm"} ml={1} mt={1} fontWeight="semibold">
                                                                            {bid.contractorBusiness}
                                                                        </Text>
                                                                    </Flex>

                                                                )
                                                            )}
                                                            <Flex justify={"flex-start"}>
                                                                {bid.licenses && bid.licenses.length > 0 && (
                                                                    <Flex mr={2}>
                                                                        <FontAwesomeIcon color='green' icon={faIdCard} />
                                                                    </Flex>
                                                                )}
                                                                {bid.contractorInsurance && bid.contractorInsurance.length > 0 && (
                                                                    <Flex mr={2}>
                                                                        <FontAwesomeIcon color='green' icon={faUserShield} />
                                                                    </Flex>
                                                                )}
                                                                {bid.contractorBonds && (
                                                                    <Flex>
                                                                        <FontAwesomeIcon color='green' icon={faBuildingColumns} />
                                                                    </Flex>
                                                                )}
                                                            </Flex>
                                                        </Flex>
                                                        <Text fontSize={"sm"} fontWeight={"semibold"}>
                                                            {bid.contractorFirstName} {bid.contractorLastName}
                                                        </Text>

                                                        <Text fontSize={"sm"}>
                                                            {bid?.contractorAddress?.street}
                                                        </Text>
                                                        <Text fontSize={"sm"}>
                                                            {bid?.contractorAddress?.city}, {bid?.contractorAddress?.state} {bid?.contractorAddress?.zipCode}
                                                        </Text>

                                                    </Flex>
                                                    <Flex mt={2} w={"50%"} direction={"column"} align={"flex-end"} justify={"flex-start"}>
                                                        <Text fontSize={"sm"} fontWeight="bold">Proposed Dates</Text>
                                                        <Text fontSize={"sm"}>
                                                            {safeFormatDate(bid.startDate, 'MMMM dd, yyyy')}
                                                        </Text>
                                                        <Text fontSize={"sm"}>
                                                            {safeFormatDate(bid.endDate, 'MMMM dd, yyyy')}
                                                        </Text>
                                                        <Text mt={2} fontSize={"sm"}>
                                                            Expiration Date
                                                        </Text>
                                                        <Text fontSize={"sm"}>
                                                            {safeFormatDate(bid.expirationDate, 'MMMM dd, yyyy')}
                                                        </Text>
                                                    </Flex>
                                                </Flex>
                                                <Flex direction={"column"}>
                                                    <Text fontSize={"sm"} fontWeight={"bold"}>{bid.title}</Text>
                                                    <Text fontSize={"sm"}>{bid.description}</Text>
                                                </Flex>
                                                <Flex mt={4} w={"100%"} bg={"gray.700"} borderRadius={"md"} >
                                                    <Flex w={"100%"} justify={"space-between"}>
                                                        <Flex w={"30%"}>
                                                            <Text fontSize={"sm"} ml={1} color={"white"}>Item</Text>
                                                        </Flex>
                                                        <Text fontSize={"sm"} color={"white"}>Qty</Text>
                                                        <Text fontSize={"sm"} color={"white"}>Rate</Text>
                                                        <Text fontSize={"sm"} mr={1} color={"white"}>Amount</Text>
                                                    </Flex>
                                                </Flex>
                                                {bid?.items?.map((item, index) => (
                                                    <>
                                                        <Flex key={index} w={"100%"} borderRadius={"md"} p={1} alignItems="start">
                                                            <Flex w={"100%"} justify={"space-between"}>
                                                                <Flex w={"30%"} direction={"column"} >
                                                                    <Text fontSize={"sm"} fontWeight={"semibold"}>{item.name}</Text>
                                                                    <Text fontSize={"xs"} mt={-1} fontWeight={"thin"}>{item.description}</Text>
                                                                </Flex>
                                                                {!item.hideDetails && <Text>{item.quantity}</Text>}
                                                                {!item.hideDetails && <Text fontSize={"sm"}>{parseFloat(item.rate).toFixed(2)}</Text>}
                                                                {!item.hideDetails && <Text fontSize={"sm"}>{typeof item.amount === 'number' ? item.amount.toFixed(2) : '0.00'}</Text>}
                                                            </Flex>
                                                        </Flex>
                                                    </>
                                                ))}
                                                <Flex mt={4} justify={"flex-end"} align={"flex-end"} w={"100%"}>
                                                    <Flex direction={"column"} align={"flex-end"} mr={1}>
                                                        <Text >Subtotal: ${bid.subTotalAmount}</Text>
                                                        {bid.discountAmount > 0 && (
                                                            <Text >Discount ({bid.discount}%): -${bid.discountAmount}</Text>
                                                        )}
                                                        <Text >Service Charge: ${bid.serviceCharge}</Text>
                                                    </Flex>
                                                </Flex>

                                                <Flex justify={"flex-end"} align={"flex-end"} w={"100%"} bg={"gray.700"} borderRadius={"md"} >
                                                    <Flex p={2} direction={"column"} mr={2} w={"100%"} justify={"flex-end"} align={"flex-end"}>
                                                        <Text color={"white"}>Total (Including Taxes): ${bid?.totalAmount}</Text>
                                                    </Flex>
                                                </Flex>
                                                <Flex direction="column" w="100%">
                                                    {bid.paymentTerms === "Stages" ? (
                                                        <Flex w={"100%"} justify={"flex-end"}>
                                                            <Box
                                                                w="fit-content"
                                                                mt={4}
                                                                borderRadius="lg"
                                                                border="1px"
                                                                p={4}
                                                                boxShadow="md"
                                                                direction="column"
                                                            >
                                                                <Heading size="md" mb={4}>
                                                                    Payment Terms:
                                                                </Heading>
                                                                {bid.customStages?.map((stage, index) => (
                                                                    <Flex key={stage.id} align="center" justify={"space-between"} mb={2}>
                                                                        <Flex align={"center"} mr={2}>
                                                                            <FontAwesomeIcon size='xs' icon={faCircleDot} />
                                                                            <Text ml={2}>{stage.description}</Text>
                                                                        </Flex>
                                                                        <Flex>
                                                                            <Box
                                                                                p={2}
                                                                                borderRadius="md"
                                                                                bg={stage.upfrontAmount > 0 ? "green.100" : "gray.100"}
                                                                                mr={2}
                                                                            >
                                                                                <Text color={stage.upfrontAmount > 0 ? "green.600" : "gray.600"}>
                                                                                    Due at Start: ${stage.upfrontAmount}
                                                                                </Text>
                                                                            </Box>

                                                                            <Box
                                                                                p={2}
                                                                                borderRadius="md"
                                                                                bg={stage.completionAmount > 0 ? "blue.100" : "gray.100"}
                                                                            >
                                                                                <Text color={stage.completionAmount > 0 ? "blue.600" : "gray.600"}>
                                                                                    Due at End: ${stage.completionAmount}
                                                                                </Text>
                                                                            </Box>
                                                                        </Flex>
                                                                    </Flex>
                                                                )) || <Text>No stages defined</Text>}
                                                            </Box>
                                                        </Flex>
                                                    ) : bid.paymentTerms === "1/2" ? (
                                                        <>
                                                            {bid.upfrontAmount && (
                                                                <Flex w={"100%"} justify={"flex-end"}>
                                                                    <Flex w={"fit-content"} mt={4} borderRadius={"lg"} direction={"column"} p={2} border={"1px"}>
                                                                        <Heading size={"sm"}>Payment Terms:</Heading>
                                                                        <Text>${bid.upfrontAmount.amount} before work starts. ${bid.upfrontAmount.amount} when completed</Text>
                                                                        <Text>{bid.upfrontAmount.description}</Text>
                                                                    </Flex>
                                                                </Flex>
                                                            )}
                                                        </>
                                                    ) : bid.paymentTerms === "UpFront" ? (
                                                        <>
                                                            {bid.upfrontAmount && (
                                                                <Flex w={"100%"} justify={"flex-end"}>
                                                                    <Flex w={"fit-content"} mt={4} borderRadius={"lg"} direction={"column"} p={2} border={"1px"}>
                                                                        <Heading size={"sm"}>Payment Terms:</Heading>
                                                                        <Text>${bid.upfrontAmount.amount} before work starts.</Text>
                                                                        <Text>{bid.upfrontAmount.description}</Text>
                                                                    </Flex>
                                                                </Flex>
                                                            )}
                                                        </>
                                                    ) : bid.paymentTerms === "Completed" ? (
                                                        <>
                                                            {bid.upfrontAmount && (
                                                                <Flex w={"100%"} justify={"flex-end"}>
                                                                    <Flex w={"fit-content"} mt={4} borderRadius={"lg"} direction={"column"} p={2} border={"1px"}>
                                                                        <Heading size={"sm"}>Payment Terms:</Heading>
                                                                        <Text>${bid.totalAmount} when completed</Text>
                                                                        <Text>{bid.upfrontAmount.description}</Text>
                                                                    </Flex>
                                                                </Flex>
                                                            )}
                                                        </>
                                                    ) : (
                                                        null
                                                    )}
                                                </Flex>

                                                {bid.notes && (
                                                    <Flex mt={4} borderRadius={"lg"} direction={"column"} p={2} bg={"gray.700"}>
                                                        <Heading color={"white"} size={"sm"}>Notes:</Heading>
                                                        <Text color={"white"}>
                                                            {bid.notes}
                                                        </Text>
                                                    </Flex>
                                                )}

                                                <Accordion mt={4} allowToggle>
                                                    {bid.contractorInsurance && bid.contractorInsurance.length > 0 && (
                                                        <AccordionItem>
                                                            <h2>
                                                                <AccordionButton>
                                                                    <Box as='span' flex='1' textAlign='left'>
                                                                        Insurance
                                                                    </Box>
                                                                    <AccordionIcon />
                                                                </AccordionButton>
                                                            </h2>
                                                            <AccordionPanel pb={4}>
                                                                {bid.contractorInsurance?.map((insurance, index) => (
                                                                    <Box w="fit-content" mt={2} key={index} p={2} shadow="md" borderWidth="1px">
                                                                        <Text fontWeight="semibold">{insurance.provider}</Text>
                                                                        <Text>Policy Number: {insurance.policyNumber}</Text>
                                                                        <Text>Coverage Amount: ${new Intl.NumberFormat('en-US', { style: 'decimal', minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(insurance.coverageAmount)}</Text>
                                                                        <Text>Expiry Date: {new Date(insurance.expiryDate).toLocaleDateString()}</Text>
                                                                        <Text>Type: {insurance.type}</Text>
                                                                    </Box>
                                                                ))}
                                                            </AccordionPanel>
                                                        </AccordionItem>
                                                    )}

                                                    {bid.contractorBonds && (
                                                        <AccordionItem>
                                                            <h2>
                                                                <AccordionButton>
                                                                    <Box as='span' flex='1' textAlign='left'>
                                                                        Bonds
                                                                    </Box>
                                                                    <AccordionIcon />
                                                                </AccordionButton>
                                                            </h2>
                                                            <AccordionPanel pb={4}>
                                                                {bid.contractorBonds && (
                                                                    <Box w="fit-content" mt={2} p={2} shadow="md" borderWidth="1px">
                                                                        <Text fontWeight="semibold">{bid.contractorBonds.bondingCompany}</Text>
                                                                        <Text>Bond Number: {bid.contractorBonds.bondNumber}</Text>
                                                                        <Text>Bond Amount: ${new Intl.NumberFormat('en-US', { style: 'decimal', minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(bid.contractorBonds.amount)}</Text>
                                                                        <Text>Expiry Date: {new Date(bid.contractorBonds.expiryDate).toLocaleDateString()}</Text>
                                                                    </Box>
                                                                )}
                                                            </AccordionPanel>
                                                        </AccordionItem>
                                                    )}

                                                    {bid.licenses && bid.licenses.length > 0 && (
                                                        <AccordionItem>
                                                            <h2>
                                                                <AccordionButton>
                                                                    <Box as='span' flex='1' textAlign='left'>
                                                                        Licenses
                                                                    </Box>
                                                                    <AccordionIcon />
                                                                </AccordionButton>
                                                            </h2>
                                                            <AccordionPanel pb={4}>
                                                                {bid.licenses?.map((license, index) => (
                                                                    <Box w="fit-content" mt={2} key={index} p={2} shadow="md" borderWidth="1px">
                                                                        <Text fontWeight="semibold">{license.type}</Text>
                                                                        <Text>Number: {license.number}</Text>
                                                                        <Text>Expiry Date: {new Date(license.expiryDate).toLocaleDateString()}</Text>
                                                                    </Box>
                                                                ))}
                                                            </AccordionPanel>
                                                        </AccordionItem>
                                                    )}
                                                </Accordion>

                                                <Flex direction="column" w="100%">
                                                    <Flex direction={"column"} p={2} w={"100%"} justify={"space-between"} align={"flex-start"}>
                                                        <Flex direction={"column"} w={"full"} justify={"flex-start"}>
                                                            <Text mt={2}>Contractor Signature</Text>
                                                            {bid.contractorSignature && (
                                                                <>
                                                                    <Image
                                                                        src={bid.contractorSignature}
                                                                        alt="Contractor Signature"
                                                                        objectFit="contain"
                                                                        maxW="400px"
                                                                        h="auto"
                                                                    />
                                                                    {bid.contractorSignatureDate && (
                                                                        <Text mt={2}>Date: {format(parseISO(bid.contractorSignatureDate), 'MMMM dd, yyyy')}</Text>
                                                                    )}
                                                                </>
                                                            )}
                                                        </Flex>
                                                        <Flex direction={"column"} w={"full"} justify={"flex-start"}>
                                                            <Text mt={2}>Home Owner Signature</Text>
                                                            {bid.ownerSignature && (
                                                                <>
                                                                    <Image
                                                                        src={bid.ownerSignature}
                                                                        alt="Home Owner Signature"
                                                                        objectFit="contain"
                                                                        maxW="400px"
                                                                        h="auto"
                                                                    />
                                                                    <Text mt={2}>Date: {format(parseISO(bid.ownerSignatureDate), 'MMMM dd, yyyy')}</Text>
                                                                </>
                                                            )}
                                                        </Flex>
                                                    </Flex>
                                                </Flex>
                                            </Flex>
                                        </Flex>
                                    </Flex>
                                    <Flex
                                        minH="100vh" // Ensure the Flex container takes up at least the full height of the viewport
                                        p={0}
                                        direction="column"
                                        w="full" // Use the full width of the viewport
                                        align="stretch" // Stretch the children to match the width of the container
                                        justify="flex-start"
                                    >
                                        <CreateEventModal
                                            isOpen={isOpen}
                                            onClose={onClose}
                                            onSave={handleSaveEvent}
                                        />
                                        <FinanceDetails
                                            transferGroupId={transferGroupId}
                                            project={project}
                                            bid={bid}
                                            destinationAccountId={contractor.destinationAccountId}
                                            contractor={contractor}
                                        />
                                        <Box
                                            w={"100%"}
                                            mt={4}
                                        >
                                            <Box
                                                shadow="lg"
                                                w={"100%"}>
                                                <Flex
                                                    direction={'column'}
                                                    spacing={4}
                                                    align="stretch"
                                                    bg={bgColor[colorMode]}
                                                    color={color[colorMode]}
                                                    w="100%"
                                                >
                                                    <Box zIndex={0} w={'100%'}>
                                                        <FullCalendar
                                                            plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
                                                            initialView="dayGridMonth"
                                                            headerToolbar={{
                                                                left: 'prev,next today',
                                                                //     center: 'title',
                                                                right: 'dayGridMonth,timeGridWeek,timeGridDay'
                                                            }}
                                                            weekends={true}
                                                            eventClick={handleEventClick}
                                                            events={formattedEvents}
                                                            dateClick={handleDateClick}
                                                            height={"550px"}

                                                        />

                                                    </Box>
                                                    <Box mt={4} maxH="500px" w={"100%"} overflowY="auto">
                                                        {Object.entries(groupEventsByDate(events)).map(([date, eventsOnDate]) => (
                                                            <VStack p={1} key={date} spacing={2} align="stretch">
                                                                <Text fontWeight="bold">{date}</Text>
                                                                {eventsOnDate.map(event => (
                                                                    <EventCard
                                                                        key={event.id}
                                                                        event={event}
                                                                        isActive={selectedDate && isSameDay(new Date(event.startDate), selectedDate)}
                                                                        handleEventClick={() => handleEventClick(event)}
                                                                    />
                                                                ))}
                                                            </VStack>
                                                        ))}
                                                    </Box>
                                                </Flex>
                                                <Flex justify="flex-end">
                                                    <Button mb={4} mr={4} bg="#1DADE3" _hover={"blue"} color={"white"} size="sm" onClick={onOpen}>
                                                        Add Event
                                                    </Button>
                                                </Flex>
                                            </Box>
                                        </Box>
                                        <Flex direction={"column"} w={"100%"} justify={"flex-start"}>
                                            <MessageComponent bid={bid} conversationId={conversationId} projectId={projectId} socket={socket} />
                                        </Flex>
                                    </Flex>
                                </Flex>
                            </Flex>
                        </>
                    ) : (
                        <>
                            <Flex direction={"column"} w={"100"}>
                                <Flex mt={4} w={"100%"} align={"center"} justify={"flex-end"}>
                                    {role === "Contractor" && (
                                        <>
                                            {bid?.contractorApproval?.approved ? (
                                                <Button mr={2}>
                                                    <FontAwesomeIcon color='#1DADE3' icon={faCheck} />
                                                    <Text mr={2} ml={2}>Approved</Text>
                                                </Button>
                                            ) : (
                                                <Button mr={2} onClick={() => handleContractorApproval(bidId)}>
                                                    <FontAwesomeIcon icon={faHammer} />
                                                    <Text ml={2}>Contractor Approval</Text>
                                                </Button>
                                            )}

                                        </>
                                    )}
                                    {role === "Home Owner" && (
                                        <>
                                            {bid?.contractorApproval?.approved && (
                                                <>
                                                    {bid?.homeownerApproval?.approved ? (
                                                        <Button mr={2}>
                                                            <FontAwesomeIcon color='#1DADE3' icon={faCheck} />
                                                            <Text mr={2} ml={2}>Approved</Text>
                                                        </Button>
                                                    ) : (
                                                        <Button mr={4} ml={2} onClick={() => handleHomeownerApproval(bidId)}>
                                                            <FontAwesomeIcon icon={faHouseChimney} />
                                                            <Text ml={2}>Homeowner Approval</Text>
                                                        </Button>
                                                    )}
                                                </>
                                            )}
                                            <ReviewModal isOpen={isReviewModalOpen} onClose={() => setReviewModalOpen(false)} onSave={handleReviewSave} />
                                        </>
                                    )}
                                </Flex>
                                <Flex direction={{ base: 'column', lg: 'row' }} w={"100%"} justify={"center"}>
                                    <Flex p={4} direction={"column"} w={{ base: '100%', lg: "40%" }} justify={"flex-start"} align={"flex-start"}>
                                        <Flex direction={"column"} w={"100%"}>
                                            <Flex mt={4} direction={"column"} shadow={"md"} p={4} border={"1px"} borderRadius={"lg"} borderColor={"lightgray"} w={"100%"} justify={"space-between"} align={"flex-start"}>
                                                <Flex mb={2} w={"100%"} justify={'space-between'}>
                                                    {bid.contractorLogo ? (
                                                        <Image src={bid.contractorLogo} w={"auto"} maxH={"100px"} />
                                                    ) : (
                                                        <Flex direction={"column"} justify={"center"} align={"center"}>
                                                            <Image mr={2} src={Logo} maxH={"70px"} />
                                                            <Heading size={"md"}>
                                                                Utah Remodels
                                                            </Heading>
                                                        </Flex>
                                                    )}
                                                    <Flex direction={"column"} justify={"flex-start"} align={"flex-end"}>
                                                        <Heading>
                                                            Proposal
                                                        </Heading>
                                                        <Text>Proposal ID # {bid.estimateId}</Text>
                                                        <Flex>

                                                            {bid.status === "open" ? (
                                                                <Badge mr={1} colorScheme='blue'>Open</Badge>
                                                            ) : bid.status === "in progress" ? (
                                                                <Badge mr={1} colorScheme='red'>In Progress</Badge>
                                                            ) : bid.status === "completed" ? (
                                                                <Badge mr={1} colorScheme='green'>Completed</Badge>
                                                            ) : null}

                                                        </Flex>
                                                    </Flex>
                                                </Flex>
                                                <Flex mb={4} w={"100%"} justify={'space-between'}>
                                                    <Flex w={{ base: '100%', lg: "50%" }} direction={"column"}>
                                                        <Flex align={"center"}>
                                                            {bid.contractorBusiness && (
                                                                bid.isVetted ? (
                                                                    <Flex mr={2} align="center">
                                                                        <FontAwesomeIcon size='xs' color='#1DADE3' icon={faCircleCheck} />
                                                                        <Text ml={1} mt={1} fontWeight="semibold">
                                                                            {bid.contractorBusiness}
                                                                        </Text>
                                                                    </Flex>
                                                                ) : (
                                                                    <Flex mr={2} align="center">
                                                                        <FontAwesomeIcon size='xs' color='lightgray' icon={faCircleCheck} />
                                                                        <Text ml={1} mt={1} fontWeight="semibold">
                                                                            {bid.contractorBusiness}
                                                                        </Text>
                                                                    </Flex>
                                                                )
                                                            )}
                                                            {bid.licenses && bid.licenses.length > 0 && (
                                                                <Flex mr={2}>
                                                                    <FontAwesomeIcon color='#1DADE3' icon={faIdCard} />
                                                                </Flex>
                                                            )}
                                                            {bid.contractorInsurance && bid.contractorInsurance.length > 0 && (
                                                                <Flex mr={2}>
                                                                    <FontAwesomeIcon color='#1DADE3' icon={faUserShield} />
                                                                </Flex>
                                                            )}
                                                            {bid.contractorBonds && (
                                                                <Flex>
                                                                    <FontAwesomeIcon color='#1DADE3' icon={faBuildingColumns} />
                                                                </Flex>
                                                            )}
                                                        </Flex>
                                                        <Text fontWeight={"semibold"}>
                                                            {bid.contractorFirstName} {bid.contractorLastName}
                                                        </Text>

                                                        <Text>
                                                            {bid.contractorAddress?.street}
                                                        </Text>
                                                        <Text>
                                                            {bid.contractorAddress?.city}, {bid.contractorAddress?.state} {bid.contractorAddress?.zipCode}
                                                        </Text>

                                                    </Flex>
                                                    <Flex direction={"column"} align={"flex-end"} justify={"flex-start"}>
                                                        <Text fontWeight={"semibold"}>
                                                            {bid.ownerFirstName} {bid.ownerLastName}
                                                        </Text>
                                                        <Text>{formatPhoneNumber(bid?.ownerPhone)}</Text>
                                                        <Text>
                                                            {project?.address?.street}
                                                        </Text>
                                                        <Text>
                                                            {project?.address?.city}, {project?.address?.state} {project?.address?.zipCode}
                                                        </Text>
                                                        <Text fontWeight="bold">Proposed Dates:</Text>
                                                        <Text>
                                                            {safeFormatDate(bid.startDate, 'MMMM dd, yyyy')} - {safeFormatDate(bid.endDate, 'MMMM dd, yyyy')}
                                                        </Text>
                                                        <Text>
                                                            Expiration Date: {safeFormatDate(bid.expirationDate, 'MMMM dd, yyyy')}
                                                        </Text>
                                                    </Flex>
                                                </Flex>
                                                <Flex direction={"column"}>
                                                    <Text fontSize={"lg"} fontWeight={"bold"}>{bid.title}</Text>
                                                    <Text>{bid.description}</Text>
                                                </Flex>
                                                <Flex mt={4} w={"100%"} bg={"gray.700"} borderRadius={"md"}>
                                                    <Flex mr={4} w={"50%"} justify={"space-between"}>
                                                        <Text ml={6} color={"white"}>Item</Text>
                                                        <Text mr={2} color={"white"}>Qty</Text>
                                                    </Flex>
                                                    <Flex ml={4} w={"50%"} justify={"space-between"}>
                                                        <Text ml={6} color={"white"}>Rate</Text>
                                                        <Text ml={4} color={"white"}>Tax (%)</Text>
                                                        <Text mr={4} color={"white"}>Amount</Text>
                                                    </Flex>
                                                </Flex>
                                                {items.length > 0 && items?.map((item, index) => (
                                                    <React.Fragment key={index}>
                                                        <Flex mt={2} w={"100%"} borderRadius={"md"} p={2} alignItems="center">
                                                            <Checkbox isChecked={item.completed} onChange={() => toggleItemCompletion(index)} mr={2} />
                                                            <Flex key={index} w={"100%"} borderRadius={"md"} p={2} alignItems="start">
                                                                <Flex mr={4} w={"50%"} justify={"space-between"}>
                                                                    <Flex direction={"column"} ml={4}>
                                                                        <Text fontWeight={"semibold"}>{item.name}</Text>
                                                                        <Text mt={-1} fontWeight={"thin"}>{item.description}</Text>
                                                                    </Flex>
                                                                    {!item.hideDetails && <Text mr={6}>{item.quantity}</Text>}
                                                                </Flex>
                                                                <Flex ml={4} w={"50%"} justify={"space-between"} alignItems="start">
                                                                    {!item.hideDetails && <Text ml={4}>{parseFloat(item.rate).toFixed(2)}</Text>}
                                                                    {!item.hideDetails && <Text>{parseFloat(item.tax).toFixed(2)}</Text>}
                                                                    {!item.hideDetails && <Text mr={2}>{typeof item.amount === 'number' ? item.amount.toFixed(2) : '0.00'}</Text>}
                                                                </Flex>
                                                            </Flex>
                                                        </Flex>
                                                    </React.Fragment>
                                                ))}
                                                <Flex mt={4} justify={"flex-end"} align={"flex-end"} w={"100%"}>
                                                    <Flex direction={"column"} align={"flex-end"} mr={4}>
                                                        <Text mb={2}>Subtotal: ${bid.subTotalAmount}</Text>
                                                        {bid.discountAmount > 0 && (
                                                            <Text mb={2}>Discount ({bid.discount}%): -${bid.discountAmount}</Text>
                                                        )}
                                                        <Text mb={2}>Service Charge: ${bid.serviceCharge}</Text>
                                                    </Flex>
                                                </Flex>

                                                <Flex justify={"flex-end"} align={"flex-end"} w={"100%"} bg={"gray.700"} borderRadius={"md"} >
                                                    <Flex p={2} direction={"column"} mr={2} w={"100%"} justify={"flex-end"} align={"flex-end"}>
                                                        <Text color={"white"}>Total (Including Taxes): ${bid.totalAmount}</Text>
                                                    </Flex>
                                                </Flex>

                                                <Flex direction="column" w="100%">
                                                    {bid.paymentTerms === "Stages" ? (
                                                        <Flex w={"100%"} justify={"flex-end"}>
                                                            <Box
                                                                w="fit-content"
                                                                mt={4}
                                                                borderRadius="lg"
                                                                border="1px"
                                                                p={4}
                                                                boxShadow="md"
                                                                direction="column"
                                                            >
                                                                <Heading size="md" mb={4}>
                                                                    Payment Terms:
                                                                </Heading>
                                                                {bid.customStages?.map((stage, index) => (
                                                                    <Flex key={stage._id} align="center" justify="space-between" mb={2}>
                                                                        <Checkbox
                                                                            isChecked={stage.isStarted}
                                                                            onChange={() => toggleStageStatus(bid._id, stage._id, 'isStarted')}
                                                                            mr={2}
                                                                        >
                                                                            Start
                                                                        </Checkbox>
                                                                        <Checkbox
                                                                            isChecked={stage.isCompleted}
                                                                            onChange={() => toggleStageStatus(bid._id, stage._id, 'isCompleted')}
                                                                            mr={2}
                                                                        >
                                                                            Complete
                                                                        </Checkbox>
                                                                        <Text ml={2}>{stage.description}</Text>
                                                                        <Flex>
                                                                            <Box
                                                                                p={2}
                                                                                borderRadius="md"
                                                                                bg={stage.upfrontAmount > 0 ? "green.100" : "gray.100"}
                                                                                mr={2}
                                                                            >
                                                                                <Text color={stage.upfrontAmount > 0 ? "green.600" : "gray.600"}>
                                                                                    Due at Start: ${stage.upfrontAmount}
                                                                                </Text>
                                                                            </Box>

                                                                            <Box
                                                                                p={2}
                                                                                borderRadius="md"
                                                                                bg={stage.completionAmount > 0 ? "blue.100" : "gray.100"}
                                                                            >
                                                                                <Text color={stage.completionAmount > 0 ? "blue.600" : "gray.600"}>
                                                                                    Due at End: ${stage.completionAmount}
                                                                                </Text>
                                                                            </Box>
                                                                        </Flex>
                                                                    </Flex>
                                                                )) || <Text>No stages defined</Text>}
                                                            </Box>
                                                        </Flex>
                                                    ) : bid.paymentTerms === "1/2" ? (
                                                        <>
                                                            {bid.upfrontAmount && (
                                                                <Flex w={"100%"} justify={"flex-end"}>
                                                                    <Flex w={"fit-content"} mt={4} borderRadius={"lg"} direction={"column"} p={2} border={"1px"}>
                                                                        <Heading size={"sm"}>Payment Terms:</Heading>
                                                                        <Text>${bid.upfrontAmount.amount} before work starts. ${bid.upfrontAmount.amount} when completed</Text>
                                                                        <Text>{bid.upfrontAmount.description}</Text>
                                                                    </Flex>
                                                                </Flex>
                                                            )}
                                                        </>
                                                    ) : bid.paymentTerms === "UpFront" ? (
                                                        <>
                                                            {bid.upfrontAmount && (
                                                                <Flex w={"100%"} justify={"flex-end"}>
                                                                    <Flex w={"fit-content"} mt={4} borderRadius={"lg"} direction={"column"} p={2} border={"1px"}>
                                                                        <Heading size={"sm"}>Payment Terms:</Heading>
                                                                        <Text>${bid.upfrontAmount.amount} before work starts.</Text>
                                                                        <Text>{bid.upfrontAmount.description}</Text>
                                                                    </Flex>
                                                                </Flex>
                                                            )}
                                                        </>
                                                    ) : bid.paymentTerms === "Completed" ? (
                                                        <>
                                                            {bid.upfrontAmount && (
                                                                <Flex w={"100%"} justify={"flex-end"}>
                                                                    <Flex w={"fit-content"} mt={4} borderRadius={"lg"} direction={"column"} p={2} border={"1px"}>
                                                                        <Heading size={"sm"}>Payment Terms:</Heading>
                                                                        <Text>${bid.totalAmount} when completed</Text>
                                                                        <Text>{bid.upfrontAmount.description}</Text>
                                                                    </Flex>
                                                                </Flex>
                                                            )}
                                                        </>
                                                    ) : (
                                                        null
                                                    )}
                                                </Flex>

                                                {bid.notes && (
                                                    <Flex w={"100%"} mt={4} borderRadius={"lg"} direction={"column"} p={2} bg={"gray.700"}>
                                                        <Heading color={"white"} size={"sm"}>Notes:</Heading>
                                                        <Text color={"white"}>
                                                            {bid.notes}
                                                        </Text>
                                                    </Flex>
                                                )}
                                                <Accordion w={"100%"} mt={4} mb={4} allowToggle>
                                                    {bid.contractorInsurance && bid.contractorInsurance.length > 0 && (
                                                        <AccordionItem>
                                                            <h2>
                                                                <AccordionButton>
                                                                    <Box as='span' flex='1' textAlign='left'>
                                                                        Insurance
                                                                    </Box>
                                                                    <AccordionIcon />
                                                                </AccordionButton>
                                                            </h2>
                                                            <AccordionPanel pb={4}>
                                                                {bid.contractorInsurance?.map((insurance, index) => (
                                                                    <Box w="fit-content" mt={2} key={index} p={2} shadow="md" borderWidth="1px">
                                                                        <Text fontWeight="semibold">{insurance.provider}</Text>
                                                                        <Text>Policy Number: {insurance.policyNumber}</Text>
                                                                        <Text>Coverage Amount: ${new Intl.NumberFormat('en-US', { style: 'decimal', minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(insurance.coverageAmount)}</Text>
                                                                        <Text>Expiry Date: {new Date(insurance.expiryDate).toLocaleDateString()}</Text>
                                                                        <Text>Type: {insurance.type}</Text>
                                                                    </Box>
                                                                ))}
                                                            </AccordionPanel>
                                                        </AccordionItem>
                                                    )}

                                                    {bid.contractorBonds && (
                                                        <AccordionItem>
                                                            <h2>
                                                                <AccordionButton>
                                                                    <Box as='span' flex='1' textAlign='left'>
                                                                        Bonds
                                                                    </Box>
                                                                    <AccordionIcon />
                                                                </AccordionButton>
                                                            </h2>
                                                            <AccordionPanel pb={4}>
                                                                <Box w="fit-content" mt={2} p={2} shadow="md" borderWidth="1px">
                                                                    <Text fontWeight="semibold">{bid.contractorBonds.bondingCompany}</Text>
                                                                    <Text>Bond Number: {bid.contractorBonds.bondNumber}</Text>
                                                                    <Text>Bond Amount: ${new Intl.NumberFormat('en-US', { style: 'decimal', minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(bid.contractorBonds.amount)}</Text>
                                                                    <Text>Expiry Date: {new Date(bid.contractorBonds.expiryDate).toLocaleDateString()}</Text>
                                                                </Box>
                                                            </AccordionPanel>
                                                        </AccordionItem>
                                                    )}

                                                    {bid.licenses && bid.licenses.length > 0 && (
                                                        <AccordionItem>
                                                            <h2>
                                                                <AccordionButton>
                                                                    <Box as='span' flex='1' textAlign='left'>
                                                                        Licenses
                                                                    </Box>
                                                                    <AccordionIcon />
                                                                </AccordionButton>
                                                            </h2>
                                                            <AccordionPanel pb={4}>
                                                                {bid.licenses?.map((license, index) => (
                                                                    <Box w="fit-content" mt={2} key={index} p={2} shadow="md" borderWidth="1px">
                                                                        <Text fontWeight="semibold">{license.type}</Text>
                                                                        <Text>Number: {license.number}</Text>
                                                                        <Text>Expiry Date: {new Date(license.expiryDate).toLocaleDateString()}</Text>
                                                                    </Box>
                                                                ))}
                                                            </AccordionPanel>
                                                        </AccordionItem>
                                                    )}
                                                </Accordion>

                                                <Flex direction="column" w="100%">
                                                    <Flex p={2} w={"100%"} justify={"space-between"} align={"flex-start"}>
                                                        <Flex p={2} direction={"column"} w={{ base: '100%', lg: "50%" }} justify={"flex-start"}>
                                                            <Text mt={2}>Contractor Signature</Text>
                                                            {bid.contractorSignature && (
                                                                <>
                                                                    <Image
                                                                        src={bid.contractorSignature}
                                                                        alt="Contractor Signature"
                                                                        objectFit="contain"
                                                                        maxW="400px"
                                                                        h="auto"
                                                                    />
                                                                    {bid.contractorSignatureDate && (
                                                                        <Text mt={2}>Date: {format(safeParseISO(bid.contractorSignatureDate), 'MMMM dd, yyyy')}</Text>
                                                                    )}
                                                                </>
                                                            )}
                                                        </Flex>
                                                        <Flex p={2} direction={"column"} w={{ base: '100%', lg: "50%" }} justify={"flex-start"}>
                                                            <Text mt={2}>Home Owner Signature</Text>
                                                            {bid.ownerSignature && (
                                                                <>
                                                                    <Image
                                                                        src={bid.ownerSignature}
                                                                        alt="Home Owner Signature"
                                                                        objectFit="contain"
                                                                        maxW="400px"
                                                                        h="auto"
                                                                    />
                                                                    <Text mt={2}>Date: {format(safeParseISO(bid.ownerSignatureDate), 'MMMM dd, yyyy')}</Text>
                                                                </>
                                                            )}
                                                        </Flex>
                                                    </Flex>
                                                </Flex>
                                            </Flex>
                                        </Flex>
                                    </Flex>
                                    <Flex
                                        minH="100vh" // Ensure the Flex container takes up at least the full height of the viewport
                                        p={4}
                                        direction="column"
                                        w="full" // Use the full width of the viewport
                                        align="stretch" // Stretch the children to match the width of the container
                                        justify="flex-start"
                                    >
                                        <CreateEventModal
                                            isOpen={isOpen}
                                            onClose={onClose}
                                            onSave={handleSaveEvent}
                                        />
                                        <FinanceDetails
                                            transferGroupId={transferGroupId}
                                            project={project}
                                            bid={bid}
                                            destinationAccountId={contractor.destinationAccountId}
                                            contractor={contractor}
                                        />
                                        <Box
                                            p={4}
                                            w={"100%"}>
                                            <Box
                                                borderWidth={1}
                                                p={2}
                                                borderColor="lightgray"
                                                borderRadius="lg"
                                                shadow="lg"
                                                w={"100%"}>
                                                <Flex
                                                    direction={{ base: 'column', lg: 'row' }} // Stack on small screens, horizontal on larger screens
                                                    spacing={4}
                                                    align="stretch"
                                                    bg={bgColor[colorMode]}
                                                    color={color[colorMode]}
                                                    p={4}
                                                    w="100%" // Use the full width
                                                >
                                                    <Box w={{ base: '100%', lg: "60%" }} flex={1} h="full">
                                                        <FullCalendar
                                                            plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]} // Include all necessary plugins
                                                            initialView="dayGridMonth" // Set the initial view to 'dayGridMonth' for the month view
                                                            headerToolbar={{
                                                                left: 'prev,next today',
                                                                //     center: 'title',
                                                                right: 'dayGridMonth,timeGridWeek,timeGridDay' // Include 'dayGridMonth'
                                                            }}
                                                            weekends={true}
                                                            eventClick={handleEventClick} // Correct prop name and no extra function wrapper
                                                            events={formattedEvents}
                                                            dateClick={handleDateClick}
                                                        />

                                                    </Box>
                                                    <Box ml={4} maxH="500px" p={4} w={{ base: '100%', lg: "40%" }} flex={1} h="full" overflowY="auto">
                                                        {Object.entries(groupEventsByDate(events)).map(([date, eventsOnDate]) => (
                                                            <VStack key={date} spacing={4} align="stretch">
                                                                <Text fontWeight="bold">{date}</Text>
                                                                {eventsOnDate.map(event => (
                                                                    <EventCard
                                                                        key={event.id}
                                                                        event={event}
                                                                        isActive={selectedDate && isSameDay(new Date(event.startDate), selectedDate)}
                                                                        handleEventClick={() => handleEventClick(event)}
                                                                    />
                                                                ))}
                                                            </VStack>
                                                        ))}
                                                    </Box>
                                                </Flex>
                                                <Flex justify="flex-end">
                                                    <Button mb={4} mr={4} bg="#1DADE3" _hover={"blue"} color={"white"} size="sm" onClick={onOpen}>
                                                        Add Event
                                                    </Button>
                                                </Flex>
                                            </Box>
                                        </Box>
                                        <Flex direction={"column"} w={"100%"} justify={"flex-start"}>
                                            <MessageComponent bid={bid} conversationId={conversationId} projectId={projectId} socket={socket} />
                                        </Flex>
                                    </Flex>
                                </Flex>
                            </Flex>
                        </>
                    )}
                </Flex>
            </Flex>
        </Flex>
    );
}

export default ActiveProject;