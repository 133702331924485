// React and library imports
import React, { useState, useEffect, useCallback } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { io } from 'socket.io-client';
import { useAtom } from 'jotai';
import { socketAtom } from '../socketAtom';
import 'react-datepicker/dist/react-datepicker.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

// Chakra UI imports
import {
    Flex, Text, Heading, Box, Button, Input, Textarea, Stack, Tag, TagLabel, useToast, Switch,
    TagCloseButton, FormControl, FormLabel, VStack, InputGroup, InputLeftElement,
    Image, HStack, Select, Checkbox, CheckboxGroup, RadioGroup, Radio, Icon, useRadio,
    useRadioGroup, SimpleGrid, Circle, Accordion, AccordionItem, AccordionButton, AccordionIcon,
    AccordionPanel, Spinner, Spacer, InputRightElement, IconButton, Progress, ProgressLabel, useColorMode, Tooltip, useBreakpointValue, Menu, MenuButton, MenuList, MenuItem
} from '@chakra-ui/react';


// FontAwesome icons
import {
    faBath, faBorderAll, faBroom, faCalendarAlt, faCheckCircle, faExclamationTriangle, faHammer, faHouseDamage, faPalette, faPlug, faSeedling, faTree, faUtensils, faWindowRestore, faWrench,
    faSnowflake,
    faHardHat,
    faCouch,
    faScrewdriver,
    faRug,
    faPaintRoller,
    faDrumSteelpan,
    faFan,
    faHome,
    faToolbox,
    faLeaf,
    faPaintBrush,
    faSwimmer,
    faRulerCombined,
    faWarehouse,
    faWater,
    faThermometerHalf,
    faVectorSquare,
    faSoap,
    faWindowMaximize,
    faTrowelBricks,
    faHouseChimneyWindow,
    faTools,
    faCogs,
    faIgloo,
    faSwimmingPool,
    faBolt,
    faShieldAlt,
    faTape,
    faAnglesRight,
    faAnglesLeft,
    faRightLong,
    faLock,
    faFileInvoiceDollar,
    faDownLong,
    faChevronDown,
} from '@fortawesome/free-solid-svg-icons';
import ContractorSideMenu from '../contractor/SideMenu';
import Header from '../elements/Header';

function RadioCard(props) {
    const { getInputProps, getCheckboxProps } = useRadio(props);
    const input = getInputProps();
    const checkbox = getCheckboxProps();

    const iconMap = {
        estimate: faFileInvoiceDollar,
        ready: faCheckCircle,
        emergency: faExclamationTriangle
    };

    return (
        <Box as="label">
            <input {...input} />
            <Box
                {...checkbox}
                cursor="pointer"
                borderWidth="1px"
                borderRadius="md"
                boxShadow="md"
                _checked={{
                    bg: "green.500",
                    color: "white",
                    borderColor: "white",
                }}
                px={5}
                py={3}
            >
                <VStack>
                    <FontAwesomeIcon icon={iconMap[props.value]} size="2x" />
                    <Text fontWeight="bold">{props.title}</Text>
                    <Text fontSize="sm">{props.children}</Text>
                </VStack>
            </Box>
        </Box>
    );
}

function ServiceRadioCard({ serviceTitles, serviceDescriptions, ...props }) {
    const { getInputProps, getCheckboxProps } = useRadio(props);
    const input = getInputProps();
    const checkbox = getCheckboxProps();

    const iconMap = {
        plumbing: faWrench,
        kitchens: faUtensils,
        bathrooms: faBath,
        cleaning: faBroom,
        lawncare: faSeedling,
        treeService: faTree,
        windows: faHouseChimneyWindow,
        roofing: faHouseDamage,
        electrical: faPlug,
        remodels: faHammer,
        decksPorches: faPalette,
        fences: faBorderAll,
        cooling: faSnowflake,
        architecture: faHardHat,
        cabinets: faCouch,
        carpentry: faScrewdriver,
        carpet: faRug,
        masonry: faTrowelBricks,
        driveways: faDrumSteelpan,
        drywall: faFan,
        flooring: faRulerCombined,
        foundations: faHome,
        garages: faWarehouse,
        gutters: faWater,
        handyman: faToolbox,
        HVAC: faThermometerHalf,
        landscape: faLeaf,
        painting: faPaintRoller,
        siding: faTape,
        poolsSpas: faSwimmer,
        doors: faWindowMaximize,
        lowVoltage: faBolt,
        security: faShieldAlt,
        poolBuilding: faSwimmingPool,
        automation: faCogs,
        welding: faTools,
        insulation: faIgloo,
        tiling: faBorderAll,
    };

    return (
        <Box as="label" cursor="pointer">
            <input {...input} />
            <Flex
                {...checkbox}
                direction="column"
                justify={"center"}
                w={"fit-content"}
                color={'lightgray'}
                align="center"
                borderRadius="md"
                p={4}
                transition="all 0.2s"
                _checked={{
                    borderColor: 'white',
                    bg: 'green',
                    color: 'white',
                    transform: 'translateY(-2px)',
                    boxShadow: 'lg',
                }}
                _hover={{
                    boxShadow: 'lg',
                    transform: 'translateY(-2px)',
                }}
            >
                <Circle p={4}>
                    <FontAwesomeIcon icon={iconMap[props.value]} size="lg" />
                </Circle>
                <Text fontWeight="bold" mt={2}>
                    {serviceTitles[props.value]}
                </Text>
            </Flex>
        </Box>
    );
}

function WelcomeProject() {
    const [currentStep, setCurrentStep] = useState(1);
    const menuType = useBreakpointValue({ base: 'drawer', lg: 'regular' });
    const [formData, setFormData] = useState({
        title: "",
        description: "",
        trade: "",
        tradesNeeded: [],
        street: "",
        city: "",
        service: "",
        platformPayment: true,
        state: "",
        zipCode: "",
        country: "USA",
        imageUrls: [],
        projectType: "",
        readyToStartTimeframe: "",
    });
    const [isLoading, setIsLoading] = useState(false);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [suggestions, setSuggestions] = useState([]);
    const [isUploading, setIsUploading] = useState(false);
    const [images] = useState([]);
    const [socket, setSocket] = useAtom(socketAtom);
    const navigate = useNavigate();
    const { colorMode } = useColorMode();
    const toast = useToast();
    const token = localStorage.getItem('token');

    useEffect(() => {
        const newSocket = io(process.env.REACT_APP_API_URL, {
            autoConnect: true,
            auth: {
                token: token,
            },
        });

        newSocket.on('connect_error', (error) => {
            console.error('Socket connection error:', error);
        });

        setSocket(newSocket);

        return () => newSocket.close();
    }, [setSocket, token]);

    useEffect(() => {
        const newSocket = io(process.env.REACT_APP_API_URL, {
            autoConnect: true,
            auth: {
                token: token,
            },
        });
        newSocket.on('connect_error', (error) => {
            console.error('Socket connection error:', error);
        });
        setSocket(newSocket);
        return () => newSocket.close();
    }, [token]);

    const handleChange = useCallback((e) => {
        if (e && e.target) {
            const { name, value } = e.target;

            // Set form data
            if (name === "readyToStartTimeframe") {
                setFormData(prev => ({ ...prev, readyToStartTimeframe: value }));
            } else {
                setFormData(prev => ({ ...prev, [name]: value }));
            }
        }
    }, []);

    // Fetch trade suggestions as user types
    const handleTradeChange = (e) => {
        const newTrade = e.target.value;
        setFormData(prev => ({ ...prev, trade: newTrade }));
        if (newTrade.length > 2) {  // Start suggesting after 2 characters
            fetchTradeSuggestions(newTrade);
        } else {
            setSuggestions([]);
        }
    };

    const selectSuggestion = (suggestion) => {
        setFormData(prev => ({
            ...prev,
            tradesNeeded: [...prev.tradesNeeded, suggestion],
            trade: ''  // Clear the input after selection
        }));
        setSuggestions([]);
    };

    const removeTradeNeeded = (index) => {
        setFormData(prev => ({
            ...prev,
            tradesNeeded: prev.tradesNeeded.filter((_, i) => i !== index)
        }));
    };

    const handleFileChange = useCallback(async (event) => {
        const files = Array.from(event.target.files);
        setIsUploading(true);
        const fileData = new FormData();
        files.forEach(file => {
            fileData.append('file', file);
        });

        try {
            const res = await axios.post(`${process.env.REACT_APP_API_URL}/api/upload`, fileData, {
                headers: { 'Content-Type': 'multipart/form-data' }
            });

            // Check if the upload was successful and the response includes a URL
            if (!res.data.error && res.data.url) {
                const newImageUrl = res.data.url;
                console.log("Upload successful, image URL:", newImageUrl);
                setFormData(prev => ({
                    ...prev,
                    imageUrls: [...prev.imageUrls, newImageUrl] // Append the new URL to the existing array
                }));
            } else {
                console.error('Upload failed or unexpected response:', res.data);
            }
        } catch (error) {
            console.error('Failed to upload images:', error);
        } finally {
            setIsUploading(false);
        }
    }, []);

    const handleImageRemove = useCallback((index) => {
        setFormData(prev => ({
            ...prev,
            imageUrls: prev.imageUrls.filter((_, i) => i !== index)
        }));
    }, []);

    const titles = {
        estimate: "Estimates Only",
        ready: "Ready to Start",
        emergency: "Rapid Response",
    };

    const descriptions = {
        estimate: "Only looking for estimates, not ready to start immediately.",
        ready: "Ready to start with a deposit to show commitment.",
        emergency: "Needs immediate attention or emergency service.",
    };

    const { getRootProps: getProjectTypeRootProps, getRadioProps: getProjectTypeRadioProps } = useRadioGroup({
        name: "projectType",
        onChange: value => setFormData({ ...formData, projectType: value })
    });

    const { getRootProps: getServiceRootProps, getRadioProps: getServiceRadioProps } = useRadioGroup({
        name: "service",
        onChange: value => setFormData({ ...formData, service: value })
    });

    const projectTypeGroup = getProjectTypeRootProps();
    const serviceGroup = getServiceRootProps();

    const serviceTitles = {
        architecture: "Builders",
        automation: "Home Automation",
        bathrooms: "Bathrooms",
        cabinets: "Cabinets & Countertops",
        carpentry: "Carpentry",
        carpet: "Carpet",
        cleaning: "Cleaning",
        cooling: "Cooling",
        decksPorches: "Decks & Porches",
        doors: "Windows & Doors",
        drywall: "Drywall & Insulation",
        electrical: "Electrical",
        fences: "Fences",
        flooring: "Flooring",
        foundations: "Foundations",
        garages: "Garages, Doors, Openers",
        gutters: "Gutters",
        handyman: "Handyman Services",
        HVAC: "Heating & Cooling",
        insulation: "Insulation",
        kitchens: "Kitchens",
        landscape: "Landscape",
        lawncare: "Lawncare",
        lowVoltage: "Low Voltage",
        masonry: "Concrete, Brick & Stone",
        painting: "Painting",
        plumbing: "Plumbing",
        poolsSpas: "Spas",
        poolBuilding: "Pool Building",
        remodels: "Remodels",
        roofing: "Roofing",
        security: "Security Systems Installation",
        siding: "Siding",
        tiling: "Tiling",
        treeService: "Trees",
        welding: "Welding",
        windows: "Windows"
    };

    const serviceDescriptions = {
        plumbing: "....",
        electrical: "....",
        cleaning: "....",
    };

    // Popular services
    const popularServices = [
        'plumbing',
        'electrical',
        'cooling',
        'kitchens',
        'bathrooms',
        'remodels',
        'cleaning',
        'lawncare',
        'treeService',
        'windows',
        'roofing',
        'painting'
    ];

    // Other services
    const otherServices = Object.keys(serviceTitles).filter(
        (service) => !popularServices.includes(service)
    );

    const fetchTradeSuggestions = async (value) => {
        if (value.length > 1) {
            try {
                const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/search/trades?query=${value}`);
                setSuggestions(response.data);
            } catch (error) {
                console.error('Failed to fetch trade suggestions:', error);
                setSuggestions([]);
            }
        } else {
            setSuggestions([]);
        }
    };

    useEffect(() => {
        if (formData.trade.length > 1) {
            const delayDebounceFn = setTimeout(() => {
                fetchTradeSuggestions(formData.trade);
            }, 300);
            return () => clearTimeout(delayDebounceFn);
        }
    }, [formData.trade]);

    const states = ['Alabama', 'Alaska', 'Arizona', 'Arkansas', 'California', 'Colorado', 'Connecticut', 'Delaware', 'Florida', 'Georgia',
        'Hawaii', 'Idaho', 'Illinois', 'Indiana', 'Iowa', 'Kansas', 'Kentucky', 'Louisiana', 'Maine', 'Maryland', 'Massachusetts', 'Michigan',
        'Minnesota', 'Mississippi', 'Missouri', 'Montana', 'Nebraska', 'Nevada', 'New Hampshire', 'New Jersey', 'New Mexico', 'New York',
        'North Carolina', 'North Dakota', 'Ohio', 'Oklahoma', 'Oregon', 'Pennsylvania', 'Rhode Island', 'South Carolina', 'South Dakota',
        'Tennessee', 'Texas', 'Utah', 'Vermont', 'Virginia', 'Washington', 'West Virginia', 'Wisconsin', 'Wyoming'
    ];

    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsSubmitting(true);

        const address = {
            street: formData.street,
            city: formData.city,
            state: formData.state,
            zipCode: formData.zipCode,
            country: formData.country,
        };

        try {

            // Prepare project data with ownerId
            const projectData = {
                ...formData,
                address,
                images: formData.imageUrls,
                tradesNeeded: formData.tradesNeeded.map(trade => trade.toLowerCase())
            };

            switch (formData.projectType) {
                case 'estimate':
                    handleEstimateProject(projectData);
                    break;

                case 'ready':
                    await handleReadyProject(projectData);
                    break;

                case 'emergency':
                    handleEmergencyProject(projectData);
                    break;

                default:
                    console.error('Invalid project type');
            }
        } catch (error) {
            setCurrentStep(6)
            setIsLoading(false);
            console.error('Error occurred:', error);

            let errorMessage = 'An error occurred while creating the project.';

            if (error.response) {
                // The request was made and the server responded with a status code
                // that falls out of the range of 2xx
                console.log(error.response.data);
                console.log(error.response.status);
                console.log(error.response.headers);

                if (error.response.status === 400) {
                    errorMessage = 'Invalid request data. Please check your input and try again.';
                } else if (error.response.status === 401) {
                    errorMessage = 'Unauthorized. Please log in and try again.';
                } else if (error.response.status === 500) {
                    errorMessage = 'Internal server error. Please try again later.';
                }
            } else if (error.request) {
                // The request was made but no response was received
                console.log(error.request);
                errorMessage = 'No response received from the server. Please try again later.';
            } else {
                // Something happened in setting up the request that triggered an Error
                console.log('Error', error.message);
            }

            toast({
                title: 'Error',
                description: errorMessage,
                status: 'error',
                duration: 9000,
                isClosable: true,
            });
        } finally {
            setIsSubmitting(false);
            setIsLoading(false);
        }
    };

    const handleEstimateProject = (projectData) => {
        socket.emit('createProject', projectData, (response) => {

            setIsSubmitting(false);
            if (response.error) {
                setCurrentStep(6)
                console.error('Failed to create project:', response.message);
            } else {
                alert('Project submitted for bidding!');
                navigate(`/projects/${response.projectId}`);
            }
        });
    };

    const handleStateChange = (selectedState) => {
        setFormData(prevFormData => ({
            ...prevFormData,
            state: selectedState
        }));
    };

    const handleReadyProject = async (projectData) => {
        socket.emit('createProject', projectData, (response) => {

            setIsSubmitting(false);
            if (response.error) {
                setCurrentStep(6)
                console.error('Failed to create project:', response.message);
            } else {
                toast({
                    title: 'Project created successfully.',
                    status: 'success',
                    duration: 9000,
                    isClosable: true,
                });
                alert('Project submitted for bidding!');
                navigate(`/projects/${response.projectId}`);
            }
        });
    };

    const handleEmergencyProject = async (projectData) => {
        try {
            socket.emit('createEmergencyProject', projectData, async (response) => {
                if (response.error) {
                    setCurrentStep(6)
                    console.error('Failed to create emergency project:', response.message);
                } else {
                    alert('Emergency project submitted for bidding!');
                    try {
                        const { data: session } = await axios.post(`${process.env.REACT_APP_API_URL}/api/emergency-create-session`, {
                            amount: 0, // Amount in cents ($0)
                            title: "Emergency Project Fee",
                            transfer_group: response.projectId
                        }, {
                            headers: {
                                'Content-Type': 'application/json'
                            }
                        });

                        // Redirect to Stripe Checkout
                        window.location = session.url;
                    } catch (error) {
                        setCurrentStep(6)
                        console.error('Failed to create checkout session:', error.message);
                    }
                }
            });
        } catch (error) {
            setCurrentStep(6)
            console.error('General error:', error.message);
        }
    };

    const progressLabelColor = colorMode === 'dark' ? 'gray.700' : 'white';
    const progressBarBg = colorMode === 'dark' ? 'gray.600' : 'gray.500';

    return (
        <Flex direction={"column"} w={"100%"} justify={"center"}>
            <Flex direction={{ base: "column", lg: "row" }} p={2} w="100%" align="flex-start" justify="flex-start">
                <ContractorSideMenu />
                <Flex m={{ base: 0, lg: 4 }} direction="column" w="100%" justify="center">
                    <Header />
                    {menuType === 'drawer' ? (
                        <>
                            <Flex direction="column" w="100%" justify="center">
                                <Flex w="100%" align="flex-start" justify="flex-start">
                                    <Flex direction="column" w="100%" justify="center">
                                        <Flex direction="column" align="center">

                                            <Box mt={2} width="100%" maxWidth="800px">
                                                <VStack spacing={6} align="stretch">
                                                    <Flex direction="column" w="100%" justify="center">
                                                        {currentStep === 1 && (
                                                            <Flex direction={"column"} minH={"650px"} overflowY={"auto"}>
                                                                <FormControl>
                                                                    <Heading mb={2} size={"lg"}>Timeframe</Heading>
                                                                    <VStack {...getProjectTypeRootProps}>
                                                                        {["estimate", "ready", "emergency"].map((value) => (
                                                                            <RadioCard
                                                                                key={value}
                                                                                {...getProjectTypeRadioProps({ name: "projectType", value })}
                                                                                title={titles[value]}
                                                                            >
                                                                                {descriptions[value]}
                                                                            </RadioCard>
                                                                        ))}
                                                                    </VStack>
                                                                    {formData.projectType === 'ready' && (
                                                                        <Flex w={"100%"} justify={"center"}>
                                                                            <FormControl mt={4}>
                                                                                <FormLabel>How soon are you ready to start?</FormLabel>
                                                                                <RadioGroup
                                                                                    value={formData.readyToStartTimeframe}
                                                                                    onChange={(value) => setFormData({ ...formData, readyToStartTimeframe: value })}
                                                                                >
                                                                                    <HStack p={4} borderRadius={"lg"} shadow={"lg"} bg={"gray.700"} spacing={6}>
                                                                                        <Radio _checked={{ bg: "#1DADE3" }} value="rightAway"><Text color={"white"}>I'm Ready!</Text></Radio>
                                                                                        <Radio _checked={{ bg: "#1DADE3" }} value="inDays"><Text color={"white"}>Days</Text></Radio>
                                                                                        <Radio _checked={{ bg: "#1DADE3" }} value="inWeeks"><Text color={"white"}>Weeks</Text></Radio>
                                                                                    </HStack>
                                                                                </RadioGroup>
                                                                            </FormControl>
                                                                        </Flex>
                                                                    )}
                                                                    {formData.projectType === 'emergency' && (
                                                                        <Box mt={8} w={"100%"} justify={"center"}>
                                                                            <Text mt={4} fontWeight={"semibold"}>Rapid Response: Get the Right Contractor, Right Now!</Text>
                                                                            <Text mt={4}>Need a help quickly? We've got you covered. Let us connect you with a vast network of trusted professionals in just a few clicks.</Text>
                                        
                                                                        </Box>

                                                                    )}
                                                                    {formData.projectType !== 'estimate' && (
                                                                        <Flex mb={4} mt={8} w="100%" justify="center">
                                                                            <Flex direction="column" p={4} borderRadius="lg" bg="gray.700" w="100%" align="center">
                                                                                <Heading size="md" color="white" mb={4}>
                                                                                    Boost Trust with Our Secure Payment System
                                                                                </Heading>

                                                                                <VStack justify="space-evenly" w="75%" mb={8}>
                                                                                    <Box p={4} borderColor="white" borderRadius="lg" borderWidth="1px">
                                                                                        <Flex w="100%" justify="space-between" align="center">
                                                                                            <FontAwesomeIcon color={"lime"} icon={faLock} /> <Heading mt={1} ml={2} color="white" size="md">Funds Added: $1200</Heading>
                                                                                        </Flex>
                                                                                        <Progress
                                                                                            value={100}  // Representing the full amount added
                                                                                            colorScheme='green'
                                                                                            size="lg"
                                                                                            bg={progressBarBg}
                                                                                            mb={2}
                                                                                            mt={4}
                                                                                            borderRadius={8}
                                                                                            hasStripe
                                                                                            isAnimated
                                                                                        >
                                                                                            <ProgressLabel color={progressLabelColor} fontSize="md">$1200</ProgressLabel>
                                                                                        </Progress>
                                                                                    </Box>
                                                                                    <FontAwesomeIcon size="2xl" color="white" icon={faDownLong} />
                                                                                    <Box p={4} borderColor="white" borderRadius="lg" borderWidth="1px">
                                                                                        <Flex w="100%" justify="space-between" align="flex-end">
                                                                                            <Heading color="white" size="md">
                                                                                                Funds Released: $950
                                                                                            </Heading>
                                                                                        </Flex>
                                                                                        <Progress
                                                                                            value={79}  // Calculation based on the amount released from the total
                                                                                            colorScheme='green'
                                                                                            size="lg"
                                                                                            bg={progressBarBg}
                                                                                            mb={2}
                                                                                            mt={4}
                                                                                            borderRadius={8}
                                                                                            hasStripe
                                                                                            isAnimated
                                                                                        >
                                                                                            <ProgressLabel color={progressLabelColor} fontSize="md">$950</ProgressLabel>
                                                                                        </Progress>
                                                                                    </Box>
                                                                                </VStack>
                                                                                <Text color="white" mb={8}>
                                                                                    Secure and transparent financial transactions are at the heart of our service.
                                                                                    Safely deposit funds into an project account and release payments to contractors
                                                                                    only upon your approval. Opt for convenient bank transfers or card payments.
                                                                                </Text>
                                                                                <Tooltip bg="black" label="A 2% service fee applies, with additional payment processing fees" placement="auto-start">
                                                                                    <FormControl display="flex" alignItems="center">
                                                                                        <FormLabel htmlFor="platform-payment-switch" mb="0" color="white" mr={4}>
                                                                                            Secure Funds
                                                                                        </FormLabel>
                                                                                        <Switch
                                                                                            id="platform-payment-switch"
                                                                                            isChecked={formData.platformPayment}
                                                                                            onChange={(e) => setFormData(prev => ({ ...prev, platformPayment: e.target.checked }))}
                                                                                            sx={{
                                                                                                '.chakra-switch__track': {
                                                                                                    _checked: {
                                                                                                        bg: "#1DADE3",
                                                                                                    }
                                                                                                }
                                                                                            }}
                                                                                        />
                                                                                    </FormControl>
                                                                                </Tooltip>
                                                                            </Flex>
                                                                        </Flex>
                                                                    )}
                                                                </FormControl>
                                                                <Spacer />
                                                                <Flex w={"100%"} align={"center"} justify={"flex-end"} borderRadius={"lg"} p={2} bg={"gray.700"}>
                                                                    <Button onClick={() => setCurrentStep(2)} bg="#1DADE3" _hover={"blue"} color={"white"} >
                                                                        <Text mr={2}>Next</Text>
                                                                        <FontAwesomeIcon icon={faAnglesRight} />
                                                                    </Button>
                                                                </Flex>
                                                            </Flex>
                                                        )}

                                                        {currentStep === 2 && (
                                                            <Flex minH={"650px"} direction={"column"}>
                                                                <Flex p={2} bg={"gray.700"} borderRadius={"lg"}>
                                                                    <FormControl isRequired>
                                                                        <FormLabel color={"white"}>Popular Services:</FormLabel>
                                                                        <SimpleGrid {...serviceGroup} columns={{ base: 3 }} spacing={4}>
                                                                            {popularServices.map((value) => (
                                                                                <ServiceRadioCard
                                                                                    key={value}
                                                                                    value={value}
                                                                                    {...getServiceRadioProps({ value })}
                                                                                    serviceTitles={serviceTitles}
                                                                                    serviceDescriptions={serviceDescriptions}
                                                                                />
                                                                            ))}
                                                                        </SimpleGrid>
                                                                    </FormControl>
                                                                </Flex>

                                                                <Accordion mt={4} allowToggle>
                                                                    <AccordionItem>
                                                                        <Heading size={"sm"}>
                                                                            <AccordionButton borderRadius={"md"} _expanded={{ bg: 'gray.700', color: 'white' }}>
                                                                                <Box as='span' flex='1' textAlign='left'>
                                                                                    More Services
                                                                                </Box>
                                                                                <AccordionIcon />
                                                                            </AccordionButton>
                                                                        </Heading>
                                                                        <AccordionPanel h={"450px"} overflowY={"auto"} borderRadius={'lg'} mt={2} bg={"gray.700"} pb={4}>
                                                                            <SimpleGrid w={"98%"} {...serviceGroup} columns={{ base: 3 }} spacing={2}>
                                                                                {otherServices.map((value) => (
                                                                                    <ServiceRadioCard
                                                                                        key={value}
                                                                                        value={value}
                                                                                        {...getServiceRadioProps({ value })}
                                                                                        serviceTitles={serviceTitles}
                                                                                        serviceDescriptions={serviceDescriptions}
                                                                                    />
                                                                                ))}
                                                                            </SimpleGrid>
                                                                        </AccordionPanel>
                                                                    </AccordionItem>
                                                                </Accordion>

                                                                <FormControl mb={8} mt={8} isRequired>
                                                                    <FormLabel>Trades Needed:</FormLabel>
                                                                    <Flex direction={"column"} position="relative">
                                                                        <Input
                                                                            placeholder="Add a trade"
                                                                            value={formData.trade}
                                                                            onChange={handleTradeChange}
                                                                            autoComplete="off"
                                                                        />
                                                                        <Flex direction="column" position="absolute" mt="12" w="full" zIndex="10" bg="white" boxShadow="md">
                                                                            {suggestions.map((suggestion, index) => (
                                                                                <Button
                                                                                    key={suggestion._id}
                                                                                    variant="ghost"
                                                                                    justifyContent="start"
                                                                                    onClick={() => selectSuggestion(suggestion.trade)}
                                                                                    _hover={{ bg: "gray.100" }}
                                                                                >
                                                                                    {suggestion.trade}
                                                                                </Button>
                                                                            ))}
                                                                        </Flex>
                                                                        <Flex direction="column" mt={2}>
                                                                            <Flex flexWrap="wrap" gap="2">
                                                                                {formData.tradesNeeded.map((trade, index) => (
                                                                                    <Tag size="lg" key={index} borderRadius="full" mb={1}>
                                                                                        <TagLabel>{trade}</TagLabel>
                                                                                        <TagCloseButton onClick={() => removeTradeNeeded(index)} />
                                                                                    </Tag>
                                                                                ))}
                                                                            </Flex>
                                                                        </Flex>
                                                                    </Flex>
                                                                </FormControl>
                                                                <Spacer />
                                                                <Flex w={"100%"} align={"center"} justify={"space-between"} borderRadius={"lg"} p={2} bg={"gray.700"}>
                                                                    <Button onClick={() => setCurrentStep(1)}>
                                                                        <FontAwesomeIcon icon={faAnglesLeft} />
                                                                        <Text ml={2}>Back</Text>
                                                                    </Button>
                                                                    <Button onClick={() => setCurrentStep(3)} bg="#1DADE3" _hover={"blue"} color={"white"} >
                                                                        <Text mr={2}>Next</Text>
                                                                        <FontAwesomeIcon icon={faAnglesRight} />
                                                                    </Button>
                                                                </Flex>
                                                            </Flex>
                                                        )}

                                                        {currentStep === 3 && (
                                                            <Flex direction={"column"} h={"300px"} overflowY={"auto"}>
                                                                <Flex justify={"space-between"} w={"100%"}>
                                                                    <Box w={"100%"}>
                                                                        <FormControl isRequired>
                                                                            <FormLabel>Tell us about your project</FormLabel>
                                                                            <Input placeholder="e.g., Kitchen Remodeling, Bathroom Renovation" name="title" value={formData.title} onChange={handleChange} />
                                                                        </FormControl>
                                                                        <FormControl isRequired mt={4}>
                                                                            <FormLabel>Can you give more details?</FormLabel>
                                                                            <Textarea placeholder="Describe what you would like done, any specific requirements, materials, etc." name="description" value={formData.description} onChange={handleChange} />
                                                                        </FormControl>
                                                                    </Box>
                                                                </Flex>
                                                                <Spacer />
                                                                <Flex w={"100%"} align={"center"} justify={"space-between"} borderRadius={"lg"} p={2} bg={"gray.700"}>
                                                                    <Button onClick={() => setCurrentStep(2)}>
                                                                        <FontAwesomeIcon icon={faAnglesLeft} />
                                                                        <Text ml={2}>Back</Text>
                                                                    </Button>
                                                                    <Button onClick={() => setCurrentStep(4)} bg="#1DADE3" _hover={"blue"} color={"white"} >
                                                                        <Text mr={2}>Next</Text>
                                                                        <FontAwesomeIcon icon={faAnglesRight} />
                                                                    </Button>
                                                                </Flex>
                                                            </Flex>
                                                        )}

                                                        {currentStep === 4 && (
                                                            <Flex direction={"column"} h={"300px"} overflowY={"auto"}>
                                                                <FormControl>
                                                                    {isUploading && (
                                                                        <Flex w={"100%"} justify={"center"}>
                                                                            <Spinner color='gray.700' size={"xl"} />
                                                                        </Flex>
                                                                    )}

                                                                    {!isUploading && (
                                                                        <>
                                                                            <FormLabel>Images:</FormLabel>
                                                                            <Input type="file" accept="image/*" onChange={handleFileChange} multiple />
                                                                        </>
                                                                    )}

                                                                    <Flex wrap="wrap" mt={4} spacing={4}>
                                                                        {formData.imageUrls.map((url, index) => (
                                                                            <Box>
                                                                                <Flex w={"100%"} mb={-8} ml={-2} justify={"flex-end"}>
                                                                                    <Button
                                                                                        size="xs"
                                                                                        shadow={"lg"}
                                                                                        colorScheme="red"
                                                                                        onClick={() => handleImageRemove(index)}
                                                                                    >
                                                                                        X
                                                                                    </Button>
                                                                                </Flex>
                                                                                <Box key={index} p={2} boxShadow="lg" lineHeight="tight" borderRadius="md" overflow="hidden">
                                                                                    <Image src={url} alt="Uploaded preview" boxSize="150px" objectFit="cover" />
                                                                                </Box>
                                                                            </Box>
                                                                        ))}
                                                                    </Flex>
                                                                </FormControl>
                                                                <Spacer />
                                                                <Flex w={"100%"} align={"center"} justify={"space-between"} borderRadius={"lg"} p={2} bg={"gray.700"}>
                                                                    <Button onClick={() => setCurrentStep(3)}>
                                                                        <FontAwesomeIcon icon={faAnglesLeft} />
                                                                        <Text ml={2}>Back</Text>
                                                                    </Button>
                                                                    <Button onClick={() => setCurrentStep(5)} bg="#1DADE3" _hover={"blue"} color={"white"} >
                                                                        <Text mr={2}>Next</Text>
                                                                        <FontAwesomeIcon icon={faAnglesRight} />
                                                                    </Button>
                                                                </Flex>
                                                            </Flex>
                                                        )}

                                                        {currentStep === 5 && (
                                                            <Flex direction={"column"} h={"400px"} overflowY={"auto"}>
                                                                <FormControl>
                                                                    <FormLabel>Street</FormLabel>
                                                                    <Input name="street" value={formData.street} onChange={handleChange} />
                                                                </FormControl>
                                                                <Flex direction={"column"} justify={"space-evenly"} w={"100%"}>
                                                                    <FormControl mt={2}>
                                                                        <FormLabel>City</FormLabel>
                                                                        <Input name="city" value={formData.city} onChange={handleChange} />
                                                                    </FormControl>
                                                                    <FormControl mt={2}>
                                                                        <FormLabel>State</FormLabel>
                                                                        <Menu>
                                                                            <MenuButton w={"100%"} as={Button} rightIcon={<Icon as={FontAwesomeIcon} icon={faChevronDown} />}>
                                                                                {formData.state || 'Select state'}
                                                                            </MenuButton>
                                                                            <MenuList w={"100%"} maxHeight="500px" overflowY="auto">
                                                                                {states.map(state => (
                                                                                    <MenuItem key={state} onClick={() => handleStateChange(state)}>
                                                                                        {state}
                                                                                    </MenuItem>
                                                                                ))}
                                                                            </MenuList>
                                                                        </Menu>
                                                                    </FormControl>
                                                                    <FormControl mt={2}>
                                                                        <FormLabel>Zip Code</FormLabel>
                                                                        <Input name="zipCode" value={formData.zipCode} onChange={handleChange} />
                                                                    </FormControl>
                                                                </Flex>
                                                                <Spacer />
                                                                <Flex w={"100%"} align={"center"} justify={"space-between"} borderRadius={"lg"} p={2} bg={"gray.700"}>
                                                                    <Button onClick={() => setCurrentStep(4)}>
                                                                        <FontAwesomeIcon icon={faAnglesLeft} />
                                                                        <Text ml={2}>Back</Text>
                                                                    </Button>
                                                                    <Button isLoading={isLoading} onClick={(e) => {
                                                                        handleSubmit(e);
                                                                        setCurrentStep(currentStep + 1);
                                                                    }} bg="#1DADE3" _hover={"blue"} color={"white"} >
                                                                        <Text mr={2}>Submit</Text>
                                                                        <FontAwesomeIcon icon={faAnglesRight} />
                                                                    </Button>
                                                                </Flex>
                                                            </Flex>
                                                        )}
                                                    </Flex>
                                                </VStack>
                                            </Box>
                                        </Flex>
                                    </Flex>
                                </Flex>
                            </Flex>
                        </>
                    ) : (
                        <>
                            <Flex direction="column" w="100%" justify="center">
                                <Flex p={2} w="100%" align="flex-start" justify="flex-start">
                                    <Flex m={4} direction="column" w="100%" justify="center">
                                        <Flex direction="column" align="center" p={8}>

                                            <Box mt={8} width="100%" maxWidth="800px">
                                                <VStack spacing={6} align="stretch">
                                                    <Flex direction="column" w="100%" justify="center">
                                                        {currentStep === 1 && (
                                                            <Flex direction={"column"} minH={"650px"} overflowY={"auto"}>
                                                                <FormControl>
                                                                    <Heading mb={2} size={"lg"}>Timeframe</Heading>
                                                                    <HStack {...getProjectTypeRootProps}>
                                                                        {["estimate", "ready", "emergency"].map((value) => (
                                                                            <RadioCard
                                                                                key={value}
                                                                                {...getProjectTypeRadioProps({ name: "projectType", value })}
                                                                                title={titles[value]}
                                                                            >
                                                                                {descriptions[value]}
                                                                            </RadioCard>
                                                                        ))}
                                                                    </HStack>
                                                                    {formData.projectType === 'ready' && (
                                                                        <Flex w={"100%"} justify={"center"}>
                                                                            <FormControl mt={4}>
                                                                                <FormLabel>How soon are you ready to start?</FormLabel>
                                                                                <RadioGroup
                                                                                    value={formData.readyToStartTimeframe}
                                                                                    onChange={(value) => setFormData({ ...formData, readyToStartTimeframe: value })}
                                                                                >
                                                                                    <HStack p={4} borderRadius={"lg"} shadow={"lg"} bg={"gray.700"} spacing={6}>
                                                                                        <Radio value="rightAway"><Text color={"white"}>I'm Ready!</Text></Radio>
                                                                                        <Radio value="inDays"><Text color={"white"}>Days</Text></Radio>
                                                                                        <Radio value="inWeeks"><Text color={"white"}>Weeks</Text></Radio>
                                                                                    </HStack>
                                                                                </RadioGroup>
                                                                            </FormControl>
                                                                        </Flex>
                                                                    )}
                                                                    {formData.projectType === 'emergency' && (
                                                                        <Box mt={8} w={"100%"} justify={"center"}>
                                                                            <Text mt={4} fontWeight={"semibold"}>Rapid Response: Get the Right Contractor, Right Now!</Text>
                                                                            <Text mt={4}>Need a help quickly? We've got you covered. Let us connect you with a vast network of trusted professionals in just a few clicks.</Text>
                                                                           
                                                                        </Box>

                                                                    )}
                                                                    {formData.projectType !== 'estimate' && (
                                                                        <Flex mb={4} mt={8} w="100%" justify="center">
                                                                            <Flex direction="column" p={4} borderRadius="lg" bg="gray.700" w="100%" align="center">
                                                                                <Heading size="md" color="white" mb={4}>
                                                                                    Boost Trust with Our Secure Payment System
                                                                                </Heading>

                                                                                <HStack justify="space-evenly" w="75%" mb={8}>
                                                                                    <Box p={4} borderColor="white" borderRadius="lg" borderWidth="1px">
                                                                                        <Flex w="100%" justify="space-between" align="center">
                                                                                            <FontAwesomeIcon color={"lime"} icon={faLock} /> <Heading mt={1} ml={2} color="white" size="md">Funds Added: $1200</Heading>
                                                                                        </Flex>
                                                                                        <Progress
                                                                                            value={100}  // Representing the full amount added
                                                                                            colorScheme='green'
                                                                                            size="lg"
                                                                                            bg={progressBarBg}
                                                                                            mb={2}
                                                                                            mt={4}
                                                                                            borderRadius={8}
                                                                                            hasStripe
                                                                                            isAnimated
                                                                                        >
                                                                                            <ProgressLabel color={progressLabelColor} fontSize="md">$1200</ProgressLabel>
                                                                                        </Progress>
                                                                                    </Box>
                                                                                    <FontAwesomeIcon size="2xl" color="white" icon={faRightLong} />
                                                                                    <Box p={4} borderColor="white" borderRadius="lg" borderWidth="1px">
                                                                                        <Flex w="100%" justify="space-between" align="flex-end">
                                                                                            <Heading color="white" size="md">
                                                                                                Funds Released: $950
                                                                                            </Heading>
                                                                                        </Flex>
                                                                                        <Progress
                                                                                            value={79}  // Calculation based on the amount released from the total
                                                                                            colorScheme='green'
                                                                                            size="lg"
                                                                                            bg={progressBarBg}
                                                                                            mb={2}
                                                                                            mt={4}
                                                                                            borderRadius={8}
                                                                                            hasStripe
                                                                                            isAnimated
                                                                                        >
                                                                                            <ProgressLabel color={progressLabelColor} fontSize="md">$950</ProgressLabel>
                                                                                        </Progress>
                                                                                    </Box>
                                                                                </HStack>
                                                                                <Text color="white" mb={8}>
                                                                                    Secure and transparent financial transactions are at the heart of our service.
                                                                                    Safely deposit funds into an project account and release payments to contractors
                                                                                    only upon your approval. Opt for convenient bank transfers or card payments.
                                                                                </Text>
                                                                                <Tooltip bg="black" label="A 2% service fee applies, with additional payment processing fees" placement="auto-start">
                                                                                    <FormControl display="flex" alignItems="center">
                                                                                        <FormLabel htmlFor="platform-payment-switch" mb="0" color="white" mr={4}>
                                                                                            Secure Funds
                                                                                        </FormLabel>
                                                                                        <Switch
                                                                                            id="platform-payment-switch"
                                                                                            isChecked={formData.platformPayment}
                                                                                            onChange={(e) => setFormData(prev => ({ ...prev, platformPayment: e.target.checked }))}
                                                                                            sx={{
                                                                                                '.chakra-switch__track': {
                                                                                                    _checked: {
                                                                                                        bg: "#1DADE3",
                                                                                                    }
                                                                                                }
                                                                                            }}
                                                                                        />
                                                                                    </FormControl>
                                                                                </Tooltip>
                                                                            </Flex>
                                                                        </Flex>
                                                                    )}
                                                                </FormControl>
                                                                <Spacer />
                                                                <Flex w={"100%"} align={"center"} justify={"flex-end"} borderRadius={"lg"} p={2} bg={"gray.700"}>
                                                                    <Button onClick={() => setCurrentStep(2)} bg="#1DADE3" _hover={"blue"} color={"white"} >
                                                                        <Text mr={2}>Next</Text>
                                                                        <FontAwesomeIcon icon={faAnglesRight} />
                                                                    </Button>
                                                                </Flex>
                                                            </Flex>
                                                        )}

                                                        {currentStep === 2 && (
                                                            <Flex minH={"650px"} direction={"column"}>
                                                                <Flex p={4} bg={"gray.700"} borderRadius={"lg"}>
                                                                    <FormControl isRequired>
                                                                        <FormLabel color={"white"}>Popular Services:</FormLabel>
                                                                        <SimpleGrid {...serviceGroup} columns={{ base: 1, sm: 2, md: 4, lg: 6 }} spacing={4}>
                                                                            {popularServices.map((value) => (
                                                                                <ServiceRadioCard
                                                                                    key={value}
                                                                                    value={value}
                                                                                    {...getServiceRadioProps({ value })}
                                                                                    serviceTitles={serviceTitles}
                                                                                    serviceDescriptions={serviceDescriptions}
                                                                                />
                                                                            ))}
                                                                        </SimpleGrid>
                                                                    </FormControl>
                                                                </Flex>

                                                                <Accordion mt={4} allowToggle>
                                                                    <AccordionItem>
                                                                        <Heading size={"sm"}>
                                                                            <AccordionButton borderRadius={"md"} _expanded={{ bg: 'gray.700', color: 'white' }}>
                                                                                <Box as='span' flex='1' textAlign='left'>
                                                                                    More Services
                                                                                </Box>
                                                                                <AccordionIcon />
                                                                            </AccordionButton>
                                                                        </Heading>
                                                                        <AccordionPanel h={"450px"} overflowY={"auto"} borderRadius={'lg'} mt={2} bg={"gray.700"} pb={4}>
                                                                            <SimpleGrid {...serviceGroup} columns={{ base: 1, sm: 2, md: 4, lg: 6 }} spacing={4}>
                                                                                {otherServices.map((value) => (
                                                                                    <ServiceRadioCard
                                                                                        key={value}
                                                                                        value={value}
                                                                                        {...getServiceRadioProps({ value })}
                                                                                        serviceTitles={serviceTitles}
                                                                                        serviceDescriptions={serviceDescriptions}
                                                                                    />
                                                                                ))}
                                                                            </SimpleGrid>
                                                                        </AccordionPanel>
                                                                    </AccordionItem>
                                                                </Accordion>
                                                                {/*
                                                                <FormControl mt={8} isRequired>
                                                                    <FormLabel>Trades Needed:</FormLabel>
                                                                    <Flex direction={"column"} position="relative">
                                                                        <Input
                                                                            placeholder="Add a trade"
                                                                            value={formData.trade}
                                                                            onChange={handleTradeChange}
                                                                            autoComplete="off"
                                                                        />
                                                                        <Flex direction="column" position="absolute" mt="12" w="full" zIndex="10" bg="white" boxShadow="md">
                                                                            {suggestions.map((suggestion, index) => (
                                                                                <Button
                                                                                    key={suggestion._id}
                                                                                    variant="ghost"
                                                                                    justifyContent="start"
                                                                                    onClick={() => selectSuggestion(suggestion.trade)}
                                                                                    _hover={{ bg: "gray.100" }}
                                                                                >
                                                                                    {suggestion.trade}
                                                                                </Button>
                                                                            ))}
                                                                        </Flex>
                                                                        <Flex direction="column" mt={2}>
                                                                            <Flex flexWrap="wrap" gap="2">
                                                                                {formData.tradesNeeded.map((trade, index) => (
                                                                                    <Tag size="lg" key={index} borderRadius="full" mb={1}>
                                                                                        <TagLabel>{trade}</TagLabel>
                                                                                        <TagCloseButton onClick={() => removeTradeNeeded(index)} />
                                                                                    </Tag>
                                                                                ))}
                                                                            </Flex>
                                                                        </Flex>
                                                                    </Flex>
                                                                </FormControl>
                                                                                */}
                                                                <Spacer />
                                                                <Flex w={"100%"} align={"center"} justify={"space-between"} borderRadius={"lg"} p={2} bg={"gray.700"}>
                                                                    <Button onClick={() => setCurrentStep(1)}>
                                                                        <FontAwesomeIcon icon={faAnglesLeft} />
                                                                        <Text ml={2}>Back</Text>
                                                                    </Button>
                                                                    <Button onClick={() => setCurrentStep(3)} bg="#1DADE3" _hover={"blue"} color={"white"} >
                                                                        <Text mr={2}>Next</Text>
                                                                        <FontAwesomeIcon icon={faAnglesRight} />
                                                                    </Button>
                                                                </Flex>
                                                            </Flex>
                                                        )}

                                                        {currentStep === 3 && (
                                                            <Flex direction={"column"} h={"650px"} overflowY={"auto"}>
                                                                <Flex justify={"space-between"} w={"100%"}>
                                                                    <Box w={"100%"}>
                                                                        <FormControl isRequired>
                                                                            <FormLabel>Tell us about your project</FormLabel>
                                                                            <Input placeholder="e.g., Kitchen Remodeling, Bathroom Renovation" name="title" value={formData.title} onChange={handleChange} />
                                                                        </FormControl>
                                                                        <FormControl isRequired  mt={4}>
                                                                            <FormLabel>Can you give more details?</FormLabel>
                                                                            <Textarea placeholder="Describe what you would like done, any specific requirements, materials, etc." name="description" value={formData.description} onChange={handleChange} />
                                                                        </FormControl>
                                                                    </Box>
                                                                </Flex>
                                                                <Spacer />
                                                                <Flex w={"100%"} align={"center"} justify={"space-between"} borderRadius={"lg"} p={2} bg={"gray.700"}>
                                                                    <Button onClick={() => setCurrentStep(2)}>
                                                                        <FontAwesomeIcon icon={faAnglesLeft} />
                                                                        <Text ml={2}>Back</Text>
                                                                    </Button>
                                                                    <Button onClick={() => setCurrentStep(4)} bg="#1DADE3" _hover={"blue"} color={"white"} >
                                                                        <Text mr={2}>Next</Text>
                                                                        <FontAwesomeIcon icon={faAnglesRight} />
                                                                    </Button>
                                                                </Flex>
                                                            </Flex>
                                                        )}

                                                        {currentStep === 4 && (
                                                            <Flex direction={"column"} h={"650px"} overflowY={"auto"}>
                                                                <FormControl>
                                                                    {isUploading && (
                                                                        <Flex w={"100%"} justify={"center"}>
                                                                            <Spinner color='gray.700' size={"xl"} />
                                                                        </Flex>
                                                                    )}

                                                                    {!isUploading && (
                                                                        <>
                                                                            <FormLabel>Images:</FormLabel>
                                                                            <Input type="file" accept="image/*" onChange={handleFileChange} multiple />
                                                                        </>
                                                                    )}

                                                                    <Flex wrap="wrap" mt={4} spacing={4}>
                                                                        {formData.imageUrls.map((url, index) => (
                                                                            <Box>
                                                                                <Flex w={"100%"} mb={-8} ml={-2} justify={"flex-end"}>
                                                                                    <Button
                                                                                        size="xs"
                                                                                        shadow={"lg"}
                                                                                        colorScheme="red"
                                                                                        onClick={() => handleImageRemove(index)}
                                                                                    >
                                                                                        X
                                                                                    </Button>
                                                                                </Flex>
                                                                                <Box key={index} p={2} boxShadow="lg" lineHeight="tight" borderRadius="md" overflow="hidden">
                                                                                    <Image src={url} alt="Uploaded preview" boxSize="150px" objectFit="cover" />
                                                                                </Box>
                                                                            </Box>
                                                                        ))}
                                                                    </Flex>
                                                                </FormControl>
                                                                <Spacer />
                                                                <Flex w={"100%"} align={"center"} justify={"space-between"} borderRadius={"lg"} p={2} bg={"gray.700"}>
                                                                    <Button onClick={() => setCurrentStep(3)}>
                                                                        <FontAwesomeIcon icon={faAnglesLeft} />
                                                                        <Text ml={2}>Back</Text>
                                                                    </Button>
                                                                    <Button onClick={() => setCurrentStep(5)} bg="#1DADE3" _hover={"blue"} color={"white"} >
                                                                        <Text mr={2}>Next</Text>
                                                                        <FontAwesomeIcon icon={faAnglesRight} />
                                                                    </Button>
                                                                </Flex>
                                                            </Flex>
                                                        )}

                                                        {currentStep === 5 && (
                                                            <Flex direction={"column"} h={"650px"} overflowY={"auto"}>
                                                                <FormControl>
                                                                    <FormLabel>Street</FormLabel>
                                                                    <Input name="street" value={formData.street} onChange={handleChange} />
                                                                </FormControl>
                                                                <Flex mt={4} justify={"space-evenly"} w={"100%"}>
                                                                    <FormControl m={2}>
                                                                        <FormLabel>City</FormLabel>
                                                                        <Input name="city" value={formData.city} onChange={handleChange} />
                                                                    </FormControl>
                                                                    <FormControl m={2}>
                                                                        <FormLabel>State</FormLabel>
                                                                        <Select placeholder="Select state" name="state" value={formData.state} onChange={handleChange}>
                                                                            {states.map(state => (
                                                                                <option key={state} value={state}>{state}</option>
                                                                            ))}
                                                                        </Select>
                                                                    </FormControl>
                                                                    <FormControl m={2}>
                                                                        <FormLabel>Zip Code</FormLabel>
                                                                        <Input name="zipCode" value={formData.zipCode} onChange={handleChange} />
                                                                    </FormControl>
                                                                </Flex>
                                                                <Spacer />
                                                                <Flex w={"100%"} align={"center"} justify={"space-between"} borderRadius={"lg"} p={2} bg={"gray.700"}>
                                                                    <Button onClick={() => setCurrentStep(4)}>
                                                                        <FontAwesomeIcon icon={faAnglesLeft} />
                                                                        <Text ml={2}>Back</Text>
                                                                    </Button>
                                                                    <Button isLoading={isLoading} onClick={(e) => {
                                                                        handleSubmit(e);
                                                                        setCurrentStep(currentStep + 1);
                                                                    }} bg="#1DADE3" _hover={"blue"} color={"white"} >
                                                                        <Text mr={2}>Submit</Text>
                                                                        <FontAwesomeIcon icon={faAnglesRight} />
                                                                    </Button>
                                                                </Flex>
                                                            </Flex>
                                                        )}
                                                    </Flex>
                                                </VStack>
                                            </Box>
                                        </Flex>
                                    </Flex>
                                </Flex>
                            </Flex>
                        </>
                    )}
                </Flex>
            </Flex>
        </Flex>
    );
}

export default WelcomeProject;