import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
    VStack, Input, Button, Container, Heading, FormControl, FormLabel,
    InputGroup, InputRightElement, IconButton, useToast, HStack, Image, Flex, Checkbox, Select,
    Tag, TagLabel, TagCloseButton, Wrap, Text, Link
} from '@chakra-ui/react';
import { ViewIcon, ViewOffIcon } from '@chakra-ui/icons';
import RoleSelector from '../elements/RadioCard';
import House from '../assets/Horizontal LOGO.webp';
import axios from 'axios';


function SignupForm() {
    const [formData, setFormData] = useState({
        firstName: '',
        lastName: '',
        email: '',
        phone: '',
        password: '',
        role: 'Home Owner',
        referralId: localStorage.getItem('referral'),
        specialty: '',
        tags: [],
        trade: '',
    });
    const [tagInput, setTagInput] = useState('');
    const [showPassword, setShowPassword] = useState(false);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [isTOSApproved, setTOSApproved] = useState(false);
    const [isGC, setGC] = useState(false);
    const navigate = useNavigate();
    const toast = useToast();

    const handleCheckboxChange = (event) => {
        setTOSApproved(event.target.checked);
    };

    const openTermsOfService = (event) => {
        event.preventDefault(); // Prevent the default link behavior
        window.open('/terms-of-service', '_blank'); // Open in a new tab
    };

    const formatPhoneNumber = (value) => {
        const phoneNumber = value.replace(/[^\d]/g, '');
        const phoneNumberLength = phoneNumber.length;
        if (phoneNumberLength < 4) return phoneNumber;
        if (phoneNumberLength < 7) {
            return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3)}`;
        }
        return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3, 6)}-${phoneNumber.slice(6, 10)}`;
    };

    const handleGoHome = () => {
        navigate('/');
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        if (name === 'phone') {
            setFormData({ ...formData, [name]: formatPhoneNumber(value) });
        } else if (name === 'email') {
            setFormData({ ...formData, [name]: value.toLowerCase() });
        } else {
            setFormData({ ...formData, [name]: value });
        }
    };

    const handleTagInputChange = (e) => {
        setTagInput(e.target.value);
    };

    const handleAddTag = (e) => {
        if (e.key === 'Enter' && tagInput.trim() && formData.tags.length < 15) {
            e.preventDefault();  // Prevent form submission on Enter key
            setFormData({
                ...formData,
                tags: [...formData.tags, tagInput.trim().toLowerCase()]
            });
            setTagInput('');
        }
    };

    const handleRemoveTag = (index) => {
        const newTags = formData.tags.filter((_, idx) => idx !== index);
        setFormData({ ...formData, tags: newTags });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsSubmitting(true);

        console.log('Submitting:', formData);  // Check what is being submitted

        try {
            const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/users/signup`, formData);
            setIsSubmitting(false);
            toast({
                title: 'Account created.',
                description: response.data.message,
                status: 'success',
                duration: 9000,
                isClosable: true,
            });
            navigate('/check-email');
        } catch (error) {
            setIsSubmitting(false);
            console.error('Submit Error:', error);  // Log errors to console for debugging
            toast({
                title: 'Error creating account.',
                description: error.response ? error.response.data.message : 'Network Error',
                status: 'error',
                duration: 9000,
                isClosable: true,
            });
        }
    };

    const togglePasswordVisibility = () => setShowPassword(!showPassword);

    return (
        <Container maxH={"100vh"} overflowY={"auto"} maxW="md" mt={10}>
            <VStack as="form" onSubmit={handleSubmit} spacing={6} justify={"center"} align="stretch">
                <Flex mb={4} w={"100%"} justify={"center"}>
                    <Image onClick={handleGoHome} src={House} maxH={"300px"} w={"fit-content"} />
                </Flex>
                <HStack>
                    <FormControl id="firstName" isRequired>
                        <FormLabel>First Name</FormLabel>
                        <Input
                            name="firstName"
                            value={formData.firstName}
                            onChange={handleChange}
                            placeholder="John Doe"
                        />
                    </FormControl>
                    <FormControl id="lastName" isRequired>
                        <FormLabel>Last Name</FormLabel>
                        <Input
                            name="lastName"
                            value={formData.lastName}
                            onChange={handleChange}
                            placeholder="John Doe"
                        />
                    </FormControl>
                </HStack>
                <FormControl id="email" isRequired>
                    <FormLabel>Email</FormLabel>
                    <Input
                        type="email"
                        name="email"
                        value={formData.email}
                        onChange={handleChange}
                        placeholder="john@example.com"
                    />
                </FormControl>
                <FormControl id="phone" isRequired>
                    <FormLabel>Phone Number</FormLabel>
                    <Input
                        type="phone"
                        name="phone"
                        value={formData.phone}
                        onChange={handleChange}
                        placeholder="(555) 555-5555"
                    />
                </FormControl>
                <FormControl id="password" isRequired>
                    <FormLabel>Password</FormLabel>
                    <InputGroup>
                        <Input
                            type={showPassword ? "text" : "password"}
                            name="password"
                            value={formData.password}
                            onChange={handleChange}
                            placeholder="••••••••"
                        />
                        <InputRightElement>
                            <IconButton
                                aria-label={showPassword ? "Hide password" : "Show password"}
                                icon={showPassword ? <ViewOffIcon /> : <ViewIcon />}
                                onClick={togglePasswordVisibility}
                                size="sm"
                                variant="ghost"
                            />
                        </InputRightElement>
                    </InputGroup>
                </FormControl>
                <FormControl as="fieldset" isRequired>
                    <FormLabel as="legend">Select One</FormLabel>
                    <RoleSelector
                        value={formData.role}
                        onChange={(value) => setFormData({ ...formData, role: value })}
                    />
                </FormControl>
                {formData.role === 'Contractor' && (
                    <>
                        <HStack align={"self-start"} w={"100%"}>
                            <FormControl w={"50%"} id="general">
                                <HStack w={"100%"}>
                                    <FormLabel>General Contractor</FormLabel>
                                    <Checkbox
                                        name="general"
                                        isChecked={isGC}
                                        onChange={(e) => setGC(e.target.checked)}
                                        colorScheme="teal"
                                    />
                                </HStack>
                            </FormControl>
                            {!isGC && (
                                <FormControl w={"50%"} id="trade" isRequired>
                                    <FormLabel>Trade</FormLabel>
                                    <Select
                                        name="trade"
                                        value={formData.trade}
                                        onChange={handleChange}
                                        placeholder="Select trade"
                                    >
                                        <option value="bricklaying">Bricklaying</option>
                                        <option value="carpentry">Carpentry</option>
                                        <option value="concrete">Concrete</option>
                                        <option value="drywall">Drywall</option>
                                        <option value="electrical">Electrical</option>
                                        <option value="flooring">Flooring</option>
                                        <option value="glazing">Glazing</option>
                                        <option value="hvac">HVAC</option>
                                        <option value="landscaping">Landscaping</option>
                                        <option value="masonry">Masonry</option>
                                        <option value="painting">Painting</option>
                                        <option value="plastering">Plastering</option>
                                        <option value="plumbing">Plumbing</option>
                                        <option value="roofing">Roofing</option>
                                        <option value="sheet metal">Sheet Metal</option>
                                        <option value="tiling">Tiling</option>
                                        <option value="waterproofing">Waterproofing</option>
                                        <option value="welding">Welding</option>
                                        <option value="window fitting">Window Fitting</option>
                                        <option value="other">Other</option>
                                    </Select>
                                </FormControl>
                            )}
                        </HStack>
                        <FormControl isRequired>
                            <FormLabel>Tags (up to 15)</FormLabel>
                            <Text>Add skills, jobs you are looking for, or specific services</Text>
                            <Input
                                type="text"
                                name="tags"
                                value={tagInput}
                                onChange={handleTagInputChange}
                                placeholder="Example: new construction... hit enter"
                                onKeyPress={event => event.key === 'Enter' && handleAddTag(event)}
                            />
                        </FormControl>
                        <Wrap spacing={1}>
                            {formData.tags.map((tag, index) => (
                                <Tag w={"fit-content"} size="md" key={index} borderRadius="full">
                                    <TagLabel>{tag}</TagLabel>
                                    <TagCloseButton onClick={() => handleRemoveTag(index)} />
                                </Tag>
                            ))}
                        </Wrap>
                    </>
                )}

                <Checkbox
                    name="termsOfService"
                    isChecked={isTOSApproved}
                    onChange={handleCheckboxChange}
                    colorScheme="blue"
                    spacing={2}
                    alignItems="flex-start"
                >
                    <Text>
                        I agree to the{' '}
                        <Link
                            to="/terms-of-service"
                            color="blue.500"
                            fontWeight="semibold"
                            textDecoration="underline"
                            _hover={{ textDecoration: 'none' }}
                            onClick={openTermsOfService}
                        >
                            Terms of Service
                        </Link>
                    </Text>
                </Checkbox>

                <Button
                    type="submit"
                    bg="#1DADE3"
                    _hover={{ bg: "blue.500" }}
                    color="white"
                    size="lg"
                    isLoading={isSubmitting}
                    isDisabled={!isTOSApproved}
                >
                    Sign Up
                </Button>
                <Text
                    fontSize="sm"
                    mt={4}
                    color="blue.500"
                    textAlign="center"
                    cursor="pointer"
                    onClick={() => navigate('/login')}
                >
                    Already have an account? Login
                </Text>
            </VStack>
        </Container>
    );
}

export default SignupForm;