import React, { useState, useEffect } from 'react';
import axios from 'axios';
import {
    Flex,
    Text,
    Heading,
    Box,
    VStack,
    SimpleGrid,
    useToast,
    Avatar,
    Stack,
    Badge,
    Icon,
    HStack,
    Divider,
    Image,
    Progress, Modal, ModalOverlay, ModalContent, ModalHeader, ModalCloseButton, ModalBody, useDisclosure, Collapse, useBreakpointValue
} from '@chakra-ui/react';
import { FaStar, FaGlobe, FaBuilding, FaBriefcase, FaShieldAlt, FaClipboardCheck, FaEnvelopeOpen } from 'react-icons/fa';
import ContractorSideMenu from '../contractor/SideMenu';
import Header from '../elements/Header';
import { format, parseISO } from 'date-fns';

const ContractorReviews = ({ userId }) => {
    const [contractor, setContractor] = useState({
        publicReviews: [], // Initialize as empty arrays
        privateReviews: [],
        licenses: [],
        servicesOffered: []
    });
    const [ratingsCount, setRatingsCount] = useState({});
    const menuType = useBreakpointValue({ base: 'drawer', lg: 'regular' });
    const [completedProjects, setCompletedProjects] = useState(0);
    const [isLoading, setIsLoading] = useState(true);
    const [selectedImage, setSelectedImage] = useState(null);
    const { isOpen, onOpen, onClose } = useDisclosure();
    const toast = useToast();
    const token = localStorage.getItem('token');

    useEffect(() => {
        const fetchContractor = async () => {
            setIsLoading(true);
            try {
                const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/contractor-info/${userId}`, {
                    headers: { Authorization: `Bearer ${token}` },
                });
                setContractor(response.data.contractor || { publicReviews: [], privateReviews: [], licenses: [], servicesOffered: [] });
                setCompletedProjects(response.data.completedProjectsCount || 0);
                calculateRatings(response.data.contractor.publicReviews);
            } catch (error) {
                toast({
                    title: "Error fetching contractor data",
                    description: error.response?.data?.message || "Failed to load data",
                    status: "error",
                    duration: 9000,
                    isClosable: true,
                });
                console.error('Error fetching data:', error);
            }
            setIsLoading(false);
        };

        fetchContractor();
    }, [userId, token]);

    const calculateRatings = (reviews) => {
        const count = reviews.reduce((acc, { rating }) => {
            const key = Math.floor(rating);
            acc[key] = (acc[key] || 0) + 1;
            return acc;
        }, {});
        setRatingsCount(count);
    };

    const handleImageClick = (imageURL) => {
        setSelectedImage(imageURL);
        onOpen();
    };

    const renderRatings = () => {
        const totalReviews = Object.values(ratingsCount).reduce((acc, cur) => acc + cur, 0);
        return (
            <Stack p={3} bg={"gray.700"} borderRadius={"md"}>
                <Text color={"gray.200"}>Stars</Text>
                {[5, 4, 3, 2, 1].map(star => (
                    <Flex w={"100%"} key={star} align="flex-start">
                        <Text color={"white"} fontSize={"xs"} ml={3}>{star}</Text>
                        <Flex w={"100%"}>
                            <Box w={{ base: "100%", lg: "90%" }}>
                                <Progress
                                    colorScheme="yellow"
                                    bg={"gray.500"}
                                    mt={2}
                                    size="xs"
                                    value={(ratingsCount[star] || 0) * 100 / totalReviews}
                                    width="full"
                                    ml={3}
                                />
                            </Box>
                            <Text fontSize={"xs"} ml={6} color={"white"}>({ratingsCount[star] || 0})</Text>
                        </Flex>
                    </Flex>
                ))}
            </Stack>
        );
    };

    const safeFormatDate = (dateString, formatString) => dateString ? format(parseISO(dateString), formatString) : 'N/A';

    const renderBasicProfile = () => (
        <VStack w={"100%"} spacing={8} align="stretch">
            <Flex w={"100%"} align="center">
                <Box w={"100%"}>
                    <Image src={contractor.business?.logo} w={"200px"} h={"auto"} />
                    <Flex w={"100%"} mt={4}>
                        <Box w={"100%"} ml={4}>
                            <Heading size="lg">{contractor.business?.name}</Heading>
                            <Flex mt={1} align={"center"}>
                                {contractor.profilePic && (
                                    <Avatar size="sm" src={contractor.profilePic} />
                                )}
                                <Heading ml={contractor.profilePic ? 2 : 0} size="md">{contractor.firstName} {contractor.lastName}</Heading>
                            </Flex>
                            {completedProjects > 10 && (
                                <Text color={"gray"} fontSize={"sm"}>Completed Projects: {completedProjects}</Text>
                            )}
                            <Text color={"gray"} fontSize={"sm"}>Joined: {safeFormatDate(contractor.createdAt, 'MMMM yyyy')}</Text>
                        </Box>
                    </Flex>
                </Box>
            </Flex>
            {!isLoading && renderRatings()}
            <VStack mt={4} maxH={"72vh"} overflowY={"scroll"} w={"100%"}>
                {contractor.publicReviews.map(review => (
                    <Box w={"100%"} key={review._id} bg="gray.600" p={4} borderRadius="md">
                        <HStack justify={"space-between"} align={"center"}>
                            <Flex>
                                {Array.from({ length: review.rating }, (_, i) => (
                                    <Icon ml={1} as={FaStar} color="goldenrod" key={i} />
                                ))}
                            </Flex>
                            <Text color={"gray"} fontSize={"sm"}>{safeFormatDate(review.date, 'MMMM yyyy')}</Text>
                        </HStack>
                        <Text color={"white"} mt={2}>{review.comment}</Text>
                        {review.imageURLs && review.imageURLs.length > 0 && (
                            <SimpleGrid columns={[1, 2, 3]} spacing={2} mt={2}>
                                {review.imageURLs.map((imageURL, index) => (
                                    <Image
                                        shadow={"lg"}
                                        key={index}
                                        src={imageURL}
                                        alt="Review Image"
                                        objectFit="cover"
                                        maxH="200px"
                                        borderRadius="md"
                                        cursor="pointer"
                                        onClick={() => handleImageClick(imageURL)}
                                    />
                                ))}
                            </SimpleGrid>
                        )}
                    </Box>
                ))}
            </VStack>
        </VStack>
    );

    const renderFullProfile = () => (
        <Flex mt={4} direction="column" p={{ base: 0, lg: 4 }} w="100%">
            <Flex w="100%" direction="column" align="center" spacing={8}>
                <Box w="100%">
                    <Image src={contractor.business?.logo} w={"200px"} h={"auto"} />
                    <Flex mt={4} align="center">
                        {contractor.profilePic && (
                            <Avatar size="sm" src={contractor.profilePic} />
                        )}
                        <Flex direction="column" ml={4}>
                            <Heading size="lg">{contractor.business?.name}</Heading>
                            <Heading size="md" mt={1}>{contractor.firstName} {contractor.lastName}</Heading>
                            {completedProjects > 10 && (
                                <Text color="gray.500" fontSize="sm">Completed Projects: {completedProjects}</Text>
                            )}
                            <Text color="gray.500" fontSize="sm">Joined: {safeFormatDate(contractor.createdAt, 'MMMM yyyy')}</Text>
                        </Flex>
                    </Flex>
                </Box>
                <VStack p={4} w="100%" spacing={4} align="flex-start">
                    <Box w="full">
                        <SimpleGrid w={"100%"} columns={{ base: 1, lg: 2 }} spacing={2}>
                            <Stack>
                                <HStack>
                                    <Icon as={FaBuilding} />
                                    <Text>
                                        {contractor.business?.address.street}, {contractor.business?.address.city},{' '}
                                        {contractor.business?.address.state} {contractor.business?.address.zipCode},{' '}
                                        {contractor.business?.address.country}
                                    </Text>
                                </HStack>
                                <HStack>
                                    <Icon as={FaGlobe} />
                                    <Text>{contractor.website}</Text>
                                </HStack>
                                <HStack>
                                    <Icon as={FaEnvelopeOpen} />
                                    <Text>{contractor.email}</Text>
                                </HStack>
                            </Stack>
                            <Stack>
                                <HStack>
                                    <Icon as={FaBriefcase} />
                                    <Text>Years in Business: {contractor.business?.yearsInBusiness}</Text>
                                </HStack>
                                <Box>
                                    <HStack>
                                        <Icon as={FaShieldAlt} />
                                        <Text>
                                            Services Offered:
                                        </Text>
                                    </HStack>
                                    {contractor.servicesOffered.map(service => (
                                        <Badge key={service} bg="#1DADE3" _hover={"blue"} color={"white"} mr={1}>
                                            {service}
                                        </Badge>
                                    ))}
                                </Box>
                            </Stack>
                        </SimpleGrid>
                    </Box>
                    <Divider />
                    {menuType === 'drawer' ? (
                        <VStack align={"flex-start"} justify={"space-evenly"}>
                            <Box w="100%">
                                <Heading size="md">Licenses and Certifications</Heading>
                                {contractor.licenses.map(license => (
                                    <Box key={license.number} p={4} borderRadius="md">
                                        <Stack>
                                            <HStack>
                                                <Icon as={FaClipboardCheck} />
                                                <Text fontWeight="bold">{license.type}</Text>
                                            </HStack>
                                            <Text>License Number: {license.number}</Text>
                                            <Text>Expiry Date: {safeFormatDate(license.expiryDate, 'yyyy-MM-dd')}</Text>
                                        </Stack>
                                    </Box>
                                ))}
                            </Box>
                            <Box w="100%">
                                <Heading size="md">Bonds</Heading>
                                {contractor?.bonds?.map((bond, index) => (
                                    <Box w={"fit-content"} key={bond.bondNumber} p={4} borderRadius="md">
                                        <Text>Bond Amount: ${bond.amount}</Text>
                                        <Text>Bonding Company: {bond.bondingCompany}</Text>
                                        <Text>Bond Number: {bond.bondNumber}</Text>
                                        <Text>Expiry Date: {safeFormatDate(bond.expiryDate, 'yyyy-MM-dd')}</Text>
                                    </Box>
                                ))}
                            </Box>
                            <Box w="100%">
                                <Heading size="md">Insurance</Heading>
                                {contractor?.insurance?.map((insurance, index) => (
                                    <Box w={"fit-content"} key={insurance.policyNumber} p={4} borderRadius="md">
                                        <Text>Provider: {insurance.provider}</Text>
                                        <Text>Policy Number: {insurance.policyNumber}</Text>
                                        <Text>Coverage Amount: ${insurance.coverageAmount}</Text>
                                        <Text>Expiry Date: {safeFormatDate(insurance.expiryDate, 'yyyy-MM-dd')}</Text>
                                        <Text>Type: {insurance.type}</Text>
                                    </Box>
                                ))}
                            </Box>
                        </VStack>
                    ) : (
                        <HStack align={"flex-start"} justify={"space-evenly"}>
                            <Box w="30%">
                                <Heading size="md">Licenses and Certifications</Heading>
                                {contractor.licenses.map(license => (
                                    <Box key={license.number} p={4} borderRadius="md">
                                        <Stack>
                                            <HStack>
                                                <Icon as={FaClipboardCheck} />
                                                <Text fontWeight="bold">{license.type}</Text>
                                            </HStack>
                                            <Text>License Number: {license.number}</Text>
                                            <Text>Expiry Date: {safeFormatDate(license.expiryDate, 'yyyy-MM-dd')}</Text>
                                        </Stack>
                                    </Box>
                                ))}
                            </Box>
                            <Box w="30%">
                                <Heading size="md">Bonds</Heading>
                                {contractor?.bonds?.map((bond, index) => (
                                    <Box w={"fit-content"} key={bond.bondNumber} p={4} borderRadius="md">
                                        <Text>Bond Amount: ${bond.amount}</Text>
                                        <Text>Bonding Company: {bond.bondingCompany}</Text>
                                        <Text>Bond Number: {bond.bondNumber}</Text>
                                        <Text>Expiry Date: {safeFormatDate(bond.expiryDate, 'yyyy-MM-dd')}</Text>
                                    </Box>
                                ))}
                            </Box>
                            <Box w="30%">
                                <Heading size="md">Insurance</Heading>
                                {contractor?.insurance?.map((insurance, index) => (
                                    <Box w={"fit-content"} key={insurance.policyNumber} p={4} borderRadius="md">
                                        <Text>Provider: {insurance.provider}</Text>
                                        <Text>Policy Number: {insurance.policyNumber}</Text>
                                        <Text>Coverage Amount: ${insurance.coverageAmount}</Text>
                                        <Text>Expiry Date: {safeFormatDate(insurance.expiryDate, 'yyyy-MM-dd')}</Text>
                                        <Text>Type: {insurance.type}</Text>
                                    </Box>
                                ))}
                            </Box>
                        </HStack>
                    )}
                    <Divider />
                </VStack>
            </Flex>
            <Flex w="100%" justify="center">
                <Box>
                    <Heading size="md" mb={2}>Reviews</Heading>
                    {!isLoading && renderRatings()}
                    <VStack mt={4} overflowY="auto" maxH="72vh">
                        {contractor.publicReviews.map(review => (
                            <Box key={review._id} bg="gray.600" p={4} borderRadius="md">
                                <HStack justify="space-between" align="center">
                                    <Flex>
                                        {Array.from({ length: review.rating }, (_, i) => (
                                            <Icon as={FaStar} color="goldenrod" key={i} />
                                        ))}
                                    </Flex>
                                    <Text color="gray.500" fontSize="sm">{safeFormatDate(review.date, 'MMMM yyyy')}</Text>
                                </HStack>
                                <Text color="white" mt={2}>{review.comment}</Text>
                                {review.imageURLs && (
                                    <SimpleGrid columns={[1, 2, 3]} spacing={2} mt={2}>
                                        {review.imageURLs.map((url, index) => (
                                            <Image
                                                key={index}
                                                src={url}
                                                alt="Review image"
                                                objectFit="cover"
                                                maxH="200px"
                                                borderRadius="md"
                                                cursor="pointer"
                                                onClick={() => handleImageClick(url)}
                                            />
                                        ))}
                                    </SimpleGrid>
                                )}
                            </Box>
                        ))}
                    </VStack>
                </Box>
            </Flex>
        </Flex>
    );

    return (
        <Flex direction="column" w="100%">
            {isLoading ? <Text>Loading...</Text> : (contractor.subscription === 'Basic' ? renderBasicProfile() : renderFullProfile())}
            <Modal isOpen={isOpen} onClose={onClose} size="xl">
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>Review Image</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody w={"100%"}>
                        <Image src={selectedImage} alt="Selected Review Image" objectFit="contain" maxW="100%" />
                    </ModalBody>
                </ModalContent>
            </Modal>
        </Flex>
    );
};

export default ContractorReviews;
