// SideExpansion.js

import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars, faBarsStaggered } from '@fortawesome/free-solid-svg-icons';
import { useSidebar } from '../elements/SidebarContext';
import { Flex } from '@chakra-ui/react';

function ExpansionBars() {
    const [bars, setBars] = useState(false);

    const { handleExpansion } = useSidebar();

    const handleBars = () => {
        setBars(!bars);
        handleExpansion();
    };

    return (
        <Flex>
            {bars ? (
                <FontAwesomeIcon onClick={handleBars} icon={faBars} color='white' size='lg' />
            ) : (
                <FontAwesomeIcon onClick={handleBars} icon={faBarsStaggered} color='white' size='lg' />
            )}
        </Flex>
    );
}

export default ExpansionBars;