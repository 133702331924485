import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { Box, Text, Button, Avatar, Badge, Flex, Icon, Heading, Spinner, Alert, AlertIcon, Image } from '@chakra-ui/react';
import { FaBuilding, FaEnvelopeOpen, FaHammer } from 'react-icons/fa';

const RandomContractors = () => {
    const [contractors, setContractors] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const navigate = useNavigate();

    useEffect(() => {
        const fetchRandomContractors = async () => {
            setLoading(true);
            setError(null);

            try {
                const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/contractors/random`, {
                    headers: { 'Content-Type': 'application/json' }
                });
                setContractors(response.data);
                setLoading(false);
            } catch (error) {
                console.error('Failed to fetch random contractors:', error);
                setError('Failed to load contractors. Please try again.');
                setLoading(false);
            }
        };

        fetchRandomContractors();
    }, []);

    const navigateToContractorProfile = (userId) => {
        localStorage.setItem('contractorId', userId);
        navigate(`/contractor/${userId}`);
    };

    if (loading) {
        return (
            <Flex justify="center" align="center" minH="100px">
                <Spinner />
            </Flex>
        );
    }

    if (error) {
        return (
            <Alert status="error" borderRadius="md" mb={4}>
                <AlertIcon />
                {error}
            </Alert>
        );
    }

    if (contractors.length === 0) {
        return null;
    }

    return (
        <Flex mb={4} w={"100%"} justify={"center"} p={2} pb={2}>
            <Flex direction={"column"} p={2} borderRadius={"lg"} bg={"gray.700"} maxW={"80%"} overflowX="scroll" pb={2}>
                <Heading color={"white"} m={2} size={"lg"}>Featured Contractors</Heading>
                <Flex maxW={"100%"} overflowX="scroll" pb={2}>
                    {contractors.map(contractor => (
                        <Box onClick={() => navigateToContractorProfile(contractor._id)} _hover={{ bg: "alphaWhite.200" }} ml={4} mr={8} key={contractor._id}>
                            <Box overflowY={"scroll"} maxH={"280px"} h={"fit-content"} key={contractor._id} borderWidth={1} borderRadius="md" p={4} mr={4} minW="300px">
                                {contractor.business?.logo && <Image p={1} borderRadius={"md"} bg={"lightgray"} mb={2} w={"auto"} h={"70px"} src={contractor.business.logo} mr={2} />}
                                <Heading color={"white"} size="md">{contractor.business?.name || 'No Business Name'}</Heading>
                                <Flex align="center" mb={2}>
                                    {contractor.profilePic && <Avatar size="md" src={contractor.business.logo} mr={2} />}
                                    <Text color={"white"} >{contractor.firstName} {contractor.lastName}</Text>

                                </Flex>
                                {/*
                                <Flex align="center" mb={2}>
                                    <Icon color={"white"} as={FaBuilding} mr={2} />
                                    <Text color={"white"}>{contractor.business?.address?.street}, {contractor.business?.address.city}</Text>
                                </Flex>
                                */}
                                <Flex align="center" mb={2}>
                                    <Icon color={"white"} as={FaHammer} mr={2} />
                                    
                                    <Text color={"white"}> Trade: {contractor.trade}</Text>
                                </Flex>
                                
                                <Flex align="center" mb={2}>
                                    <Icon color={"white"} as={FaEnvelopeOpen} mr={2} />
                                    <Text color={"white"}>{contractor.email}</Text>
                                </Flex>
                                <Flex maxW={"300px"} wrap="wrap" mb={2}>
                                    {contractor.servicesOffered.map(service => (
                                        <Badge key={service} bg="#1DADE3" _hover={"blue"} color={"white"} mr={2} mb={2}>{service}</Badge>
                                    ))}
                                </Flex>
                            </Box>
                        </Box>
                    ))}
                </Flex>
            </Flex>
        </Flex>
    );
};

export default RandomContractors;