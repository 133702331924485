import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import House from '../assets/Horizontal LOGO.webp';
import {
    VStack, Input, Button, Container, Heading, FormControl, FormLabel, InputGroup, InputRightElement, Image, IconButton, useToast, Modal, ModalOverlay, ModalContent, ModalHeader, ModalCloseButton, ModalBody, ModalFooter, Flex, Text, HStack
} from '@chakra-ui/react';
import { EmailIcon, PhoneIcon, ViewIcon, ViewOffIcon } from '@chakra-ui/icons';

function LoginForm() {
    const navigate = useNavigate();
    const [loginData, setLoginData] = useState({ email: '', password: '', phoneNumber: '' });
    const [showPassword, setShowPassword] = useState(false);
    const [isForgotPasswordOpen, setIsForgotPasswordOpen] = useState(false);
    const [resetEmail, setResetEmail] = useState('');
    const [isPhoneLogin, setIsPhoneLogin] = useState(true);
    const [requested, setRequested] = useState(false);
    const [code, setCode] = useState('');
    const toast = useToast();

    const handleForgotPasswordOpen = () => setIsForgotPasswordOpen(true);
    const handleForgotPasswordClose = () => setIsForgotPasswordOpen(false);

    const sendResetEmail = async () => {
        try {
            await axios.post(`${process.env.REACT_APP_API_URL}/api/reset-password`, { email: resetEmail });
            toast({
                title: 'Reset Email Sent.',
                description: "Check your email for reset instructions.",
                status: 'success',
                duration: 9000,
                isClosable: true,
            });
            handleForgotPasswordClose();
        } catch (error) {
            toast({
                title: 'Error sending reset email.',
                description: 'Please try again later.',
                status: 'error',
                duration: 9000,
                isClosable: true,
            });
        }
    };

    const formatPhoneNumber = (value) => {
        if (!value) return value;

        // Remove all non-digit characters
        const phoneNumber = value.replace(/[^\d]/g, '');

        // Format the number as (555) 555-5555
        const phoneNumberLength = phoneNumber.length;

        if (phoneNumberLength < 4) return phoneNumber;
        if (phoneNumberLength < 7) {
            return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3)}`;
        }
        return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3, 6)}-${phoneNumber.slice(6, 10)}`;
    };

    const handlePhoneChange = (e) => {
        const formattedPhoneNumber = formatPhoneNumber(e.target.value);
        setLoginData({ ...loginData, phoneNumber: formattedPhoneNumber });
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setLoginData({ ...loginData, [name]: value });
    };

    const handleGoHome = () => {
        navigate('/');
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/login`, loginData);

            if (response.status === 200) {
                console.log('response', response);

                localStorage.setItem('id', response.data.id);
                localStorage.setItem('token', response.data.token);
                localStorage.setItem('role', response.data.role);
                localStorage.setItem('firstName', response.data.firstName);
                localStorage.setItem('lastName', response.data.lastName);
                const contractorId = localStorage.getItem('contractorId');

                const stripe = await response.data.user.destinationAccountId;
                console.log('stripe', stripe, response.data.user.destinationAccountId);

                if (loginData.phoneNumber !== '') {
                    sendVerification();
                } else {
                    toast({
                        title: 'Login successful.',
                        description: "You're now logged in!",
                        status: 'success',
                        duration: 9000,
                        isClosable: true,
                    });

                    localStorage.setItem('verified', true);

                    if (response.data.role === 'Contractor') {
                        navigate('/contractor/dashboard');
                    } else if (contractorId) {
                        navigate(`/contractor/${contractorId}`);
                    } else {
                        navigate('/create-project');
                    }
                }

                /*
                if (response.data.role === 'Contractor' && !stripe) {
                    navigate('/contractor-details');
                } else if (response.data.role === 'Contractor' && response.data.subscription === 'Free') {
                    navigate('/subscriptions');
                } else if (response.data.role === 'Contractor' && response.data.subscription !== 'Free' && stripe) {
                    navigate('/contractor/dashboard');
                } else if (contractorId) {
                    navigate(`/contractor/${contractorId}`);
                } else {
                    navigate('/create-project');
                }
                */
            }
        } catch (error) {
            if (error.response && error.response.status === 403 && error.response.data.canReactivate) {
                const reactivate = window.confirm(error.response.data.message);
                if (reactivate) {
                    reactivateAccount(loginData.email);
                }
            } else {
                console.error('Login failed:', error);
                toast({
                    title: 'Login failed.',
                    description: 'An error occurred. Please try again.',
                    status: 'error',
                    duration: 9000,
                    isClosable: true,
                });
            }
        }
    };

    // Function to reactivate account
    const reactivateAccount = async (email) => {
        try {
            const response = await axios.patch(`${process.env.REACT_APP_API_URL}/api/users/reactivate`, { email }, {
                headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
            });
            if (response.status === 200) {
                toast({
                    title: 'Account Reactivated.',
                    description: 'Your account has been reactivated. Please log in again.',
                    status: 'success',
                    duration: 9000,
                    isClosable: true,
                });
                // Clear any stored credentials and reinitialize the login flow
                localStorage.clear();
                setLoginData({ email: '', password: '' });
            }
        } catch (error) {
            console.error('Error reactivating account:', error);
            toast({
                title: 'Account Reactivation Failed.',
                description: 'Could not reactivate account. Please contact support.',
                status: 'error',
                duration: 9000,
                isClosable: true,
            });
        }
    };

    const sendVerification = () => {
        const token = localStorage.getItem('token');
        setRequested(true);
        axios.post(`${process.env.REACT_APP_API_URL}/api/send-verification`, {}, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        })
            .then(response => {


            })
            .catch(error => {
                console.error('Error sending verification:', error);
            });
    };

    const verifyCode = () => {
        const token = localStorage.getItem('token');
        axios.post(`${process.env.REACT_APP_API_URL}/api/verify-code`, { code }, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        })
            .then(response => {
                toast({
                    title: "Verification Success",
                    description: "Your code was verified successfully",
                    status: "success",
                    duration: 9000,
                    isClosable: true
                });

                toast({
                    title: 'Login successful.',
                    description: "You're now logged in!",
                    status: 'success',
                    duration: 9000,
                    isClosable: true,
                });

                localStorage.setItem('verified', true);
                const role = localStorage.getItem('role');
                const contractorId = localStorage.getItem('contractorId');

                if (role === 'Contractor') {
                    navigate('/contractor/dashboard');
                } else if (contractorId) {
                    navigate(`/contractor/${contractorId}`);
                } else {
                    navigate('/create-project');
                }
            })
            .catch(error => {
                console.error('Verification error:', error);
                toast({
                    title: "Verification Failed",
                    description: "Failed to verify the code",
                    status: "error",
                    duration: 9000,
                    isClosable: true
                });
            });
    };


    const togglePasswordVisibility = () => setShowPassword(!showPassword);

    const setEmailLogin = () => {
        if (isPhoneLogin) {
            setIsPhoneLogin(false); // Switch to email login
            setLoginData({ ...loginData, email: '', phoneNumber: '' }); // Reset the input fields
        }
    };

    const setPhoneLogin = () => {
        if (!isPhoneLogin) {
            setIsPhoneLogin(true); // Switch to phone login
            setLoginData({ ...loginData, email: '', phoneNumber: '' }); // Reset the input fields
        }
    };

    return (
        <Container maxW="md" mt={10}>
            <VStack as="form" onSubmit={handleSubmit} spacing={6} justify={"center"} align="stretch">
                <Flex mb={4} w={"100%"} justify={"center"}>
                    <Image onClick={handleGoHome} src={House} maxH={"300px"} h={"auto"} w={"auto"} />
                </Flex>

                <HStack>
                    <IconButton
                        variant='outline'
                        onClick={setPhoneLogin}  // Only switches to phone login if email login is active
                        colorScheme={isPhoneLogin ? 'teal' : 'gray'}
                        aria-label='Log in with phone'
                        icon={<PhoneIcon />}
                    />

                    <IconButton
                        variant='outline'
                        onClick={setEmailLogin}  // Only switches to email login if phone login is active
                        colorScheme={!isPhoneLogin ? 'teal' : 'gray'}
                        aria-label='Log in with email'
                        icon={<EmailIcon />}
                    />
                </HStack>
                {requested ? (

                    <Flex direction={{ base: "column", lg: "row" }} justify={"flex-end"} align={"center"}>
                        {requested && (
                            <Input
                                placeholder="Enter verification code"
                                value={code}
                                onChange={(e) => setCode(e.target.value)}
                                w={{ base: "200px", lg: "400px" }}
                                mr={2}
                            />
                        )}
                        {!requested && (
                            <Button border={"2px"} shadow={"md"} borderColor={"lemonchiffon"} mr={2} size={{ base: "xs", lg: "sm" }} onClick={sendVerification}>Sign Proposal</Button>
                        )}
                        {requested && (
                            <Button mr={2} size={"md"} bg="#1DADE3" _hover={"blue"} color={"white"} onClick={verifyCode}>Verify Code</Button>
                        )}
                    </Flex>

                ) : (
                    <>
                        {isPhoneLogin ? (
                            <FormControl id="phoneNumber" isRequired>
                                <FormLabel>Phone Number</FormLabel>
                                <Input
                                    type="tel"
                                    name="phoneNumber"
                                    value={loginData.phoneNumber}
                                    onChange={handlePhoneChange}
                                    placeholder="(555) 555-5555"
                                />
                            </FormControl>
                        ) : (
                            <FormControl id="email" isRequired>
                                <FormLabel>Email</FormLabel>
                                <Input
                                    type="email"
                                    name="email"
                                    value={loginData.email}
                                    onChange={handleChange}
                                    placeholder="john@example.com"
                                />
                            </FormControl>
                        )}

                        <FormControl id="password" isRequired>
                            <FormLabel>Password</FormLabel>
                            <InputGroup>
                                <Input
                                    type={showPassword ? "text" : "password"}
                                    name="password"
                                    value={loginData.password}
                                    onChange={handleChange}
                                    placeholder="••••••••"
                                />
                                <InputRightElement>
                                    <IconButton
                                        aria-label={showPassword ? "Hide password" : "Show password"}
                                        icon={showPassword ? <ViewOffIcon /> : <ViewIcon />}
                                        onClick={togglePasswordVisibility}
                                        size="sm"
                                        variant="ghost"
                                    />
                                </InputRightElement>
                            </InputGroup>
                        </FormControl>
                        <Button type="submit" bg="#1DADE3" _hover={"blue"} color={"white"} size="lg">Login</Button>

                    </>
                )}

                <Button onClick={handleForgotPasswordOpen}>Forgot Password?</Button>
                <Text
                    fontSize="sm"
                    mt={4}
                    color="blue.500"
                    textAlign="center"
                    cursor="pointer"
                    onClick={() => navigate('/signup')}
                >
                    Don't have an account? Sign up.
                </Text>

            </VStack>
            <Modal isOpen={isForgotPasswordOpen} onClose={handleForgotPasswordClose}>
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>Reset Your Password</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                        <FormControl>
                            <FormLabel>Email</FormLabel>
                            <Input type="email" value={resetEmail} onChange={(e) => setResetEmail(e.target.value)} placeholder="Enter your email" />
                        </FormControl>
                    </ModalBody>
                    <ModalFooter>
                        <Button bg="#1DADE3" _hover={"blue"} color={"white"} mr={3} onClick={sendResetEmail}>Send Reset Link</Button>
                        <Button variant="ghost" onClick={handleForgotPasswordClose}>Cancel</Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>
        </Container>
    );
}

export default LoginForm;