import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Heading, Text, Box, Button, Flex, Image } from '@chakra-ui/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHome } from '@fortawesome/free-solid-svg-icons';
import Logo from '../assets/Horizontal LOGO.webp';
import BROKEN from '../assets/BROKEN.webp';

function PageNotFound() {
    const navigate = useNavigate();

    const handleGoHome = () => {
        navigate('/');
    };

    return (
        <Flex
            direction="column"
            align={"flex-start"}
            w="100%"
            h="100vh" // Ensures the Flex container takes up the full viewport height
            justify="flex-start"
            bgImage={BROKEN} // Correct the path to your image
            bgPosition="center" // Centers the background image
            bgRepeat="no-repeat" // Prevents the image from repeating
            bgSize="cover" // Ensures the background image covers the entire Flex area
        >
            <Flex  p={8} w={"100%"} align={"flex-start"} justify={"center"}>
                <Box>
                    <Image mb={4} src={Logo} w={"400px"} h={"auto"} />
                    <Box mb={8} bg="gray.100" p={6} borderRadius="md">
                        <Heading as="h1" size="xl" mb={4}>
                            404 - Page Not Found
                        </Heading>
                        <Text mb={6}>
                            Oops! The page you're looking for doesn't exist.
                        </Text>
                        <Button
                            leftIcon={<FontAwesomeIcon icon={faHome} />}
                            colorScheme="blue"
                            onClick={handleGoHome}
                        >
                            Go to Home
                        </Button>
                    </Box>
                </Box>
            </Flex>
        </Flex>
    );
}

export default PageNotFound;