import React, { useState } from 'react';
import axios from 'axios';
import { Box, Button, Flex, Heading, Input, InputGroup, InputLeftElement, Text, useBreakpointValue } from '@chakra-ui/react';
import { PhoneIcon } from '@chakra-ui/icons';

const VerifyPhone = ({ onPhoneVerified }) => {
    const [code, setCode] = useState('');
    const [isVerified, setIsVerified] = useState(false);
    const [sent, setSent] = useState(false);
    const [error, setError] = useState(null);
    const menuType = useBreakpointValue({ base: 'drawer', lg: 'regular' });
    const token = localStorage.getItem('token');

    const handleSendCode = async () => {
        setSent(true);
        try {
            await axios.post(`${process.env.REACT_APP_API_URL}/api/verify-phone`, null, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            });

        } catch (error) {
            setError(error.response.data.message);
        }
    };

    const handleVerifyCode = async () => {
        try {
            const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/verify-code-phone`, { code }, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            });
            if (response.data.success) {
                setIsVerified(true);
                onPhoneVerified();
            } else {
                setError(response.data.message);
            }
        } catch (error) {
            setError(error.response?.data?.message || "An unexpected error occurred");
        }
    };

    return (
        <Flex w={"100%"} align={"center"} direction={"column"}>
            {menuType === 'drawer' ? (
                <>
                    {!isVerified ? (
                        <Box w={"100%"}>
                            <Heading fontSize={"md"} mb={4}>Verify Your Phone Number</Heading>
                            {sent && (
                                <InputGroup>
                                    <InputLeftElement pointerEvents='none'>
                                        <PhoneIcon color='gray.300' />
                                    </InputLeftElement>
                                    <Input
                                        type="text"
                                        placeholder="Enter verification code"
                                        value={code}
                                        onChange={(e) => setCode(e.target.value)}
                                    />
                                </InputGroup>
                            )}
                            <Flex mt={2} w={"100%"} justify={"flex-end"}>
                                {!sent ? (
                                    <Button onClick={handleSendCode}>Send Code</Button>
                                ) : (
                                    <>
                                        {error && <p>{error}</p>}
                                        <Button onClick={handleVerifyCode}>Verify</Button>
                                    </>
                                )}
                            </Flex>
                        </Box>
                    ) : (
                        <Heading>Phone number verified successfully!</Heading>
                    )}
                </>
            ) : (
                <>
                    {!isVerified ? (
                        <Box w={"100%"}>
                            <Heading mb={4}>Verify Your Phone Number</Heading>
                            {sent && (
                                <InputGroup>
                                    <InputLeftElement pointerEvents='none'>
                                        <PhoneIcon color='gray.300' />
                                    </InputLeftElement>
                                    <Input
                                        type="text"
                                        placeholder="Enter verification code"
                                        value={code}
                                        onChange={(e) => setCode(e.target.value)}
                                    />
                                </InputGroup>
                            )}
                            <Flex mt={2} w={"100%"} justify={"flex-end"}>
                                {!sent ? (
                                    <Button onClick={handleSendCode}>Send Code</Button>
                                ) : (
                                    <>
                                        {error && <p>{error}</p>}
                                        <Button onClick={handleVerifyCode}>Verify Code</Button>
                                    </>
                                )}
                            </Flex>
                        </Box>
                    ) : (
                        <Heading>Phone number verified successfully!</Heading>
                    )}
                </>
            )}
        </Flex>
    );
};

export default VerifyPhone;