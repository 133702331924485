import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import {
    Menu, MenuButton, MenuList, MenuItem, Button, Avatar, Text, Flex, MenuDivider, useColorMode, Modal,
    ModalOverlay, ModalContent, ModalHeader, ModalFooter, ModalBody, ModalCloseButton, FormControl,
    FormLabel, Input, useDisclosure, HStack, InputGroup, InputRightElement, Select,
    Drawer, DrawerBody, DrawerHeader, DrawerOverlay, DrawerContent, DrawerCloseButton, Link, Box
} from '@chakra-ui/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRightFromBracket, faChevronDown, faGears, faMoon, faSun, faUser } from '@fortawesome/free-solid-svg-icons';

const UserMenu = ({ firstName, profilePic }) => {
    const { colorMode, toggleColorMode } = useColorMode();
    const navigate = useNavigate();
    const { isOpen, onOpen, onClose } = useDisclosure();
    const { isOpen: isDrawerOpen, onOpen: onDrawerOpen, onClose: onDrawerClose } = useDisclosure();
    const [user, setUser] = useState({});
    const [formData, setFormData] = useState({
        firstName: '',
        lastName: '',
        email: '',
        phone: '',
        password: '',
        address: {
            street: '',
            city: '',
            state: '',
            zipCode: '',
            country: ''
        }
    });
    const [initialFormData, setInitialFormData] = useState({});
    const [showPassword, setShowPassword] = useState(false);
    const token = localStorage.getItem('token');

    useEffect(() => {
        const getUser = async () => {
            try {
                const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/contractor`, {
                    headers: { Authorization: `Bearer ${token}` }
                });
                const fetchedData = response.data;
                setUser(fetchedData);
                const initialData = {
                    firstName: fetchedData.firstName || '',
                    lastName: fetchedData.lastName || '',
                    email: fetchedData.email || '',
                    phone: fetchedData.phone || '',
                    password: '',
                    address: {
                        street: fetchedData.address?.street || '',
                        city: fetchedData.address?.city || '',
                        state: fetchedData.address?.state || '',
                        zipCode: fetchedData.address?.zipCode || '',
                        country: fetchedData.address?.country || ''
                    }
                };
                setFormData(initialData);
                setInitialFormData(initialData);
            } catch (error) {
                console.error('Error fetching user data:', error);
            }
        };
        getUser();
    }, [token]);

    const formatPhoneNumber = (value) => {
        const phoneNumber = value.replace(/[^\d]/g, '');
        const phoneNumberLength = phoneNumber.length;
        if (phoneNumberLength < 4) return phoneNumber;
        if (phoneNumberLength < 7) {
            return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3)}`;
        }
        return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3, 6)}-${phoneNumber.slice(6, 10)}`;
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        if (name.startsWith('address.')) {
            // Handle address fields
            const [field, subField] = name.split('.');
            setFormData({
                ...formData,
                [field]: {
                    ...formData[field],
                    [subField]: value,
                },
            });
        } else if (name === 'phone') {
            setFormData({ ...formData, [name]: formatPhoneNumber(value) });
        } else if (name === 'email') {
            setFormData({ ...formData, [name]: value.toLowerCase() });
        } else {
            setFormData({ ...formData, [name]: value });
        }
    };

    const handleLogOut = () => {
        window.localStorage.clear();
        navigate('/login');
    };

    const handleSaveChanges = async () => {
        const changes = Object.keys(formData).reduce((acc, key) => {
            if (key !== 'password' && formData[key] !== initialFormData[key]) {
                acc[key] = formData[key];
            }
            return acc;
        }, {});

        if (Object.keys(changes).length === 0) {
            console.log('No changes to save.');
            onClose();
            return;
        }

        try {
            const response = await axios.patch(`${process.env.REACT_APP_API_URL}/api/users/me`, changes, {
                headers: { Authorization: `Bearer ${token}` }
            });
            console.log('Update successful:', response.data);
            onClose();
            setInitialFormData(formData); // Update initial form data to new saved data
        } catch (error) {
            console.error('Error updating user data:', error);
            if (error.response) {
                console.error('Error data:', error.response.data);
                console.error('Error status:', error.response.status);
                console.error('Error headers:', error.response.headers);
            } else if (error.request) {
                console.error('Error request:', error.request);
            } else {
                console.error('Error message:', error.message);
            }
        }
    };

    const handlePasswordChange = async () => {
        try {
            await axios.patch(`${process.env.REACT_APP_API_URL}/api/users/password`, { password: formData.password }, {
                headers: { Authorization: `Bearer ${token}` }
            });
            console.log('Password updated successfully');
            // Optionally, you can reset the password field in the form data
            setFormData({ ...formData, password: '' });
        } catch (error) {
            console.error('Error updating password:', error);
            if (error.response) {
                console.error('Error data:', error.response.data);
                console.error('Error status:', error.response.status);
                console.error('Error headers:', error.response.headers);
            } else if (error.request) {
                console.error('Error request:', error.request);
            } else {
                console.error('Error message:', error.message);
            }
        }
    };

    const archiveAccount = async () => {
        try {
            const response = await axios.patch(`${process.env.REACT_APP_API_URL}/api/users/archive`, {}, {
                headers: { Authorization: `Bearer ${token}` }
            });
            console.log('Account archived:', response.data);
            onDrawerClose(); // Close the drawer
            handleLogOut();
        } catch (error) {
            console.error('Error archiving account:', error);
        }
    };

    const states = [
        "Alabama", "Alaska", "Arizona", "Arkansas", "California", "Colorado", "Connecticut", "Delaware", "Florida", "Georgia",
        "Hawaii", "Idaho", "Illinois", "Indiana", "Iowa", "Kansas", "Kentucky", "Louisiana", "Maine", "Maryland",
        "Massachusetts", "Michigan", "Minnesota", "Mississippi", "Missouri", "Montana", "Nebraska", "Nevada", "New Hampshire", "New Jersey",
        "New Mexico", "New York", "North Carolina", "North Dakota", "Ohio", "Oklahoma", "Oregon", "Pennsylvania", "Rhode Island", "South Carolina",
        "South Dakota", "Tennessee", "Texas", "Utah", "Vermont", "Virginia", "Washington", "West Virginia", "Wisconsin", "Wyoming"
    ];

    const togglePasswordVisibility = () => setShowPassword(!showPassword);

    return (
        <>
            <Menu>
                <MenuButton as={Button} rounded={'full'} variant={'link'} cursor={'pointer'}>
                    <Flex align={"center"}>
                        <Avatar size={'sm'} name={firstName} src={profilePic} />
                        <Text color={"white"} ml={2} mr={2}>{firstName}</Text>
                        <FontAwesomeIcon icon={faChevronDown} />
                    </Flex>
                </MenuButton>
                <MenuList>
                    <MenuItem onClick={onOpen}>
                        <FontAwesomeIcon icon={faUser} />
                        <Text ml={4}>My Account</Text>
                    </MenuItem>
                    <MenuItem onClick={onDrawerOpen}>
                        <FontAwesomeIcon icon={faGears} />
                        <Text ml={4}>Settings</Text>
                    </MenuItem>
                    <MenuDivider />
                    <MenuItem onClick={toggleColorMode}>
                        <Text mr={2}>Toggle Theme</Text>
                        <FontAwesomeIcon icon={colorMode === 'light' ? faMoon : faSun} />
                    </MenuItem>
                    <MenuDivider />
                    <MenuItem onClick={handleLogOut}>
                        <FontAwesomeIcon icon={faArrowRightFromBracket} />
                        <Text ml={4}>Logout</Text>
                    </MenuItem>
                </MenuList>
            </Menu>

            <Modal isOpen={isOpen} onClose={onClose}>
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>Edit Your Account</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody pb={6}>
                        <HStack spacing={4}>
                            <FormControl>
                                <FormLabel>First Name</FormLabel>
                                <Input name="firstName" value={formData.firstName} onChange={handleChange} />
                            </FormControl>
                            <FormControl>
                                <FormLabel>Last Name</FormLabel>
                                <Input name="lastName" value={formData.lastName} onChange={handleChange} />
                            </FormControl>
                        </HStack>
                        <FormControl mt={4}>
                            <FormLabel>Email</FormLabel>
                            <Input type="email" name="email" value={formData.email} onChange={handleChange} />
                        </FormControl>
                        <FormControl id="phone" isRequired>
                            <FormLabel>Phone Number</FormLabel>
                            <Input
                                type="phone"
                                name="phone"
                                value={formData.phone}
                                onChange={handleChange}
                                placeholder="(555) 555-5555"
                            />
                        </FormControl>
                        <FormControl mt={4}>
                            <FormLabel>Password</FormLabel>
                            <InputGroup size="md">
                                <Input
                                    pr="4.5rem"
                                    type={showPassword ? 'text' : 'password'}
                                    name="password"
                                    value={formData.password}
                                    onChange={handleChange}
                                />
                                <InputRightElement width="4.5rem">
                                    <Button h="1.75rem" size="sm" onClick={togglePasswordVisibility}>
                                        {showPassword ? 'Hide' : 'Show'}
                                    </Button>
                                </InputRightElement>
                            </InputGroup>
                        </FormControl>
                        <FormControl mt={4}>
                            <FormLabel>Address</FormLabel>
                            <Input name="address.street" placeholder="Street" value={formData.address.street} onChange={handleChange} id="address-street" />
                            <HStack mt={2}>
                                <Input name="address.city" placeholder="City" value={formData.address.city} onChange={handleChange} id="address-city" />
                                <Select
                                    name="address.state"
                                    placeholder="Select state"
                                    value={formData.address.state}
                                    onChange={handleChange}
                                    id="address-state"
                                >
                                    {states.map(state => (
                                        <option key={state} value={state}>{state}</option>
                                    ))}
                                </Select>

                            </HStack>
                            <HStack mt={2}>
                                <Input name="address.zipCode" placeholder="Zip Code" value={formData.address.zipCode} onChange={handleChange} id="address-zipcode" />
                                <Input name="address.country" placeholder="Country" value={formData.address.country} onChange={handleChange} id="address-country" />
                            </HStack>
                        </FormControl>
                    </ModalBody>
                    <ModalFooter>
                        <Button colorScheme="blue" mr={3} onClick={handleSaveChanges}>
                            Save
                        </Button>
                        <Button bg="#1DADE3" _hover={"blue"} color={"white"}  mr={3} onClick={handlePasswordChange}>
                            Update Password
                        </Button>
                        <Button onClick={onClose}>Cancel</Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>

            <Drawer placement="right" onClose={onDrawerClose} isOpen={isDrawerOpen}>
                <DrawerOverlay />
                <DrawerContent>
                    <DrawerCloseButton />
                    <DrawerHeader>Settings</DrawerHeader>
                    <DrawerBody>
                        <Box>
                            <Link href="mailto:cashby71@protonmail.com" isExternal>
                                <Button colorScheme="blue">Email Customer Service</Button>
                            </Link>
                            <Button mt={4} colorScheme="orange" onClick={archiveAccount}>Archive Account</Button>
                        </Box>
                    </DrawerBody>
                </DrawerContent>
            </Drawer>
        </>
    );
};

export default UserMenu;
