import React, { useState } from 'react';
import { Flex, IconButton, Spinner, Text } from '@chakra-ui/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import axios from 'axios';
import { faUserPlus } from '@fortawesome/free-solid-svg-icons';

function CreateStripeAccountButton() {
    const token = localStorage.getItem('token');
    const [isLoading, setIsLoading] = useState(false);

    const handleCreateAccount = async () => {
        setIsLoading(true);
        const headers = {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json'
        };
        try {
            const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/stripe/create-express-account`, {}, { headers });
            // Redirect to Stripe's onboarding flow
            window.location.href = response.data.url;
        } catch (error) {
            console.error('Error creating Stripe account:', error);
        }
    };

    return (
        <Flex onClick={handleCreateAccount} p={1} justify={"center"} align={"center"} w={"100%"}>
            {isLoading ? (
                <Spinner size={"lg"} color='#1DADE3' />
            ) : (
                <>
                    <IconButton
                        icon={<FontAwesomeIcon icon={faUserPlus} />}
                        bg="#1DADE3"
                        color={"white"}
                        aria-label="Create Stripe Account"
                    />
                    <Text color={"white"} ml={2}>Create Referral Account</Text>
                </>
            )}
        </Flex>
    );
}

export default CreateStripeAccountButton;