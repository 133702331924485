import React, { useState, useEffect } from 'react';
import { useAtom } from 'jotai';
import { socketAtom } from '../socketAtom';
import { Box, Text, List, ListItem, Spinner, Heading, Flex, Button, Link } from '@chakra-ui/react';
import { BeatLoader } from 'react-spinners';
import { faExternalLinkAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import axios from 'axios';

const HandoffList = ({ state }) => {
    const [socket] = useAtom(socketAtom);
    const token = localStorage.getItem('token');
    const [handoffs, setHandoffs] = useState([]);
    const [contractor, setContractor] = useState({});
    const [loading, setLoading] = useState(true);
    const [buyingHandoffId, setBuyingHandoffId] = useState(null);
    const id = localStorage.getItem('id');
    console.log('id', id);

    useEffect(() => {
        // Join the socket to a room based on the state
        const roomName = `Handoff-${state}`;
        socket.emit('joinRoom', roomName);

        const headers = {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
        };

        // Fetch existing handoffs for the state
        const fetchHandoffs = async () => {
            try {
                const response = await fetch(`${process.env.REACT_APP_API_URL}/api/handoffs?state=${state}`, {
                    headers,
                });
                const data = await response.json();
                // Sort handoffs by creation date, newest first
                const sortedHandoffs = data.handoffs.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
                setHandoffs(sortedHandoffs);
                setLoading(false);
            } catch (error) {
                console.error('Error fetching handoffs:', error);
                setLoading(false);
            }
        };

        fetchHandoffs();

        // Listen for new handoffs in the room
        socket.on('newHandoff', (newHandoff) => {
            if (newHandoff.homeowner.address.state === state) {
                setHandoffs((prevHandoffs) => [newHandoff, ...prevHandoffs]);
            }
        });

        return () => {
            socket.off('newHandoff');
            socket.emit('leaveRoom', roomName);
        };
    }, [socket, state, token]);

    useEffect(() => {
        const getContractor = async () => {
            setLoading(true);
            try {
                const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/contractor`, {
                    headers: { Authorization: `Bearer ${token}` }
                });
                const fetchedData = response.data;
                setContractor(fetchedData);
                setLoading(false);
            } catch (error) {
                console.error('Error fetching contractor data:', error);
                setLoading(false);
            }
        };
        getContractor();
    }, [token]);

    const handleBuyHandoff = (handoffId) => {
        setBuyingHandoffId(handoffId);
        socket.emit('buyHandoff', { handoffId }, (response) => {
            if (response.error) {
                console.error('Error buying handoff:', response.message);
                // Handle the error, e.g., show an error message to the user
            } else {
                if (response.url) {
                    // Open the Stripe Checkout URL in a new window or tab
                    window.open(response.url, '_blank');
                } else {
                    // Handle the case when no URL is provided, e.g., show a success message to the user
                    console.log('Handoff purchased successfully.');
                }
            }
        });
    };

    const isUserInContractors = (handoff) => {
        console.log('Checking contractors for handoff:', handoff.contractors);
        const userInContractors = handoff.contractors.some((contractor) => contractor === id);
        console.log('User in contractors:', userInContractors);
        return userInContractors;
    };

    return (
        <Box p={4} borderRadius="md" w="100%">
            <Heading color={"white"} as="h2" size="md" mb={4}>
                Handoffs in {state}
            </Heading>
            {loading ? (
                <Spinner />
            ) : (
                <List maxH={"600px"} overflowY={"scroll"} spacing={3}>
                    {handoffs?.map((handoff) => {
                        const disabled = isUserInContractors(handoff);
                        const isLoading = handoff._id === buyingHandoffId;
                        return (
                            <ListItem border={"1px"} color={"white"} key={handoff._id} p={2} borderRadius="md" boxShadow="sm">
                                <Flex justify={"space-between"}>
                                    <Text fontWeight={'semibold'}>
                                        {handoff.homeowner.firstName}
                                    </Text>
                                    <Text fontSize={'lg'} fontWeight={'semibold'} color={"green.200"}>${handoff.leadPrice}</Text>
                                </Flex>
                                <Text>
                                    {handoff.homeowner.address.city},{' '}
                                    {handoff.homeowner.address.state} {handoff.homeowner.address.zipCode}
                                </Text>
                                <Flex w={"100%"}>
                                    <Flex direction={"column"} w={"100%"}>
                                        <Text>{handoff.leadDescription}</Text>
                                        <Flex justify={"flex-end"} w={"100%"}>
                                            {contractor.isVetted ? (
                                                <Button
                                                    isLoading={disabled || isLoading}
                                                    spinnerPlacement='end'
                                                    spinner={<BeatLoader size={8} color='white' />}
                                                    colorScheme='green'
                                                    onClick={() => handleBuyHandoff(handoff._id)}
                                                >
                                                    Buy
                                                </Button>
                                            ) : (
                                                <Flex w={'fit-content'} p={2} borderRadius={"md"} bg={"gray.300"}>
                                                    <Text color={"black"} mr={2}>Complete Verification to Get Handoff</Text>
                                                    <Link color={"black"} href="https://calendly.com/cameron-pvai/30min" isExternal>
                                                        <Flex align={"center"}><FontAwesomeIcon color={"black"} icon={faExternalLinkAlt} /> <Text ml={2} color={"black"}>Calendly</Text></Flex>
                                                    </Link>
                                                </Flex>
                                            )}
                                        </Flex>
                                    </Flex>
                                </Flex>
                            </ListItem>
                        );
                    })}
                </List>
            )}
        </Box>
    );
};

export default HandoffList;