import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Logo from '../assets/Horizontal LOGO.webp';
import { Container, Heading, Text, Box, UnorderedList, ListItem, Link, Checkbox, Button, Icon, Flex, HStack, Image, useBreakpointValue, VStack } from '@chakra-ui/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAnglesLeft, faAnglesRight, faArrowDown, faArrowRight, faCircleCheck, faExternalLink, faInfoCircle, faLock, faSearch, faSearchDollar, faStar, faUser, faWarning } from '@fortawesome/free-solid-svg-icons';
import { faCreditCard } from '@fortawesome/free-regular-svg-icons';
import axios from 'axios';

const ContractorDetails = () => {
    const [currentStep, setCurrentStep] = useState(1);
    const navigate = useNavigate();
    const [accept, setAccept] = useState(false);
    const menuType = useBreakpointValue({ base: 'drawer', lg: 'regular' });

    const handleSubmit = async () => {
        if (accept) {
            try {
                const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/acceptTOS`, null, {
                    headers: {
                        'Authorization': `Bearer ${localStorage.getItem('token')}`
                    }
                });
                if (response.status === 201) {
                    navigate('/contractor/dashboard');
                }
            } catch (error) {
                console.error('Error accepting TOS:', error);
                // Handle error appropriately
            }
        }
    };

    return (
        <Container maxW="container.lg" py={8}>
            <Flex w={"100%"} justify={"center"}><Image mb={4} src={Logo} w={"400px"} h={"auto"} /></Flex>
            {menuType === 'drawer' ? (
                <>
                    {currentStep === 1 && (
                        <Box mb={8} bg="gray.100" p={6} borderRadius="md">
                            <Heading as="h2" size="xl" mb={4} color="blue.500">
                                How It Works
                            </Heading>
                            <VStack p={4} align={"center"} justify={"space-between"}>
                                <Box>
                                    <Heading as="h3" size="lg" color="gray">
                                        <FontAwesomeIcon icon={faUser} /> Business Profile
                                    </Heading>
                                </Box>
                                <FontAwesomeIcon color="gray" icon={faArrowDown} />
                                <Box>
                                    <Heading as="h3" size="lg" color="gray">
                                        <FontAwesomeIcon icon={faCreditCard} /> Stripe Account
                                    </Heading>
                                </Box>
                                <FontAwesomeIcon color="gray" icon={faArrowDown} />
                                <Box>
                                    <Heading as="h3" size="lg" color="gray">
                                        <FontAwesomeIcon icon={faLock} /> Get Verified
                                    </Heading>
                                </Box>
                            </VStack>
                        </Box>
                    )}
                    {currentStep === 2 && (
                        <>
                            <Heading as="h1" size="2xl" mb={8} color="blue.500" textAlign="center">
                                Terms of Offering Services Via UtahRemodels.com
                            </Heading>
                            <Text fontSize="lg" mb={8} textAlign="center">
                                UtahRemodels.com Contractors Terms of Service<br />
                                May 16th, 2024
                            </Text>
                            <Box mb={8} bg="gray.100" p={6} borderRadius="md">
                                <Heading as="h2" size="lg" mb={4} color="blue.500">
                                    Table of Contents
                                </Heading>
                                <UnorderedList spacing={2}>
                                    <ListItem><Link href="#acceptance">1. Acceptance of Terms</Link></ListItem>
                                    <ListItem><Link href="#proposals">2. Proposal Requirements</Link></ListItem>
                                    <ListItem><Link href="#payments">3. Payment Processing</Link></ListItem>
                                    <ListItem><Link href="#proposal-limits">4. Proposal Limits</Link></ListItem>
                                    <ListItem><Link href="#elite-subscriptions">5. Elite Subscriptions</Link></ListItem>
                                    <ListItem><Link href="#refunds">6. Refund Policy</Link></ListItem>
                                    <ListItem><Link href="#disputes">7. Dispute Resolution</Link></ListItem>
                                    <ListItem><Link href="#contractor-info">8. Contractor Information and Verification</Link></ListItem>
                                    <ListItem><Link href="#termination">9. Termination</Link></ListItem>
                                    <ListItem><Link href="#liability">10. Limitation of Liability</Link></ListItem>
                                    <ListItem><Link href="#governing-law">11. Governing Law</Link></ListItem>
                                    <ListItem><Link href="#contact">12. Contact Us</Link></ListItem>
                                </UnorderedList>
                            </Box>

                            <Box id="acceptance" mb={8}>
                                <Heading as="h2" size="xl" mb={4}>
                                    1. Acceptance of Terms
                                </Heading>
                                <Text fontSize="lg" mb={4}>
                                    By accessing or using UtahRemodels.com, you, as a contractor, agree to be bound by these Terms of Service (the "Terms"). These Terms apply to all users of the site, including contractors, homeowners, merchants, and contributors of content, information, and other materials or services on the website.
                                </Text>

                            </Box>

                            <Box id="proposals" mb={8}>
                                <Heading as="h2" size="xl" mb={4}>
                                    2. Proposal Requirements
                                </Heading>
                                <Text fontSize="lg" mb={4}>
                                    All proposals submitted by contractors must be compatible with flat rate pricing. Contractors are required to provide clear, detailed, and accurate pricing information for their services.
                                </Text>

                            </Box>

                            <Box id="payments" mb={8}>
                                <Heading as="h2" size="xl" mb={4}>
                                    3. Payment Processing
                                </Heading>
                                <Text fontSize="lg" mb={4}>
                                    Homeowners have the option to pay for services in person or through the UtahRemodels.com platform. If a homeowner chooses to pay online, a 2% service fee will be automatically added to the total cost on the Proposal as a Serivce Fee.
                                </Text>
                                <Text fontSize="lg" mb={4}>
                                    When the Homeowner initiates a transfer of funds, you will see the 2% held back automatically.
                                </Text>

                            </Box>

                            <Box id="proposal-limits" mb={8}>
                                <Heading as="h2" size="xl" mb={4}>
                                    4. Proposal Limits
                                </Heading>
                                <Text fontSize="lg" mb={4}>
                                    A maximum of four (5) contractors can provide proposals for each project listed on UtahRemodels.com. This ensures that homeowners receive a manageable number of competitive bids while maintaining fairness for all contractors. As a contractor, you understand and agree to this limitation when submitting proposals through the platform.
                                </Text>

                            </Box>


                            <Box id="elite-subscriptions" mb={8}>
                                <Heading as="h2" size="xl" mb={4}>
                                    5. Elite Subscriptions
                                </Heading>
                                <Text fontSize="lg" mb={4}>
                                    UtahRemodels.com offers "Elite" subscriptions for contractors, which are prominently advertised on the website's home page. In the future, review ratings and verifications will be required for contractors to maintain their Elite subscription status.
                                </Text>

                            </Box>

                            <Box id="refunds" mb={8}>
                                <Heading as="h2" size="xl" mb={4}>
                                    6. Refund Policy
                                </Heading>
                                <Text fontSize="lg" mb={4}>
                                    If a homeowner does not make contact with a contractor, the contractor may be eligible for a refund of any fees paid to UtahRemodels.com. Refund requests will be evaluated on a case-by-case basis.
                                </Text>
                            </Box>

                            <Box id="disputes" mb={8}>
                                <Heading as="h2" size="xl" mb={4}>
                                    7. Dispute Resolution
                                </Heading>
                                <Text fontSize="lg">
                                    UtahRemodels.com is not a party to the actual transactions between homeowners and contractors. Any disputes regarding services, damages, or liabilities should be resolved directly between the homeowner and the contractor. In the event of a homeowner not transferring on-platform funds to a contractor for completed work, UtahRemodels.com will make a determination to abstain or transfer funds based on the evidence provided by both parties.
                                </Text>

                            </Box>

                            <Box id="contractor-info" mb={8}>
                                <Heading as="h2" size="xl" mb={4}>
                                    8. Contractor Information and Verification
                                </Heading>
                                <Text fontSize="lg">
                                    As a contractor, you must hold the proper business and trade licenses, insurance, bonds, and any other required documentation for the work you are conducting through UtahRemodels.com. You are responsible for providing accurate and up-to-date information regarding your licenses, bonds, insurance, and criminal background checks, which may be displayed on the platform. UtahRemodels.com may use third-party sources to verify this information. Homeowners are responsible for verifying all such information before engaging a contractor.
                                </Text>

                            </Box>

                            <Box id="termination" mb={8}>
                                <Heading as="h2" size="xl" mb={4}>
                                    9. Termination
                                </Heading>
                                <Text fontSize="lg">
                                    UtahRemodels.com may terminate or suspend your account and bar access to the website immediately, without prior notice or liability, under our sole discretion, for any reason whatsoever and without limitation, including but not limited to a breach of the Terms.
                                </Text>

                            </Box>

                            <Box id="liability" mb={8}>
                                <Heading as="h2" size="xl" mb={4}>
                                    10. Limitation of Liability
                                </Heading>
                                <Text fontSize="lg">
                                    In no event will UtahRemodels.com, nor its directors, employees, partners, agents, suppliers, or affiliates, be liable for any indirect, incidental, special, consequential, or punitive damages, resulting from your access to or use of or inability to access or use the website.
                                </Text>

                            </Box>

                            <Box id="governing-law" mb={8}>
                                <Heading as="h2" size="xl" mb={4}>
                                    11. Governing Law
                                </Heading>
                                <Text fontSize="lg">
                                    These Terms shall be governed and construed in accordance with the laws of the State of Utah, United States, without regard to its conflict of law provisions.
                                </Text>

                            </Box>

                            <Box id="contact" mb={8}>
                                <Heading as="h2" size="xl" mb={4}>
                                    12. Contact Us
                                </Heading>
                                <Text fontSize="lg">
                                    If you have any questions about these Terms, please contact us at <Link href="mailto:Hi@UtahRemodels.com" color="blue.500">Hi@UtahRemodels.com</Link> <FontAwesomeIcon icon={faExternalLink} />
                                </Text>
                                <Checkbox m={4} p={2} bg={ accept ? "" : "red.100"} borderRadius={"md"} borderColor={"red"} color={"blue.500"} isChecked={accept} onChange={(e) => setAccept(e.target.checked)}>I have read and accept the Contractor Terms</Checkbox>
                            </Box>
                        </>
                    )}
                    <HStack mb={8} w={"100%"} justify={"space-between"} mt={4}>
                        <Button size={"sm"} onClick={() => setCurrentStep(currentStep - 1)} colorScheme="blue" leftIcon={<FontAwesomeIcon icon={faAnglesLeft} />}>
                            Back
                        </Button>
                        {currentStep === 2 ? (
                            <>
                                {accept && (
                                    <Button size={"sm"} onClick={handleSubmit} colorScheme="blue" rightIcon={<FontAwesomeIcon icon={faAnglesRight} />}>
                                        I Accept the Contractor Terms
                                    </Button>
                                )}
                            </>
                        ) : (
                            <Button size={"sm"} onClick={() => setCurrentStep(currentStep + 1)} colorScheme="blue" rightIcon={<FontAwesomeIcon icon={faAnglesRight} />}>
                                Next
                            </Button>
                        )}
                    </HStack>
                </>
            ) : (
                <>
                    {currentStep === 1 && (
                        <Box mb={8} bg="gray.100" p={6} borderRadius="md">
                            <Heading as="h2" size="xl" mb={4} color="blue.500">
                                How It Works
                            </Heading>
                            <Flex p={4} align={"center"} justify={"space-between"}>
                                <Box>
                                    <Heading as="h3" size="lg" color="gray">
                                        <FontAwesomeIcon icon={faUser} /> Business Profile
                                    </Heading>
                                </Box>
                                <FontAwesomeIcon color="gray" icon={faArrowRight} />
                                <Box>
                                    <Heading as="h3" size="lg" color="gray">
                                        <FontAwesomeIcon icon={faCreditCard} /> Stripe Account
                                    </Heading>
                                </Box>
                                <FontAwesomeIcon color="gray" icon={faArrowRight} />
                                <Box>
                                    <Heading as="h3" size="lg" color="gray">
                                        <FontAwesomeIcon icon={faLock} /> Get Verified
                                    </Heading>
                                </Box>
                            </Flex>
                        </Box>
                    )}
                    {currentStep === 2 && (
                        <>
                            <Heading as="h1" size="2xl" mb={8} color="blue.500" textAlign="center">
                                Terms of Offering Services Via UtahRemodels.com
                            </Heading>
                            <Text fontSize="lg" mb={8} textAlign="center">
                                UtahRemodels.com Contractors Terms of Service<br />
                                May 16th, 2024
                            </Text>
                            <Box mb={8} bg="gray.100" p={6} borderRadius="md">
                                <Heading as="h2" size="lg" mb={4} color="blue.500">
                                    Table of Contents
                                </Heading>
                                <UnorderedList spacing={2}>
                                    <ListItem><Link href="#acceptance">1. Acceptance of Terms</Link></ListItem>
                                    <ListItem><Link href="#proposals">2. Proposal Requirements</Link></ListItem>
                                    <ListItem><Link href="#payments">3. Payment Processing</Link></ListItem>
                                    <ListItem><Link href="#proposal-limits">4. Proposal Limits</Link></ListItem>
                                    <ListItem><Link href="#elite-subscriptions">5. Elite Subscriptions</Link></ListItem>
                                    <ListItem><Link href="#refunds">6. Refund Policy</Link></ListItem>
                                    <ListItem><Link href="#disputes">7. Dispute Resolution</Link></ListItem>
                                    <ListItem><Link href="#contractor-info">8. Contractor Information and Verification</Link></ListItem>
                                    <ListItem><Link href="#termination">9. Termination</Link></ListItem>
                                    <ListItem><Link href="#liability">10. Limitation of Liability</Link></ListItem>
                                    <ListItem><Link href="#governing-law">11. Governing Law</Link></ListItem>
                                    <ListItem><Link href="#contact">12. Contact Us</Link></ListItem>
                                </UnorderedList>
                            </Box>

                            <Box id="acceptance" mb={8}>
                                <Heading as="h2" size="xl" mb={4}>
                                    1. Acceptance of Terms
                                </Heading>
                                <Text fontSize="lg" mb={4}>
                                    By accessing or using UtahRemodels.com, you, as a contractor, agree to be bound by these Terms of Service (the "Terms"). These Terms apply to all users of the site, including contractors, homeowners, merchants, and contributors of content, information, and other materials or services on the website.
                                </Text>

                            </Box>

                            <Box id="proposals" mb={8}>
                                <Heading as="h2" size="xl" mb={4}>
                                    2. Proposal Requirements
                                </Heading>
                                <Text fontSize="lg" mb={4}>
                                    All proposals submitted by contractors must be compatible with flat rate pricing. Contractors are required to provide clear, detailed, and accurate pricing information for their services.
                                </Text>

                            </Box>

                            <Box id="payments" mb={8}>
                                <Heading as="h2" size="xl" mb={4}>
                                    3. Payment Processing
                                </Heading>
                                <Text fontSize="lg" mb={4}>
                                    Homeowners have the option to pay for services in person or through the UtahRemodels.com platform. If a homeowner chooses to pay online, a 2% service fee will be automatically added to the total cost on the Proposal as a Serivce Fee.
                                </Text>
                                <Text fontSize="lg" mb={4}>
                                    When the Homeowner initiates a transfer of funds, you will see the 2% held back automatically.
                                </Text>

                            </Box>

                            <Box id="proposal-limits" mb={8}>
                                <Heading as="h2" size="xl" mb={4}>
                                    4. Proposal Limits
                                </Heading>
                                <Text fontSize="lg" mb={4}>
                                    A maximum of four (5) contractors can provide proposals for each project listed on UtahRemodels.com. This ensures that homeowners receive a manageable number of competitive bids while maintaining fairness for all contractors. As a contractor, you understand and agree to this limitation when submitting proposals through the platform.
                                </Text>

                            </Box>


                            <Box id="elite-subscriptions" mb={8}>
                                <Heading as="h2" size="xl" mb={4}>
                                    5. Elite Subscriptions
                                </Heading>
                                <Text fontSize="lg" mb={4}>
                                    UtahRemodels.com offers "Elite" subscriptions for contractors, which are prominently advertised on the website's home page. In the future, review ratings and verifications will be required for contractors to maintain their Elite subscription status.
                                </Text>

                            </Box>

                            <Box id="refunds" mb={8}>
                                <Heading as="h2" size="xl" mb={4}>
                                    6. Refund Policy
                                </Heading>
                                <Text fontSize="lg" mb={4}>
                                    If a homeowner does not make contact with a contractor, the contractor may be eligible for a refund of any fees paid to UtahRemodels.com. Refund requests will be evaluated on a case-by-case basis.
                                </Text>
                            </Box>

                            <Box id="disputes" mb={8}>
                                <Heading as="h2" size="xl" mb={4}>
                                    7. Dispute Resolution
                                </Heading>
                                <Text fontSize="lg">
                                    UtahRemodels.com is not a party to the actual transactions between homeowners and contractors. Any disputes regarding services, damages, or liabilities should be resolved directly between the homeowner and the contractor. In the event of a homeowner not transferring on-platform funds to a contractor for completed work, UtahRemodels.com will make a determination to abstain or transfer funds based on the evidence provided by both parties.
                                </Text>

                            </Box>

                            <Box id="contractor-info" mb={8}>
                                <Heading as="h2" size="xl" mb={4}>
                                    8. Contractor Information and Verification
                                </Heading>
                                <Text fontSize="lg">
                                    As a contractor, you must hold the proper business and trade licenses, insurance, bonds, and any other required documentation for the work you are conducting through UtahRemodels.com. You are responsible for providing accurate and up-to-date information regarding your licenses, bonds, insurance, and criminal background checks, which may be displayed on the platform. UtahRemodels.com may use third-party sources to verify this information. Homeowners are responsible for verifying all such information before engaging a contractor.
                                </Text>

                            </Box>

                            <Box id="termination" mb={8}>
                                <Heading as="h2" size="xl" mb={4}>
                                    9. Termination
                                </Heading>
                                <Text fontSize="lg">
                                    UtahRemodels.com may terminate or suspend your account and bar access to the website immediately, without prior notice or liability, under our sole discretion, for any reason whatsoever and without limitation, including but not limited to a breach of the Terms.
                                </Text>

                            </Box>

                            <Box id="liability" mb={8}>
                                <Heading as="h2" size="xl" mb={4}>
                                    10. Limitation of Liability
                                </Heading>
                                <Text fontSize="lg">
                                    In no event will UtahRemodels.com, nor its directors, employees, partners, agents, suppliers, or affiliates, be liable for any indirect, incidental, special, consequential, or punitive damages, resulting from your access to or use of or inability to access or use the website.
                                </Text>

                            </Box>

                            <Box id="governing-law" mb={8}>
                                <Heading as="h2" size="xl" mb={4}>
                                    11. Governing Law
                                </Heading>
                                <Text fontSize="lg">
                                    These Terms shall be governed and construed in accordance with the laws of the State of Utah, United States, without regard to its conflict of law provisions.
                                </Text>

                            </Box>

                            <Box id="contact" mb={8}>
                                <Heading as="h2" size="xl" mb={4}>
                                    12. Contact Us
                                </Heading>
                                <Text fontSize="lg">
                                    If you have any questions about these Terms, please contact us at <Link href="mailto:Hi@UtahRemodels.com" color="blue.500">Hi@UtahRemodels.com</Link> <FontAwesomeIcon icon={faExternalLink} />
                                </Text>
                                <Checkbox mt={4} borderRadius={"md"} p={2} bg={accept ? "" : "red.100"} borderColor={"red"} color={"blue.500"} isChecked={accept} onChange={(e) => setAccept(e.target.checked)}>I have read and accept the Contractor Terms</Checkbox>
                            </Box>
                        </>
                    )}


                    <HStack w={"100%"} justify={"space-between"} mt={4}>
                        <Button onClick={() => setCurrentStep(currentStep - 1)} colorScheme="blue" leftIcon={<FontAwesomeIcon icon={faAnglesLeft} />}>
                            Back
                        </Button>
                        {currentStep === 2 ? (
                            <>
                                {accept && (
                                    <Button onClick={handleSubmit} colorScheme="blue" rightIcon={<FontAwesomeIcon icon={faAnglesRight} />}>
                                        I have read and accept the Contractor Terms
                                    </Button>
                                )}
                            </>
                        ) : (
                            <Button onClick={() => setCurrentStep(currentStep + 1)} colorScheme="blue" rightIcon={<FontAwesomeIcon icon={faAnglesRight} />}>
                                Next
                            </Button>
                        )}
                    </HStack>
                </>
            )}
        </Container>
    );
};

export default ContractorDetails;