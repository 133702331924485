import React, { useState, useEffect } from 'react';
import { Box, Flex, Text, VStack, Input, Button, Select, SimpleGrid, Image, useDisclosure, Divider, Spacer, Tag, TagLabel, TagCloseButton, Badge, Modal, ModalOverlay, ModalContent, ModalBody, Heading, useBreakpointValue, Link } from '@chakra-ui/react';
import Header from '../elements/Header';
import ContractorSideMenu from './SideMenu';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { socketAtom } from '../socketAtom';
import { useAtom } from 'jotai';
import axios from 'axios';
import { io } from 'socket.io-client';
import { faExternalLinkAlt, faLock, faLockOpen, faMagnifyingGlassPlus } from '@fortawesome/free-solid-svg-icons';

function RecommendedProjects() {
    const [socket, setSocket] = useAtom(socketAtom);
    const menuType = useBreakpointValue({ base: 'drawer', lg: 'regular' });
    const [projects, setProjects] = useState([]);
    const [contractor, setContractor] = useState({});
    const [titleFilter, setTitleFilter] = useState('');
    const [cityFilter, setCityFilter] = useState('');
    const [statusFilter, setStatusFilter] = useState('');
    const [tradeTags, setTradeTags] = useState([]);
    const [tradeFilter, setTradeFilter] = useState('');
    const [serviceFilter, setServiceFilter] = useState('');
    const [loading, setLoading] = useState(true);
    const [trades, setTrades] = useState([]);
    const [tradeSearch, setTradeSearch] = useState('');
    const [tradeSuggestions, setTradeSuggestions] = useState([]);
    const token = localStorage.getItem('token');
    const contractorId = localStorage.getItem('id');
    const { isOpen, onOpen, onClose } = useDisclosure();
    const [selectedImg, setSelectedImg] = useState('');

    const hasContractorBidOnProject = (project) => {
        return project.bids.some((bid) => bid.contractor === contractorId);
    };

    useEffect(() => {
        const fetchRecommendedProjects = async () => {
            const headers = {
                Authorization: `Bearer ${localStorage.getItem('token')}`,
            };

            try {
                const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/recommended-projects`, { headers });
                const sortedProjects = response.data.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
                setProjects(sortedProjects);
            } catch (error) {
                console.error('Failed to fetch recommended projects:', error);
            } finally {
                setLoading(false);
            }
        };

        fetchRecommendedProjects();
    }, []);

    useEffect(() => {
        const newSocket = io(process.env.REACT_APP_API_URL, {
            autoConnect: true,
            auth: {
                token: token,
            },
        });

        newSocket.on('connect_error', (error) => {
            console.error('Socket connection error:', error);
        });

        newSocket.on('redirectToStripeCheckout', (data) => {
            if (data.url) {
                window.location.href = data.url;
            }
        });

        setSocket(newSocket);

        return () => {
            newSocket.off('redirectToStripeCheckout');
            newSocket.close();
        };
    }, [setSocket, token]);

    useEffect(() => {
        const fetchTrades = async () => {
            try {
                const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/trades`);
                const sortedTrades = response.data.sort((a, b) => a.trade.localeCompare(b.trade));
                setTrades(sortedTrades);
            } catch (error) {
                console.error('Failed to fetch trades:', error);
            }
        };

        fetchTrades()

        const handleBidPlaced = (updatedProject) => {
            setProjects((prevProjects) =>
                prevProjects.map((project) =>
                    project._id === updatedProject._id
                        ? {
                            ...updatedProject,
                            bids: updatedProject.bids,
                        }
                        : project
                )
            );
        };

        const handleProjectUpdated = (updatedProject) => {
            setProjects((prevProjects) =>
                prevProjects.map((project) =>
                    project._id === updatedProject._id
                        ? {
                            ...updatedProject,
                            bids: updatedProject.bids,
                            leadPrice: project.leadPrice
                        }
                        : project
                )
            );
        };

        socket.on('bidPlaced', handleBidPlaced);
        socket.on('projectUpdated', handleProjectUpdated);

        return () => {
            socket.off('bidPlaced', handleBidPlaced);
            socket.off('projectUpdated', handleProjectUpdated);
        };
    }, [socket]);

    const handleTitleFilterChange = (e) => setTitleFilter(e.target.value);
    const handleCityFilterChange = (e) => setCityFilter(e.target.value);
    const handleStatusFilterChange = (e) => setStatusFilter(e.target.value);

    const handleTradeSearchChange = (e) => {
        const searchText = e.target.value;
        setTradeSearch(searchText);
        setTradeSuggestions(trades.filter(trade => trade.trade.toLowerCase().includes(searchText.toLowerCase())));
    };

    const handleImageClick = (url) => {
        setSelectedImg(url);
        onOpen();
    };

    const addTradeTag = (trade) => {
        if (!tradeTags.includes(trade) && trade) {
            setTradeTags([...tradeTags, trade]);
        }
        setTradeSearch('');
        setTradeSuggestions([]);
    };

    const removeTradeTag = (index) => {
        setTradeTags(tradeTags.filter((_, i) => i !== index));
    };

    useEffect(() => {
        const getContractor = async () => {
            setLoading(true);
            try {
                const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/contractor`, {
                    headers: { Authorization: `Bearer ${token}` }
                });
                const fetchedData = response.data;
                setContractor(fetchedData);
                setLoading(false);
            } catch (error) {
                console.error('Error fetching contractor data:', error);
                setLoading(false);
            }
        };
        getContractor();
    }, [token]);

    const filteredProjects = projects.filter(project =>
        project.title.toLowerCase().includes(titleFilter.toLowerCase()) &&
        project.address.city.toLowerCase().includes(cityFilter.toLowerCase()) &&
        (statusFilter === '' || project.status === statusFilter) &&
        (serviceFilter === '' || project.service === serviceFilter) &&
        (tradeTags.length === 0 || tradeTags.some(tag => project.tradesNeeded.includes(tag)))
    );

    return (
        <Flex direction="column" w="100%" justify="center">
            <Flex direction={{ base: "column", lg: "row" }} p={2} w="100%" align="flex-start" justify="flex-start">
                <ContractorSideMenu />
                <Flex m={{ base: 0, lg: 4 }} direction="column" w="100%" justify="center">
                    <Header />
                    <Flex direction="column" w="100%" align="flex-start" justify="flex-stat">
                        {menuType === 'drawer' ? (
                            <VStack mt={4} w="100%" spacing={4} align="stretch">
                                <Flex direction={"column"} h={"fit-content"} justify={"center"} align={"center"} w={"100%"}>
                                    <Input
                                        value={titleFilter}
                                        m={2}
                                        onChange={handleTitleFilterChange}
                                        placeholder="Filter projects by title"
                                    />
                                    <Input
                                        value={cityFilter}
                                        m={2}
                                        onChange={handleCityFilterChange}
                                        placeholder="Search by city..."
                                    />
                                    <Select value={serviceFilter} onChange={(e) => setServiceFilter(e.target.value)} placeholder="Filter by service" m={2}>
                                        <option value="">All Services</option>
                                        <option value="architecture">Architecture</option>
                                        <option value="automation">Automation</option>
                                        <option value="bathrooms">Bathrooms</option>
                                        <option value="cabinets">Cabinets</option>
                                        <option value="carpentry">Carpentry</option>
                                        <option value="carpet">Carpet</option>
                                        <option value="cleaning">Cleaning</option>
                                        <option value="cooling">Cooling</option>
                                        <option value="decksPorches">Decks & Porches</option>
                                        <option value="doors">Doors</option>
                                        <option value="driveways">Driveways</option>
                                        <option value="drywall">Drywall</option>
                                        <option value="electrical">Electrical</option>
                                        <option value="fences">Fences</option>
                                        <option value="flooring">Flooring</option>
                                        <option value="foundations">Foundations</option>
                                        <option value="garages">Garages</option>
                                        <option value="gutters">Gutters</option>
                                        <option value="handyman">Handyman Services</option>
                                        <option value="HVAC">HVAC</option>
                                        <option value="insulation">Insulation</option>
                                        <option value="kitchens">Kitchens</option>
                                        <option value="landscape">Landscape</option>
                                        <option value="lawncare">Lawn Care</option>
                                        <option value="lowVoltage">Low Voltage</option>
                                        <option value="masonry">Masonry</option>
                                        <option value="painting">Painting</option>
                                        <option value="plumbing">Plumbing</option>
                                        <option value="poolBuilding">Pool Building</option>
                                        <option value="poolsSpas">Pools & Spas</option>
                                        <option value="remodels">Remodels</option>
                                        <option value="roofing">Roofing</option>
                                        <option value="security">Security</option>
                                        <option value="siding">Siding</option>
                                        <option value="tiling">Tiling</option>
                                        <option value="treeService">Tree Service</option>
                                        <option value="welding">Welding</option>
                                        <option value="windows">Windows</option>
                                    </Select>


                                    <Input value={tradeSearch} onChange={handleTradeSearchChange} placeholder="Search and add trades..." />
                                    {tradeSuggestions.map((suggestion, index) => (
                                        <Flex borderRadius={"lg"} mb={2} bg={"gray.700"} direction={"column-reverse"} justify={"center"} w={"100%"} key={index} p={2} onClick={() => addTradeTag(suggestion.trade)} cursor="pointer">
                                            <Text color={"white"}>{suggestion.trade}</Text>
                                        </Flex>
                                    ))}
                                    <Flex wrap="wrap" mt={2}>
                                        {tradeTags.map((tag, index) => (
                                            <Tag size="lg" key={index} borderRadius="full" m={1}>
                                                <TagLabel>{tag}</TagLabel>
                                                <TagCloseButton onClick={() => removeTradeTag(index)} />
                                            </Tag>
                                        ))}
                                    </Flex>
                                </Flex>
                                {loading ? (
                                    <Text>Loading...</Text>
                                ) : (
                                    <Flex direction={"column"} align={"center"} justify={"flex-start"} w={"100%"} maxH={"90vh"} overflowY={"scroll"}>
                                        {filteredProjects.map(project => (
                                            <Box w={"100%"} bg={project.pinged ? "green.50" : ""} mb={8} borderRadius={"lg"} key={project._id} p={5} shadow="md" borderWidth="1px">
                                                <Flex maxH={"500px"} overflowY={"scroll"} w={"100%"} justify={"space-between"}>
                                                    <Flex direction={"column"} w={"100%"} align={"flex-start"}>
                                                        {project.pinged && (
                                                            <Badge mb={4} bg="#1DADE3" _hover={"blue"} color={"white"} >Pinged by Homeowner</Badge>
                                                        )}
                                                        <Flex w={"100%"} color={"white"} p={2} align={"flex-start"} justify={"space-between"} borderRadius={"lg"} bg={"gray.700"}>
                                                            <Box w={"100%"} color={"white"}>
                                                                <Heading size={"md"}>{project.title}</Heading>
                                                                <Text color="gray.200">{project.address.city}, {project.address.state}</Text>
                                                                {project.platformPayment === false ? <Flex align={"center"}><FontAwesomeIcon color="yellow" icon={faLockOpen} /><Text mt={1} ml={2} fontSize="md" color={"yellow"}>Unsecured Payments</Text></Flex> : <Flex align={"center"}><FontAwesomeIcon color={"limegreen"} icon={faLock} /><Text mt={1} ml={2} fontSize="md" color={"limegreen"}>Secured Payments</Text></Flex>}
                                                                <Text fontSize="md" color="gray.100">
                                                                    {project.bids.length === 0
                                                                        ? 'No one has purchased this lead'
                                                                        : project.bids.length === 1
                                                                            ? '1 person has purchased this lead'
                                                                            : `${project.bids.length} people have purchased this lead`}
                                                                </Text>

                                                            </Box>
                                                            {project.projectType === "ready" ? (
                                                                <Flex color={"white"} align={"flex-end"} direction={"column"} justify={"flex-end"}>
                                                                    <Badge colorScheme='green'>{project.projectType}</Badge>
                                                                    {project.readyToStartTimeframe === "inDays" ? (
                                                                        <Text textAlign="right" fontSize="xs">Ready to start in days</Text>
                                                                    ) : project.readyToStartTimeframe === "inWeeks" ? (
                                                                        <Text textAlign="right" fontSize="xs">Ready to start in weeks</Text>
                                                                    ) : project.readyToStartTimeframe === "rightAway" ? (
                                                                        <Text textAlign="right" fontSize="xs">Ready to start right away</Text>
                                                                    ) : null}
                                                                </Flex>
                                                            ) : project.projectType === "estimate" ? (
                                                                <Badge colorScheme='orange'>Getting Proposals</Badge>
                                                            ) : project.projectType === "emergency" ? (
                                                                <Badge colorScheme='pink'>Immediately</Badge>
                                                            ) : null}

                                                        </Flex>
                                                        <Flex
                                                            mt={4}
                                                            w="100%"
                                                            justify="space-between"
                                                            align="flex-start"
                                                            direction={{ base: "column", md: "row" }} // Mobile-first design
                                                        >
                                                            {/* Project Description */}
                                                            <Flex
                                                                maxH={{ base: "200px", md: "300px" }} // Adjust height for mobile
                                                                overflowY="scroll"
                                                                w={{ base: "100%", md: "55%" }} // Full width on mobile
                                                                direction="column"
                                                                mb={{ base: 4, md: 0 }} // Add bottom margin for mobile
                                                            >
                                                                <Text mt={2}>{project.description}</Text>
                                                            </Flex>

                                                            {/* Project Images */}
                                                            <Flex
                                                                p={2}
                                                                justify="flex-end"
                                                                maxH={{ base: "200px", md: "400px" }} // Adjust for mobile
                                                                overflowY="scroll"
                                                                align="flex-end"
                                                                w={{ base: "100%", md: "45%" }} // Full width on mobile
                                                            >
                                                                {project.images && project.images.length > 0 && (
                                                                    <SimpleGrid
                                                                        overflowY="scroll"
                                                                        maxH={{ base: "200px", md: "300px" }} // Adjust for mobile
                                                                        borderRadius="md"
                                                                        p={1}
                                                                        bg="blackAlpha.800"
                                                                        columns={{ base: 2, md: 2 }} // Keep columns consistent
                                                                        spacing={2}
                                                                    >
                                                                        {project.images.map((url, index) => (
                                                                            <a key={index} onClick={() => handleImageClick(url)} href="#!">
                                                                                <Image
                                                                                    border="1px"
                                                                                    borderColor="gray.700"
                                                                                    borderRadius="md"
                                                                                    shadow="md"
                                                                                    src={url}
                                                                                    alt={`Project Image ${index + 1}`}
                                                                                    maxW={{ base: "100px", md: "150px" }} // Smaller image size on mobile
                                                                                    h="auto"
                                                                                    objectFit="contain"
                                                                                    _hover={{ transform: 'scale(1.05)', cursor: 'pointer' }}
                                                                                />
                                                                            </a>
                                                                        ))}
                                                                    </SimpleGrid>
                                                                )}

                                                                {/* Modal for viewing images */}
                                                                <Modal isOpen={isOpen} onClose={onClose} size="xl" isCentered>
                                                                    <ModalOverlay />
                                                                    <ModalContent>
                                                                        <ModalBody>
                                                                            <a href={selectedImg} target="_blank" rel="noopener noreferrer">
                                                                                <Image borderRadius="md" src={selectedImg} alt="Selected Project Image" maxW="full" h="auto" />
                                                                                <Flex borderRadius="md" p={2} bg="gray.700" mt={2} w="100%" justify="flex-end">
                                                                                    <FontAwesomeIcon size='lg' color='white' icon={faMagnifyingGlassPlus} />
                                                                                    <Text ml={4} color="white">Click to Enlarge</Text>
                                                                                </Flex>
                                                                            </a>
                                                                        </ModalBody>
                                                                    </ModalContent>
                                                                </Modal>
                                                            </Flex>
                                                        </Flex>

                                                        <Spacer />
                                                        {project.bids.length >= 4 ? (
                                                            <Text color={"orange"} fontWeight={'semibold'}>Received Max Bids</Text>
                                                        ) : (
                                                            <>
                                                                {contractor.isVetted ? (
                                                                    <Button
                                                                        bg="#1DADE3" _hover={"blue"} color={"white"}
                                                                        mt={2}
                                                                        size={"sm"}
                                                                        onClick={() => {
                                                                            socket.emit('placeBid', { projectId: project._id }, () => {
                                                                            });
                                                                        }}
                                                                        isDisabled={project.isActive || (!project.isActive && hasContractorBidOnProject(project))}
                                                                    >
                                                                        {!project.isActive ? (hasContractorBidOnProject(project) ? 'Purchased' : `Secure for $${project.leadPrice}`) : "No Longer Taking Bids"}
                                                                    </Button>
                                                                ) : (
                                                                    <Flex w={'fit-content'} p={2} borderRadius={"md"} bg={"yellow.200"}>
                                                                        <Text mr={2}>Complete Verification to Secure Bid</Text>
                                                                        <Link href="https://calendly.com/cameron-pvai/30min" isExternal>
                                                                            <FontAwesomeIcon icon={faExternalLinkAlt} /> Calendly
                                                                        </Link>
                                                                    </Flex>
                                                                )}
                                                            </>
                                                        )}
                                                    </Flex>
                                                </Flex>
                                            </Box>
                                        ))}
                                    </Flex>
                                )}
                            </VStack>
                        ) : (
                            <VStack mt={4} w="80%" spacing={4} align="stretch">
                                <Flex w={"100%"}>
                                    <Flex ml={4} direction={"column"} h={"fit-content"} justify={"center"} align={"center"} w={"20%"}>
                                        <Input
                                            value={titleFilter}
                                            m={2}
                                            onChange={handleTitleFilterChange}
                                            placeholder="Filter projects by title"
                                        />
                                        <Input
                                            value={cityFilter}
                                            m={2}
                                            onChange={handleCityFilterChange}
                                            placeholder="Search by city..."
                                        />
                                        <Select value={serviceFilter} onChange={(e) => setServiceFilter(e.target.value)} placeholder="Filter by service" m={2}>
                                            <option value="">All Services</option>
                                            <option value="architecture">Architecture</option>
                                            <option value="automation">Automation</option>
                                            <option value="bathrooms">Bathrooms</option>
                                            <option value="cabinets">Cabinets</option>
                                            <option value="carpentry">Carpentry</option>
                                            <option value="carpet">Carpet</option>
                                            <option value="cleaning">Cleaning</option>
                                            <option value="cooling">Cooling</option>
                                            <option value="decksPorches">Decks & Porches</option>
                                            <option value="doors">Doors</option>
                                            <option value="driveways">Driveways</option>
                                            <option value="drywall">Drywall</option>
                                            <option value="electrical">Electrical</option>
                                            <option value="fences">Fences</option>
                                            <option value="flooring">Flooring</option>
                                            <option value="foundations">Foundations</option>
                                            <option value="garages">Garages</option>
                                            <option value="gutters">Gutters</option>
                                            <option value="handyman">Handyman Services</option>
                                            <option value="HVAC">HVAC</option>
                                            <option value="insulation">Insulation</option>
                                            <option value="kitchens">Kitchens</option>
                                            <option value="landscape">Landscape</option>
                                            <option value="lawncare">Lawn Care</option>
                                            <option value="lowVoltage">Low Voltage</option>
                                            <option value="masonry">Masonry</option>
                                            <option value="painting">Painting</option>
                                            <option value="plumbing">Plumbing</option>
                                            <option value="poolBuilding">Pool Building</option>
                                            <option value="poolsSpas">Pools & Spas</option>
                                            <option value="remodels">Remodels</option>
                                            <option value="roofing">Roofing</option>
                                            <option value="security">Security</option>
                                            <option value="siding">Siding</option>
                                            <option value="tiling">Tiling</option>
                                            <option value="treeService">Tree Service</option>
                                            <option value="welding">Welding</option>
                                            <option value="windows">Windows</option>
                                        </Select>


                                        <Input value={tradeSearch} onChange={handleTradeSearchChange} placeholder="Search and add trades..." />
                                        {tradeSuggestions.map((suggestion, index) => (
                                            <Flex borderRadius={"lg"} mb={2} bg={"gray.700"} direction={"column-reverse"} justify={"center"} w={"100%"} key={index} p={2} onClick={() => addTradeTag(suggestion.trade)} cursor="pointer">
                                                <Text color={"white"}>{suggestion.trade}</Text>
                                            </Flex>
                                        ))}
                                        <Flex wrap="wrap" mt={2}>
                                            {tradeTags.map((tag, index) => (
                                                <Tag size="lg" key={index} borderRadius="full" m={1}>
                                                    <TagLabel>{tag}</TagLabel>
                                                    <TagCloseButton onClick={() => removeTradeTag(index)} />
                                                </Tag>
                                            ))}
                                        </Flex>
                                    </Flex>
                                    {loading ? (
                                        <Text>Loading...</Text>
                                    ) : (
                                        <Flex direction={"column"} align={"center"} justify={"flex-start"} w={"80%"} maxH={"90vh"} overflowY={"scroll"}>
                                            {filteredProjects.map(project => (
                                                <Box w={"80%"} bg={project.pinged ? "green.50" : ""} mb={8} borderRadius={"lg"} key={project._id} p={5} shadow="md" borderWidth="1px">
                                                    <Flex maxH={"500px"} overflowY={"scroll"} w={"100%"} justify={"space-between"}>
                                                        <Flex direction={"column"} w={"100%"} align={"flex-start"}>
                                                            {project.pinged && (
                                                                <Badge mb={4} bg="#1DADE3" _hover={"blue"} color={"white"} >Pinged by Homeowner</Badge>
                                                            )}
                                                            <Flex w={"100%"} color={"white"} p={2} align={"flex-start"} justify={"space-between"} borderRadius={"lg"} bg={"gray.700"}>
                                                                <Box color={"white"}>
                                                                    <Heading size={"md"}>{project.title}</Heading>
                                                                    <Text color="gray.200">{project.address.city}, {project.address.state}</Text>
                                                                    {project.platformPayment === false ? <Flex align={"center"}><FontAwesomeIcon color="yellow" icon={faLockOpen} /><Text mt={1} ml={2} fontSize="md" color={"yellow"}>Unsecured Payments</Text></Flex> : <Flex align={"center"}><FontAwesomeIcon color={"limegreen"} icon={faLock} /><Text mt={1} ml={2} fontSize="md" color={"limegreen"}>Secured Payments</Text></Flex>}
                                                                    <Text fontSize="md" color="gray.100">
                                                                        {project.bids.length === 0
                                                                            ? 'No one has purchased this lead'
                                                                            : project.bids.length === 1
                                                                                ? '1 person has purchased this lead'
                                                                                : `${project.bids.length} people have purchased this lead`}
                                                                    </Text>

                                                                </Box>
                                                                {project.projectType === "ready" ? (
                                                                    <Flex color={"white"} align={"flex-end"} direction={"column"} justify={"flex-end"}>
                                                                        <Badge colorScheme='green'>{project.projectType}</Badge>
                                                                        {project.readyToStartTimeframe === "inDays" ? (
                                                                            <Text>Ready to start in days</Text>
                                                                        ) : project.readyToStartTimeframe === "inWeeks" ? (
                                                                            <Text>Ready to start in weeks</Text>
                                                                        ) : project.readyToStartTimeframe === "rightAway" ? (
                                                                            <Text>Ready to start right away</Text>
                                                                        ) : null}
                                                                    </Flex>
                                                                ) : project.projectType === "estimate" ? (
                                                                    <Badge colorScheme='orange'>Getting Proposals</Badge>
                                                                ) : project.projectType === "emergency" ? (
                                                                    <Badge colorScheme='pink'>Immediately</Badge>
                                                                ) : null}

                                                            </Flex>
                                                            <Flex mt={4} w={"100%"} justify={"space-between"} align={"flex-start"}>
                                                                <Flex maxH="300px" overflowY="scroll" w={"55%"} direction={"column"}>
                                                                    <Text mt={2}>{project.description}</Text>
                                                                </Flex>
                                                                <Flex p={2} justify="flex-end" maxH="400px" overflowY="scroll" align="flex-end" w="45%">
                                                                    {project.images && project.images.length > 0 && (
                                                                        <SimpleGrid overflowY={"scroll"} maxH={"300px"} borderRadius={"md"} p={1} bg={"blackAlpha.800"} columns={{ base: 2 }} spacing={2}>
                                                                            {project.images.map((url, index) => (
                                                                                <a key={index} onClick={() => handleImageClick(url)} href="#!">
                                                                                    <Image
                                                                                        border="1px"
                                                                                        borderColor="gray.700"
                                                                                        borderRadius="md"
                                                                                        shadow="md"
                                                                                        src={url}
                                                                                        alt={`Project Image ${index + 1}`}
                                                                                        maxW={"150px"}
                                                                                        h="auto"
                                                                                        objectFit="contain"
                                                                                        _hover={{ transform: 'scale(1.05)', cursor: 'pointer' }}
                                                                                    />
                                                                                </a>
                                                                            ))}
                                                                        </SimpleGrid>
                                                                    )}

                                                                    {/* Modal for viewing images */}
                                                                    <Modal isOpen={isOpen} onClose={onClose} size="xl" isCentered>
                                                                        <ModalOverlay />
                                                                        <ModalContent>
                                                                            <ModalBody>
                                                                                <a href={selectedImg} target="_blank" rel="noopener noreferrer">
                                                                                    <Image borderRadius={"md"} src={selectedImg} alt="Selected Project Image" maxW="full" h="auto" />
                                                                                    <Flex borderRadius={"md"} p={2} bg={"gray.700"} mt={2} w={"100%"} justify={"flex-end"}>
                                                                                        <FontAwesomeIcon size='lg' color='white' icon={faMagnifyingGlassPlus} />
                                                                                        <Text ml={4} color={"white"}>Click to Enlarge</Text>
                                                                                    </Flex>
                                                                                </a>
                                                                            </ModalBody>
                                                                        </ModalContent>
                                                                    </Modal>
                                                                </Flex>
                                                            </Flex>
                                                            <Spacer />
                                                            {project.bids.length >= 4 ? (
                                                                <Text color={"orange"} fontWeight={'semibold'}>Received Max Bids</Text>
                                                            ) : (
                                                                <>
                                                                    {contractor.isVetted ? (
                                                                        <Button
                                                                            bg="#1DADE3" _hover={"blue"} color={"white"}
                                                                            mt={2}
                                                                            size={"sm"}
                                                                            onClick={() => {
                                                                                socket.emit('placeBid', { projectId: project._id }, () => {
                                                                                });
                                                                            }}
                                                                            isDisabled={project.isActive || (!project.isActive && hasContractorBidOnProject(project))}
                                                                        >
                                                                            {!project.isActive ? (hasContractorBidOnProject(project) ? 'Purchased' : `Secure for $${project.leadPrice}`) : "No Longer Taking Bids"}
                                                                        </Button>
                                                                    ) : (
                                                                        <Flex w={'fit-content'} p={2} borderRadius={"md"} bg={"yellow.200"}>
                                                                            <Text mr={2}>Complete Verification to Secure Bid</Text>
                                                                            <Link href="https://calendly.com/cameron-pvai/30min" isExternal>
                                                                                <FontAwesomeIcon icon={faExternalLinkAlt} /> Calendly
                                                                            </Link>
                                                                        </Flex>
                                                                    )}
                                                                </>
                                                            )}
                                                        </Flex>
                                                    </Flex>
                                                </Box>
                                            ))}
                                        </Flex>
                                    )}
                                </Flex>
                            </VStack>
                        )}
                    </Flex>
                </Flex>
            </Flex>
        </Flex>
    );
}

export default RecommendedProjects;