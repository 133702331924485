import React, { useState } from 'react';
import axios from 'axios';
import { Flex, Button, FormControl, FormLabel, Input, Box, Text, VStack, Spinner } from '@chakra-ui/react';
import ContractorSideMenu from '../contractor/SideMenu';
import Header from '../elements/Header';

function AICreateProject() {
    const [currentStep, setCurrentStep] = useState(1);
    const [loading, setLoading] = useState(false);
    const [homeownerResponses, setHomeownerResponses] = useState({});
    const [aiResponses, setAIResponses] = useState({});
    const totalSteps = 3;

    const handleNextStep = async () => {
        if (currentStep < totalSteps) {
            setCurrentStep(currentStep + 1);
        } else {
            // Handle submission or final actions here
        }
    };

    const handleInputChange = (e) => {
        setHomeownerResponses({
            ...homeownerResponses,
            [currentStep]: e.target.value
        });
    };

    const handleSubmitResponse = async () => {
        setLoading(true);

        const endpoint = currentStep === 1 ? '/api/ai/create-project/1' : '/api/ai/create-project/2';
        const previousMessages = Object.entries(aiResponses).map(([step, response]) => ({
            role: 'system',
            content: response
        }));

        try {
            const response = await axios.post(`${process.env.REACT_APP_API_URL}${endpoint}`, {
                homeownerResponse: homeownerResponses[currentStep],
                previousMessages: currentStep === 1 ? [] : previousMessages
            });
            setAIResponses({
                ...aiResponses,
                [currentStep]: response.data.aiResponse
            });
        } catch (error) {
            console.error('Failed to fetch AI response:', error);
        } finally {
            setLoading(false);
            if (currentStep < totalSteps) {
                setCurrentStep(currentStep + 1);
            }
        }
    };

    const renderStep = () => {
        switch (currentStep) {
            case 1:
                return (
                    <FormControl>
                        <FormLabel>What can we help you with?</FormLabel>
                        <Input onChange={handleInputChange} value={homeownerResponses[currentStep] || ''} />
                    </FormControl>
                );
            case 2:
                return (
                    <Box>
                        <Text>AI Response: {aiResponses[1]}</Text>
                        <FormControl>
                            <FormLabel>Could you provide more details?</FormLabel>
                            <Input onChange={handleInputChange} value={homeownerResponses[currentStep] || ''} />
                        </FormControl>
                    </Box>
                );
            default:
                return <Text>Thank you for your responses.</Text>;
        }
    };

    return (
        <Flex direction="column" w="100%" justify="center">
            <Flex p={2} w="100%" align="flex-start" justify="flex-start">
                <ContractorSideMenu />
                <Flex m={4} direction="column" w="100%" justify="center">
                    <Header />
                    <Flex direction="column" align="center" p={8}>
                        {loading && (
                            <Spinner />
                        )}
                        <VStack spacing={4}>
                            {renderStep()}
                            <Button onClick={handleSubmitResponse} mt={4}>Next</Button>
                        </VStack>
                    </Flex>
                </Flex>
            </Flex>
        </Flex>
    );
}

export default AICreateProject;