import React from 'react';
import { Container, Heading, Text, Box, UnorderedList, ListItem, Link } from '@chakra-ui/react';
import { ExternalLinkIcon } from '@chakra-ui/icons';

const TermsOfService = () => {
    return (
        <Container maxW="container.lg" py={8}>
            <Heading as="h1" size="2xl" mb={8} color="blue.500">
                Terms of Service
            </Heading>
            <Text fontSize="lg" mb={8}>
                UtahRemodels.com Terms of Service<br />
                May 16th, 2024
            </Text>

            <Box mb={8}>
                <Heading as="h2" size="lg" mb={4} color="blue.500">
                    Table of Contents
                </Heading>
                <UnorderedList>
                    <ListItem><Link href="#acceptance">1. Acceptance of Terms</Link></ListItem>
                    <ListItem><Link href="#modification">2. Modification of Terms</Link></ListItem>
                    <ListItem><Link href="#use">3. Use of the Website</Link></ListItem>
                    <ListItem><Link href="#content">4. Content</Link></ListItem>
                    <ListItem><Link href="#payments">5. Payments</Link></ListItem>
                    <ListItem><Link href="#disputes">6. Dispute Resolution</Link></ListItem>
                    <ListItem><Link href="#contractor-info">7. Contractor Information and Verification</Link></ListItem>
                    <ListItem><Link href="#termination">8. Termination</Link></ListItem>
                    <ListItem><Link href="#liability">9. Limitation of Liability</Link></ListItem>
                    <ListItem><Link href="#governing-law">10. Governing Law</Link></ListItem>
                    <ListItem><Link href="#changes">11. Changes</Link></ListItem>
                    <ListItem><Link href="#contact">12. Contact Us</Link></ListItem>
                </UnorderedList>
            </Box>

            <Box id="acceptance" mb={8}>
                <Heading as="h2" size="xl" mb={4}>
                    1. Acceptance of Terms
                </Heading>
                <Text fontSize="lg" mb={4}>
                    By accessing or using UtahRemodels.com, you agree to be bound by these Terms of Service (the "Terms"). These Terms apply to all users of the site, including contractors, homeowners, merchants, and contributors of content, information, and other materials or services on the website.
                </Text>
            </Box>

            <Box id="modification" mb={8}>
                <Heading as="h2" size="xl" mb={4}>
                    2. Modification of Terms
                </Heading>
                <Text fontSize="lg" mb={4}>
                    UtahRemodels.com reserves the right, at its sole discretion, to modify or replace these Terms at any time. If the alterations constitute a material change to the Terms, UtahRemodels.com will notify you by posting an announcement on the website. What constitutes a material change will be determined at UtahRemodels.com's sole discretion.
                </Text>
            </Box>

            <Box id="use" mb={8}>
                <Heading as="h2" size="xl" mb={4}>
                    3. Use of the Website
                </Heading>
                <Text fontSize="lg" mb={4}>
                    a. Eligibility: You must be at least 18 years old to use UtahRemodels.com. By agreeing to these Terms, you represent and warrant that you are 18 years of age or older.
                </Text>
                <Text fontSize="lg" mb={4}>
                    b. Account Registration: To access some features of the website, you must register for an account. When registering, you must provide accurate and complete information. Each registration is for a single user only.
                </Text>
                <Text fontSize="lg">
                    c. Prohibited Uses: You may use UtahRemodels.com only for lawful purposes and in accordance with these Terms. You agree not to use the website in any way that violates any applicable national, state, local, or international law or regulation, or for the purpose of exploiting or harming minors.
                </Text>
            </Box>

            <Box id="content" mb={8}>
                <Heading as="h2" size="xl" mb={4}>
                    4. Content
                </Heading>
                <Text fontSize="lg" mb={4}>
                    a. User Contributions: Users are responsible for any content that they post on UtahRemodels.com, including its legality, reliability, and appropriateness.
                </Text>
                <Text fontSize="lg" mb={4}>
                    b. Copyright Policy: UtahRemodels.com respects the intellectual property rights of others. It is our policy to respond to any claim that Content posted on the site infringes on the copyright or other intellectual properties of any person or entity.
                </Text>
                <Text fontSize="lg">
                    c. Content Removal: UtahRemodels.com reserves the right to remove any content deemed non-compliant with these Terms or any applicable laws.
                </Text>
            </Box>

            <Box id="payments" mb={8}>
                <Heading as="h2" size="xl" mb={4}>
                    5. Payments
                </Heading>
                <Text fontSize="lg">
                    Payments for services facilitated through UtahRemodels.com are processed through Stripe. All payment information and processing comply with Stripe's security and privacy policies.
                </Text>
            </Box>

            <Box id="disputes" mb={8}>
                <Heading as="h2" size="xl" mb={4}>
                    6. Dispute Resolution
                </Heading>
                <Text fontSize="lg">
                    UtahRemodels.com is not a party to the actual transactions between homeowners and contractors. Any disputes regarding services, damages, or liabilities should be resolved directly between the homeowner and the contractor.
                </Text>
            </Box>

            <Box id="contractor-info" mb={8}>
                <Heading as="h2" size="xl" mb={4}>
                    7. Contractor Information and Verification
                </Heading>
                <Text fontSize="lg">
                    UtahRemodels.com displays indicators and information regarding contractors' licenses, bonds, insurance, and criminal background checks, which are provided by the contractors themselves or third-party sources. Homeowners are responsible for verifying all such information before engaging a contractor.
                </Text>
            </Box>

            <Box id="termination" mb={8}>
                <Heading as="h2" size="xl" mb={4}>
                    8. Termination
                </Heading>
                <Text fontSize="lg">
                    UtahRemodels.com may terminate or suspend your account and bar access to the website immediately, without prior notice or liability, under our sole discretion, for any reason whatsoever and without limitation, including but not limited to a breach of the Terms.
                </Text>
            </Box>

            <Box id="liability" mb={8}>
                <Heading as="h2" size="xl" mb={4}>
                    9. Limitation of Liability
                </Heading>
                <Text fontSize="lg">
                    In no event will UtahRemodels.com, nor its directors, employees, partners, agents, suppliers, or affiliates, be liable for any indirect, incidental, special, consequential, or punitive damages, resulting from your access to or use of or inability to access or use the website.
                </Text>
            </Box>

            <Box id="governing-law" mb={8}>
                <Heading as="h2" size="xl" mb={4}>
                    10. Governing Law
                </Heading>
                <Text fontSize="lg">
                    These Terms shall be governed and construed in accordance with the laws of the State of Utah, United States, without regard to its conflict of law provisions.
                </Text>
            </Box>

            <Box id="changes" mb={8}>
                <Heading as="h2" size="xl" mb={4}>
                    11. Changes
                </Heading>
                <Text fontSize="lg">
                    We reserve the right, at our sole discretion, to modify or replace these Terms at any time. If a revision is material, we will provide at least 30 days' notice prior to any new terms taking effect.
                </Text>
            </Box>

            <Box id="contact" mb={8}>
                <Heading as="h2" size="xl" mb={4}>
                    12. Contact Us
                </Heading>
                <Text fontSize="lg">
                    If you have any questions about these Terms, please contact us at <Link href="mailto:Hi@UtahRemodels.com" color="blue.500">Hi@UtahRemodels.com</Link> <ExternalLinkIcon mx="2px" />
                </Text>
            </Box>
        </Container>
    );
};

export default TermsOfService;