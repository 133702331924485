import { createContext, useState, useContext } from 'react';

const SidebarContext = createContext();

export function useSidebar() {
  return useContext(SidebarContext);
}

export function SidebarProvider({ children }) {
  const [expanded, setExpanded] = useState(true);

  const handleExpansion = () => {
    setExpanded((prev) => !prev);
  };

  return (
    <SidebarContext.Provider value={{ expanded, handleExpansion }}>
      {children}
    </SidebarContext.Provider>
  );
}