import React, { useState, useEffect } from 'react';
import axios from 'axios';
import {
    Box,
    Heading,
    Text,
    Progress,
    Flex,
    useColorMode,
    Spinner,
    Alert,
    AlertIcon,
    AlertDescription,
    ProgressLabel,
    VStack,
    Badge,
    SimpleGrid,
    FormControl,
    FormLabel,
    Select,
    Input,
    Button,
    useBreakpointValue
} from '@chakra-ui/react';
import TransferButton from './TransferButton';
import CheckoutRedirectButton from './PayWithBank';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBank, faBuildingColumns, faCheck, faCreditCard, faFileInvoiceDollar, faMoneyBill, faMoneyCheck } from '@fortawesome/free-solid-svg-icons';

const InPersonPaymentForm = ({ projectId, onPaymentSuccess, conversationId }) => {
    const [paymentType, setPaymentType] = useState('');
    const [amount, setAmount] = useState('');
    const [payments, setPayments] = useState([]); // Add a state to store payments
    const token = localStorage.getItem('token');

    const handlePayment = async () => {
        if (!amount || !paymentType) {
            alert('Please fill all fields.');
            return;
        }

        try {
            const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/projects/${projectId}/${conversationId}/add-payment`, {
                amount: parseInt(amount, 10),
                paymentType,
                status: 'succeeded'
            }, {
                headers: { Authorization: `Bearer ${token}` }
            });

            console.log('Payment response:', response.data);
            const newPayment = response.data.paymentIntent; // Assuming the response contains the new payment intent
            setPayments(prevPayments => [...prevPayments, newPayment]); // Update the payments state
            onPaymentSuccess(newPayment);
            setPaymentType('');
            setAmount('');
        } catch (error) {
            console.error('Error processing payment:', error);
            alert('Failed to process payment. Please try again.');
        }
    };

    return (
        <Box
            p={4}
            borderWidth={1}
            borderRadius={8}
            borderColor="lightgray"
            boxShadow="lg"
            w="100%"
        >
            <FormControl>
                <FormLabel fontSize="lg" fontWeight="bold">Add an In-Person Payment</FormLabel>
                <Select
                    placeholder="Choose payment method"
                    value={paymentType}
                    onChange={(e) => setPaymentType(e.target.value)}
                    mb={4}
                >
                    <option value="cash">Cash</option>
                    <option value="check">Check</option>
                    <option value="other">Other</option>
                </Select>
                <FormLabel mt={4} fontWeight="semibold">Payment Amount</FormLabel>
                <Input
                    type="number"
                    value={amount}
                    onChange={(e) => setAmount(e.target.value)}
                    placeholder="Enter payment amount"
                />
                <Button
                    mt={4}
                    bg="#1DADE3" _hover={"blue"} color={"white"}
                    width="full"
                    onClick={handlePayment}
                >
                    Process Payment
                </Button>
            </FormControl>

            {/* Render the list of payments */}
            <Box mt={4}>
                <Heading size="md" mb={2}>Recent Payments</Heading>
                {payments.length > 0 ? (
                    <VStack spacing={2} align="start">
                        {payments.map((payment, index) => (
                            <Box key={index} p={2} bg="gray.100" borderRadius="md">
                                <Text>Amount: ${payment.amount}</Text>
                                <Text>Payment Type: {payment.paymentType}</Text>
                                <Text>Status: {payment.status}</Text>
                            </Box>
                        ))}
                    </VStack>
                ) : (
                    <Text>No recent payments.</Text>
                )}
            </Box>
        </Box>
    );
};

const FinanceDetails = ({ transferGroupId, bid, destinationAccountId, project }) => {
    const { colorMode } = useColorMode();
    const [charges, setCharges] = useState([]);
    const menuType = useBreakpointValue({ base: 'drawer', lg: 'regular' });
    const [transfers, setTransfers] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const platformPayment = useState(project.platformPayment || false);

    console.log('project', project);

    const role = localStorage.getItem('role');

    const fetchData = async () => {
        try {
            setLoading(true);
            const chargesResponse = await axios.get(`${process.env.REACT_APP_API_URL}/api/charges/${transferGroupId}`);
            const transfersResponse = await axios.get(`${process.env.REACT_APP_API_URL}/api/transfers/${transferGroupId}`);

            setCharges(chargesResponse.data);
            setTransfers(transfersResponse.data);
        } catch (error) {
            setError('Failed to fetch data');
            console.error('Error fetching data:', error);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchData();
    }, [transferGroupId]);

    const onTransferSuccess = (newTransfer) => {
        setTransfers(prev => [...prev, newTransfer]);
    };

    const onPaymentSuccess = (newPaymentIntent) => {
        const updatedProject = {
            ...project,
            paymentIntents: [...project.paymentIntents, newPaymentIntent]
        };

        // Update the project state or perform any other necessary actions
        // For example, you could call a function to update the project data in the database
        console.log('Updated project:', updatedProject);
    };

    const calculateTotalReceived = () => charges?.reduce((total, charge) => total + (charge.amount || 0), 0);
    const calculateTotalTransferred = () => transfers?.reduce((total, transfer) => total + (transfer.amount || 0), 0) / 0.98;
    const calculatePaymentTotal = () => {
        return project.paymentIntents?.reduce((total, intent) => {
            // Only sum the subtotal if the status is not "REQUIRES_PAYMENT_METHOD"
            if (intent.status !== "requires_payment_method") {
                return total + (intent.subTotal || 0);
            }
            return total;
        }, 0);
    };

    const totalReceived = calculateTotalReceived();
    const totalTransferred = calculateTotalTransferred();
    const paymentTotal = calculatePaymentTotal();
    const remainingAmount = totalReceived - totalTransferred;
    const fundingRemaining = bid.totalAmount - paymentTotal;
    const payoutsRemaining = bid.totalAmount - (totalTransferred / 100);


    const progressLabelColor = colorMode === 'dark' ? 'gray.700' : 'white';
    const progressBarBg = colorMode === 'dark' ? 'gray.600' : 'gray.300';

    if (loading) return <Flex m={4} w={"100%"} align={"center"} justify={"center"}><Spinner color='green' size="xl" /></Flex>;

    return (
        <>
            {menuType === 'drawer' ? (
                <>
                    <Flex direction={"column"} w={"100%"} align={"flex-start"} justify={"space-between"}>
                        <Flex direction={"column"} w={"100%"} justify={"space-between"}>
                            {error && (
                                <Alert status="error" mb={4}>
                                    <AlertIcon />
                                    <AlertDescription>{error}</AlertDescription>
                                </Alert>
                            )}
                            <Box
                                w={{base: "100%", lg: project.platformPayment === false ? "100%" : "50%"}}
                                p={2}
                                borderWidth={1}
                                borderRadius={8}
                                borderColor={"lightgray"}
                                boxShadow="lg"
                            >
                                {project.platformPayment === false ? (
                                    <Flex w={"100%"} justify={"space-between"} align={"flex-end"}>
                                        <Heading size="md">Funds Paid: ${paymentTotal.toFixed(2)}</Heading>
                                    </Flex>

                                ) : (
                                    <Flex w={"100%"} justify={"space-between"} align={"flex-end"}>
                                        <Heading size="md">Funds Added: ${paymentTotal.toFixed(2)}</Heading>
                                    </Flex>
                                )}

                                <Progress
                                    value={((paymentTotal).toFixed(2) * 100) / bid.totalAmount}
                                    colorScheme='green'
                                    size="lg"
                                    bg={progressBarBg}
                                    mb={2}
                                    mt={4}
                                    borderRadius={8}
                                    hasStripe
                                    isAnimated
                                >
                                    <ProgressLabel color={progressLabelColor} fontSize="md">
                                        ${(paymentTotal).toFixed(2)}
                                    </ProgressLabel>
                                </Progress>
                                <Flex w={"100%"} justify={"space-between"} align={"flex-end"}>
                                    <Text fontSize="sm" color="gray.500">
                                        {((paymentTotal) / bid.totalAmount * 100).toFixed(2)}% funded
                                    </Text>
                                    <Text fontSize="sm" color="gray.500">Remaining: ${fundingRemaining.toFixed(2)}</Text>
                                </Flex>
                                {role == "Home Owner" && project.platformPayment === true && (
                                    <CheckoutRedirectButton bidId={bid._id} projectId={project._id} bidTotal={bid.totalAmount} paymentTotal={paymentTotal} transferGroupId={transferGroupId} title={bid.title} />
                                )}
                            </Box>
                            {project.platformPayment === true && (
                                <Box
                                    w={"100%"}
                                    p={2}
                                    mt={4}
                                    borderWidth={1}
                                    borderRadius={8}
                                    borderColor={"lightgray"}
                                    boxShadow="lg"
                                >
                                    <Flex w={"100%"} justify={"space-between"} align={"flex-end"}>
                                        <Heading size="md">
                                            Funds Released: $ {(totalTransferred / 100).toFixed(2)}
                                        </Heading>
                                    </Flex>
                                    <Progress
                                        value={totalTransferred / bid.totalAmount}
                                        colorScheme='green'
                                        size="lg"
                                        bg={progressBarBg}
                                        mb={2}
                                        mt={4}
                                        borderRadius={8}
                                        hasStripe
                                        isAnimated
                                    >
                                        <ProgressLabel color={progressLabelColor} fontSize="md">
                                            ${(totalTransferred / 100).toFixed(2)}
                                        </ProgressLabel>
                                    </Progress>
                                    <Flex w={"100%"} justify={"space-between"} align={"flex-end"}>
                                        <Text fontSize="sm" color="gray.500">
                                            {((totalTransferred / 100) / bid.totalAmount * 100).toFixed(2)}% transfered
                                        </Text>
                                        <Text fontSize="sm" color="gray.500">Remaining: ${payoutsRemaining.toFixed(2)}</Text>
                                    </Flex>
                                    {role == "Home Owner" && project.platformPayment === true && (
                                        <TransferButton
                                            destinationAccountId={destinationAccountId}
                                            orderId={transferGroupId}
                                            totalTransferred={totalTransferred}
                                            bid={bid}
                                            payoutsRemaining={payoutsRemaining}
                                            onTransferSuccess={onTransferSuccess}
                                        />
                                    )}
                                </Box>
                            )}
                        </Flex>
                        <Flex spacing={2} align="stretch" w="100%">
                            {project.paymentIntents.length > 0 && (
                                <Flex direction="column" align="stretch" w={{ base: "100%", lg: project.platformPayment === false ? "65%" : "100%" }}>
                                    <Heading mt={4} mb={2} size="md">Payments</Heading>
                                    <SimpleGrid maxH={{ base: "", lg: "230px" }} overflowY="scroll" columns={{ base: 1, lg: project.platformPayment === false ? 3 : 5 }} spacing={2}>
                                        {project.paymentIntents.filter(intent => intent.status !== "requires_payment_method").map((intent) => (
                                            <Box borderColor="lightgray" p={2} shadow="md" w="100%" borderRadius="lg" borderWidth="1px" key={intent._id._id}>
                                                <Text>Status:
                                                    <Badge ml={1} colorScheme={intent.status === 'succeeded' ? 'green' : 'red'}>
                                                        {intent.status}
                                                    </Badge>
                                                </Text>
                                                <Text>Amount: ${intent?.subTotal?.toFixed(2)}</Text>
                                                <Text>
                                                    Payment Type:
                                                    {intent.paymentType.includes("card") ? (
                                                        <>
                                                            <Text mr={2} as="span"> Card</Text>
                                                            <FontAwesomeIcon icon={faCreditCard} />
                                                        </>
                                                    ) : intent.paymentType.includes("us_bank_account") ? (
                                                        <>
                                                            <Text mr={2} as="span"> Bank</Text>
                                                            <FontAwesomeIcon icon={faBuildingColumns} />
                                                        </>
                                                    ) : intent.paymentType.includes("check") ? (
                                                        <>
                                                            <Text mr={2} as="span"> Check</Text>
                                                            <FontAwesomeIcon icon={faMoneyCheck} />
                                                        </>
                                                    ) : intent.paymentType.includes("cash") ? (
                                                        <>
                                                            <Text mr={2} as="span"> Cash</Text>
                                                            <FontAwesomeIcon icon={faMoneyBill} />
                                                        </>
                                                    ) : intent.paymentType.includes("other") ? (
                                                        <>
                                                            <Text mr={2} as="span"> Other</Text>
                                                            <FontAwesomeIcon icon={faFileInvoiceDollar} />
                                                        </>
                                                    ) : null}
                                                </Text>
                                            </Box>
                                        ))}
                                    </SimpleGrid>
                                </Flex>
                            )}

                            {project.platformPayment === false && (
                                <Flex justify={"center"} p={4} w={"30%"}>
                                    <InPersonPaymentForm
                                        projectId={project._id}
                                        onPaymentSuccess={onPaymentSuccess}
                                        conversationId={bid.conversationId}
                                    />
                                </Flex>
                            )}
                        </Flex>
                    </Flex>
                </>
            ) : (
                <>
                    <Flex direction={"column"} w={"100%"} align={"flex-start"} justify={"space-between"}>
                        <Flex p={4} w={"100%"} justify={"space-between"}>
                            {error && (
                                <Alert status="error" mb={4}>
                                    <AlertIcon />
                                    <AlertDescription>{error}</AlertDescription>
                                </Alert>
                            )}
                            <Box
                                p={4}
                                w={project.platformPayment === false ? "100%" : "50%"}
                                mr={4}
                                borderWidth={1}
                                borderRadius={8}
                                borderColor={"lightgray"}
                                boxShadow="lg"
                            >
                                {project.platformPayment === false ? (
                                    <Flex w={"100%"} justify={"space-between"} align={"flex-end"}>
                                        <Heading size="md">Funds Paid: ${paymentTotal.toFixed(2)}</Heading>
                                    </Flex>

                                ) : (
                                    <Flex w={"100%"} justify={"space-between"} align={"flex-end"}>
                                        <Heading size="md">Funds Added: ${paymentTotal.toFixed(2)}</Heading>
                                    </Flex>
                                )}

                                <Progress
                                    value={((paymentTotal).toFixed(2) * 100) / bid.totalAmount}
                                    colorScheme='green'
                                    size="lg"
                                    bg={progressBarBg}
                                    mb={2}
                                    mt={4}
                                    borderRadius={8}
                                    hasStripe
                                    isAnimated
                                >
                                    <ProgressLabel color={progressLabelColor} fontSize="md">
                                        ${(paymentTotal).toFixed(2)}
                                    </ProgressLabel>
                                </Progress>
                                <Flex w={"100%"} justify={"space-between"} align={"flex-end"}>
                                    <Text fontSize="sm" color="gray.500">
                                        {((paymentTotal) / bid.totalAmount * 100).toFixed(2)}% funded
                                    </Text>
                                    <Text fontSize="sm" color="gray.500">Remaining: ${fundingRemaining.toFixed(2)}</Text>
                                </Flex>
                                {role == "Home Owner" && project.platformPayment === true && (
                                    <CheckoutRedirectButton bidId={bid._id} projectId={project._id} bidTotal={bid.totalAmount} paymentTotal={paymentTotal} transferGroupId={transferGroupId} title={bid.title} />
                                )}
                            </Box>
                            {project.platformPayment === true && (
                                <Box
                                    w={"50%"}
                                    p={4}
                                    ml={4}
                                    borderWidth={1}
                                    borderRadius={8}
                                    borderColor={"lightgray"}
                                    boxShadow="lg"
                                >
                                    <Flex w={"100%"} justify={"space-between"} align={"flex-end"}>
                                        <Heading size="md">
                                            Funds Released: $ {(totalTransferred / 100).toFixed(2)}
                                        </Heading>
                                    </Flex>
                                    <Progress
                                        value={totalTransferred / bid.totalAmount}
                                        colorScheme='green'
                                        size="lg"
                                        bg={progressBarBg}
                                        mb={2}
                                        mt={4}
                                        borderRadius={8}
                                        hasStripe
                                        isAnimated
                                    >
                                        <ProgressLabel color={progressLabelColor} fontSize="md">
                                            ${(totalTransferred / 100).toFixed(2)}
                                        </ProgressLabel>
                                    </Progress>
                                    <Flex w={"100%"} justify={"space-between"} align={"flex-end"}>
                                        <Text fontSize="sm" color="gray.500">
                                            {((totalTransferred / 100) / bid.totalAmount * 100).toFixed(2)}% transfered
                                        </Text>
                                        <Text fontSize="sm" color="gray.500">Remaining: ${payoutsRemaining.toFixed(2)}</Text>
                                    </Flex>
                                    {role == "Home Owner" && project.platformPayment === true && (
                                        <TransferButton
                                            destinationAccountId={destinationAccountId}
                                            orderId={transferGroupId}
                                            totalTransferred={totalTransferred}
                                            bid={bid}
                                            payoutsRemaining={payoutsRemaining}
                                            onTransferSuccess={onTransferSuccess}
                                        />
                                    )}
                                </Box>
                            )}
                        </Flex>
                        <Flex p={4} spacing={4} align="stretch" w="100%">
                            {project.paymentIntents.length > 0 && (
                                <Flex direction="column" p={4} align="stretch" w={{ base: "100%", lg: project.platformPayment === false ? "65%" : "100%" }}>
                                    <Heading mb={2} size="md">Payments</Heading>
                                    <SimpleGrid maxH={{ base: "", lg: "230px" }} overflowY="scroll" columns={{ base: 3, lg: project.platformPayment === false ? 3 : 5 }} spacing={2}>
                                        {project.paymentIntents.filter(intent => intent.status !== "requires_payment_method").map((intent) => (
                                            <Box borderColor="lightgray" p={3} shadow="md" w="230px" borderRadius="lg" borderWidth="1px" key={intent._id._id}>
                                                <Text>Status:
                                                    <Badge ml={1} colorScheme={intent.status === 'succeeded' ? 'green' : 'red'}>
                                                        {intent.status}
                                                    </Badge>
                                                </Text>
                                                <Text>Amount: ${intent?.subTotal?.toFixed(2)}</Text>
                                                <Text>
                                                    Payment Type:
                                                    {intent.paymentType.includes("card") ? (
                                                        <>
                                                            <Text mr={2} as="span"> Card</Text>
                                                            <FontAwesomeIcon icon={faCreditCard} />
                                                        </>
                                                    ) : intent.paymentType.includes("us_bank_account") ? (
                                                        <>
                                                            <Text mr={2} as="span"> Bank</Text>
                                                            <FontAwesomeIcon icon={faBuildingColumns} />
                                                        </>
                                                    ) : intent.paymentType.includes("check") ? (
                                                        <>
                                                            <Text mr={2} as="span"> Check</Text>
                                                            <FontAwesomeIcon icon={faMoneyCheck} />
                                                        </>
                                                    ) : intent.paymentType.includes("cash") ? (
                                                        <>
                                                            <Text mr={2} as="span"> Cash</Text>
                                                            <FontAwesomeIcon icon={faMoneyBill} />
                                                        </>
                                                    ) : intent.paymentType.includes("other") ? (
                                                        <>
                                                            <Text mr={2} as="span"> Other</Text>
                                                            <FontAwesomeIcon icon={faFileInvoiceDollar} />
                                                        </>
                                                    ) : null}
                                                </Text>
                                            </Box>
                                        ))}
                                    </SimpleGrid>
                                </Flex>
                            )}

                            {project.platformPayment === false && (
                                <Flex justify={"center"} p={4} w={"30%"}>
                                    <InPersonPaymentForm
                                        projectId={project._id}
                                        onPaymentSuccess={onPaymentSuccess}
                                        conversationId={bid.conversationId}
                                    />
                                </Flex>
                            )}
                        </Flex>
                    </Flex>
                </>
            )}
        </>
    );
};

export default FinanceDetails;