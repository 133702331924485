// src/contexts/ChatContext.js
import React, { createContext, useContext, useState } from 'react';

const ChatContext = createContext();

export const useChat = () => useContext(ChatContext);

export const ChatProvider = ({ children }) => {
    const [isChatOpen, setChatOpen] = useState(false);

    const toggleChat = () => setChatOpen(!isChatOpen);

    return (
        <ChatContext.Provider value={{ isChatOpen, toggleChat }}>
            {children}
        </ChatContext.Provider>
    );
};
