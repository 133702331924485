import React, { useState, useEffect } from 'react';
import {
    Box, Flex, Text, VStack, Input, Button, Select, Spinner, Textarea,
    FormControl, FormLabel, Grid, GridItem, Heading, Divider, Image, SimpleGrid, useBreakpointValue
} from '@chakra-ui/react';
import Header from '../elements/Header';
import ContractorSideMenu from '../contractor/SideMenu';
import VerifyPhone from '../elements/VerifyPhone';
import axios from 'axios';
import { io } from 'socket.io-client';
import { useAtom } from 'jotai';
import { socketAtom } from '../socketAtom';

function Verification() {
    const [contractor, setContractor] = useState({
        licenses: [{ type: '' }],
        insurance: [{ provider: '', type: '' }]
    });
    const [loading, setLoading] = useState(false);
    const menuType = useBreakpointValue({ base: 'drawer', lg: 'regular' });
    const [logoFile, setLogoFile] = useState(null);
    const [editing, setEditing] = useState(false);
    const token = localStorage.getItem('token');
    const [socket, setSocket] = useAtom(socketAtom);

    useEffect(() => {
        const newSocket = io(process.env.REACT_APP_API_URL, {
            autoConnect: true,
            auth: {
                token: token,
            },
        });

        newSocket.on('connect_error', (error) => {
            console.error('Socket connection error:', error);
        });

        setSocket(newSocket);

        return () => newSocket.close();
    }, [setSocket, token]);

    useEffect(() => {
        const getContractor = async () => {
            setLoading(true);
            try {
                const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/contractor`, {
                    headers: { Authorization: `Bearer ${token}` }
                });
                const fetchedData = response.data;
                fetchedData.specialties = fetchedData.specialties || [];
                fetchedData.professionalAssociations = fetchedData.professionalAssociations || [];
                fetchedData.servicesOffered = fetchedData.servicesOffered || [];
                fetchedData.warranties = fetchedData.warranties || [];
                setContractor(fetchedData);
                setLoading(false);
            } catch (error) {
                console.error('Error fetching contractor data:', error);
                setLoading(false);
            }
        };
        getContractor();
    }, [token]);

    const handleFileChange = (event) => {
        setLogoFile(event.target.files[0]);
    };

    const uploadLogo = async () => {
        if (!logoFile) {
            alert('Please select a file first.');
            return;
        }
        const formData = new FormData();
        formData.append('logo', logoFile);

        try {
            setLoading(true);
            const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/upload-logo`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                    Authorization: `Bearer ${localStorage.getItem('token')}`,
                },
            });

            setContractor(prevState => ({
                ...prevState,
                business: { ...prevState.business, logo: response.data.location }
            }));

            setLoading(false);
        } catch (error) {
            console.error('Error uploading logo:', error);
            setLoading(false);
        }
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        const path = name.split('.');
        let updated = { ...contractor };
        let current = updated;

        for (let i = 0; i < path.length; i++) {
            const key = path[i];
            if (i === path.length - 1) {
                current[key] = value;
            } else {
                if (/\[\d+\]/.test(key)) {
                    const index = key.match(/\d+/)[0];
                    current = current[key.replace(/\[\d+\]/, '')][index];
                } else {
                    current[key] = current[key] || {};
                    current = current[key];
                }
            }
        }

        if (name === "servicesOffered" || name === "warranties") {
            current[path[path.length - 1]] = value.split(',').map(item => item.trim());
        } else {
            current[path[path.length - 1]] = value;
        }

        setContractor(updated);
    };

    const handleInsuranceChange = (index, field, value) => {
        let newInsurance = [...contractor.insurance];
        if (!newInsurance[index]) {
            newInsurance[index] = { provider: '', type: '' };
        }
        newInsurance[index][field] = value;
        setContractor(prev => ({ ...prev, insurance: newInsurance }));
    };

    const handleLicenseChange = (index, field, value) => {
        let newLicenses = [...contractor.licenses];
        if (!newLicenses[index]) {
            newLicenses[index] = { type: '' };
        }
        newLicenses[index][field] = value;
        setContractor(prev => ({ ...prev, licenses: newLicenses }));
    };

    const handleEditMode = () => setEditing(!editing);

    const handleSaveContractor = async () => {
        setLoading(true);
        try {
            const response = await axios.put(`${process.env.REACT_APP_API_URL}/api/contractor`, contractor, {
                headers: { Authorization: `Bearer ${token}` }
            });
            setLoading(false);
            setEditing(false);
        } catch (error) {
            console.error('Error saving contractor data:', error);
            setLoading(false);
        }
    };

    const handlePortfolioChange = async (event) => {
        const files = Array.from(event.target.files);

        const uploadedImages = await Promise.all(
            files.map(async (file) => {
                const formData = new FormData();
                formData.append('portfolio', file);

                try {
                    const response = await axios.post(
                        `${process.env.REACT_APP_API_URL}/api/upload-portfolio`,
                        formData,
                        {
                            headers: {
                                'Content-Type': 'multipart/form-data',
                                Authorization: `Bearer ${localStorage.getItem('token')}`,
                            },
                        }
                    );

                    return response.data.portfolio[0].url;
                } catch (error) {
                    console.error('Error uploading image:', error);
                    return null;
                }
            })
        );

        setContractor((prevState) => ({
            ...prevState,
            business: {
                ...prevState.business,
                portfolio: [
                    {
                        ...prevState.business.portfolio[0],
                        imageURLs: [
                            ...(prevState.business.portfolio[0]?.imageURLs || []),
                            ...uploadedImages.filter((url) => url !== null),
                        ],
                    },
                ],
            },
        }));
    };

    return (
        <Flex direction={"column"} w={"100%"} justify={"center"}>
            <Flex direction={{ base: "column", lg: "row" }} p={2} w="100%" align="flex-start" justify="flex-start">
                <ContractorSideMenu />
                <Flex m={{ base: 0, lg: 4 }} direction="column" w="100%" justify="center">
                    <Header />
                    {menuType === 'drawer' ? (
                        <Flex direction="column" w="100%" align="center">
                            <Heading mb={4}>Contractor Information</Heading>
                            {loading ? (
                                <Spinner />
                            ) : (
                                <Grid templateColumns="repeat(1, 1fr)" gap={6} w="100%">
                                    <GridItem>
                                        <Box borderWidth={1} borderRadius="lg" p={4}>
                                            <Heading size="md" mb={4}>Business Information</Heading>
                                            <FormControl id="businessName" mb={4}>
                                                <FormLabel>Business Name</FormLabel>
                                                {editing ? (
                                                    <Input name="business.name" value={contractor?.business?.name || ''} onChange={handleInputChange} />
                                                ) : (
                                                    <Text>{contractor?.business?.name}</Text>
                                                )}
                                            </FormControl>
                                            <FormControl id="businessDescription" mb={4}>
                                                <FormLabel>Business Description</FormLabel>
                                                {editing ? (
                                                    <Textarea
                                                        name="business.description"
                                                        value={contractor?.business?.description || ''}
                                                        onChange={handleInputChange}
                                                        placeholder="Enter a description of your business"
                                                    />
                                                ) : (
                                                    <Text>{contractor?.business?.description}</Text>
                                                )}
                                            </FormControl>
                                            <FormControl id="businessType" mb={4}>
                                                <FormLabel>Business Type</FormLabel>
                                                {editing ? (
                                                    <Select name="business.type" value={contractor?.business?.type || ''} onChange={handleInputChange}>
                                                        <option value="">Select Business Type</option>
                                                        <option value="Sole Proprietorship">Sole Proprietorship</option>
                                                        <option value="Partnership">Partnership</option>
                                                        <option value="Corporation">Corporation</option>
                                                    </Select>
                                                ) : (
                                                    <Text>{contractor?.business?.type}</Text>
                                                )}
                                            </FormControl>
                                            {contractor?.business?.logo && (
                                                <Image src={contractor?.business?.logo} w={"200px"} h={"auto"} />
                                            )}
                                            <FormControl id="businessLogo" mb={4}>
                                                <FormLabel>Business Logo</FormLabel>
                                                <Input type="file" onChange={handleFileChange} />
                                                <Button mt={2} onClick={uploadLogo}>Upload Logo</Button>
                                            </FormControl>
                                            <FormControl id="businessAddress" mb={4}>
                                                <FormLabel>Business Address</FormLabel>
                                                {editing ? (
                                                    <VStack align="flex-start">
                                                        <Input
                                                            placeholder="Street"
                                                            name="business.address.street"
                                                            value={contractor?.business?.address?.street || ''}
                                                            onChange={handleInputChange}
                                                            mb={2}
                                                        />
                                                        <Input
                                                            placeholder="City"
                                                            name="business.address.city"
                                                            value={contractor?.business?.address?.city || ''}
                                                            onChange={handleInputChange}
                                                            mb={2}
                                                        />
                                                        <Input
                                                            placeholder="State"
                                                            name="business.address.state"
                                                            value={contractor?.business?.address?.state || ''}
                                                            onChange={handleInputChange}
                                                            mb={2}
                                                        />
                                                        <Input
                                                            placeholder="Zip Code"
                                                            name="business.address.zipCode"
                                                            value={contractor?.business?.address?.zipCode || ''}
                                                            onChange={handleInputChange}
                                                            mb={2}
                                                        />
                                                        <Input
                                                            placeholder="Country"
                                                            name="business.address.country"
                                                            value={contractor?.business?.address?.country || ''}
                                                            onChange={handleInputChange}
                                                        />
                                                    </VStack>
                                                ) : (
                                                    <VStack align="flex-start">
                                                        <Text>{contractor?.business?.address?.street}</Text>
                                                        <Text>{contractor?.business?.address?.city}</Text>
                                                        <Text>{contractor?.business?.address?.state}</Text>
                                                        <Text>{contractor?.business?.address?.zipCode}</Text>
                                                        <Text>{contractor?.business?.address?.country}</Text>
                                                    </VStack>
                                                )}
                                            </FormControl>
                                            <FormControl id="businessPhone" mb={4}>
                                                <FormLabel>Business Phone</FormLabel>
                                                {editing ? (
                                                    <Input
                                                        name="business.phone"
                                                        value={contractor?.business?.phone || ''}
                                                        onChange={handleInputChange}
                                                    />
                                                ) : (
                                                    <Text>{contractor?.business?.phone}</Text>
                                                )}
                                            </FormControl>
                                            <FormControl id="businessEmail" mb={4}>
                                                <FormLabel>Business Email</FormLabel>
                                                {editing ? (
                                                    <Input
                                                        name="business.email"
                                                        value={contractor?.business?.email || ''}
                                                        onChange={handleInputChange}
                                                    />
                                                ) : (
                                                    <Text>{contractor?.business?.email}</Text>
                                                )}
                                            </FormControl>
                                            <FormControl id="businessWebsite" mb={4}>
                                                <FormLabel>Business Website</FormLabel>
                                                {editing ? (
                                                    <Input
                                                        name="business.website"
                                                        value={contractor?.business?.website || ''}
                                                        onChange={handleInputChange}
                                                    />
                                                ) : (
                                                    <Text>{contractor?.business?.website}</Text>
                                                )}
                                            </FormControl>
                                            <FormControl id="yearsInBusiness" mb={4}>
                                                <FormLabel>Years in Business</FormLabel>
                                                {editing ? (
                                                    <Input
                                                        type="number"
                                                        name="business.yearsInBusiness"
                                                        value={contractor?.business?.yearsInBusiness || ''}
                                                        onChange={handleInputChange}
                                                    />
                                                ) : (
                                                    <Text>{contractor?.business?.yearsInBusiness}</Text>
                                                )}
                                            </FormControl>
                                            <FormControl id="yearsOfExperience" mb={4}>
                                                <FormLabel>Years of Experience</FormLabel>
                                                {editing ? (
                                                    <Input
                                                        type="number"
                                                        name="yearsOfExperience"
                                                        value={contractor?.yearsOfExperience || ''}
                                                        onChange={handleInputChange}
                                                        placeholder="Enter total years of experience"
                                                    />
                                                ) : (
                                                    <Text>{contractor?.yearsOfExperience}</Text>
                                                )}
                                            </FormControl>
                                        </Box>
                                        <Box borderWidth={1} borderRadius="lg" p={4} mb={4}>
                                            <Heading size="md" mb={4}>Services Offered</Heading>
                                            <FormControl id="servicesOffered" mb={4}>
                                                <FormLabel>Services</FormLabel>
                                                {editing ? (
                                                    <Input
                                                        name="servicesOffered"
                                                        value={Array.isArray(contractor?.servicesOffered) ? contractor?.servicesOffered?.join(', ') : ''}
                                                        onChange={handleInputChange}
                                                        placeholder="Enter services separated by commas"
                                                    />
                                                ) : (
                                                    <Text>{contractor?.servicesOffered?.join(', ')}</Text>
                                                )}
                                            </FormControl>
                                        </Box>
                                    </GridItem>
                                    <GridItem>
                                        <Box borderWidth={1} borderRadius="lg" p={4} mb={4}>
                                            <Heading size="md" mb={4}>Business Portfolio</Heading>
                                            {editing ? (
                                                <>
                                                    <FormControl id="portfolioImages" mb={4}>
                                                        <FormLabel>Upload Images</FormLabel>
                                                        <Input type="file" multiple onChange={handlePortfolioChange} />
                                                    </FormControl>
                                                    {contractor.business.portfolio[0]?.imageURLs?.map((imageURL, index) => (
                                                        <FormControl key={index} mb={4}>
                                                            <Image src={imageURL} alt={`Portfolio Image ${index}`} mb={2} />
                                                            <FormLabel>Image Description</FormLabel>
                                                        </FormControl>
                                                    ))}
                                                    <FormControl mb={4}>
                                                        <Input
                                                            type="text"
                                                            value={contractor.business.portfolio[0]?.description || ''}
                                                            onChange={(e) => {
                                                                setContractor((prevState) => ({
                                                                    ...prevState,
                                                                    business: {
                                                                        ...prevState.business,
                                                                        portfolio: [
                                                                            {
                                                                                ...prevState.business.portfolio[0],
                                                                                description: e.target.value,
                                                                            },
                                                                        ],
                                                                    },
                                                                }));
                                                            }}
                                                            placeholder="Enter description for the image"
                                                        />
                                                    </FormControl>
                                                </>
                                            ) : (
                                                <>
                                                    <SimpleGrid columns={{ base: 3, lg: 6 }} spacing={4}>
                                                        {contractor?.business?.portfolio[0]?.imageURLs?.map((imageURL, index) => (
                                                            <Box key={index}>
                                                                <Image w={"200px"} h={"auto"} src={imageURL} alt={`Portfolio Image ${index}`} mb={2} />
                                                            </Box>
                                                        ))}
                                                    </SimpleGrid>
                                                    <Text>{contractor?.business?.portfolio[0]?.description || ''}</Text>
                                                </>
                                            )}
                                        </Box>
                                        <Box borderWidth={1} borderRadius="lg" p={4} mb={4}>
                                            <Heading size="md" mb={4}>Licensing Information</Heading>
                                            <FormControl id="licenseType" mb={4}>
                                                <FormLabel>License Type</FormLabel>
                                                {editing ? (
                                                    <Select
                                                        name="licenses[0].type"
                                                        value={contractor?.licenses[0]?.type || ''}
                                                        onChange={(e) => handleLicenseChange(0, 'type', e.target.value)}
                                                    >
                                                        <option value="">Select License Type</option>
                                                        <option value="General Contractor">General Contractor</option>
                                                        <option value="Electrician">Electrician</option>
                                                        <option value="Plumber">Plumber</option>
                                                    </Select>
                                                ) : (
                                                    <Text>{contractor?.licenses[0]?.type || ''}</Text>
                                                )}
                                            </FormControl>
                                            <FormControl id="licenseNumber" mb={4}>
                                                <FormLabel>License Number</FormLabel>
                                                {editing ? (
                                                    <Input
                                                        name="licenses[0].number"
                                                        value={contractor?.licenses[0]?.number || ''}
                                                        onChange={(e) => handleLicenseChange(0, 'number', e.target.value)}
                                                    />
                                                ) : (
                                                    <Text>{contractor?.licenses[0]?.number || ''}</Text>
                                                )}
                                            </FormControl>
                                            <FormControl id="licenseExpiryDate" mb={4}>
                                                <FormLabel>Expiry Date</FormLabel>
                                                {editing ? (
                                                    <Input
                                                        type="date"
                                                        name="licenses[0].expiryDate"
                                                        value={contractor?.licenses[0]?.expiryDate || ''}
                                                        onChange={(e) => handleLicenseChange(0, 'expiryDate', e.target.value)}
                                                    />
                                                ) : (
                                                    <Text>{contractor?.licenses[0]?.expiryDate || ''}</Text>
                                                )}
                                            </FormControl>
                                            <FormControl id="licenseCertifications" mb={4}>
                                                <FormLabel>Certifications</FormLabel>
                                                {editing ? (
                                                    <Textarea
                                                        name="licenses[0].certifications"
                                                        value={contractor?.licenses[0]?.certifications?.join(", ") || ''}
                                                        onChange={(e) => handleLicenseChange(0, 'certifications', e.target.value.split(", "))}
                                                        placeholder="Enter certifications separated by commas"
                                                    />
                                                ) : (
                                                    <Text>{contractor?.licenses[0]?.certifications?.join(", ") || ''}</Text>)}
                                            </FormControl>
                                        </Box>
                                        <Box borderWidth={1} borderRadius="lg" p={4} mb={4}>
                                            <Heading size="md" mb={4}>Insurance Information</Heading>
                                            <FormControl id="insuranceProvider" mb={4}>
                                                <FormLabel>Insurance Provider</FormLabel>
                                                {editing ? (
                                                    <Input
                                                        name="insurance[0].provider"
                                                        value={contractor?.insurance[0]?.provider || ''}
                                                        onChange={(e) => handleInsuranceChange(0, 'provider', e.target.value)}
                                                    />
                                                ) : (
                                                    <Text>{contractor?.insurance[0]?.provider || ''}</Text>
                                                )}
                                            </FormControl>
                                            <FormControl id="insuranceType" mb={4}>
                                                <FormLabel>Insurance Type</FormLabel>
                                                {editing ? (
                                                    <Select
                                                        name="insurance[0].type"
                                                        value={contractor?.insurance[0]?.type || ''}
                                                        onChange={(e) => handleInsuranceChange(0, 'type', e.target.value)}
                                                    >
                                                        <option value="">Select Insurance Type</option>
                                                        <option value="General Liability">General Liability</option>
                                                        <option value="Workers Compensation">Workers Compensation</option>
                                                    </Select>
                                                ) : (
                                                    <Text>{contractor?.insurance[0]?.type || ''}</Text>
                                                )}
                                            </FormControl>
                                            <FormControl id="insurancePolicyNumber" mb={4}>
                                                <FormLabel>Policy Number</FormLabel>
                                                {editing ? (
                                                    <Input
                                                        name="insurance[0].policyNumber"
                                                        value={contractor?.insurance[0]?.policyNumber || ''}
                                                        onChange={(e) => handleInsuranceChange(0, 'policyNumber', e.target.value)}
                                                    />
                                                ) : (
                                                    <Text>{contractor?.insurance[0]?.policyNumber || ''}</Text>
                                                )}
                                            </FormControl>
                                            <FormControl id="insuranceCoverageAmount" mb={4}>
                                                <FormLabel>Coverage Amount</FormLabel>
                                                {editing ? (
                                                    <Input
                                                        type="number"
                                                        name="insurance[0].coverageAmount"
                                                        value={contractor?.insurance[0]?.coverageAmount || ''}
                                                        onChange={(e) => handleInsuranceChange(0, 'coverageAmount', e.target.value)}
                                                    />
                                                ) : (
                                                    <Text>{contractor?.insurance[0]?.coverageAmount || ''}</Text>
                                                )}
                                            </FormControl>
                                            <FormControl id="insuranceExpiryDate" mb={4}>
                                                <FormLabel>Expiry Date</FormLabel>
                                                {editing ? (
                                                    <Input
                                                        type="date"
                                                        name="insurance[0].expiryDate"
                                                        value={contractor?.insurance[0]?.expiryDate || ''}
                                                        onChange={(e) => handleInsuranceChange(0, 'expiryDate', e.target.value)}
                                                    />
                                                ) : (
                                                    <Text>{contractor?.insurance[0]?.expiryDate || ''}</Text>
                                                )}
                                            </FormControl>
                                        </Box>
                                        <Box borderWidth={1} borderRadius="lg" p={4} mb={4}>
                                            <Heading size="md" mb={4}>Bonding Information</Heading>
                                            <FormControl id="bondNumber" mb={4}>
                                                <FormLabel>Bond Number</FormLabel>
                                                {editing ? (
                                                    <Input
                                                        name="bonds.bondNumber"
                                                        value={contractor?.bonds?.bondNumber || ''}
                                                        onChange={handleInputChange}
                                                    />
                                                ) : (
                                                    <Text>{contractor?.bonds?.bondNumber}</Text>
                                                )}
                                            </FormControl>
                                            <FormControl id="bondingCompany" mb={4}>
                                                <FormLabel>Bonding Company</FormLabel>
                                                {editing ? (
                                                    <Input
                                                        name="bonds.bondingCompany"
                                                        value={contractor?.bonds?.bondingCompany || ''}
                                                        onChange={handleInputChange}
                                                    />
                                                ) : (
                                                    <Text>{contractor?.bonds?.bondingCompany}</Text>
                                                )}
                                            </FormControl>
                                            <FormControl id="bondAmount" mb={4}>
                                                <FormLabel>Bond Amount</FormLabel>
                                                {editing ? (
                                                    <Input
                                                        type="number"
                                                        name="bonds.amount"
                                                        value={contractor?.bonds?.amount || ''}
                                                        onChange={handleInputChange}
                                                    />
                                                ) : (
                                                    <Text>{contractor?.bonds?.amount ? `$${contractor.bonds.amount.toLocaleString()}` : ''}</Text>
                                                )}
                                            </FormControl>
                                            <FormControl id="bondExpiryDate" mb={4}>
                                                <FormLabel>Bond Expiry Date</FormLabel>
                                                {editing ? (
                                                    <Input
                                                        type="date"
                                                        name="bonds.expiryDate"
                                                        value={contractor?.bonds?.expiryDate ? contractor?.bonds?.expiryDate.slice(0, 10) : ''}
                                                        onChange={handleInputChange}
                                                    />
                                                ) : (
                                                    <Text>{contractor?.bonds?.expiryDate}</Text>
                                                )}
                                            </FormControl>
                                        </Box>
                                        <Box borderWidth={1} borderRadius="lg" p={4} mb={4}>
                                            <Heading size="md" mb={4}>Warranties</Heading>
                                            <FormControl id="warranties" mb={4}>
                                                <FormLabel>Warranties Offered</FormLabel>
                                                {editing ? (
                                                    <Input
                                                        name="warranties"
                                                        value={Array.isArray(contractor?.warranties) ? contractor?.warranties?.join(', ') : ''}
                                                        onChange={handleInputChange}
                                                        placeholder="Enter warranties separated by commas"
                                                    />
                                                ) : (
                                                    <Text>{contractor?.warranties?.join(', ')}</Text>
                                                )}
                                            </FormControl>
                                        </Box>
                                    </GridItem>
                                </Grid>
                            )}
                            {contractor.phoneVerified === false && (
                                <Box w={"100%"} borderWidth={1} borderRadius="lg" p={4} mb={4}>
                                    <VerifyPhone />
                                </Box>
                            )}
                            <Flex justify="flex-end" mt={4}>
                                <Button onClick={editing ? handleSaveContractor : handleEditMode}>
                                    {editing ? 'Save' : 'Edit'}
                                </Button>
                            </Flex>
                        </Flex>
                    ) : (
                        <Flex direction="column" w="100%" align="center">
                            <Heading mb={4}>Contractor Information</Heading>
                            {loading ? (
                                <Spinner />
                            ) : (
                                <Grid templateColumns="repeat(2, 1fr)" gap={6} w="100%">
                                    <GridItem>
                                        <Box borderWidth={1} borderRadius="lg" p={4}>
                                            <Heading size="md" mb={4}>Business Information</Heading>
                                            <FormControl id="businessName" mb={4}>
                                                <FormLabel>Business Name</FormLabel>
                                                {editing ? (
                                                    <Input name="business.name" value={contractor?.business?.name || ''} onChange={handleInputChange} />
                                                ) : (
                                                    <Text>{contractor?.business?.name}</Text>
                                                )}
                                            </FormControl>
                                            <FormControl id="businessDescription" mb={4}>
                                                <FormLabel>Business Description</FormLabel>
                                                {editing ? (
                                                    <Textarea
                                                        name="business.description"
                                                        value={contractor?.business?.description || ''}
                                                        onChange={handleInputChange}
                                                        placeholder="Enter a description of your business"
                                                    />
                                                ) : (
                                                    <Text>{contractor?.business?.description}</Text>
                                                )}
                                            </FormControl>
                                            <FormControl id="businessType" mb={4}>
                                                <FormLabel>Business Type</FormLabel>
                                                {editing ? (
                                                    <Select name="business.type" value={contractor?.business?.type || ''} onChange={handleInputChange}>
                                                        <option value="">Select Business Type</option>
                                                        <option value="Sole Proprietorship">Sole Proprietorship</option>
                                                        <option value="Partnership">Partnership</option>
                                                        <option value="Corporation">Corporation</option>
                                                    </Select>
                                                ) : (
                                                    <Text>{contractor?.business?.type}</Text>
                                                )}
                                            </FormControl>
                                            {contractor?.business?.logo && (
                                                <Image src={contractor?.business?.logo} w={"200px"} h={"auto"} />
                                            )}
                                            <FormControl id="businessLogo" mb={4}>
                                                <FormLabel>Business Logo</FormLabel>
                                                <Input type="file" onChange={handleFileChange} />
                                                <Button mt={2} onClick={uploadLogo}>Upload Logo</Button>
                                            </FormControl>
                                            <FormControl id="businessAddress" mb={4}>
                                                <FormLabel>Business Address</FormLabel>
                                                {editing ? (
                                                    <VStack align="flex-start">
                                                        <Input
                                                            placeholder="Street"
                                                            name="business.address.street"
                                                            value={contractor?.business?.address?.street || ''}
                                                            onChange={handleInputChange}
                                                            mb={2}
                                                        />
                                                        <Input
                                                            placeholder="City"
                                                            name="business.address.city"
                                                            value={contractor?.business?.address?.city || ''}
                                                            onChange={handleInputChange}
                                                            mb={2}
                                                        />
                                                        <Input
                                                            placeholder="State"
                                                            name="business.address.state"
                                                            value={contractor?.business?.address?.state || ''}
                                                            onChange={handleInputChange}
                                                            mb={2}
                                                        />
                                                        <Input
                                                            placeholder="Zip Code"
                                                            name="business.address.zipCode"
                                                            value={contractor?.business?.address?.zipCode || ''}
                                                            onChange={handleInputChange}
                                                            mb={2}
                                                        />
                                                        <Input
                                                            placeholder="Country"
                                                            name="business.address.country"
                                                            value={contractor?.business?.address?.country || ''}
                                                            onChange={handleInputChange}
                                                        />
                                                    </VStack>
                                                ) : (
                                                    <VStack align="flex-start">
                                                        <Text>{contractor?.business?.address?.street}</Text>
                                                        <Text>{contractor?.business?.address?.city}</Text>
                                                        <Text>{contractor?.business?.address?.state}</Text>
                                                        <Text>{contractor?.business?.address?.zipCode}</Text>
                                                        <Text>{contractor?.business?.address?.country}</Text>
                                                    </VStack>
                                                )}
                                            </FormControl>
                                            <FormControl id="businessPhone" mb={4}>
                                                <FormLabel>Business Phone</FormLabel>
                                                {editing ? (
                                                    <Input
                                                        name="business.phone"
                                                        value={contractor?.business?.phone || ''}
                                                        onChange={handleInputChange}
                                                    />
                                                ) : (
                                                    <Text>{contractor?.business?.phone}</Text>
                                                )}
                                            </FormControl>
                                            <FormControl id="businessEmail" mb={4}>
                                                <FormLabel>Business Email</FormLabel>
                                                {editing ? (
                                                    <Input
                                                        name="business.email"
                                                        value={contractor?.business?.email || ''}
                                                        onChange={handleInputChange}
                                                    />
                                                ) : (
                                                    <Text>{contractor?.business?.email}</Text>
                                                )}
                                            </FormControl>
                                            <FormControl id="businessWebsite" mb={4}>
                                                <FormLabel>Business Website</FormLabel>
                                                {editing ? (
                                                    <Input
                                                        name="business.website"
                                                        value={contractor?.business?.website || ''}
                                                        onChange={handleInputChange}
                                                    />
                                                ) : (
                                                    <Text>{contractor?.business?.website}</Text>
                                                )}
                                            </FormControl>
                                            <FormControl id="yearsInBusiness" mb={4}>
                                                <FormLabel>Years in Business</FormLabel>
                                                {editing ? (
                                                    <Input
                                                        type="number"
                                                        name="business.yearsInBusiness"
                                                        value={contractor?.business?.yearsInBusiness || ''}
                                                        onChange={handleInputChange}
                                                    />
                                                ) : (
                                                    <Text>{contractor?.business?.yearsInBusiness}</Text>
                                                )}
                                            </FormControl>
                                            <FormControl id="yearsOfExperience" mb={4}>
                                                <FormLabel>Years of Experience</FormLabel>
                                                {editing ? (
                                                    <Input
                                                        type="number"
                                                        name="yearsOfExperience"
                                                        value={contractor?.yearsOfExperience || ''}
                                                        onChange={handleInputChange}
                                                        placeholder="Enter total years of experience"
                                                    />
                                                ) : (
                                                    <Text>{contractor?.yearsOfExperience}</Text>
                                                )}
                                            </FormControl>
                                        </Box>
                                    </GridItem>
                                    <GridItem>
                                        <Box borderWidth={1} borderRadius="lg" p={4} mb={4}>
                                            <Heading size="md" mb={4}>Business Portfolio</Heading>
                                            {editing ? (
                                                <>
                                                    <FormControl id="portfolioImages" mb={4}>
                                                        <FormLabel>Upload Images</FormLabel>
                                                        <Input type="file" multiple onChange={handlePortfolioChange} />
                                                    </FormControl>
                                                    {contractor.business.portfolio[0]?.imageURLs?.map((imageURL, index) => (
                                                        <FormControl key={index} mb={4}>
                                                            <Image src={imageURL} alt={`Portfolio Image ${index}`} mb={2} />
                                                            <FormLabel>Image Description</FormLabel>
                                                        </FormControl>
                                                    ))}
                                                    <FormControl mb={4}>
                                                        <Input
                                                            type="text"
                                                            value={contractor.business.portfolio[0]?.description || ''}
                                                            onChange={(e) => {
                                                                setContractor((prevState) => ({
                                                                    ...prevState,
                                                                    business: {
                                                                        ...prevState.business,
                                                                        portfolio: [
                                                                            {
                                                                                ...prevState.business.portfolio[0],
                                                                                description: e.target.value,
                                                                            },
                                                                        ],
                                                                    },
                                                                }));
                                                            }}
                                                            placeholder="Enter description for the image"
                                                        />
                                                    </FormControl>
                                                </>
                                            ) : (
                                                <>
                                                    <SimpleGrid columns={{ base: 3, lg: 6 }} spacing={4}>
                                                        {contractor?.business?.portfolio[0]?.imageURLs?.map((imageURL, index) => (
                                                            <Box key={index}>
                                                                <Image w={"200px"} h={"auto"} src={imageURL} alt={`Portfolio Image ${index}`} mb={2} />
                                                            </Box>
                                                        ))}
                                                    </SimpleGrid>
                                                    <Text>{contractor?.business?.portfolio[0]?.description || ''}</Text>
                                                </>
                                            )}
                                        </Box>
                                        <Box borderWidth={1} borderRadius="lg" p={4} mb={4}>
                                            <Heading size="md" mb={4}>Licensing Information</Heading>
                                            <FormControl id="licenseType" mb={4}>
                                                <FormLabel>License Type</FormLabel>
                                                {editing ? (
                                                    <Select
                                                        name="licenses[0].type"
                                                        value={contractor?.licenses[0]?.type || ''}
                                                        onChange={(e) => handleLicenseChange(0, 'type', e.target.value)}
                                                    >
                                                        <option value="">Select License Type</option>
                                                        <option value="General Contractor">General Contractor</option>
                                                        <option value="Electrician">Electrician</option>
                                                        <option value="Plumber">Plumber</option>
                                                    </Select>
                                                ) : (
                                                    <Text>{contractor?.licenses[0]?.type || ''}</Text>
                                                )}
                                            </FormControl>
                                            <FormControl id="licenseNumber" mb={4}>
                                                <FormLabel>License Number</FormLabel>
                                                {editing ? (
                                                    <Input
                                                        name="licenses[0].number"
                                                        value={contractor?.licenses[0]?.number || ''}
                                                        onChange={(e) => handleLicenseChange(0, 'number', e.target.value)}
                                                    />
                                                ) : (
                                                    <Text>{contractor?.licenses[0]?.number || ''}</Text>
                                                )}
                                            </FormControl>
                                            <FormControl id="licenseExpiryDate" mb={4}>
                                                <FormLabel>Expiry Date</FormLabel>
                                                {editing ? (
                                                    <Input
                                                        type="date"
                                                        name="licenses[0].expiryDate"
                                                        value={contractor?.licenses[0]?.expiryDate || ''}
                                                        onChange={(e) => handleLicenseChange(0, 'expiryDate', e.target.value)}
                                                    />
                                                ) : (
                                                    <Text>{contractor?.licenses[0]?.expiryDate || ''}</Text>
                                                )}
                                            </FormControl>
                                            <FormControl id="licenseCertifications" mb={4}>
                                                <FormLabel>Certifications</FormLabel>
                                                {editing ? (
                                                    <Textarea
                                                        name="licenses[0].certifications"
                                                        value={contractor?.licenses[0]?.certifications?.join(", ") || ''}
                                                        onChange={(e) => handleLicenseChange(0, 'certifications', e.target.value.split(", "))}
                                                        placeholder="Enter certifications separated by commas"
                                                    />
                                                ) : (
                                                    <Text>{contractor?.licenses[0]?.certifications?.join(", ") || ''}</Text>)}
                                            </FormControl>
                                        </Box>
                                        <Box borderWidth={1} borderRadius="lg" p={4} mb={4}>
                                            <Heading size="md" mb={4}>Insurance Information</Heading>
                                            <FormControl id="insuranceProvider" mb={4}>
                                                <FormLabel>Insurance Provider</FormLabel>
                                                {editing ? (
                                                    <Input
                                                        name="insurance[0].provider"
                                                        value={contractor?.insurance[0]?.provider || ''}
                                                        onChange={(e) => handleInsuranceChange(0, 'provider', e.target.value)}
                                                    />
                                                ) : (
                                                    <Text>{contractor?.insurance[0]?.provider || ''}</Text>
                                                )}
                                            </FormControl>
                                            <FormControl id="insuranceType" mb={4}>
                                                <FormLabel>Insurance Type</FormLabel>
                                                {editing ? (
                                                    <Select
                                                        name="insurance[0].type"
                                                        value={contractor?.insurance[0]?.type || ''}
                                                        onChange={(e) => handleInsuranceChange(0, 'type', e.target.value)}
                                                    >
                                                        <option value="">Select Insurance Type</option>
                                                        <option value="General Liability">General Liability</option>
                                                        <option value="Workers Compensation">Workers Compensation</option>
                                                    </Select>
                                                ) : (
                                                    <Text>{contractor?.insurance[0]?.type || ''}</Text>
                                                )}
                                            </FormControl>
                                            <FormControl id="insurancePolicyNumber" mb={4}>
                                                <FormLabel>Policy Number</FormLabel>
                                                {editing ? (
                                                    <Input
                                                        name="insurance[0].policyNumber"
                                                        value={contractor?.insurance[0]?.policyNumber || ''}
                                                        onChange={(e) => handleInsuranceChange(0, 'policyNumber', e.target.value)}
                                                    />
                                                ) : (
                                                    <Text>{contractor?.insurance[0]?.policyNumber || ''}</Text>
                                                )}
                                            </FormControl>
                                            <FormControl id="insuranceCoverageAmount" mb={4}>
                                                <FormLabel>Coverage Amount</FormLabel>
                                                {editing ? (
                                                    <Input
                                                        type="number"
                                                        name="insurance[0].coverageAmount"
                                                        value={contractor?.insurance[0]?.coverageAmount || ''}
                                                        onChange={(e) => handleInsuranceChange(0, 'coverageAmount', e.target.value)}
                                                    />
                                                ) : (
                                                    <Text>{contractor?.insurance[0]?.coverageAmount || ''}</Text>
                                                )}
                                            </FormControl>
                                            <FormControl id="insuranceExpiryDate" mb={4}>
                                                <FormLabel>Expiry Date</FormLabel>
                                                {editing ? (
                                                    <Input
                                                        type="date"
                                                        name="insurance[0].expiryDate"
                                                        value={contractor?.insurance[0]?.expiryDate || ''}
                                                        onChange={(e) => handleInsuranceChange(0, 'expiryDate', e.target.value)}
                                                    />
                                                ) : (
                                                    <Text>{contractor?.insurance[0]?.expiryDate || ''}</Text>
                                                )}
                                            </FormControl>
                                        </Box>
                                        <Box borderWidth={1} borderRadius="lg" p={4} mb={4}>
                                            <Heading size="md" mb={4}>Bonding Information</Heading>
                                            <FormControl id="bondNumber" mb={4}>
                                                <FormLabel>Bond Number</FormLabel>
                                                {editing ? (
                                                    <Input
                                                        name="bonds.bondNumber"
                                                        value={contractor?.bonds?.bondNumber || ''}
                                                        onChange={handleInputChange}
                                                    />
                                                ) : (
                                                    <Text>{contractor?.bonds?.bondNumber}</Text>
                                                )}
                                            </FormControl>
                                            <FormControl id="bondingCompany" mb={4}>
                                                <FormLabel>Bonding Company</FormLabel>
                                                {editing ? (
                                                    <Input
                                                        name="bonds.bondingCompany"
                                                        value={contractor?.bonds?.bondingCompany || ''}
                                                        onChange={handleInputChange}
                                                    />
                                                ) : (
                                                    <Text>{contractor?.bonds?.bondingCompany}</Text>
                                                )}
                                            </FormControl>
                                            <FormControl id="bondAmount" mb={4}>
                                                <FormLabel>Bond Amount</FormLabel>
                                                {editing ? (
                                                    <Input
                                                        type="number"
                                                        name="bonds.amount"
                                                        value={contractor?.bonds?.amount || ''}
                                                        onChange={handleInputChange}
                                                    />
                                                ) : (
                                                    <Text>{contractor?.bonds?.amount ? `$${contractor.bonds.amount.toLocaleString()}` : ''}</Text>
                                                )}
                                            </FormControl>
                                            <FormControl id="bondExpiryDate" mb={4}>
                                                <FormLabel>Bond Expiry Date</FormLabel>
                                                {editing ? (
                                                    <Input
                                                        type="date"
                                                        name="bonds.expiryDate"
                                                        value={contractor?.bonds?.expiryDate ? contractor?.bonds?.expiryDate.slice(0, 10) : ''}
                                                        onChange={handleInputChange}
                                                    />
                                                ) : (
                                                    <Text>{contractor?.bonds?.expiryDate}</Text>
                                                )}
                                            </FormControl>
                                        </Box>
                                    </GridItem>
                                </Grid>
                            )}
                            <Grid mt={4} templateColumns="repeat(2, 1fr)" gap={6} w="100%">
                                <GridItem>
                                    <Box borderWidth={1} borderRadius="lg" p={4} mb={4}>
                                        <Heading size="md" mb={4}>Services Offered</Heading>
                                        <FormControl id="servicesOffered" mb={4}>
                                            <FormLabel>Services</FormLabel>
                                            {editing ? (
                                                <Input
                                                    name="servicesOffered"
                                                    value={Array.isArray(contractor?.servicesOffered) ? contractor?.servicesOffered?.join(', ') : ''}
                                                    onChange={handleInputChange}
                                                    placeholder="Enter services separated by commas"
                                                />
                                            ) : (
                                                <Text>{contractor?.servicesOffered?.join(', ')}</Text>
                                            )}
                                        </FormControl>
                                    </Box>
                                </GridItem>
                                <GridItem>
                                    <Box borderWidth={1} borderRadius="lg" p={4} mb={4}>
                                        <Heading size="md" mb={4}>Warranties</Heading>
                                        <FormControl id="warranties" mb={4}>
                                            <FormLabel>Warranties Offered</FormLabel>
                                            {editing ? (
                                                <Input
                                                    name="warranties"
                                                    value={Array.isArray(contractor?.warranties) ? contractor?.warranties?.join(', ') : ''}
                                                    onChange={handleInputChange}
                                                    placeholder="Enter warranties separated by commas"
                                                />
                                            ) : (
                                                <Text>{contractor?.warranties?.join(', ')}</Text>
                                            )}
                                        </FormControl>
                                    </Box>
                                </GridItem>
                            </Grid>
                            {contractor.phoneVerified === false && (
                                <Box borderWidth={1} borderRadius="lg" p={4} mb={4}>
                                    <VerifyPhone />
                                </Box>
                            )}
                            <Flex justify="flex-end" mt={4}>
                                <Button onClick={editing ? handleSaveContractor : handleEditMode}>
                                    {editing ? 'Save' : 'Edit'}
                                </Button>
                            </Flex>
                        </Flex>
                    )}
                </Flex>
            </Flex>
        </Flex>
    );
}

export default Verification;