import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useAtom } from 'jotai';
import { socketAtom } from '../socketAtom';
import { io } from 'socket.io-client';
import axios from 'axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { format, parseISO } from 'date-fns';
import { Tabs, TabList, Tab, TabPanels, TabPanel, Flex, Text, Heading, Image, Badge, HStack, Box, useToast, Button, Accordion, AccordionItem, AccordionButton, AccordionIcon, AccordionPanel, Circle, useBreakpointValue } from '@chakra-ui/react';
import ContractorSideMenu from '../contractor/SideMenu';
import Header from '../elements/Header';
import MessageComponent from '../elements/MessageComponent';
import { useSocketInstance } from '../contractor/SideMenu';
import Logo from '../assets/Horizontal LOGO.webp';
import SignatureCapture from '../elements/SignatureCapture';
import { faBuildingColumns, faCircleCheck, faCircleDot, faFileSignature, faIdCard, faUserShield } from '@fortawesome/free-solid-svg-icons';
import PublicProfile from '../contractor/PublicProfile';
import ContractorReviews from '../elements/ContractorImport';
import EliteContractors from '../elements/EliteContractors';

const ProjectPage = () => {
    const [socket] = useAtom(socketAtom);
    const menuType = useBreakpointValue({ base: 'drawer', lg: 'regular' });
    const { projectId } = useParams();
    const [bidsData, setBidsData] = useState([]);
    const [activeBidId, setActiveBidId] = useState(null);
    const [ownerSignature, setOwnerSignature] = useState('');
    const [contractorId, setContractorId] = useState('');
    const [selectedConversationId, setSelectedConversationId] = useState(null);
    const [isActive, setIsActive] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const toast = useToast();
    const token = localStorage.getItem('token');

    const [, setSocket] = useAtom(socketAtom);

    useEffect(() => {
        const newSocket = io(process.env.REACT_APP_API_URL, {
            autoConnect: true,
            auth: {
                token: token,
            },
        });

        newSocket.on('connect_error', (error) => {
            console.error('Socket connection error:', error);
        });

        setSocket(newSocket);

        return () => newSocket.close();
    }, [setSocket, token]);

    useEffect(() => {
        fetchData();
    }, [projectId, token]);

    const fetchData = async () => {
        const headers = { Authorization: `Bearer ${token}` };
        try {
            const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/projects/${projectId}/bids`, { headers });
            const { bids, bidNotifications } = response.data;

            const combinedBids = bids?.map(bid => ({
                ...bid,
                type: 'bid',
                conversationId: bid.conversationId || bid._id // Check for conversationId or fallback to _id
            }));

            // Adjust here to access the _id from the Bids array
            const notifications = bidNotifications?.map(notification => ({
                ...notification?.Bids[0], // Assuming there's always at least one bid in the Bids array
                conversationId: notification?.Bids[0]?._id, // Use the _id from the first bid in the array
                type: 'notification'
            }));

            setBidsData([...combinedBids, ...notifications]);

            // Finding the first valid conversationId
            const validConversationId = combinedBids?.find(bid => bid.conversationId)?.conversationId ||
                notifications?.find(notification => notification.conversationId)?.conversationId;

            if (validConversationId) {
                setSelectedConversationId(validConversationId);
            } else {
            }
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };

    const handleTabChange = (index) => {
        const newSelectedBid = bidsData[index];
        setSelectedConversationId(newSelectedBid.type === 'bid' ? newSelectedBid.conversationId : newSelectedBid._id);
    };

    const handleAcceptBid = (bidId) => {
        setActiveBidId(bidId);
        setIsActive(true);
    };

    const handleSignatureSave = async (signatureData) => {
        setIsLoading(true);
        const { blob, location } = signatureData;
        const formData = new FormData();
        formData.append('signature', blob);
        if (location) {
            formData.append('latitude', location.latitude);
            formData.append('longitude', location.longitude);
        }

        try {
            const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/homeOwner/updateBidWithSignature/${activeBidId}/${projectId}`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                    'Authorization': `Bearer ${localStorage.getItem('token')}`,
                },
            });
            if (response.data.success) {
                setOwnerSignature(response.data.url);
                fetchData();

                const eventMessage = {
                    conversationId: selectedConversationId,
                    message: `Bid Accepted!`,
                    messageType: 'accepted',
                };

                socket.emit('send-message', eventMessage);

                toast({
                    title: 'Signature Uploaded Successfully',
                    description: response.data.message,
                    status: 'success',
                    duration: 3000,
                    isClosable: true,
                });
            } else {
                toast({
                    title: 'Error Uploading Signature',
                    description: response.data.message || 'Failed to upload the signature.',
                    status: 'error',
                    duration: 3000,
                    isClosable: true,
                });
            }
        } catch (error) {
            console.error('Error uploading signature:', error);
            toast({
                title: 'Error submitting bid',
                description: error.response?.data?.message || error.message,
                status: 'error',
                duration: 3000,
                isClosable: true,
            });
        }
        setIsLoading(false);
    };

    const safeFormatDate = (dateString, formatString) => {
        if (!dateString) return 'N/A';
        const date = parseISO(dateString);
        return format(date, formatString);
    };

    return (
        <Flex direction={"column"} w={"100%"} justify={"center"}>
            <Flex direction={{ base: "column", lg: "row" }} p={2} w="100%" align="flex-start" justify="flex-start">
                <ContractorSideMenu />
                <Flex m={{ base: 0, lg: 4 }} direction="column" w="100%" justify="center">
                    <Header />
                    {menuType === 'drawer' ? (
                        <>
                            <Flex mt={1} direction={"column"} w={"100"}>
                                <EliteContractors projectId={projectId} />
                                <Tabs mt={2} variant='enclosed' colorScheme='green' onChange={handleTabChange}>
                                    <TabList>
                                        {bidsData?.map((bid, index) => (
                                            <Tab key={index}>
                                                {bid.type === 'bid'
                                                    ? (bid.contractorBusiness || `${bid.contractorFirstName} ${bid.contractorLastName}`)
                                                    : `Pending Bid: ${bid._id.substring(18)}`}
                                            </Tab>
                                        ))}
                                    </TabList>
                                    <TabPanels>
                                        {bidsData?.map((bid, index) => (
                                            <TabPanel justify={"center"} w={"100"} key={index}>
                                                <Flex direction={"column"} justify={"space-between"} w={"100"}>
                                                    <Flex justify={"center"} w={"100%"}>
                                                        {bid.type === 'bid' ? (
                                                            <Flex w={"100%"}>
                                                                <Flex maxH={"100vh"} overflowY={"scroll"} border={"1px"} borderColor={"lightgrey"} shadow={"md"} borderRadius={"md"} direction={"column"} p={1} w={"100%"}>
                                                                    <Flex direction={"column"} w={"100%"} justify={'space-between'}>
                                                                        <Flex direction={"column"} w={"100%"} justify={"space-between"}>
                                                                            <>
                                                                                {bid.contractorLogo ? (
                                                                                    <Flex w={"100%"} justify={"space-between"}>
                                                                                        <Image src={bid.contractorLogo} maxW={"50%"} maxH={"auto"} />
                                                                                        <Flex direction={"column"} align={"flex-end"}>
                                                                                            <Heading size={"sm"}>
                                                                                                Proposal
                                                                                            </Heading>
                                                                                            <Text fontSize={"sm"} textAlign="right">Proposal ID # {bid.estimateId}</Text>
                                                                                        </Flex>
                                                                                    </Flex>
                                                                                ) : (
                                                                                    <Flex w={"100%"} justify={"space-between"}>
                                                                                        <Image src={Logo} maxH={"40px"} />
                                                                                        <Flex direction={"column"} align={"flex-end"}>
                                                                                            <Heading size={"sm"}>
                                                                                                Proposal
                                                                                            </Heading>
                                                                                            <Text fontSize={"sm"} textAlign="right">Proposal ID # {bid.estimateId}</Text>
                                                                                        </Flex>
                                                                                    </Flex>
                                                                                )}
                                                                            </>
                                                                            <Flex align={"flex-end"} justify={"flex-end"} direction={"column"}>
                                                                                {bid.status === "open" ? (
                                                                                    <Badge mr={1} colorScheme='blue'>Open</Badge>
                                                                                ) : bid.status === "in progress" ? (
                                                                                    <Badge mr={1} colorScheme='red'>In Progress</Badge>
                                                                                ) : bid.status === "completed" ? (
                                                                                    <Badge mr={1} colorScheme='green'>Completed</Badge>
                                                                                ) : null}
                                                                            </Flex>
                                                                        </Flex>
                                                                    </Flex>
                                                                    <Flex mb={4} w={"100%"} justify={'space-between'}>
                                                                        <Flex w={"50%"} direction={"column"}>
                                                                            <Flex direction={"column"} align={"flex-start"}>
                                                                                {bid.contractorBusiness && (
                                                                                    bid.isVetted ? (

                                                                                        <Flex mr={2} align="center">
                                                                                            <FontAwesomeIcon size='xs' color='green' icon={faCircleCheck} />
                                                                                            <Text fontSize={"sm"} ml={1} mt={1} fontWeight="semibold">
                                                                                                {bid.contractorBusiness}
                                                                                            </Text>
                                                                                        </Flex>

                                                                                    ) : (

                                                                                        <Flex mr={2} align="center">
                                                                                            <FontAwesomeIcon size='xs' color='lightgray' icon={faCircleCheck} />
                                                                                            <Text fontSize={"sm"} ml={1} mt={1} fontWeight="semibold">
                                                                                                {bid.contractorBusiness}
                                                                                            </Text>
                                                                                        </Flex>

                                                                                    )
                                                                                )}
                                                                                <Flex justify={"flex-start"}>
                                                                                    {bid.licenses && bid.licenses.length > 0 && (
                                                                                        <Flex mr={2}>
                                                                                            <FontAwesomeIcon color='green' icon={faIdCard} />
                                                                                        </Flex>
                                                                                    )}
                                                                                    {bid.contractorInsurance && bid.contractorInsurance.length > 0 && (
                                                                                        <Flex mr={2}>
                                                                                            <FontAwesomeIcon color='green' icon={faUserShield} />
                                                                                        </Flex>
                                                                                    )}
                                                                                    {bid.contractorBonds && (
                                                                                        <Flex>
                                                                                            <FontAwesomeIcon color='green' icon={faBuildingColumns} />
                                                                                        </Flex>
                                                                                    )}
                                                                                </Flex>
                                                                            </Flex>
                                                                            <Text fontSize={"sm"} fontWeight={"semibold"}>
                                                                                {bid.contractorFirstName} {bid.contractorLastName}
                                                                            </Text>

                                                                            <Text fontSize={"sm"}>
                                                                                {bid.contractorAddress.street}
                                                                            </Text>
                                                                            <Text fontSize={"sm"}>
                                                                                {bid.contractorAddress.city}, {bid.contractorAddress.state} {bid.contractorAddress.zipCode}
                                                                            </Text>

                                                                        </Flex>
                                                                        <Flex mt={2} w={"50%"} direction={"column"} align={"flex-end"} justify={"flex-start"}>
                                                                            <Text fontSize={"sm"} fontWeight="bold">Proposed Dates</Text>
                                                                            <Text fontSize={"sm"}>
                                                                                {safeFormatDate(bid.startDate, 'MMMM dd, yyyy')}
                                                                            </Text>
                                                                            <Text fontSize={"sm"}>
                                                                                {safeFormatDate(bid.endDate, 'MMMM dd, yyyy')}
                                                                            </Text>
                                                                            <Text mt={2} fontSize={"sm"}>
                                                                                Expiration Date
                                                                            </Text>
                                                                            <Text fontSize={"sm"}>
                                                                                {safeFormatDate(bid.expirationDate, 'MMMM dd, yyyy')}
                                                                            </Text>
                                                                            {!bid.ownerSignature && (
                                                                                <Flex mt={2} key={index} direction={"column"}>
                                                                                    {isActive ? (
                                                                                        <Flex direction={"column"} justify={"flex-end"} align={"flex-end"}>
                                                                                            <SignatureCapture isLoading={isLoading} onSignatureSave={handleSignatureSave} />
                                                                                        </Flex>
                                                                                    ) : (
                                                                                        <Button key={index} bg="#1DADE3" _hover={"blue"} color={"white"} size={"sm"} onClick={() => handleAcceptBid(bid._id)}>Accept Bid</Button>
                                                                                    )}
                                                                                </Flex>
                                                                            )}
                                                                        </Flex>
                                                                    </Flex>
                                                                    <Flex direction={"column"}>
                                                                        <Text fontSize={"sm"} fontWeight={"bold"}>{bid.title}</Text>
                                                                        <Text fontSize={"sm"}>{bid.description}</Text>
                                                                    </Flex>
                                                                    <Flex mt={4} w={"100%"} bg={"gray.700"} borderRadius={"md"} >
                                                                        <Flex w={"100%"} justify={"space-between"}>
                                                                            <Text fontSize={"sm"} ml={1} mr={8} color={"white"}>Item</Text>
                                                                            <Text fontSize={"sm"} color={"white"}>Qty</Text>
                                                                            <Text fontSize={"sm"} color={"white"}>Rate</Text>
                                                                            <Text fontSize={"sm"} mr={1} color={"white"}>Amount</Text>
                                                                        </Flex>
                                                                    </Flex>
                                                                    {bid?.items?.map((item, index) => (
                                                                        <>
                                                                            <Flex key={index} w={"100%"} borderRadius={"md"} p={1} alignItems="start">
                                                                                <Flex w={"50%"} justify={"space-between"}>
                                                                                    <Flex direction={"column"} >
                                                                                        <Text fontSize={"sm"} fontWeight={"semibold"}>{item.name}</Text>
                                                                                        <Text fontSize={"xs"} mt={-1} fontWeight={"thin"}>{item.description}</Text>
                                                                                    </Flex>
                                                                                    {!item.hideDetails && <Text mr={6}>{item.quantity}</Text>}
                                                                                    {!item.hideDetails && <Text fontSize={"sm"} ml={4}>{parseFloat(item.rate).toFixed(2)}</Text>}
                                                                                    {!item.hideDetails && <Text fontSize={"sm"} mr={2}>{typeof item.amount === 'number' ? item.amount.toFixed(2) : '0.00'}</Text>}
                                                                                </Flex>
                                                                            </Flex>
                                                                        </>
                                                                    ))}
                                                                    <Flex mt={4} justify={"flex-end"} align={"flex-end"} w={"100%"}>
                                                                        <Flex direction={"column"} align={"flex-end"} mr={4}>
                                                                            <Text mb={2}>Subtotal: ${bid.subTotalAmount}</Text>
                                                                            {bid.discountAmount > 0 && (
                                                                                <Text mb={2}>Discount ({bid.discount}%): -${bid.discountAmount}</Text>
                                                                            )}
                                                                            <Text mb={2}>Service Charge: ${bid.serviceCharge}</Text>
                                                                        </Flex>
                                                                    </Flex>

                                                                    <Flex justify={"flex-end"} align={"flex-end"} w={"100%"} bg={"gray.700"} borderRadius={"md"} >
                                                                        <Flex p={2} direction={"column"} mr={2} w={"100%"} justify={"flex-end"} align={"flex-end"}>
                                                                            <Text color={"white"}>Total (Including Taxes): ${bid?.totalAmount}</Text>
                                                                        </Flex>
                                                                    </Flex>
                                                                    <Flex direction="column" w="100%">
                                                                        {bid.paymentTerms === "Stages" ? (
                                                                            <Flex w={"100%"} justify={"flex-end"}>
                                                                                <Box
                                                                                    w="fit-content"
                                                                                    mt={4}
                                                                                    borderRadius="lg"
                                                                                    border="1px"
                                                                                    p={4}
                                                                                    boxShadow="md"
                                                                                    direction="column"
                                                                                >
                                                                                    <Heading size="md" mb={4}>
                                                                                        Payment Terms:
                                                                                    </Heading>
                                                                                    {bid.customStages?.map((stage, index) => (
                                                                                        <Flex key={stage.id} align="center" justify={"space-between"} mb={2}>
                                                                                            <Flex align={"center"} mr={2}>
                                                                                                <FontAwesomeIcon size='xs' icon={faCircleDot} />
                                                                                                <Text ml={2}>{stage.description}</Text>
                                                                                            </Flex>
                                                                                            <Flex>
                                                                                                <Box
                                                                                                    p={2}
                                                                                                    borderRadius="md"
                                                                                                    bg={stage.upfrontAmount > 0 ? "green.100" : "gray.100"}
                                                                                                    mr={2}
                                                                                                >
                                                                                                    <Text color={stage.upfrontAmount > 0 ? "green.600" : "gray.600"}>
                                                                                                        Due at Start: ${stage.upfrontAmount}
                                                                                                    </Text>
                                                                                                </Box>

                                                                                                <Box
                                                                                                    p={2}
                                                                                                    borderRadius="md"
                                                                                                    bg={stage.completionAmount > 0 ? "blue.100" : "gray.100"}
                                                                                                >
                                                                                                    <Text color={stage.completionAmount > 0 ? "blue.600" : "gray.600"}>
                                                                                                        Due at End: ${stage.completionAmount}
                                                                                                    </Text>
                                                                                                </Box>
                                                                                            </Flex>
                                                                                        </Flex>
                                                                                    )) || <Text>No stages defined</Text>}
                                                                                </Box>
                                                                            </Flex>
                                                                        ) : bid.paymentTerms === "1/2" ? (
                                                                            <>
                                                                                {bid.upfrontAmount && (
                                                                                    <Flex w={"100%"} justify={"flex-end"}>
                                                                                        <Flex w={"fit-content"} mt={4} borderRadius={"lg"} direction={"column"} p={2} border={"1px"}>
                                                                                            <Heading size={"sm"}>Payment Terms:</Heading>
                                                                                            <Text>${bid.upfrontAmount.amount} before work starts. ${bid.upfrontAmount.amount} when completed</Text>
                                                                                            <Text>{bid.upfrontAmount.description}</Text>
                                                                                        </Flex>
                                                                                    </Flex>
                                                                                )}
                                                                            </>
                                                                        ) : bid.paymentTerms === "UpFront" ? (
                                                                            <>
                                                                                {bid.upfrontAmount && (
                                                                                    <Flex w={"100%"} justify={"flex-end"}>
                                                                                        <Flex w={"fit-content"} mt={4} borderRadius={"lg"} direction={"column"} p={2} border={"1px"}>
                                                                                            <Heading size={"sm"}>Payment Terms:</Heading>
                                                                                            <Text>${bid.upfrontAmount.amount} before work starts.</Text>
                                                                                            <Text>{bid.upfrontAmount.description}</Text>
                                                                                        </Flex>
                                                                                    </Flex>
                                                                                )}
                                                                            </>
                                                                        ) : bid.paymentTerms === "Completed" ? (
                                                                            <>
                                                                                {bid.upfrontAmount && (
                                                                                    <Flex w={"100%"} justify={"flex-end"}>
                                                                                        <Flex w={"fit-content"} mt={4} borderRadius={"lg"} direction={"column"} p={2} border={"1px"}>
                                                                                            <Heading size={"sm"}>Payment Terms:</Heading>
                                                                                            <Text>${bid.total} when completed</Text>
                                                                                            <Text>{bid.upfrontAmount.description}</Text>
                                                                                        </Flex>
                                                                                    </Flex>
                                                                                )}
                                                                            </>
                                                                        ) : (
                                                                            null
                                                                        )}
                                                                    </Flex>

                                                                    {bid.notes && (
                                                                        <Flex mt={4} borderRadius={"lg"} direction={"column"} p={2} bg={"gray.700"}>
                                                                            <Heading color={"white"} size={"sm"}>Notes:</Heading>
                                                                            <Text color={"white"}>
                                                                                {bid.notes}
                                                                            </Text>
                                                                        </Flex>
                                                                    )}

                                                                    <Accordion mt={4} allowToggle>
                                                                        {bid.contractorInsurance && bid.contractorInsurance.length > 0 && (
                                                                            <AccordionItem>
                                                                                <h2>
                                                                                    <AccordionButton>
                                                                                        <Box as='span' flex='1' textAlign='left'>
                                                                                            Insurance
                                                                                        </Box>
                                                                                        <AccordionIcon />
                                                                                    </AccordionButton>
                                                                                </h2>
                                                                                <AccordionPanel pb={4}>
                                                                                    {bid.contractorInsurance?.map((insurance, index) => (
                                                                                        <Box w="fit-content" mt={2} key={index} p={2} shadow="md" borderWidth="1px">
                                                                                            <Text fontWeight="semibold">{insurance.provider}</Text>
                                                                                            <Text>Policy Number: {insurance.policyNumber}</Text>
                                                                                            <Text>Coverage Amount: ${new Intl.NumberFormat('en-US', { style: 'decimal', minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(insurance.coverageAmount)}</Text>
                                                                                            <Text>Expiry Date: {new Date(insurance.expiryDate).toLocaleDateString()}</Text>
                                                                                            <Text>Type: {insurance.type}</Text>
                                                                                        </Box>
                                                                                    ))}
                                                                                </AccordionPanel>
                                                                            </AccordionItem>
                                                                        )}

                                                                        {bid.contractorBonds && (
                                                                            <AccordionItem>
                                                                                <h2>
                                                                                    <AccordionButton>
                                                                                        <Box as='span' flex='1' textAlign='left'>
                                                                                            Bonds
                                                                                        </Box>
                                                                                        <AccordionIcon />
                                                                                    </AccordionButton>
                                                                                </h2>
                                                                                <AccordionPanel pb={4}>
                                                                                    {bid.contractorBonds && (
                                                                                        <Box w="fit-content" mt={2} key={index} p={2} shadow="md" borderWidth="1px">
                                                                                            <Text fontWeight="semibold">{bid.contractorBonds.bondingCompany}</Text>
                                                                                            <Text>Bond Number: {bid.contractorBonds.bondNumber}</Text>
                                                                                            <Text>Bond Amount: ${new Intl.NumberFormat('en-US', { style: 'decimal', minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(bid.contractorBonds.amount)}</Text>
                                                                                            <Text>Expiry Date: {new Date(bid.contractorBonds.expiryDate).toLocaleDateString()}</Text>
                                                                                        </Box>
                                                                                    )}
                                                                                </AccordionPanel>
                                                                            </AccordionItem>
                                                                        )}

                                                                        {bid.licenses && bid.licenses.length > 0 && (
                                                                            <AccordionItem>
                                                                                <h2>
                                                                                    <AccordionButton>
                                                                                        <Box as='span' flex='1' textAlign='left'>
                                                                                            Licenses
                                                                                        </Box>
                                                                                        <AccordionIcon />
                                                                                    </AccordionButton>
                                                                                </h2>
                                                                                <AccordionPanel pb={4}>
                                                                                    {bid.licenses?.map((license, index) => (
                                                                                        <Box w="fit-content" mt={2} key={index} p={2} shadow="md" borderWidth="1px">
                                                                                            <Text fontWeight="semibold">{license.type}</Text>
                                                                                            <Text>Number: {license.number}</Text>
                                                                                            <Text>Expiry Date: {new Date(license.expiryDate).toLocaleDateString()}</Text>
                                                                                        </Box>
                                                                                    ))}
                                                                                </AccordionPanel>
                                                                            </AccordionItem>
                                                                        )}
                                                                    </Accordion>

                                                                    <Flex direction="column" w="100%">
                                                                        <Text>
                                                                            {bid.notes}
                                                                        </Text>

                                                                        <Flex direction={"column"} p={2} w={"100%"} justify={"space-between"} align={"flex-start"}>
                                                                            <Flex direction={"column"} w={"full"} justify={"flex-start"}>
                                                                                <Text mt={2}>Contractor Signature</Text>
                                                                                {bid.contractorSignature && (
                                                                                    <>
                                                                                        <Image
                                                                                            src={bid.contractorSignature}
                                                                                            alt="Contractor Signature"
                                                                                            objectFit="contain"
                                                                                            maxW="400px"
                                                                                            h="auto"
                                                                                        />
                                                                                        {bid.contractorSignatureDate && (
                                                                                            <Text mt={2}>Date: {format(parseISO(bid.contractorSignatureDate), 'MMMM dd, yyyy')}</Text>
                                                                                        )}
                                                                                    </>
                                                                                )}
                                                                            </Flex>
                                                                            <Flex direction={"column"} w={"full"} justify={"flex-start"}>
                                                                                <Text mt={2}>Home Owner Signature</Text>
                                                                                {bid.ownerSignature && (
                                                                                    <>
                                                                                        <Image
                                                                                            src={bid.ownerSignature}
                                                                                            alt="Home Owner Signature"
                                                                                            objectFit="contain"
                                                                                            maxW="400px"
                                                                                            h="auto"
                                                                                        />
                                                                                        <Text mt={2}>Date: {format(parseISO(bid.ownerSignatureDate), 'MMMM dd, yyyy')}</Text>
                                                                                    </>
                                                                                )}
                                                                            </Flex>
                                                                        </Flex>
                                                                    </Flex>
                                                                </Flex>
                                                            </Flex>
                                                        ) : (
                                                            <Text>No Bid Submitted Yet.</Text>

                                                        )}
                                                    </Flex>
                                                    {bid.type === 'bid' && (
                                                        <Flex w={"100%"}>
                                                            <ContractorReviews userId={bid.contractorId} />
                                                        </Flex>
                                                    )} 
                                                </Flex>
                                            </TabPanel>
                                        ))}
                                    </TabPanels>
                                    <MessageComponent conversationId={selectedConversationId} projectId={projectId} socket={socket} />
                                </Tabs>
                            </Flex>
                        </>
                    ) : (
                        <>
                            <Flex p={4} direction={"column"} w={"100"}>
                                <EliteContractors projectId={projectId} />
                                <Tabs mt={2} variant='enclosed' colorScheme='green' onChange={handleTabChange}>
                                    <TabList>
                                        {bidsData?.map((bid, index) => (
                                            <Tab key={index}>
                                                {bid.type === 'bid'
                                                    ? (bid.contractorBusiness || `${bid.contractorFirstName} ${bid.contractorLastName}`)
                                                    : `Pending Bid: ${bid._id.substring(18)}`}
                                            </Tab>
                                        ))}
                                    </TabList>
                                    <TabPanels>
                                        {bidsData?.map((bid, index) => (
                                            <TabPanel justify={"center"} w={"100"} key={index}>
                                                <Flex justify={"space-between"} w={"100"}>
                                                    <Flex justify={"center"} w={"50%"}>
                                                        {bid.type === 'bid' ? (
                                                            <Flex w={"100%"}>
                                                                <Flex maxH={"88vh"} overflowY={"scroll"} border={"1px"} borderColor={"lightgrey"} shadow={"md"} borderRadius={"md"} direction={"column"} p={6} minW={"40%"} w={"90%"}>
                                                                    <Flex w={"100%"} justify={'space-between'}>
                                                                        {bid.contractorLogo ? (
                                                                            <Image src={bid.contractorLogo} w={"auto"} maxH={"100px"} />
                                                                        ) : (
                                                                            <Flex direction={"column"} justify={"center"} align={"center"}>
                                                                                <Image mr={2} src={Logo} maxH={"70px"} />
                                                                                <Heading size={"md"}>
                                                                                    Utah Remodels
                                                                                </Heading>
                                                                            </Flex>
                                                                        )}
                                                                        <Flex direction={"column"} justify={"flex-start"} align={"flex-end"}>
                                                                            <Heading>
                                                                            Proposal
                                                                            </Heading>
                                                                            <Text>Proposal ID # {bid.estimateId}</Text>
                                                                            <Flex>
                                                                                {bid.status === "open" ? (
                                                                                    <Badge mr={1} colorScheme='blue'>Open</Badge>
                                                                                ) : bid.status === "in progress" ? (
                                                                                    <Badge mr={1} colorScheme='red'>In Progress</Badge>
                                                                                ) : bid.status === "completed" ? (
                                                                                    <Badge mr={1} colorScheme='green'>Completed</Badge>
                                                                                ) : null}
                                                                            </Flex>
                                                                        </Flex>
                                                                    </Flex>
                                                                    <Flex mb={4} w={"100%"} justify={'space-between'}>
                                                                        <Flex w={"50%"} direction={"column"}>
                                                                            <Flex align={"center"}>
                                                                                {bid.contractorBusiness && (
                                                                                    bid.isVetted ? (
                                                                                        <Flex mr={2} align="center">
                                                                                            <FontAwesomeIcon size='xs' color='green' icon={faCircleCheck} />
                                                                                            <Text ml={1} mt={1} fontWeight="semibold">
                                                                                                {bid.contractorBusiness}
                                                                                            </Text>
                                                                                        </Flex>
                                                                                    ) : (
                                                                                        <Flex mr={2} align="center">
                                                                                            <FontAwesomeIcon size='xs' color='lightgray' icon={faCircleCheck} />
                                                                                            <Text ml={1} mt={1} fontWeight="semibold">
                                                                                                {bid.contractorBusiness}
                                                                                            </Text>
                                                                                        </Flex>
                                                                                    )
                                                                                )}
                                                                                {bid.licenses && bid.licenses.length > 0 && (
                                                                                    <Flex mr={2}>
                                                                                        <FontAwesomeIcon color='green' icon={faIdCard} />
                                                                                    </Flex>
                                                                                )}
                                                                                {bid.contractorInsurance && bid.contractorInsurance.length > 0 && (
                                                                                    <Flex mr={2}>
                                                                                        <FontAwesomeIcon color='green' icon={faUserShield} />
                                                                                    </Flex>
                                                                                )}
                                                                                {bid.contractorBonds && (
                                                                                    <Flex>
                                                                                        <FontAwesomeIcon color='green' icon={faBuildingColumns} />
                                                                                    </Flex>
                                                                                )}
                                                                            </Flex>
                                                                            <Text fontWeight={"semibold"}>
                                                                                {bid.contractorFirstName} {bid.contractorLastName}
                                                                            </Text>

                                                                            <Text>
                                                                                {bid.contractorAddress.street}
                                                                            </Text>
                                                                            <Text>
                                                                                {bid.contractorAddress.city}, {bid.contractorAddress.state} {bid.contractorAddress.zipCode}
                                                                            </Text>

                                                                        </Flex>
                                                                        <Flex direction={"column"} align={"flex-end"} justify={"flex-start"}>
                                                                            <Text fontWeight="bold">Proposed Dates:</Text>
                                                                            <Text>
                                                                                {safeFormatDate(bid.startDate, 'MMMM dd, yyyy')} - {safeFormatDate(bid.endDate, 'MMMM dd, yyyy')}
                                                                            </Text>
                                                                            <Text>
                                                                                Expiration Date: {safeFormatDate(bid.expirationDate, 'MMMM dd, yyyy')}
                                                                            </Text>
                                                                            {!bid.ownerSignature && (
                                                                                <Flex mt={2} key={index} direction={"column"}>
                                                                                    {isActive ? (
                                                                                        <Flex direction={"column"} justify={"flex-end"} align={"flex-end"}>
                                                                                            <SignatureCapture isLoading={isLoading} onSignatureSave={handleSignatureSave} />
                                                                                        </Flex>
                                                                                    ) : (
                                                                                        <Button key={index} bg="#1DADE3" _hover={"blue"} color={"white"} size={"sm"} onClick={() => handleAcceptBid(bid._id)}>Accept Bid</Button>
                                                                                    )}
                                                                                </Flex>
                                                                            )}
                                                                        </Flex>
                                                                    </Flex>
                                                                    <Flex direction={"column"}>
                                                                        <Text fontSize={"lg"} fontWeight={"bold"}>{bid.title}</Text>
                                                                        <Text>{bid.description}</Text>
                                                                    </Flex>
                                                                    <Flex mt={4} w={"100%"} bg={"gray.700"} borderRadius={"md"} >
                                                                        <Flex mr={4} w={"50%"} justify={"space-between"}>
                                                                            <Text ml={6} color={"white"}>Item</Text>
                                                                            <Text mr={4} color={"white"}>Qty</Text>
                                                                        </Flex>
                                                                        <Flex ml={4} w={"50%"} justify={"space-between"}>
                                                                            <Text ml={4} color={"white"}>Rate</Text>
                                                                            <Text ml={4} color={"white"}>Tax (%)</Text>
                                                                            <Text mr={4} color={"white"}>Amount</Text>
                                                                        </Flex>
                                                                    </Flex>
                                                                    {bid?.items?.map((item, index) => (
                                                                        <>
                                                                            <Flex key={index} w={"100%"} borderRadius={"md"} p={2} alignItems="start">
                                                                                <Flex mr={4} w={"50%"} justify={"space-between"}>
                                                                                    <Flex direction={"column"} ml={4}>
                                                                                        <Text fontWeight={"semibold"}>{item.name}</Text>
                                                                                        <Text mt={-1} fontWeight={"thin"}>{item.description}</Text>
                                                                                    </Flex>
                                                                                    {!item.hideDetails && <Text mr={6}>{item.quantity}</Text>}
                                                                                </Flex>
                                                                                <Flex ml={4} w={"50%"} justify={"space-between"} alignItems="start">
                                                                                    {!item.hideDetails && <Text ml={4}>{parseFloat(item.rate).toFixed(2)}</Text>}
                                                                                    {!item.hideDetails && <Text>{parseFloat(item.tax).toFixed(2)}</Text>}
                                                                                    {!item.hideDetails && <Text mr={2}>{typeof item.amount === 'number' ? item.amount.toFixed(2) : '0.00'}</Text>}
                                                                                </Flex>
                                                                            </Flex>
                                                                        </>
                                                                    ))}
                                                                    <Flex mt={4} justify={"flex-end"} align={"flex-end"} w={"100%"}>
                                                                        <Flex direction={"column"} align={"flex-end"} mr={4}>
                                                                            <Text mb={2}>Subtotal: ${bid.subTotalAmount}</Text>
                                                                            {bid.discountAmount > 0 && (
                                                                                <Text mb={2}>Discount ({bid.discount}%): -${bid.discountAmount}</Text>
                                                                            )}
                                                                            <Text mb={2}>Service Charge: ${bid.serviceCharge}</Text>
                                                                        </Flex>
                                                                    </Flex>

                                                                    <Flex justify={"flex-end"} align={"flex-end"} w={"100%"} bg={"gray.700"} borderRadius={"md"} >
                                                                        <Flex p={2} direction={"column"} mr={2} w={"100%"} justify={"flex-end"} align={"flex-end"}>
                                                                            <Text color={"white"}>Total (Including Taxes): ${bid?.totalAmount}</Text>
                                                                        </Flex>
                                                                    </Flex>
                                                                    <Flex direction="column" w="100%">
                                                                        {bid.paymentTerms === "Stages" ? (
                                                                            <Flex w={"100%"} justify={"flex-end"}>
                                                                                <Box
                                                                                    w="fit-content"
                                                                                    mt={4}
                                                                                    borderRadius="lg"
                                                                                    border="1px"
                                                                                    p={4}
                                                                                    boxShadow="md"
                                                                                    direction="column"
                                                                                >
                                                                                    <Heading size="md" mb={4}>
                                                                                        Payment Terms:
                                                                                    </Heading>
                                                                                    {bid.customStages?.map((stage, index) => (
                                                                                        <Flex key={stage.id} align="center" justify={"space-between"} mb={2}>
                                                                                            <Flex align={"center"} mr={2}>
                                                                                                <FontAwesomeIcon size='xs' icon={faCircleDot} />
                                                                                                <Text ml={2}>{stage.description}</Text>
                                                                                            </Flex>
                                                                                            <Flex>
                                                                                                <Box
                                                                                                    p={2}
                                                                                                    borderRadius="md"
                                                                                                    bg={stage.upfrontAmount > 0 ? "green.100" : "gray.100"}
                                                                                                    mr={2}
                                                                                                >
                                                                                                    <Text color={stage.upfrontAmount > 0 ? "green.600" : "gray.600"}>
                                                                                                        Due at Start: ${stage.upfrontAmount}
                                                                                                    </Text>
                                                                                                </Box>

                                                                                                <Box
                                                                                                    p={2}
                                                                                                    borderRadius="md"
                                                                                                    bg={stage.completionAmount > 0 ? "blue.100" : "gray.100"}
                                                                                                >
                                                                                                    <Text color={stage.completionAmount > 0 ? "blue.600" : "gray.600"}>
                                                                                                        Due at End: ${stage.completionAmount}
                                                                                                    </Text>
                                                                                                </Box>
                                                                                            </Flex>
                                                                                        </Flex>
                                                                                    )) || <Text>No stages defined</Text>}
                                                                                </Box>
                                                                            </Flex>
                                                                        ) : bid.paymentTerms === "1/2" ? (
                                                                            <>
                                                                                {bid.upfrontAmount && (
                                                                                    <Flex w={"100%"} justify={"flex-end"}>
                                                                                        <Flex w={"fit-content"} mt={4} borderRadius={"lg"} direction={"column"} p={2} border={"1px"}>
                                                                                            <Heading size={"sm"}>Payment Terms:</Heading>
                                                                                            <Text>${bid.upfrontAmount.amount} before work starts. ${bid.upfrontAmount.amount} when completed</Text>
                                                                                            <Text>{bid.upfrontAmount.description}</Text>
                                                                                        </Flex>
                                                                                    </Flex>
                                                                                )}
                                                                            </>
                                                                        ) : bid.paymentTerms === "UpFront" ? (
                                                                            <>
                                                                                {bid.upfrontAmount && (
                                                                                    <Flex w={"100%"} justify={"flex-end"}>
                                                                                        <Flex w={"fit-content"} mt={4} borderRadius={"lg"} direction={"column"} p={2} border={"1px"}>
                                                                                            <Heading size={"sm"}>Payment Terms:</Heading>
                                                                                            <Text>${bid.upfrontAmount.amount} before work starts.</Text>
                                                                                            <Text>{bid.upfrontAmount.description}</Text>
                                                                                        </Flex>
                                                                                    </Flex>
                                                                                )}
                                                                            </>
                                                                        ) : bid.paymentTerms === "Completed" ? (
                                                                            <>
                                                                                {bid.upfrontAmount && (
                                                                                    <Flex w={"100%"} justify={"flex-end"}>
                                                                                        <Flex w={"fit-content"} mt={4} borderRadius={"lg"} direction={"column"} p={2} border={"1px"}>
                                                                                            <Heading size={"sm"}>Payment Terms:</Heading>
                                                                                            <Text>${bid.total} when completed</Text>
                                                                                            <Text>{bid.upfrontAmount.description}</Text>
                                                                                        </Flex>
                                                                                    </Flex>
                                                                                )}
                                                                            </>
                                                                        ) : (
                                                                            null
                                                                        )}
                                                                    </Flex>

                                                                    {bid.notes && (
                                                                        <Flex mt={4} borderRadius={"lg"} direction={"column"} p={2} bg={"gray.700"}>
                                                                            <Heading color={"white"} size={"sm"}>Notes:</Heading>
                                                                            <Text color={"white"}>
                                                                                {bid.notes}
                                                                            </Text>
                                                                        </Flex>
                                                                    )}

                                                                    <Accordion mt={4} allowToggle>
                                                                        {bid.contractorInsurance && bid.contractorInsurance.length > 0 && (
                                                                            <AccordionItem>
                                                                                <h2>
                                                                                    <AccordionButton>
                                                                                        <Box as='span' flex='1' textAlign='left'>
                                                                                            Insurance
                                                                                        </Box>
                                                                                        <AccordionIcon />
                                                                                    </AccordionButton>
                                                                                </h2>
                                                                                <AccordionPanel pb={4}>
                                                                                    {bid.contractorInsurance?.map((insurance, index) => (
                                                                                        <Box w="fit-content" mt={2} key={index} p={2} shadow="md" borderWidth="1px">
                                                                                            <Text fontWeight="semibold">{insurance.provider}</Text>
                                                                                            <Text>Policy Number: {insurance.policyNumber}</Text>
                                                                                            <Text>Coverage Amount: ${new Intl.NumberFormat('en-US', { style: 'decimal', minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(insurance.coverageAmount)}</Text>
                                                                                            <Text>Expiry Date: {new Date(insurance.expiryDate).toLocaleDateString()}</Text>
                                                                                            <Text>Type: {insurance.type}</Text>
                                                                                        </Box>
                                                                                    ))}
                                                                                </AccordionPanel>
                                                                            </AccordionItem>
                                                                        )}

                                                                        {bid.contractorBonds && (
                                                                            <AccordionItem>
                                                                                <h2>
                                                                                    <AccordionButton>
                                                                                        <Box as='span' flex='1' textAlign='left'>
                                                                                            Bonds
                                                                                        </Box>
                                                                                        <AccordionIcon />
                                                                                    </AccordionButton>
                                                                                </h2>
                                                                                <AccordionPanel pb={4}>
                                                                                    {bid.contractorBonds && (
                                                                                        <Box w="fit-content" mt={2} key={index} p={2} shadow="md" borderWidth="1px">
                                                                                            <Text fontWeight="semibold">{bid.contractorBonds.bondingCompany}</Text>
                                                                                            <Text>Bond Number: {bid.contractorBonds.bondNumber}</Text>
                                                                                            <Text>Bond Amount: ${new Intl.NumberFormat('en-US', { style: 'decimal', minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(bid.contractorBonds.amount)}</Text>
                                                                                            <Text>Expiry Date: {new Date(bid.contractorBonds.expiryDate).toLocaleDateString()}</Text>
                                                                                        </Box>
                                                                                    )}
                                                                                </AccordionPanel>
                                                                            </AccordionItem>
                                                                        )}

                                                                        {bid.licenses && bid.licenses.length > 0 && (
                                                                            <AccordionItem>
                                                                                <h2>
                                                                                    <AccordionButton>
                                                                                        <Box as='span' flex='1' textAlign='left'>
                                                                                            Licenses
                                                                                        </Box>
                                                                                        <AccordionIcon />
                                                                                    </AccordionButton>
                                                                                </h2>
                                                                                <AccordionPanel pb={4}>
                                                                                    {bid.licenses?.map((license, index) => (
                                                                                        <Box w="fit-content" mt={2} key={index} p={2} shadow="md" borderWidth="1px">
                                                                                            <Text fontWeight="semibold">{license.type}</Text>
                                                                                            <Text>Number: {license.number}</Text>
                                                                                            <Text>Expiry Date: {new Date(license.expiryDate).toLocaleDateString()}</Text>
                                                                                        </Box>
                                                                                    ))}
                                                                                </AccordionPanel>
                                                                            </AccordionItem>
                                                                        )}
                                                                    </Accordion>


                                                                    <Flex direction="column" w="100%">
                                                                        <Text>
                                                                            {bid.notes}
                                                                        </Text>
                                                                        <Text>{bid.upfrontAmount.amount}</Text>
                                                                        <Text>{bid.upfrontAmount.description}</Text>

                                                                        <Flex p={2} w={"100%"} justify={"space-between"} align={"flex-start"}>
                                                                            <Flex direction={"column"} w={"full"} justify={"flex-start"}>
                                                                                <Text mt={2}>Contractor Signature</Text>
                                                                                {bid.contractorSignature && (
                                                                                    <>
                                                                                        <Image
                                                                                            src={bid.contractorSignature}
                                                                                            alt="Contractor Signature"
                                                                                            objectFit="contain"
                                                                                            maxW="400px"
                                                                                            h="auto"
                                                                                        />
                                                                                        {bid.contractorSignatureDate && (
                                                                                            <Text mt={2}>Date: {format(parseISO(bid.contractorSignatureDate), 'MMMM dd, yyyy')}</Text>
                                                                                        )}
                                                                                    </>
                                                                                )}
                                                                            </Flex>
                                                                            <Flex direction={"column"} w={"full"} justify={"flex-start"}>
                                                                                <Text mt={2}>Home Owner Signature</Text>
                                                                                {bid.ownerSignature && (
                                                                                    <>
                                                                                        <Image
                                                                                            src={bid.ownerSignature}
                                                                                            alt="Home Owner Signature"
                                                                                            objectFit="contain"
                                                                                            maxW="400px"
                                                                                            h="auto"
                                                                                        />
                                                                                        <Text mt={2}>Date: {format(parseISO(bid.ownerSignatureDate), 'MMMM dd, yyyy')}</Text>
                                                                                    </>
                                                                                )}
                                                                            </Flex>
                                                                        </Flex>
                                                                    </Flex>
                                                                </Flex>
                                                            </Flex>
                                                        ) : (
                                                            <Text>No Bid Submitted Yet.</Text>

                                                        )}
                                                    </Flex>
                                                    {bid.type === 'bid' && (
                                                        <Flex w={"50%"}>
                                                            <ContractorReviews userId={bid.contractorId} />
                                                        </Flex>
                                                    )}
                                                </Flex>
                                            </TabPanel>
                                        ))}
                                    </TabPanels>
                                    <MessageComponent conversationId={selectedConversationId} projectId={projectId} socket={socket} />
                                </Tabs>
                            </Flex>
                        </>
                    )}
                </Flex>
            </Flex>
        </Flex>
    );
}

export default ProjectPage;