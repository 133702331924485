import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { Box, Text, Button, Avatar, Badge, Flex, Icon, Heading, Spinner, Alert, AlertIcon, Image } from '@chakra-ui/react';
import { FaBuilding, FaEnvelopeOpen } from 'react-icons/fa';

const EliteContractors = ({ projectId }) => {
    const [contractors, setContractors] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const navigate = useNavigate();

    useEffect(() => {
        const fetchEliteContractors = async () => {
            setLoading(true);
            setError(null);

            try {
                const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/contractors/elite`, { projectId }, {
                    headers: { 'Content-Type': 'application/json' }
                });
                setContractors(response.data);
                setLoading(false);
            } catch (error) {
                console.error('Failed to fetch elite contractors:', error);
                setError('Failed to load contractors. Please try again.');
                setLoading(false);
            }
        };

        fetchEliteContractors();
    }, [projectId]);

    const navigateToContractorProfile = (userId) => {
        localStorage.setItem('projectId', projectId);
        navigate(`/contractor/${userId}`);
    };

    if (loading) {
        return (
            <Flex justify="center" align="center" minH="100px">
                <Spinner />
            </Flex>
        );
    }

    if (error) {
        return (
            <Alert status="error" borderRadius="md" mb={4}>
                <AlertIcon />
                {error}
            </Alert>
        );
    }

    if (contractors.length === 0) {
        return (
            <Alert status="info" borderRadius="md" mb={4}>
                <AlertIcon />
                No contractors found.
            </Alert>
        );
    }

    return (
        <Flex direction={"column"} p={2} borderRadius={"lg"} bg={"gray.700"} overflowX="auto" pb={2}>
            <Heading color={"white"} m={2} size={"sm"}>Elite Contractors</Heading>
            <Flex overflowX="auto" pb={2}>
                {contractors.map(contractor => (
                    <Box>
                        <Box overflowY={"scroll"} maxH={"280px"} h={"fit-content"} key={contractor._id} borderWidth={1} borderRadius="md" p={4} mr={4} minW="300px">
                            {contractor.business?.logo && <Image p={1} borderRadius={"md"} bg={"lightgray"} mb={2} w={"200px"} h={"auto"} src={contractor.business.logo} mr={2} />}
                            <Heading color={"white"} size="md">{contractor.business?.name || 'No Business Name'}</Heading>
                            <Flex align="center" mb={2}>
                                {contractor.profilePic && <Avatar size="md" src={contractor.business.logo} mr={2} />}
                                <Text color={"white"} >{contractor.firstName} {contractor.lastName}</Text>
                            </Flex>
                            <Flex align="center" mb={2}>
                                <Icon color={"white"} as={FaBuilding} mr={2} />
                                <Text color={"white"}>{contractor.business?.address?.street}, {contractor.business?.address.city}</Text>
                            </Flex>
                            <Flex align="center" mb={2}>
                                <Icon color={"white"} as={FaEnvelopeOpen} mr={2} />
                                <Text color={"white"}>{contractor.email}</Text>
                            </Flex>
                            <Flex maxW={"300px"} wrap="wrap" mb={2}>
                                {contractor.servicesOffered.map(service => (
                                    <Badge key={service} bg="#1DADE3" _hover={"blue"} color={"white"} mr={2} mb={2}>{service}</Badge>
                                ))}
                            </Flex>
                        </Box>
                        <Button mt={"1"} size="sm" onClick={() => navigateToContractorProfile(contractor._id)} colorScheme="blue">View</Button>
                    </Box>
                ))}
            </Flex>
        </Flex>
    );
};

export default EliteContractors;