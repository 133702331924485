import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Heading, Text, Box, Button, Flex, Image, useDisclosure, Modal, ModalOverlay, ModalContent, ModalHeader, ModalCloseButton, ModalBody, Textarea, ModalFooter } from '@chakra-ui/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHome, faPaperPlane } from '@fortawesome/free-solid-svg-icons';
import Logo from '../assets/Horizontal LOGO.webp';
import BROKEN from '../assets/BROKEN.webp';
import axios from 'axios';

function BrokenPage() {
    const navigate = useNavigate();
    const { isOpen, onOpen, onClose } = useDisclosure();
    const [feedback, setFeedback] = useState('');

    const handleGoHome = () => {
        navigate('/');
    };

    const handleFeedbackChange = (event) => {
        setFeedback(event.target.value);
    };

    const submitFeedback = async () => {
        try {
            const token = localStorage.getItem('token');  // Retrieve the token from localStorage
            if (!token) {
                console.error('No token found');
                return;  // Optionally handle the lack of a token more gracefully
            }

            const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/feedback`, { message: feedback }, {
                headers: {
                    'Authorization': `Bearer ${token}`  // Include the token in the Authorization header
                }
            });

            console.log('Feedback submitted successfully:', response.data);
            onClose();  // Close the modal after successful submission
        } catch (error) {
            console.error('Failed to submit feedback:', error);
            // Optionally handle errors, such as showing an error message to the user
        }
    };

    return (
        <Flex
            direction="column"
            align="flex-start"
            w="100%"
            h="100vh"
            justify="flex-start"
            bgImage={BROKEN}
            bgPosition="center"
            bgRepeat="no-repeat"
            bgSize="cover"
        >
            <Flex p={8} w="100%" align="flex-start" justify="center">
                <Box>
                    <Image mb={4} src={Logo} w="400px" h="auto" />
                    <Box mb={8} bg="gray.100" p={6} borderRadius="md">
                        <Heading as="h1" size="xl" mb={4}>
                            Oops!
                        </Heading>
                        <Text mb={6}>
                            We Broke Something! We are working on fixing this!
                        </Text>
                        <Button
                            leftIcon={<FontAwesomeIcon icon={faHome} />}
                            colorScheme="blue"
                            onClick={handleGoHome}
                        >
                            Go to Home
                        </Button>
                        <Button
                            leftIcon={<FontAwesomeIcon icon={faPaperPlane} />}
                            colorScheme="blue"
                            onClick={onOpen}
                            mt={{base: 2, lg: 0}}
                            ml={{base: 0, lg: 4}}
                        >
                            Tell us what happened
                        </Button>
                    </Box>
                </Box>
            </Flex>
            {/* Feedback Modal */}
            <Modal isOpen={isOpen} onClose={onClose}>
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>Feedback on Error</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                        <Textarea
                            value={feedback}
                            onChange={handleFeedbackChange}
                            placeholder="Please tell us what happened..."
                        />
                    </ModalBody>
                    <ModalFooter>
                        <Button colorScheme="blue" mr={3} onClick={submitFeedback}>
                            Submit
                        </Button>
                        <Button variant="ghost" onClick={onClose}>Cancel</Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>
        </Flex >
    );
}

export default BrokenPage;
