import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useAtom } from 'jotai';
import { socketAtom } from '../socketAtom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { io } from 'socket.io-client';
import axios from 'axios';
import {
    Flex,
    Text,
    Heading,
    Box,
    VStack,
    SimpleGrid,
    useToast,
    Avatar,
    Stack,
    Badge,
    Icon,
    HStack,
    Divider,
    Image,
    Progress, Modal, ModalOverlay, ModalContent, ModalHeader, ModalCloseButton, ModalBody, useDisclosure, Button, useBreakpointValue
} from '@chakra-ui/react';
import { FaStar, FaGlobe, FaBuilding, FaBriefcase, FaShieldAlt, FaClipboardCheck, FaEnvelopeOpen } from 'react-icons/fa';
import ContractorSideMenu from '../contractor/SideMenu';
import Header from '../elements/Header';
import { format, parseISO } from 'date-fns';
import { faPaperPlane } from '@fortawesome/free-solid-svg-icons';

const PublicProfile = () => {
    const [socket] = useAtom(socketAtom);
    const { userId } = useParams();
    const menuType = useBreakpointValue({ base: 'drawer', lg: 'regular' });
    const [contractor, setContractor] = useState({
        publicReviews: [], // Initialize as empty arrays
        privateReviews: [],
        licenses: [],
        servicesOffered: []
    });
    const [ratingsCount, setRatingsCount] = useState({});
    const [completedProjects, setCompletedProjects] = useState(0);
    const [isLoading, setIsLoading] = useState(true);
    const [selectedImage, setSelectedImage] = useState(null);
    const { isOpen, onOpen, onClose } = useDisclosure();
    const toast = useToast();
    const token = localStorage.getItem('token');
    const [, setSocket] = useAtom(socketAtom);

    useEffect(() => {
        const newSocket = io(process.env.REACT_APP_API_URL, {
            autoConnect: true,
            auth: {
                token: token,
            },
        });

        newSocket.on('connect_error', (error) => {
            console.error('Socket connection error:', error);
        });

        setSocket(newSocket);

        return () => newSocket.close();
    }, [setSocket, token]);

    useEffect(() => {
        const fetchContractor = async () => {
            setIsLoading(true);
            try {
                const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/contractor-info/${userId}`, {
                    headers: { Authorization: `Bearer ${token}` },
                });
                setContractor(response.data.contractor || { publicReviews: [], privateReviews: [], licenses: [], servicesOffered: [] });
                setCompletedProjects(response.data.completedProjectsCount || 0);
                calculateRatings(response.data.contractor.publicReviews);
            } catch (error) {
                toast({
                    title: "Error fetching contractor data",
                    description: error.response?.data?.message || "Failed to load data",
                    status: "error",
                    duration: 9000,
                    isClosable: true,
                });
                console.error('Error fetching data:', error);
            }
            setIsLoading(false);
        };

        fetchContractor();
    }, [userId, token]);

    const calculateRatings = (reviews) => {
        const count = reviews.reduce((acc, { rating }) => {
            const key = Math.floor(rating);
            acc[key] = (acc[key] || 0) + 1;
            return acc;
        }, {});
        setRatingsCount(count);
    };

    const handleImageClick = (imageURL) => {
        setSelectedImage(imageURL);
        onOpen();
    };

    const pingContractor = () => {
        const projectId = localStorage.getItem('projectId');

        socket.emit('pingContractor', { contractorId: userId, projectId });
        toast({
            title: "Ping sent!",
            description: "Successfully pinged the contractor with project details.",
            status: "success",
            duration: 5000,
            isClosable: true,
        });
    };

    const renderRatings = () => {
        const totalReviews = Object.values(ratingsCount).reduce((acc, cur) => acc + cur, 0);
        return (
            <Stack p={3} bg={"gray.700"} borderRadius={"md"}>
                <Text color={"gray.200"}>Stars</Text>
                {[5, 4, 3, 2, 1].map(star => (
                    <Flex w={"100%"} key={star} align="flex-start">
                        <Text color={"white"} fontSize={"xs"} ml={3}>{star}</Text>
                        <Flex w={"100%"}>
                            <Box w={"90%"}>
                                <Progress
                                    colorScheme="yellow"
                                    bg={"gray.500"}
                                    mt={2}
                                    size="xs"
                                    value={(ratingsCount[star] || 0) * 100 / totalReviews}
                                    width="full"
                                    ml={3}
                                />
                            </Box>
                            <Text fontSize={"xs"} ml={6} color={"white"}>({ratingsCount[star] || 0})</Text>
                        </Flex>
                    </Flex>
                ))}
            </Stack>
        );
    };

    const safeFormatDate = (dateString, formatString) => dateString ? format(parseISO(dateString), formatString) : 'N/A';

    const renderBasicProfile = () => (
        <VStack w={"800px"} spacing={8} align="stretch">
            <Flex align="center">
                <Box>
                    <Image src={contractor.business?.logo} />
                    <Flex mt={4}>
                        <Box ml={4}>
                            <Heading size="lg">{contractor.business?.name}</Heading>
                            <Flex mt={1} align={"center"}>
                                {contractor.profilePic && (
                                    <Avatar size="sm" src={contractor.profilePic} />
                                )}
                                <Heading ml={contractor.profilePic ? 2 : 0} size="md">{contractor.firstName} {contractor.lastName}</Heading>
                            </Flex>
                            {completedProjects > 10 && (
                                <Text color={"gray"} fontSize={"sm"}>Completed Projects: {completedProjects}</Text>
                            )}
                            <Text color={"gray"} fontSize={"sm"}>Joined: {safeFormatDate(contractor.createdAt, 'MMMM yyyy')}</Text>
                        </Box>
                    </Flex>
                </Box>
            </Flex>
            {!isLoading && renderRatings()}
            <VStack mt={4} maxH={"72vh"} overflowY={"scroll"} w={"800px"}>
                {contractor.publicReviews.map(review => (
                    <Box w={"800px"} key={review._id} bg="gray.600" p={4} borderRadius="md">
                        <HStack justify={"space-between"} align={"center"}>
                            <Flex>
                                {Array.from({ length: review.rating }, (_, i) => (
                                    <Icon ml={1} as={FaStar} color="goldenrod" key={i} />
                                ))}
                            </Flex>
                            <Text color={"gray"} fontSize={"sm"}>{safeFormatDate(review.date, 'MMMM yyyy')}</Text>
                        </HStack>
                        <Text color={"white"} mt={2}>{review.comment}</Text>
                        {review.imageURLs && review.imageURLs.length > 0 && (
                            <SimpleGrid columns={[1, 2, 3]} spacing={2} mt={2}>
                                {review.imageURLs.map((imageURL, index) => (
                                    <Image
                                        shadow={"lg"}
                                        key={index}
                                        src={imageURL}
                                        alt="Review Image"
                                        objectFit="cover"
                                        maxH="200px"
                                        borderRadius="md"
                                        cursor="pointer"
                                        onClick={() => handleImageClick(imageURL)}
                                    />
                                ))}
                            </SimpleGrid>
                        )}
                    </Box>
                ))}
            </VStack>
        </VStack>
    );

    const renderFullProfile = () => (
        <Flex p={8} w={"100%"} justify={"space-between"}>
            <Flex direction={'column'} justify={"flex-start"} w={"45%"} spacing={8} align="flex-start">
                <Box  p={6} borderRadius="lg" boxShadow="md" w="100%">
                    <Flex align="center" justify="flex-start" mb={4}>
                        <Image src={contractor.business?.logo} alt="Business Logo" objectFit="contain" maxW="200px" />
                    </Flex>
                    <VStack spacing={2} align="stretch">
                        <Flex align="center">
                            {contractor.profilePic && (
                                <Avatar size="lg" src={contractor.profilePic} mr={4} />
                            )}
                            <Box>
                                <HStack>
                                    <Heading size="xl">{contractor.business?.name}</Heading>
                                    <Button
                                        leftIcon={<FontAwesomeIcon icon={faPaperPlane} />}
                                        colorScheme="blue"
                                        size="xs"
                                        onClick={pingContractor}
                                    >
                                        Invite Contractor
                                    </Button>
                                </HStack>
                                <Text color="gray.500" fontSize="md">
                                    {contractor.firstName} {contractor.lastName}
                                </Text>
                            </Box>
                        </Flex>
                        <HStack>
                            <Icon as={FaEnvelopeOpen} color="gray.500" />
                            <Text>{contractor.email}</Text>
                        </HStack>
                        <HStack>
                            <Icon as={FaGlobe} color="gray.500" />
                            <Text>{contractor.website}</Text>
                        </HStack>
                    </VStack>
                </Box>

                <Box  p={6} borderRadius="lg" boxShadow="md" w="100%" mt={8}>
                    <VStack spacing={4} align="stretch">
                        <HStack>
                            <Icon as={FaBuilding} color="gray.500" />
                            <Text>
                                {contractor.business?.address.street}, {contractor.business?.address.city},{' '}
                                {contractor.business?.address.state} {contractor.business?.address.zipCode},{' '}
                                {contractor.business?.address.country}
                            </Text>
                        </HStack>
                        <HStack>
                            <Icon as={FaBriefcase} color="gray.500" />
                            <Text>Years in Business: {contractor.business?.yearsInBusiness}</Text>
                        </HStack>
                        <HStack>
                            <Icon as={FaShieldAlt} color="gray.500" />
                            <Text>
                                Services Offered:{' '}
                                {contractor.servicesOffered.map(service => (
                                    <Badge key={service} colorScheme="blue" mr={1}>
                                        {service}
                                    </Badge>
                                ))}
                            </Text>
                        </HStack>
                        {completedProjects > 10 && (
                            <HStack>
                                <Icon as={FaClipboardCheck} color="gray.500" />
                                <Text>Completed Projects: {completedProjects}</Text>
                            </HStack>
                        )}
                        <Text color="gray.500">Joined: {safeFormatDate(contractor.createdAt, 'MMMM yyyy')}</Text>
                    </VStack>
                </Box>
                <Flex direction={"column"} justify={"flex-start"} align={"flex-start"} mt={4} >
                    <Box w={"100%"}  p={6} borderRadius="lg" boxShadow="md">
                        <Heading size="sm" mb={4}>Licenses & Certifications</Heading>
                        <SimpleGrid columns={2} spacing={4}>
                            {contractor.licenses.map(license => (
                                <Box w={"fit-content"} key={license.number} p={4} borderWidth={1} borderColor="gray.200" borderRadius="md">
                                    <HStack mb={2}>
                                        <Icon as={FaClipboardCheck} color="green.500" />
                                        <Text fontWeight="bold">{license.type}</Text>
                                    </HStack>
                                    <Text fontSize="sm">
                                        <strong>License Number:</strong> {license.number}
                                    </Text>
                                    <Text fontSize="sm">
                                        <strong>Expiry Date:</strong> {safeFormatDate(license.expiryDate, 'MM/dd/yyyy')}
                                    </Text>
                                </Box>
                            ))}
                        </SimpleGrid>
                    </Box>

                    <Box w={"100%"}  p={6} borderRadius="lg" boxShadow="md" mt={8}>
                        <Heading size="sm" mb={4}>Insurance</Heading>
                        <SimpleGrid columns={2} spacing={4}>
                            {contractor.insurance.map((policy, index) => (
                                <Box w={"fit-content"} key={index} p={4} borderWidth={1} borderColor="gray.200" borderRadius="md">
                                    <HStack mb={2}>
                                        <Icon as={FaShieldAlt} color="blue.500" />
                                        <Text fontWeight="bold">{policy.type}</Text>
                                    </HStack>
                                    <Text fontSize="sm">
                                        <strong>Provider:</strong> {policy.provider}
                                    </Text>
                                    <Text fontSize="sm">
                                        <strong>Policy Number:</strong> {policy.policyNumber}
                                    </Text>
                                    <Text fontSize="sm">
                                        <strong>Coverage Amount:</strong> ${policy.coverageAmount}
                                    </Text>
                                    <Text fontSize="sm">
                                        <strong>Expiry Date:</strong> {safeFormatDate(policy.expiryDate, 'MM/dd/yyyy')}
                                    </Text>
                                </Box>
                            ))}
                        </SimpleGrid>
                    </Box>

                    <Box w={"100%"}  p={6} borderRadius="lg" boxShadow="md" mt={8}>
                        <Heading size="sm" mb={4}>Bonds</Heading>
                        <SimpleGrid columns={2} spacing={4}>
                            {contractor.bonds.map((bond, index) => (
                                <Box w={"fit-content"} key={index} p={4} borderWidth={1} borderColor="gray.200" borderRadius="md">
                                    <Text fontSize="sm">
                                        <strong>Bonding Company:</strong> {bond.bondingCompany}
                                    </Text>
                                    <Text fontSize="sm">
                                        <strong>Bond Number:</strong> {bond.bondNumber}
                                    </Text>
                                    <Text fontSize="sm">
                                        <strong>Amount:</strong> ${bond.amount}
                                    </Text>
                                    <Text fontSize="sm">
                                        <strong>Expiry Date:</strong> {safeFormatDate(bond.expiryDate, 'MM/dd/yyyy')}
                                    </Text>
                                </Box>
                            ))}
                        </SimpleGrid>
                    </Box>
                </Flex>
            </Flex>

            <Flex direction="column" w="50%">
                <Box  p={6} borderRadius="lg" boxShadow="md" mt={8}>
                    <Heading size="lg" mb={4}>Reviews</Heading>
                    {!isLoading && renderRatings()}
                    <VStack spacing={4} align="stretch" mt={4} maxH="50vh" overflowY="auto">
                        {contractor.publicReviews.map(review => (
                            <Box key={review._id} p={4} borderWidth={1} borderColor="gray.200" borderRadius="md">
                                <HStack justify="space-between">
                                    <HStack>
                                        {Array.from({ length: review.rating }, (_, i) => (
                                            <Icon as={FaStar} color="yellow.500" key={i} />
                                        ))}
                                    </HStack>
                                    <Text color="gray.500" fontSize="sm">{safeFormatDate(review.date, 'MM/dd/yyyy')}</Text>
                                </HStack>
                                <Text mt={2}>{review.comment}</Text>
                                {review.imageURLs?.length > 0 && (
                                    <SimpleGrid columns={6} spacing={2} mt={4}>
                                        {review.imageURLs.map((imageURL, index) => (
                                            <Image
                                                key={index}
                                                src={imageURL}
                                                alt={`Review Image ${index + 1}`}
                                                objectFit="cover"
                                                w="100%"
                                                h="120px"
                                                borderRadius="md"
                                                cursor="pointer"
                                                onClick={() => handleImageClick(imageURL)}
                                            />
                                        ))}
                                    </SimpleGrid>
                                )}
                            </Box>
                        ))}
                    </VStack>
                </Box>
            </Flex>
        </Flex>
    );

    const renderMobileBasicProfile = () => (
        <Flex direction={"column"} p={2} w={"100%"} justify={"space-between"}>
            <Flex direction={'column'} justify={"flex-start"} w={"100%"} spacing={8} align="flex-start">
                <Box  p={6} borderRadius="lg" boxShadow="md" w="100%">
                    <Box w="100%">
                        <Image src={contractor?.business?.logo} />
                        <Flex w="100%" mt={4}>
                            <Box w="100%" ml={4}>
                                <Heading size="lg">{contractor.business?.name}</Heading>
                                <Flex mt={1} align={"center"}>
                                    {contractor.profilePic && (
                                        <Avatar size="sm" src={contractor.profilePic} />
                                    )}
                                    <Heading ml={contractor.profilePic ? 2 : 0} size="md">{contractor.firstName} {contractor.lastName}</Heading>
                                </Flex>
                                {completedProjects > 10 && (
                                    <Text color={"gray"} fontSize={"sm"}>Completed Projects: {completedProjects}</Text>
                                )}
                                <Text color={"gray"} fontSize={"sm"}>Joined: {safeFormatDate(contractor.createdAt, 'MMMM yyyy')}</Text>
                            </Box>
                        </Flex>
                    </Box>
                </Box>
            </Flex>
            {!isLoading && renderRatings()}
            <VStack mt={4} maxH={"72vh"} overflowY={"scroll"} w={"100%"}>
                {contractor.publicReviews.map(review => (
                    <Box w={"100%"} key={review._id} bg="gray.600" p={4} borderRadius="md">
                        <HStack justify={"space-between"} align={"center"}>
                            <Flex>
                                {Array.from({ length: review.rating }, (_, i) => (
                                    <Icon ml={1} as={FaStar} color="goldenrod" key={i} />
                                ))}
                            </Flex>
                            <Text color={"gray"} fontSize={"sm"}>{safeFormatDate(review.date, 'MMMM yyyy')}</Text>
                        </HStack>
                        <Text color={"white"} mt={2}>{review.comment}</Text>
                        {review.imageURLs && review.imageURLs.length > 0 && (
                            <SimpleGrid columns={[1, 2, 3]} spacing={2} mt={2}>
                                {review.imageURLs.map((imageURL, index) => (
                                    <Image
                                        shadow={"lg"}
                                        key={index}
                                        src={imageURL}
                                        alt="Review Image"
                                        objectFit="cover"
                                        maxH="200px"
                                        borderRadius="md"
                                        cursor="pointer"
                                        onClick={() => handleImageClick(imageURL)}
                                    />
                                ))}
                            </SimpleGrid>
                        )}
                    </Box>
                ))}
            </VStack>
        </Flex>
    );

    const renderMobileFullProfile = () => (
        <Flex direction={"column"} p={2} w={"100%"} justify={"space-between"}>
            <Flex direction={'column'} justify={"flex-start"} w={"100%"} spacing={8} align="flex-start">
                <Box  p={6} borderRadius="lg" boxShadow="md" w="100%">
                    <Flex align="center" justify="flex-start" mb={4}>
                        <Image src={contractor?.business?.logo} alt="Business Logo" objectFit="contain" maxW="200px" />
                    </Flex>
                    <VStack spacing={2} align="stretch">
                        <Flex align="center">
                            {contractor.profilePic && (
                                <Avatar size="lg" src={contractor.profilePic} mr={4} />
                            )}
                            <Box>
                                <Heading size="xl">{contractor.business?.name}</Heading>
                                <Button
                                    leftIcon={<FontAwesomeIcon icon={faPaperPlane} />}
                                    colorScheme="blue"
                                    size="xs"
                                    onClick={pingContractor}
                                    mb={2}
                                >
                                    Invite Contractor
                                </Button>
                                <Text color="gray.500" fontSize="md">
                                    {contractor.firstName} {contractor.lastName}
                                </Text>
                            </Box>
                        </Flex>
                        <HStack>
                            <Icon as={FaEnvelopeOpen} color="gray.500" />
                            <Text>{contractor.email}</Text>
                        </HStack>
                        {contractor.website && (
                            <HStack>
                                <Icon as={FaGlobe} color="gray.500" />
                                <Text>{contractor.website}</Text>
                            </HStack>
                        )}
                    </VStack>
                </Box>
                <Box  p={6} borderRadius="lg" boxShadow="md" w="100%" mt={8}>
                    <VStack spacing={4} align="stretch">
                        <HStack align={"flex-start"}>
                            <Icon mt={1} as={FaBuilding} color="gray.500" />
                            <Text>
                                {contractor.business?.address.street}, {contractor.business?.address.city},{' '}
                                {contractor.business?.address.state} {contractor.business?.address.zipCode},{' '}
                                {contractor.business?.address.country}
                            </Text>
                        </HStack>
                        <HStack align={"flex-start"}>
                            <Icon mt={1} as={FaBriefcase} color="gray.500" />
                            <Text>Years in Business: {contractor.business?.yearsInBusiness}</Text>
                        </HStack>
                        <HStack align={"flex-start"}>
                            <Icon mt={1} as={FaShieldAlt} color="gray.500" />
                            <Text>
                                Services Offered:{' '}
                                {contractor.servicesOffered.map(service => (
                                    <Badge key={service} colorScheme="blue" mr={1}>
                                        {service}
                                    </Badge>
                                ))}
                            </Text>
                        </HStack>
                        {completedProjects > 10 && (
                            <HStack>
                                <Icon as={FaClipboardCheck} color="gray.500" />
                                <Text>Completed Projects: {completedProjects}</Text>
                            </HStack>
                        )}
                        <Text color="gray.500">Joined: {safeFormatDate(contractor.createdAt, 'MMMM yyyy')}</Text>
                    </VStack>
                </Box>
                <Flex w={"100%"} direction={"column"} justify={"flex-start"} align={"flex-start"} mt={4} >
                    <Box w={"100%"}  p={6} borderRadius="lg" boxShadow="md">
                        <Heading size="sm" mb={4}>Licenses & Certifications</Heading>
                        <SimpleGrid columns={1} spacing={4}>
                            {contractor.licenses.map(license => (
                                <Box w={"100%"} key={license.number} p={4} borderWidth={1} borderColor="gray.200" borderRadius="md">
                                    <HStack mb={2}>
                                        <Icon as={FaClipboardCheck} color="green.500" />
                                        <Text fontWeight="bold">{license.type}</Text>
                                    </HStack>
                                    <Text fontSize="sm">
                                        <strong>License Number:</strong> {license.number}
                                    </Text>
                                    <Text fontSize="sm">
                                        <strong>Expiry Date:</strong> {safeFormatDate(license.expiryDate, 'MM/dd/yyyy')}
                                    </Text>
                                </Box>
                            ))}
                        </SimpleGrid>
                    </Box>

                    <Box w={"100%"}  p={6} borderRadius="lg" boxShadow="md" mt={8}>
                        <Heading size="sm" mb={4}>Insurance</Heading>
                        <SimpleGrid columns={1} spacing={4}>
                            {contractor.insurance.map((policy, index) => (
                                <Box w={"100%"} key={index} p={4} borderWidth={1} borderColor="gray.200" borderRadius="md">
                                    <HStack mb={2}>
                                        <Icon as={FaShieldAlt} color="blue.500" />
                                        <Text fontWeight="bold">{policy.type}</Text>
                                    </HStack>
                                    <Text fontSize="sm">
                                        <strong>Provider:</strong> {policy.provider}
                                    </Text>
                                    <Text fontSize="sm">
                                        <strong>Policy Number:</strong> {policy.policyNumber}
                                    </Text>
                                    <Text fontSize="sm">
                                        <strong>Coverage Amount:</strong> ${policy.coverageAmount}
                                    </Text>
                                    <Text fontSize="sm">
                                        <strong>Expiry Date:</strong> {safeFormatDate(policy.expiryDate, 'MM/dd/yyyy')}
                                    </Text>
                                </Box>
                            ))}
                        </SimpleGrid>
                    </Box>

                    <Box w={"100%"}  p={6} borderRadius="lg" boxShadow="md" mt={8}>
                        <Heading size="sm" mb={4}>Bonds</Heading>
                        <SimpleGrid columns={1} spacing={4}>
                            {contractor.bonds.map((bond, index) => (
                                <Box w={"100%"} key={index} p={4} borderWidth={1} borderColor="gray.200" borderRadius="md">
                                    <Text fontSize="sm">
                                        <strong>Bonding Company:</strong> {bond.bondingCompany}
                                    </Text>
                                    <Text fontSize="sm">
                                        <strong>Bond Number:</strong> {bond.bondNumber}
                                    </Text>
                                    <Text fontSize="sm">
                                        <strong>Amount:</strong> ${bond.amount}
                                    </Text>
                                    <Text fontSize="sm">
                                        <strong>Expiry Date:</strong> {safeFormatDate(bond.expiryDate, 'MM/dd/yyyy')}
                                    </Text>
                                </Box>
                            ))}
                        </SimpleGrid>
                    </Box>
                </Flex>
            </Flex>

            <Flex direction="column" w="100%">
                <Box  p={2} borderRadius="lg" boxShadow="md" mt={2}>
                    <Heading size="lg" mb={4}>Reviews</Heading>
                    {!isLoading && renderRatings()}
                    <VStack spacing={4} align="stretch" mt={4} maxH="50vh" overflowY="auto">
                        {contractor.publicReviews.map(review => (
                            <Box key={review._id} p={4} borderWidth={1} borderColor="gray.200" borderRadius="md">
                                <HStack justify="space-between">
                                    <HStack>
                                        {Array.from({ length: review.rating }, (_, i) => (
                                            <Icon as={FaStar} color="yellow.500" key={i} />
                                        ))}
                                    </HStack>
                                    <Text color="gray.500" fontSize="sm">{safeFormatDate(review.date, 'MM/dd/yyyy')}</Text>
                                </HStack>
                                <Text mt={2}>{review.comment}</Text>
                                {review.imageURLs?.length > 0 && (
                                    <SimpleGrid columns={2} spacing={2} mt={4}>
                                        {review.imageURLs.map((imageURL, index) => (
                                            <Image
                                                key={index}
                                                src={imageURL}
                                                alt={`Review Image ${index + 1}`}
                                                objectFit="cover"
                                                w="100%"
                                                h="120px"
                                                borderRadius="md"
                                                cursor="pointer"
                                                onClick={() => handleImageClick(imageURL)}
                                            />
                                        ))}
                                    </SimpleGrid>
                                )}
                            </Box>
                        ))}
                    </VStack>
                </Box>
            </Flex>
        </Flex>
    );

    return (
        <Flex direction={"column"} w={"100%"} justify={"center"}>
            <Flex direction={{ base: "column", lg: "row" }} p={2} w="100%" align="flex-start" justify="flex-start">
                <ContractorSideMenu />
                <Flex m={{ base: 0, lg: 4 }} direction="column" w="100%" justify="center">
                    <Header />
                    {menuType === 'drawer' ? (
                        <>
                            {isLoading ? (
                                <Text>Loading...</Text>
                            ) : (
                                contractor.subscription === 'Basic' ? renderMobileBasicProfile() : renderMobileFullProfile()
                            )}
                            <Modal isOpen={isOpen} onClose={onClose} size="xl">
                                <ModalOverlay />
                                <ModalContent>
                                    <ModalHeader>Review Image</ModalHeader>
                                    <ModalCloseButton />
                                    <ModalBody>
                                        <Image src={selectedImage} alt="Selected Review Image" objectFit="contain" maxW="100%" />
                                    </ModalBody>
                                </ModalContent>
                            </Modal>
                        </>
                    ) : (
                        <>
                            {isLoading ? (
                                <Text>Loading...</Text>
                            ) : (
                                contractor.subscription === 'Basic' ? renderBasicProfile() : renderFullProfile()
                            )}
                            <Modal isOpen={isOpen} onClose={onClose} size="xl">
                                <ModalOverlay />
                                <ModalContent>
                                    <ModalHeader>Review Image</ModalHeader>
                                    <ModalCloseButton />
                                    <ModalBody>
                                        <Image src={selectedImage} alt="Selected Review Image" objectFit="contain" maxW="100%" />
                                    </ModalBody>
                                </ModalContent>
                            </Modal>
                        </>
                    )}
                </Flex>
            </Flex>
        </Flex>
    );
};

export default PublicProfile;
